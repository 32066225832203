@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');

body {
    font-family: 'Roboto', sans-serif;
    margin: 0 auto;
    width: 100%;
    font-size: 14px;
    color: var(--main-secondary);
}

.icon-menu-dashboards {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiflow/assets/icon-dashboard.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiflow/assets/icon-dashboard.svg) no-repeat 50% 50%;
}

.icon-menu-affiliates {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiflow/assets/icon-affiliate.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiflow/assets/icon-affiliate.svg) no-repeat 50% 50%;
}

.icon-menu-commissions {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiflow/assets/icon-commission.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiflow/assets/icon-commission.svg) no-repeat 50% 50%;
}

.icon-menu-purchase {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladishare/icon-purchase.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladishare/icon-purchase.svg) no-repeat 50% 50%;
}

.icon-menu-campains {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiflow/assets/icon-campain.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiflow/assets/icon-campain.svg) no-repeat 50% 50%;
}

.icon-menu-invoices {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiflow/assets/icon-invoice.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiflow/assets/icon-invoice.svg) no-repeat 50% 50%;
}

/* .icon-menu-reports {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiflow/assets/icon-report.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiflow/assets/icon-report.svg) no-repeat 50% 50%;
} */

.icon-menu-sources {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiflow/assets/icon-source.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiflow/assets/icon-source.svg) no-repeat 50% 50%;
}

.icon-menu-apps {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-cartbon.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-cartbon.svg) no-repeat 50% 50%;
}

.icon-menu-settings {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-menu-setting.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-menu-setting.svg) no-repeat 50% 50%;
}


.icon-menu-orders {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-menu-order.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-menu-product.svg) no-repeat 50% 50%;
}

.icon-menu-products {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-menu-product.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-menu-product.svg) no-repeat 50% 50%;
}

.icon-menu-payments {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/menu-icon-payment.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/menu-icon-payment.svg) no-repeat 50% 50%;
}

.icon-menu-courses {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/ldicon-course.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/ldicon-course.svg) no-repeat 50% 50%;
}

.icon-menu-customers {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/menu-icon-customer.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/menu-icon-customer.svg) no-repeat 50% 50%;
}

.icon-menu-discounts {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-menu-discount.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-menu-discount.svg) no-repeat 50% 50%;
}

.icon-menu-reports {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-menu-report.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-menu-report.svg) no-repeat 50% 50%;
}

.icon-menu-integrates {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-menu-integrate.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-menu-integrate.svg) no-repeat 50% 50%;
}

th.sort {
    position: relative;
    cursor: pointer;
}

th.sort::before {
    content: '\ea18';
    font-family: LadiIcons;
    font-size: 7px;
    position: absolute;
    right: 5px;
    top: -3px;
    bottom: 0;
    height: 14px;
}

th.sort.sort-desc::before {
    content: '';
}

th.sort::after {
    content: '\ea17';
    font-family: LadiIcons;
    font-size: 7px;
    position: absolute;
    right: 5px;
    top: 3px;
    bottom: 0;
    height: 0;
}

th.sort.sort-asc::after {
    content: '';
}

.ladiui.page-container a.active {
    background-color: rgba(0, 0, 0, .1);
    border-radius: 2px;
    cursor: pointer;
}

.hidden {
    display: none !important;
}

.text-uppercase {
    text-transform: uppercase;
}

.clearfix {
    clear: both;
}

.ladiui.item.bg-form.bg-commissionn-plan {
    background-color: #F1F3F4;
}

.group-input-custom {
    position: relative;
}

.ladiui.btn-group.btn-group-custom {
    position: absolute;
    top: 6px;
    right: 12px;
    background: #F1F3F4;
    max-width: 60px;
    border-radius: 2px;
    text-align: center;
}

.ladiui.btn-group.btn-group-custom .btn-group .dropdown-menu {
    min-width: 0px;
    max-width: 60px;
}

.ladiui.btn-group.btn-group-custom .btn-group .dropdown-toggle {
    max-width: 60px;
    min-width: 34px;
    padding: 5px;
    height: 20px;
    line-height: 20px;
    background: #F1F3F4;
}

.ladiui.btn-group.btn-group-custom .btn-group {
    max-width: 60px;
    min-width: 34px;
}

.ladiui.btn-group.btn-group-custom .ladiui.dropdown-toggle:after {
    margin: 5px;
}

.ladiui.btn-group.btn-group-custom .ladiui.dropdown-item {
    padding: 5px;
}

#create-ladishare-comission-plan .ladiui.switch {
    margin: -3px 10px 0px 10px !important;
}

.box-commission-plan {
    margin-top: 32px;
}

.box-commission-plan .card-header {
    font-size: 16px !important;
}

.ladiui.badge {
    border-radius: 2px;
    height: 20px;
    padding: 2px 6px 2px 6px;
    color: #fff;
    font-size: 12px;
}

.ladiui.badge.badge-success {
    background-color: #41B983;
}

.ladiui.badge.badge-warning {
    background-color: #E89D35;
}

/* .ladiui.card {
    border: 1px solid #F1F3F4;
}

.ladiui.card-header {
    background-color: #F1F3F4;
    border-bottom-color: #F1F3F4;
} */

.box-commission-plan .ladiui.btn-group {
    padding: 0;
}

.box-commission-plan .ladiui.btn-group .ladiui-btn-dropdown.dropdown-toggle {
    transform: rotate(90deg);
    margin-right: -8px;
}

.ladiui.table-list-commission-plan-payment {
    margin-top: 10px;
    min-width: 0;
}

.ladiui.table-list-commission-plan-payment tr {
    border-bottom: 1px solid #EAEAEA;
}

.ladiui.table-list-commission-plan-payment tbody tr:last-child {
    border: none;
}

.ladiui.table-list-commission-plan-payment th,
.ladiui.small-label {
    font-size: 14px;
    font-weight: 400;
    color: #727272 !important;
}

.ladiui.content-left {
    width: 318px;
    max-width: 318px;
}

.ladiui.content-right {
    width: calc(100% - 318px);
    max-width: calc(100% - 318px);
    min-width: auto;
}

.ladiui .info-affiliate-detail .info-email {
    color: #727272;
}

.ladiui.group-info {
    padding: 12px 0;
}

.ladiui.group-info p {
    padding-top: 8px;
}

.ladiui.group-info p span {
    color: #41B983;
}

.ladiui.divider {
    margin: 12px -24px;
    border: 1px solid #F1F3F4;
}

.color-grey {
    color: #727272;
}

.ladiui.box-statistics {
    border-radius: 2px;
    height: 94px;
    border: 1px solid #F1F3F4;
    width: calc((100% - 72px)/4);
    margin: 12px 0;
    padding: 16px;
    box-sizing: border-box;
    align-items: center;
    display: flex;
    justify-content: inherit;
}

.ladiui.box-statistics .decrease,
.ladiui.box-statistics .increase {
    font-size: 10px;
}

.ladiui.box-statistics .increase {
    color: #41B983;
}

.ladiui.box-statistics .decrease {
    color: #D94F45;
}

.ladiui.box-statistics.box-bg-grey {
    background-color: #F1F3F4;
    height: 82px;
    width: calc((100% - 48px)/3);
}

.ladiui.new-tab-custom {
    position: relative;
    z-index: 1000;
    top: 0;
    left: 0;
    width: auto;
    height: auto;
    background-color: #fff;
    transition: width .3s;
    overflow: hidden;
}

.ladiui.new-tab-custom:hover {
    display: flex;
    width: auto;
}

.ladiui.action-history {
    margin-top: 14px;
}

.ladiui.affiliate-timeline-note {
    margin-top: 40px;
    align-items: baseline;
    justify-content: space-between;
}

.ladiui.affiliate-timeline-note>div {
    width: calc((100% - 24px)/2);
}

.ladiui.affiliate-timeline-note .box {
    border: 1px solid #F1F3F4;
    padding: 16px;
    margin-top: 8px;
    border-radius: 2px;
}

.ladiui.affiliate-timeline-note .box.box-note {
    /* min-height: 150px; */
    white-space: pre-wrap;
}

.ladiui.content-main {
    height: auto;
    min-height: 91.5vh;
}

.ladiui.table-scroll {
    overflow: auto;
}

.ladiui.table {
    min-width: auto;
}

.icon-circle {
    background-color: #fff;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ladiui.table-vertical td {
    color: var(--main-default);
}

.color-1 {
    color: var(--main-secondary) !important;
}

.color-2 {
    color: var(--main-default) !important;
}

.ladiui.subtitle-2.content-tag-text.active {
    color: var(--main-primary) !important;
}

.ladiui.title-text-form-lever2.margin-label {
    margin: 12px 68px 12px 0;
}

.edit-info-affiliate {
    color: var(--main-primary);
}

.edit-info-affiliate i {
    background-color: var(--main-primary) !important;
}

.filter-condtion-area {
    position: relative;
}

.list-filter-condition {
    display: none;
    position: absolute;
    min-width: 100px;
    background: #FFFFFF;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    border-radius: 2px;
    padding: 16px;
    margin-top: 10px;
    opacity: 1;
    z-index: 1;

}

.list-filter-condition.show {
    display: block;
}

/* .list-filter-condition label {
    width: 80px;
} */

.list-filter-condition .ladiui.dropdown-toggle {
    min-width: 110px;
}

.list-filter-condition input {
    min-width: 170px;
}

.ladiui.content-main-tool.align-item-baseline {
    height: auto !important;
    margin-top: 0;
}

.align-item-baseline {
    align-items: baseline !important;
}

.result-filter-condition {
    position: absolute;
}

.result-filter-condition span {
    margin-top: 12px;
    margin-right: 12px;
}

.ladiui.btn-tag {
    background: #F1F3F4;
    border-radius: 2px;
    height: 24px;
    color: var(--main-default);
    font-size: 12px;
    line-height: 24px;
    padding: 0 6px;
    white-space: pre;
}

.ladiui.general-setting-content label {
    min-width: 110px;
}

.ladiui.general-setting-content {
    max-width: 600px;
    position: relative;
}

.ladiui.note-content {
    position: relative;
}

.ladiui.note-content button.btn-link {
    position: absolute;
    top: -5px;
    right: -5px;
}

.affiliate-setting-content,
.email-notify-setting-content {
    width: 614px;
}

.affiliate-setting-content>div.flex-row,
.email-notify-setting-content>div.flex-row {
    align-items: baseline;
    justify-content: space-between;
}

.affiliate-setting-content>div.flex-row .label-setting label {
    font-size: 16px;
}

.affiliate-setting-content>div.flex-row .label-setting div {
    color: #6D6D6D;
}

.ladiui.summary {
    background: #F1F3F4;
    border-radius: 2px;
}

.ladiui.summary td {
    padding: 10px 18px 10px 0;
}

.ladiui.summary td span.total-record {
    color: #AFAFAF;
}

#modal-detail-commission {
    color: var(--main-default);
}

#modal-detail-commission .item-detail-commission {
    padding: 10px 0;
    flex: 1 0 31%;
    margin: 5px;
}

#modal-detail-commission .item-detail-commission label {
    color: #AFAFAF;
    font-weight: 400;
}

#modal-detail-commission .flex-item-detail-commission {
    flex: 1 0 31%;
}

.ladiui.modal .dropdown-custom .btn-group .dropdown-menu,
.ladiui.modal .dropdown-custom .btn-group .dropdown-toggle,
.ladiui.modal .dropdown-custom .btn-group {
    width: 175px !important;
}

.ladiui.modal .dropdown-custom .btn-group .dropdown-toggle span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ladiui.ladi-check-all-action a {
    font-weight: normal;
}