.report-overview {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.report-overview .item {
  width: 50%;
  padding-left: 0px;
  padding-right: 30px;
  margin-bottom: 30px;
}
.report-overview .item-top-product {
  width: 100%;
  padding-left: 0px;
  padding-right: 20px;
  margin-bottom: 20px;
}
.report-overview .chart-item {
  /* width: 50%; */
  /* border: 1px solid #b3b3b3; */
  background: #ffffff;
}

.report-overview .chart-item .head-card {
  display: flex;
  align-items: center;
  padding: 12px 0;
}
.report-overview .chart-item .head-card h3 {
  font-weight: 500;
  font-size: 15px;
}
.report-overview .chart-item .bottom-cart {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-top: 1px solid #eaedf0;
  padding: 15px 0px;
  font-weight: bold;
}
.mr-l-5 {
  margin-left: 5px;
}

.report-overview .chart-item .head-card.top-product {
  justify-content: space-between;
}

.report-overview .chart-item .head-card .item-card {
  display: flex;
  align-items: center;
}

.content-chart-utm{
  display: flex;
  /* align-items: center; */
  justify-content: center;
}

.chart-utm{
  width: 40%;
}
.table-utm{
  width: 60%;
  height: 400px;
  overflow: auto;
}
