/* style font */
.ladiui .item-title {
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: var(--main-default) !important;
}

.block {
    display: block;
}
.t-145 {
    top: 145px !important;
}
.p-0 {
    padding: 0px !important;
}
.mr-4 {
    margin-right: 4px !important;
}
.pb-4 {
    padding-bottom: 4px!important;
}
.pb-8 {
    padding-bottom: 8px !important;
}
.mb-24 {
    margin-bottom: 24px !important;
}

.mb-27 {
    margin-bottom: 27px!important;
}

.mt-24 {
    margin-top: 24px !important;
}

.w-500 {
    width: 500px !important;
}

.w-200 {
    width: 200px !important;
}

.w-150 {
    width: 150px !important;
}

.w-190 {
    width: 190px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mt-0 {
    margin-top: 0px !important;
}

.w-600 {
    width: 600px !important;
}
.w-800 {
    width: 800px !important;
}
.w-900 {
    width: 900px !important;
}
.pt-16 {
    padding-top: 16px !important;
}
.pl-16 {
    padding-left: 16px !important;
}
.pb-16 {
    padding-bottom: 16px !important;
}
.pd-0 {
    padding: 0px !important;
}

.pd-3 {
    padding: 3px !important;
}
.pb-4 {
    padding-bottom: 4px !important;
}

.m-t-16 {
    margin-top: 16px;
}

.m-r-20 {
    margin-right: 20px;
}

.w-100 {
    width: 100px !important;
}

.w-175 {
    width: 175px !important;
}

.w-576 {
    width: 576px !important;
}

.w-553 {
    width: 553px !important;
}

.w-44 {
    width: 44px !important;
}

.w-400 {
    width: 400px!important;
}
.w-440 {
    width: 440px !important;
}

.w-180 {
    width: 180px !important;
}

.w-220 {
    width: 220px !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.ml-0 {
    margin-left: 0 !important;
}
.ml-8 {
    margin-left: 8px!important;
}

.mr-0 {
    margin-right: 0 !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.pl-16 {
    padding-left: 16px !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.pr-100 {
    padding-right: 100px !important;
}

.m-12 {
    margin: 12px!important;
}

.ml-12 {
    margin-left: 12px !important;
}

.pr-16 {
    padding-right: 16px!important;
}

.pr-18 {
    padding-right: 18px!important;
}

.ml-18 {
    margin-left: 18px!important;
}

.ml-32 {
    margin-left: 32px!important;
}

.mt-16 {
    margin-top: 16px!important;
}

.mr-16 {
    margin-right: 16px !important;
}

.mt-14 {
    margin-top: 14px!important;
}


.mt-10 {
    margin-top: 10px !important;
}

.mr-8 {
    margin-right: 8px !important;
}

.mt-8 {
    margin-top: 8px !important;
}

.ml-24 {
    margin-left: 24px !important;
}

.mr-50 {
    margin-right: 50px!important;
}

.mr-24 {
    margin-right: 24px!important;
}

.mb-10 {
    margin-bottom: 10px!important;
}

.mb-12 {
    margin-bottom: 12px!important;
}

.mb-8 {
    margin-bottom: 8px!important;
}

.mb-16 {
    margin-bottom: 16px !important;
}

.mt-19 {
    margin-top: 19px!important;
}

.mt-22 {
    margin-top: 22px !important;
}

.pt-20 {
    padding-top: 20px !important;
}

.bold-500 {
    font-weight: 500!important;
}

.font-size-24 {
    font-size: 24px !important;
}

.font-size-18 {
    font-size: 18px !important;
}

.font-size-16 {
    font-size: 16px !important;
}

.font-size-14 {
    font-size: 14px !important;
}

.font-size-12 {
    font-size: 12px !important;
}

.font-size-11 {
    font-size: 11px !important;
}

.box-shadow-none {
    box-shadow: unset !important;
}

.display-none {
    display: none!important;
}

.r-0 {
    right: 0px !important;
}

.pd-5 {
    padding: 5px !important;
}

.t-40 {
    top: 40px !important
}

.mr-12 {
    margin-right: 12px !important;
}

.ml-3 {
    margin-left: 3px!important;
}

.t-300 {
    top: 300px !important;
}

.text-left {
    text-align: left !important;
}

.mr-28 {
    margin-right: 28px !important;
}

.pr-15 {
    padding-right: 15px !important;
}

.border-none {
    border: none !important;
}

.m-0 {
    margin: 0px !important;
}

.pos-rel {
    position: relative !important;
}

.bt-0 {
    bottom: 0px !important;
}

.bt-36 {
    bottom: 36px!important;
}

.pointer {
    cursor: pointer;
}

.mr-20 {
    margin-right: 20px !important;
}

.al-item-start {
    align-items: flex-start !important;
}

.wp-50 {
    width: 49% !important;
}

.flex-start {
    justify-content: flex-start!important;
}

.flex {
    display: flex !important;
    align-items: center !important;
    /* justify-content: space-between; */
}
.space-between {
    justify-content: space-between;
}

.items-baseline {
    align-items: baseline !important;
}

.w-unset {
    width: unset !important;
    padding: 0px !important;
}

.mr-10 {
    margin-right: 10px !important;
}

.mr-18 {
    margin-right: 18px;
}

.pb-8 {
    padding-bottom: 8px !important;
}

.pb-24 {
    padding-bottom: 24px !important;
}

.pt-24 {
    padding-top: 24px !important;
}

.w100 {
    width: 100% !important;
}


.pr-30 {
    padding-right: 30px !important;
}

.pl-25 {
    padding-left: 25px !important;
}

.my-24 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
}

.mr-14 {
    margin-right: 14px !important;
}

.py-12 {
    padding: 12px 0px !important;
}

.mt-34 {
    margin-top: 34px !important;
}
.mt-18 {
    margin-top: 18px;
}

.mt-12 {
    margin-top: 12px;
}

.pt-8 {
    padding-top: 8px !important;
}

.mr-12 {
    margin-right: 12px !important;
}

.mr-11 {
    margin-right: 11px;
}

.h-auto {
    height: auto !important;
}

.relative {
    position: relative;
}

.cursor-pointer {
    cursor: pointer!important;
}

.color-default {
    color: var(--main-primary) !important;
}

.py-24 {
    padding: 24px 0px !important;
}

.pl-24 {
    padding-left: 24px !important;
}

.pr-24 {
    padding-right: 24px !important;
}

.items-center {
    align-items: center !important;
}

.transparent {
    background-color: transparent !important;
}

.t-317 {
    top: 317px !important;
}

.mr-12 {
    margin-right: 12px !important;
}
.mr-4 {
    margin-right: 4px !important;
}

.w-50 {
    width: 50px !important;
}

.boder-search {
    background: #FFFFFF;
    border: 1px solid #F1F3F4;
    border-radius: 2px;
}
.px-5 {
    padding: 0px 5px!important;
}
.inline-block {
    display: inline-block !important;
}
.h-36 {
    height: 36px !important;
}
.no-border {
    border: none !important;
}
.justify-content-center {
    justify-content: center !important;
}
.space-nowrap {
    white-space: nowrap !important;
}
.position-initial {
    position: initial !important;
}
.bold-400 {
    font-weight: 400 !important;
}