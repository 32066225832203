@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap);
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, input, button, textarea, select {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
a {
    text-decoration: none;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
/*--------------------------------

LadiIcons Web Font
Generated using nucleoapp.com

-------------------------------- */
@font-face {
    font-family: 'LadiIcons';
    src: url(/static/media/LadiIcons.879856c1.eot);
    src: url(/static/media/LadiIcons.879856c1.eot) format('embedded-opentype'), url(/static/media/LadiIcons.6e3206de.woff2) format('woff2'), url(/static/media/LadiIcons.bb325b2e.woff) format('woff'), url(/static/media/LadiIcons.d344f17a.ttf) format('truetype'), url(/static/media/LadiIcons.ee3f0afe.svg) format('svg');
    font-weight: normal;
    font-style: normal;
}
/*------------------------
	base class definition
-------------------------*/
.ladi-icon {
  display: inline-block;
  font: normal normal normal 1em/1 'LadiIcons';
  speak: none;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*------------------------
  change icon size
-------------------------*/
/* relative units */
.ladi-icon-sm {
  font-size: 0.8em;
}
.ladi-icon-lg {
  font-size: 1.2em;
}
/* absolute units */
.ladi-icon-16 {
  font-size: 16px;
}
.ladi-icon-32 {
  font-size: 32px;
}
/*----------------------------------
  add a square/circle background
-----------------------------------*/
.ladi-icon-bg-square,
.ladi-icon-bg-circle {
  padding: 0.35em;
  background-color: #eee;
}
.ladi-icon-bg-circle {
  border-radius: 50%;
}
/*------------------------------------
  use icons as list item markers
-------------------------------------*/
.ladi-icon-ul {
  padding-left: 0;
  list-style-type: none;
}
.ladi-icon-ul > li {
  display: flex;
  align-items: flex-start;
  line-height: 1.4;
}
.ladi-icon-ul > li > .ladi-icon {
  margin-right: 0.4em;
  line-height: inherit;
}
/*------------------------
  spinning icons
-------------------------*/
.ladi-icon-is-spinning {
  -webkit-animation: ladi-icon-spin 2s infinite linear;
  animation: ladi-icon-spin 2s infinite linear;
}
@-webkit-keyframes ladi-icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes ladi-icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/*------------------------
  rotated/flipped icons
-------------------------*/
.ladi-icon-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.ladi-icon-rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.ladi-icon-rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}
.ladi-icon-flip-y {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0);
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.ladi-icon-flip-x {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: scale(1, -1);
  transform: scale(1, -1);
}
/*------------------------
  font icons
-------------------------*/

.icon-home::before {
    content: "\ea03";
}

.icon-dots::before {
    content: "\ea04";
}

.icon-c-add::before {
    content: "\ea05";
}

.icon-img::before {
    content: "\ea06";
}

.icon-button::before {
    content: "\ea07";
}

.icon-text::before {
    content: "\ea08";
}

.icon-paragraph::before {
    content: "\ea09";
}

.icon-list-bullet::before {
    content: "\ea0a";
}

.icon-shape-line::before {
    content: "\ea0b";
}

.icon-shape-rectangle::before {
    content: "\ea0c";
}

.icon-shape-star::before {
    content: "\ea0d";
}

.icon-html::before {
    content: "\ea0e";
}

.icon-video-player::before {
    content: "\ea0f";
}

.icon-slider::before {
    content: "\ea10";
}

.icon-gallery::before {
    content: "\ea11";
}

.icon-form::before {
    content: "\ea12";
}

.icon-time-clock::before {
    content: "\ea13";
}

.icon-section::before {
    content: "\ea14";
}

.icon-popup::before {
    content: "\ea15";
}

.icon-app-store::before {
    content: "\ea16";
}

.icon-triangle-down::before {
    content: "\ea17";
}

.icon-triangle-up::before {
    content: "\ea18";
}

.icon-publish::before {
    content: "\ea19";
}

.icon-cloud-download::before {
    content: "\ea1a";
}

.icon-preview::before {
    content: "\ea1b";
}

.icon-b-preview::before {
    content: "\ea1c";
}

.icon-undo::before {
    content: "\ea1d";
}

.icon-redo::before {
    content: "\ea1e";
}

.icon-settings::before {
    content: "\ea1f";
}

.icon-history::before {
    content: "\ea20";
}

.icon-fonts::before {
    content: "\ea21";
}

.icon-align-justify::before {
    content: "\ea22";
}

.icon-align-center::before {
    content: "\ea23";
}

.icon-align-left-2::before {
    content: "\ea24";
}

.icon-mobile::before {
    content: "\ea25";
}

.icon-pc::before {
    content: "\ea26";
}

.icon-back-arrow::before {
    content: "\ea27";
}

.icon-e-remove::before {
    content: "\ea28";
}

.icon-data-upload::before {
    content: "\ea29";
}

.icon-dots-to-close::before {
    content: "\ea2a";
}

.icon-floppy-disk::before {
    content: "\ea2b";
}

.icon-cross-vertical::before {
    content: "\ea2c";
}

.icon-hyperlink::before {
    content: "\ea2d";
}

.icon-link-broken::before {
    content: "\ea2e";
}

.icon-text-color::before {
    content: "\ea2f";
}

.icon-bold::before {
    content: "\ea30";
}

.icon-italic::before {
    content: "\ea31";
}

.icon-underline::before {
    content: "\ea32";
}

.icon-strikethrough::before {
    content: "\ea33";
}

.icon-crop::before {
    content: "\ea34";
}

.icon-duplicate::before {
    content: "\ea35";
}

/* .icon-bin::before {
    content: "\ea36";
} */

.icon-lock::before {
    content: "\ea37";
}

.icon-unlocked::before {
    content: "\ea38";
}

.icon-arrow-up::before {
    content: "\ea39";
}

.icon-arrow-down::before {
    content: "\ea3a";
}

.icon-phone::before {
    content: "\ea3b";
}

.icon-email::before {
    content: "\ea3c";
}

.icon-notify::before {
    content: "\ea3d";
}

.icon-pen-tool::before {
    content: "\ea3e";
}

.icon-themes::before {
    content: "\ea3f";
}

.icon-tag::before {
    content: "\ea40";
}

.icon-domain::before {
    content: "\ea41";
}

.icon-menu::before {
    content: "\ea42";
}

.icon-apps::before {
    content: "\ea43";
}

.icon-zoom::before {
    content: "\ea44";
}

.icon-double-arrow-right::before {
    content: "\ea45";
}

.icon-double-arrow-left::before {
    content: "\ea46";
}

.icon-up-arrow::before {
    content: "\ea47";
}

.icon-right-arrow::before {
    content: "\ea48";
}

.icon-left-arrow::before {
    content: "\ea49";
}

.icon-down-arrow::before {
    content: "\ea4a";
}

.icon-page-setting::before {
    content: "\ea4b";
}

.icon-bill::before {
    content: "\ea4c";
}

.icon-orders::before {
    content: "\ea4d";
}

.icon-products::before {
    content: "\ea4e";
}

.icon-customers::before {
    content: "\ea4f";
}

.icon-users::before {
    content: "\ea50";
}

.icon-discounts::before {
    content: "\ea51";
}

.icon-store::before {
    content: "\ea52";
}

.icon-ring::before {
    content: "\ea53";
}

.icon-maps::before {
    content: "\ea54";
}

.icon-edit::before {
    content: "\ea55";
}

.icon-intergration::before {
    content: "\ea56";
}

.icon-c-warning::before {
    content: "\ea57";
}

.icon-c-question::before {
    content: "\ea58";
}

.icon-crown::before {
    content: "\ea59";
}

.icon-print::before {
    content: "\ea5b";
}

.icon-hot-key::before {
    content: "\ea5c";
}

.icon-keyboard::before {
    content: "\ea5d";
}

.icon-blog::before {
    content: "\ea5e";
}

.icon-chat-support::before {
    content: "\ea5f";
}

.icon-b-chat::before {
    content: "\ea60";
}

.icon-logo-facebook::before {
    content: "\ea61";
}

.icon-logout::before {
    content: "\ea62";
}

.icon-key::before {
    content: "\ea63";
}

.icon-access-key::before {
    content: "\ea64";
}

.icon-project::before {
    content: "\ea65";
}

.icon-affilate::before {
    content: "\ea66";
}

.icon-referral::before {
    content: "\ea67";
}

.icon-dock-bottom::before {
    content: "\ea68";
}

.icon-dock-right::before {
    content: "\ea69";
}

.icon-dock-left::before {
    content: "\ea6a";
}

.icon-dock-top::before {
    content: "\ea6b";
}

.icon-format-left::before {
    content: "\ea6c";
}

.icon-format-right::before {
    content: "\ea6d";
}

.icon-align-left::before {
    content: "\ea6e";
}

.icon-align-right::before {
    content: "\ea6f";
}

.icon-align-top::before {
    content: "\ea70";
}

.icon-align-bottom::before {
    content: "\ea71";
}

.icon-distribute-vertical::before {
    content: "\ea72";
}

.icon-distribute-horizontal::before {
    content: "\ea73";
}

.icon-align-center-vertical::before {
    content: "\ea74";
}

.icon-align-center-horizontal::before {
    content: "\ea75";
}

.icon-caps-all::before {
    content: "\ea76";
}

.icon-check-single::before {
    content: "\ea77";
}

.icon-d-check::before {
    content: "\ea78";
}

.icon-g-check::before {
    content: "\ea79";
}

.icon-delivery::before {
    content: "\ea7a";
}

.icon-palette::before {
    content: "\ea7b";
}

.icon-border-radius::before {
    content: "\ea7c";
}

.icon-sync::before {
    content: "\ea7d";
}

.icon-copy::before {
    content: "\ea7e";
}

.icon-flip-vertical::before {
    content: "\ea7f";
}

.icon-flip-horizontal::before {
    content: "\ea80";
}

.icon-triangle-right::before {
    content: "\ea81";
}

.icon-triangle-left::before {
    content: "\ea82";
}

.icon-refresh::before {
    content: "\ea83";
}

.icon-filter::before {
    content: "\ea84";
}

.icon-mask::before {
    content: "\ea85";
}

.icon-backward::before {
    content: "\ea86";
}

.icon-forward::before {
    content: "\ea87";
}

.icon-rotate::before {
    content: "\ea88";
}

.icon-grid-view::before {
    content: "\ea89";
}

.icon-element-repeater::before {
    content: "\ea8a";
}

.icon-spin-lucky::before {
    content: "\ea8b";
}

.icon-ab-testing::before {
    content: "\ea8c";
}

.icon-report::before {
    content: "\ea8d";
}

.icon-inbox::before {
    content: "\ea8e";
}

.icon-at-sign::before {
    content: "\ea8f";
}

.icon-send-message::before {
    content: "\ea90";
}

.icon-chat-user::before {
    content: "\ea91";
}

.icon-credit-card::before {
    content: "\ea92";
}

.icon-re-open::before {
    content: "\ea93";
}

.icon-add-user::before {
    content: "\ea94";
}

.icon-image::before {
    content: "\ea95";
}

.icon-attach::before {
    content: "\ea96";
}

.icon-smile::before {
    content: "\ea97";
}

.icon-share::before {
    content: "\ea98";
}

.icon-visit-link::before {
    content: "\ea99";
}

.icon-notes::before {
    content: "\ea9a";
}

.icon-logo-fb-simple::before {
    content: "\ea9b";
}

.icon-campaign-flow::before {
    content: "\ea9c";
}

.icon-logo-messenger::before {
    content: "\ea9d";
}

.icon-cart-add::before {
    content: "\ea9e";
}

.icon-cart::before {
    content: "\ea9f";
}

.icon-shopping-cart::before {
    content: "\eaa0";
}

.icon-basket::before {
    content: "\eaa1";
}

.icon-pin::before {
    content: "\eaa2";
}

.icon-move-item::before {
    content: "\eaa4";
}

.icon-move-2::before {
    content: "\eaa5";
}

.icon-remove-background::before {
    content: "\eaa6";
}

.icon-status::before {
    content: "\eaa7";
}

.icon-align-right-2::before {
    content: "\eaa8";
}

.icon-antenna::before {
    content: "\eaa9";
}

.icon-broadcast::before {
    content: "\eaaa";
}

.icon-c-delete::before {
    content: "\eaab";
}

.icon-input::before {
    content: "\eaac";
}

.icon-sms::before {
    content: "\eaad";
}

.icon-layer::before {
    content: "\eaae";
}

.icon-discount-2::before {
    content: "\eaaf";
}


body {
    font-family: 'Roboto', sans-serif;
    margin: 0 auto;
    width: 100%;
    font-size: 14px;
    color: var(--main-secondary);
}

.icon-menu-dashboards {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiflow/assets/icon-dashboard.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiflow/assets/icon-dashboard.svg) no-repeat 50% 50%;
}

.icon-menu-affiliates {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiflow/assets/icon-affiliate.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiflow/assets/icon-affiliate.svg) no-repeat 50% 50%;
}

.icon-menu-commissions {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiflow/assets/icon-commission.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiflow/assets/icon-commission.svg) no-repeat 50% 50%;
}

.icon-menu-purchase {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladishare/icon-purchase.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladishare/icon-purchase.svg) no-repeat 50% 50%;
}

.icon-menu-campains {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiflow/assets/icon-campain.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiflow/assets/icon-campain.svg) no-repeat 50% 50%;
}

.icon-menu-invoices {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiflow/assets/icon-invoice.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiflow/assets/icon-invoice.svg) no-repeat 50% 50%;
}

/* .icon-menu-reports {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiflow/assets/icon-report.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiflow/assets/icon-report.svg) no-repeat 50% 50%;
} */

.icon-menu-sources {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiflow/assets/icon-source.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiflow/assets/icon-source.svg) no-repeat 50% 50%;
}

.icon-menu-apps {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-cartbon.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-cartbon.svg) no-repeat 50% 50%;
}

.icon-menu-settings {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-menu-setting.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-menu-setting.svg) no-repeat 50% 50%;
}


.icon-menu-orders {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-menu-order.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-menu-product.svg) no-repeat 50% 50%;
}

.icon-menu-products {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-menu-product.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-menu-product.svg) no-repeat 50% 50%;
}

.icon-menu-payments {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/menu-icon-payment.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/menu-icon-payment.svg) no-repeat 50% 50%;
}

.icon-menu-courses {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/ldicon-course.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/ldicon-course.svg) no-repeat 50% 50%;
}

.icon-menu-customers {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/menu-icon-customer.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/menu-icon-customer.svg) no-repeat 50% 50%;
}

.icon-menu-discounts {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-menu-discount.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-menu-discount.svg) no-repeat 50% 50%;
}

.icon-menu-reports {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-menu-report.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-menu-report.svg) no-repeat 50% 50%;
}

.icon-menu-integrates {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-menu-integrate.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-menu-integrate.svg) no-repeat 50% 50%;
}

th.sort {
    position: relative;
    cursor: pointer;
}

th.sort::before {
    content: '\ea18';
    font-family: LadiIcons;
    font-size: 7px;
    position: absolute;
    right: 5px;
    top: -3px;
    bottom: 0;
    height: 14px;
}

th.sort.sort-desc::before {
    content: '';
}

th.sort::after {
    content: '\ea17';
    font-family: LadiIcons;
    font-size: 7px;
    position: absolute;
    right: 5px;
    top: 3px;
    bottom: 0;
    height: 0;
}

th.sort.sort-asc::after {
    content: '';
}

.ladiui.page-container a.active {
    background-color: rgba(0, 0, 0, .1);
    border-radius: 2px;
    cursor: pointer;
}

.hidden {
    display: none !important;
}

.text-uppercase {
    text-transform: uppercase;
}

.clearfix {
    clear: both;
}

.ladiui.item.bg-form.bg-commissionn-plan {
    background-color: #F1F3F4;
}

.group-input-custom {
    position: relative;
}

.ladiui.btn-group.btn-group-custom {
    position: absolute;
    top: 6px;
    right: 12px;
    background: #F1F3F4;
    max-width: 60px;
    border-radius: 2px;
    text-align: center;
}

.ladiui.btn-group.btn-group-custom .btn-group .dropdown-menu {
    min-width: 0px;
    max-width: 60px;
}

.ladiui.btn-group.btn-group-custom .btn-group .dropdown-toggle {
    max-width: 60px;
    min-width: 34px;
    padding: 5px;
    height: 20px;
    line-height: 20px;
    background: #F1F3F4;
}

.ladiui.btn-group.btn-group-custom .btn-group {
    max-width: 60px;
    min-width: 34px;
}

.ladiui.btn-group.btn-group-custom .ladiui.dropdown-toggle:after {
    margin: 5px;
}

.ladiui.btn-group.btn-group-custom .ladiui.dropdown-item {
    padding: 5px;
}

#create-ladishare-comission-plan .ladiui.switch {
    margin: -3px 10px 0px 10px !important;
}

.box-commission-plan {
    margin-top: 32px;
}

.box-commission-plan .card-header {
    font-size: 16px !important;
}

.ladiui.badge {
    border-radius: 2px;
    height: 20px;
    padding: 2px 6px 2px 6px;
    color: #fff;
    font-size: 12px;
}

.ladiui.badge.badge-success {
    background-color: #41B983;
}

.ladiui.badge.badge-warning {
    background-color: #E89D35;
}

/* .ladiui.card {
    border: 1px solid #F1F3F4;
}

.ladiui.card-header {
    background-color: #F1F3F4;
    border-bottom-color: #F1F3F4;
} */

.box-commission-plan .ladiui.btn-group {
    padding: 0;
}

.box-commission-plan .ladiui.btn-group .ladiui-btn-dropdown.dropdown-toggle {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    margin-right: -8px;
}

.ladiui.table-list-commission-plan-payment {
    margin-top: 10px;
    min-width: 0;
}

.ladiui.table-list-commission-plan-payment tr {
    border-bottom: 1px solid #EAEAEA;
}

.ladiui.table-list-commission-plan-payment tbody tr:last-child {
    border: none;
}

.ladiui.table-list-commission-plan-payment th,
.ladiui.small-label {
    font-size: 14px;
    font-weight: 400;
    color: #727272 !important;
}

.ladiui.content-left {
    width: 318px;
    max-width: 318px;
}

.ladiui.content-right {
    width: calc(100% - 318px);
    max-width: calc(100% - 318px);
    min-width: auto;
}

.ladiui .info-affiliate-detail .info-email {
    color: #727272;
}

.ladiui.group-info {
    padding: 12px 0;
}

.ladiui.group-info p {
    padding-top: 8px;
}

.ladiui.group-info p span {
    color: #41B983;
}

.ladiui.divider {
    margin: 12px -24px;
    border: 1px solid #F1F3F4;
}

.color-grey {
    color: #727272;
}

.ladiui.box-statistics {
    border-radius: 2px;
    height: 94px;
    border: 1px solid #F1F3F4;
    width: calc((100% - 72px)/4);
    margin: 12px 0;
    padding: 16px;
    box-sizing: border-box;
    align-items: center;
    display: flex;
    justify-content: inherit;
}

.ladiui.box-statistics .decrease,
.ladiui.box-statistics .increase {
    font-size: 10px;
}

.ladiui.box-statistics .increase {
    color: #41B983;
}

.ladiui.box-statistics .decrease {
    color: #D94F45;
}

.ladiui.box-statistics.box-bg-grey {
    background-color: #F1F3F4;
    height: 82px;
    width: calc((100% - 48px)/3);
}

.ladiui.new-tab-custom {
    position: relative;
    z-index: 1000;
    top: 0;
    left: 0;
    width: auto;
    height: auto;
    background-color: #fff;
    transition: width .3s;
    overflow: hidden;
}

.ladiui.new-tab-custom:hover {
    display: flex;
    width: auto;
}

.ladiui.action-history {
    margin-top: 14px;
}

.ladiui.affiliate-timeline-note {
    margin-top: 40px;
    align-items: baseline;
    justify-content: space-between;
}

.ladiui.affiliate-timeline-note>div {
    width: calc((100% - 24px)/2);
}

.ladiui.affiliate-timeline-note .box {
    border: 1px solid #F1F3F4;
    padding: 16px;
    margin-top: 8px;
    border-radius: 2px;
}

.ladiui.affiliate-timeline-note .box.box-note {
    /* min-height: 150px; */
    white-space: pre-wrap;
}

.ladiui.content-main {
    height: auto;
    min-height: 91.5vh;
}

.ladiui.table-scroll {
    overflow: auto;
}

.ladiui.table {
    min-width: auto;
}

.icon-circle {
    background-color: #fff;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ladiui.table-vertical td {
    color: var(--main-default);
}

.color-1 {
    color: var(--main-secondary) !important;
}

.color-2 {
    color: var(--main-default) !important;
}

.ladiui.subtitle-2.content-tag-text.active {
    color: var(--main-primary) !important;
}

.ladiui.title-text-form-lever2.margin-label {
    margin: 12px 68px 12px 0;
}

.edit-info-affiliate {
    color: var(--main-primary);
}

.edit-info-affiliate i {
    background-color: var(--main-primary) !important;
}

.filter-condtion-area {
    position: relative;
}

.list-filter-condition {
    display: none;
    position: absolute;
    min-width: 100px;
    background: #FFFFFF;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    border-radius: 2px;
    padding: 16px;
    margin-top: 10px;
    opacity: 1;
    z-index: 1;

}

.list-filter-condition.show {
    display: block;
}

/* .list-filter-condition label {
    width: 80px;
} */

.list-filter-condition .ladiui.dropdown-toggle {
    min-width: 110px;
}

.list-filter-condition input {
    min-width: 170px;
}

.ladiui.content-main-tool.align-item-baseline {
    height: auto !important;
    margin-top: 0;
}

.align-item-baseline {
    align-items: baseline !important;
}

.result-filter-condition {
    position: absolute;
}

.result-filter-condition span {
    margin-top: 12px;
    margin-right: 12px;
}

.ladiui.btn-tag {
    background: #F1F3F4;
    border-radius: 2px;
    height: 24px;
    color: var(--main-default);
    font-size: 12px;
    line-height: 24px;
    padding: 0 6px;
    white-space: pre;
}

.ladiui.general-setting-content label {
    min-width: 110px;
}

.ladiui.general-setting-content {
    max-width: 600px;
    position: relative;
}

.ladiui.note-content {
    position: relative;
}

.ladiui.note-content button.btn-link {
    position: absolute;
    top: -5px;
    right: -5px;
}

.affiliate-setting-content,
.email-notify-setting-content {
    width: 614px;
}

.affiliate-setting-content>div.flex-row,
.email-notify-setting-content>div.flex-row {
    align-items: baseline;
    justify-content: space-between;
}

.affiliate-setting-content>div.flex-row .label-setting label {
    font-size: 16px;
}

.affiliate-setting-content>div.flex-row .label-setting div {
    color: #6D6D6D;
}

.ladiui.summary {
    background: #F1F3F4;
    border-radius: 2px;
}

.ladiui.summary td {
    padding: 10px 18px 10px 0;
}

.ladiui.summary td span.total-record {
    color: #AFAFAF;
}

#modal-detail-commission {
    color: var(--main-default);
}

#modal-detail-commission .item-detail-commission {
    padding: 10px 0;
    flex: 1 0 31%;
    margin: 5px;
}

#modal-detail-commission .item-detail-commission label {
    color: #AFAFAF;
    font-weight: 400;
}

#modal-detail-commission .flex-item-detail-commission {
    flex: 1 0 31%;
}

.ladiui.modal .dropdown-custom .btn-group .dropdown-menu,
.ladiui.modal .dropdown-custom .btn-group .dropdown-toggle,
.ladiui.modal .dropdown-custom .btn-group {
    width: 175px !important;
}

.ladiui.modal .dropdown-custom .btn-group .dropdown-toggle span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ladiui.ladi-check-all-action a {
    font-weight: normal;
}
.ladiui.list.inline {
    margin: 0;
    padding: 0;
    display: inline-block;
}

.ladiui.list.inline > li {
    display: inline-block;
}

.ladiui.list.inline > li > a {
    padding: 10px;
}

.ladiui.breadcrumbs {
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 15px 0;
}

.ladiui.breadcrumbs > li {
    display: inline-block;
    padding: 5px;
}

.ladiui.breadcrumbs > li:first-child:before {
    content: '';
    margin: 0;
}

.ladiui.breadcrumbs > li:before {
    font: normal normal normal 1em/1 'LadiIcons';
    content: '\ea21';
    margin-left: 0;
    margin-right: 15px;
    font-size: 8px;
    color: #8697a8;
}

.ladiui.breadcrumbs > li,
.ladiui.breadcrumbs > li > a {
    color: #313942;
    font-size: 14px;
}

.ladiui.breadcrumbs > li.active {
    font-weight: 700;
}

.ladiui-input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}

/* .ladiui-input-group-prepend {
	margin-left: -1px
} */

.ladiui-input-group .ladiui.input {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
}

.ladiui-input-group-text {
    display: flex;
    align-items: center;
    padding: 6px 12px;
    margin-bottom: 0;
    font-weight: 400;
    line-height: 26px;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #fff;
    border: 1px solid #ebedf2;
    border-radius: 4px;
    border-right: 0;
}

.ladiui-input-group > .ladiui-input-group-append:last-child > .ladiui-input-group-text:not(:last-child),
.ladiui-input-group > .ladiui-input-group-append:not(:last-child) > .ladiui-input-group-text,
.ladiui-input-group > .ladiui-input-group-prepend > .ladiui-input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.ladiui-input-group > .ladiui.input:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.ladiui.select {
    display: block;
    width: 100%;
    padding: 6px 12px;
    font-weight: 400;
    height: 40px;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ebedf2;
    border-radius: 4px;
    transition: border-color 0.15s ease-out;
}

.ladiui.textarea {
    display: block;
    width: 100%;
    padding: 6px 12px;
    font-weight: 400;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ebedf2;
    min-height: 120px;
    border-radius: 4px;
    transition: border-color 0.15s ease-out, box-shadow 0.15s ease-out;
}

.ladiui.form-group {
    margin-bottom: 24px;
    width: 100%;
}

.clearfix {
    clear: both;
}

/* .ladiui.input.focus,
.ladiui.input:focus,
.ladiui.select.dropdown.focus,
.ladiui.textarea:focus {
	border-color: #1853fa;
	outline: 0
} */

.ladiui.dropdown {
    cursor: pointer;
    position: relative;
    display: inline-block;
    outline: 0;
    text-align: left;
    transition: box-shadow 0.1s ease, width 0.1s ease;
    -webkit-tap-highlight-color: transparent;
}

.ladiui.select.dropdown .menu {
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-overflow-scrolling: touch;
    width: auto;
    outline: 0;
    margin: 2px -1px;
    border-radius: 0 0 0.28571429rem 0.28571429rem;
    /* box-shadow: 0 2px 3px 0 rgba(34, 36, 38, .15); */
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    transition: opacity 0.1s ease;
}

.ladiui.dropdown > .text {
    display: inline-block;
    transition: none;
    padding: 6px 30px 6px 12px;
    width: 100%;
    z-index: 100;
    position: relative;
}

.ladiui.select.dropdown {
    cursor: pointer;
    word-wrap: break-word;
    line-height: 1em;
    white-space: normal;
    outline: 0;
    left: 0;
    top: 1px;
    -webkit-transform: rotateZ(0);
    transform: rotateZ(0);
    background: #fff;
    display: inline-block;
    line-height: 27px;
    border: 1px solid #ebedf2;
    border-radius: 0.28571429rem;
    transition: box-shadow 0.1s ease, width 0.1s ease;
    position: relative;
}

.ladiui.select.dropdown:after {
    content: '\ea4a';
    width: 30px;
    height: 30px;
    position: absolute;
    font-family: 'LadiIcons';
    top: 5px;
    right: -8px;
    font-size: 13px;
}

.ladiui.dropdown .menu.open {
    display: block;
    left: 0;
    visibility: visible;
    opacity: 1;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: top;
    transform-origin: top;
    transition: -webkit-transform 0.4s ease;
    transition: transform 0.4s ease;
    transition: transform 0.4s ease, -webkit-transform 0.4s ease;
}

.ladiui.dropdown .menu {
    cursor: auto;
    position: absolute;
    display: none;
    outline: 0;
    top: 100%;
    min-width: -webkit-max-content;
    min-width: max-content;
    margin: 0;
    padding: 0 0;
    background: #fff;
    font-size: 1em;
    text-shadow: none;
    text-align: left;
    box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0.28571429rem;
    transition: opacity 0.1s ease;
    z-index: 11;
    will-change: transform, opacity;
    transition: opacity 0.6s, visibility 0.6s;
}

.ladiui.dropdown .menu > .item {
    position: relative;
    cursor: pointer;
    display: block;
    border: none;
    height: auto;
    text-align: left;
    border-top: none;
    line-height: 1em;
    color: rgba(0, 0, 0, 0.87);
    padding: 0.78571429rem 1.14285714rem !important;
    font-size: 1rem;
    text-transform: none;
    font-weight: 400;
    box-shadow: none;
    -webkit-touch-callout: none;
}

/* .ladiui.input,
.ladiui.select {
	box-shadow: 0 8px 16px 0 rgba(169, 194, 209, .1);
	-webkit-box-shadow: 0 8px 16px 0 rgba(169, 194, 209, .1);
	color: #313942
} */

/* .ladiui.input.error,
.ladiui.select.error {
	border-color: #d50000;
} */

.ladiui.fixed-sidebar .ladiui-aside {
    position: fixed;
    width: 200px;
    top: 57px;
}

.ladiui.fixed-sidebar .ladiui-main {
    width: calc(100% - 200px);
    float: right;
    padding-bottom: 40px;
}

.ladiui.datepicker {
    cursor: pointer;
    word-wrap: break-word;
    line-height: 1em;
    white-space: normal;
    outline: 0;
    left: 0;
    -webkit-transform: rotateZ(0);
    transform: rotateZ(0);
    background: #fff;
    display: inline-block;
    line-height: 25px;
    border: 1px solid #ebedf2;
    border-radius: 0.28571429rem;
    transition: box-shadow 0.1s ease, width 0.1s ease;
    position: relative;
    padding: 6px 30px 6px 12px;
}

.ladiui.datepicker:after {
    content: '\ea6b';
    width: 30px;
    height: 30px;
    position: absolute;
    font-family: 'LadiIcons';
    top: 5px;
    right: -5px;
    font-size: 16px;
}

.ladiui.datepicker .date-value {
    color: #313942;
}

/* .ladiui.dropdown-menu { */
/* position: relative;
	display: inline-block;
	background: #fff;
	border-radius: 5px;
	z-index: 1;
	top: 2px
} */

.ladiui.dropdown-menu.rounded {
    border: 1px solid #ebedf2;
}

.ladiui.dropdown-menu-item {
    color: #313942;
    height: 38px;
    padding: 0 12px;
    border: none;
    cursor: pointer;
    line-height: 38px;
}

.ladiui.dropdown-menu-item i {
    font-size: 13px;
    margin-left: 4px;
}

.ladiui.dropdown-submenu {
    display: none;
    position: absolute;
    border-radius: 5px;
    min-width: 160px;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    background: #fff;
    top: 43px;
}

.ladiui.dropdown-submenu a {
    color: #313942;
    padding: 6px 12px;
    text-decoration: none;
    display: block;
    text-align: left;
    line-height: 34px;
}

.ladiui.dropdown-submenu a:hover {
    background-color: #ebedf2;
    border: none;
}

/* .ladiui-modal {
	position: fixed;
	width: 100%;
	height: 100vh;
	top: 0px;
	left: 0px;
	overflow: hidden;
	opacity: 1;
	transition: all 150ms linear;
	z-index: 15;
	outline: 0;
	display: none;
	background-color: rgba(0, 0, 0, 0.1);
}

.ladiui-modal-content {
	top: calc(5% - 5px);
	left: 0;
	right: 0;
	max-width: 900px;
	margin: 0 auto;
	box-shadow: 0 5px 100px rgba(6, 21, 40, .5);
	background: #fff;
	border-radius: 8px;
	z-index: 21;
	position: relative;
}

.ladiui-modal-header {
	padding: 15px 20px;
	border-bottom: 1px solid rgba(0, 0, 0, .02);
	background: rgba(6, 21, 40, .05);
	position: relative
} */

.ladiui-modal-header i.icon-e-remove {
    font-size: 12px;
}

.ladiui-modal-title {
    font-size: 16px;
    font-weight: 600;
}

.ladiui-close-modal {
    position: absolute;
    top: 3px;
    right: 0;
    font-size: 18px;
    padding: 11px;
    cursor: pointer;
    opacity: 1;
    transition: opacity 150ms linear;
    background: 0 0;
    border: 0;
}

.ladiui-modal-header h3 {
    margin: 0;
    font-size: 15px;
    font-weight: 600;
}

.ladiui-modal-nav {
    border-bottom: 1px solid rgba(6, 21, 40, 0.05);
    height: 55px;
    position: relative;
    padding-top: 10px;
}

.ladiui-modal-container {
    padding: 20px;
    vertical-align: top;
    background: #fff;
    display: inline-block;
    width: calc(100% - 225px);
}

.ladiui-modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(53, 53, 53, 0.16);
    z-index: 14;
    height: 100%;
    width: 100%;
}

.visible {
    display: block !important;
}

.hide {
    display: none !important;
}

.ladiui-tabs {
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.ladiui-tabs li {
    float: left;
    list-style: none;
    padding: 10px 20px;
    border-radius: 10px 10px 0 0;
    margin-right: 5px;
    margin-top: 5px;
    cursor: pointer;
}

.ladiui-tabs li {
    display: inline-block;
}

/* .ladiui-tabs li.active a {
	color: #1853fa;
	border-bottom: 2px solid #1853fa;
	padding-bottom: 5px
} */

/* .ladiui-tabs li a {
	color: #313942;
	text-decoration: none
} */

.ladiui-tab-content {
    padding: 15px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 72vh;
}

.ladiui-tab-item {
    display: none;
}

.ladiui-tab-item.active {
    display: block;
}

.tox .tox-notification {
    display: none !important;
}

.ladiui-col-4 {
    width: 40%;
    float: left;
}

.ladiui-col-8 {
    width: 60%;
    float: right;
}

.ladiui-widget {
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
}

.ladiui-widget .ladiui-widget-head {
    height: 40px;
    background: rgba(235, 240, 243, 0.1);
}

.ladiui-widget .ladiui-widget-head h3 {
    line-height: 40px;
    display: block;
    padding: 0;
    margin: 0;
    font-size: 15px;
    text-indent: 20px;
}

.ladiui-widget-body ul {
    margin: 0;
    padding: 0;
}

.ladiui-widget-body > ul > li:last-child {
    border-bottom: 0;
}

.ladiui-widget-body > ul > li {
    border-bottom: 1px solid #ebeef0;
    box-sizing: border-box;
}

.ladiui-widget-body > ul > li > a {
    background: #fff;
    color: #313942;
    box-shadow: none;
    transition: none;
    display: block;
    padding: 10px 12px;
    font-size: 15px;
    font-weight: 500;
    line-height: 45px;
}

.pull-right {
    float: right;
}

.pull-left {
    float: left;
}

.ladiui-form-group > .ladi-col {
    display: block;
}

/* .ladiui-button-upload input[type=file] {
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
	width: 100%;
	height: 40px
} */

.ladiui-select-options {
    height: 40px;
    border-radius: 4px;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    -webkit-box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.1);
    color: #313942;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ebedf2;
    position: relative;
    min-width: 90px;
    z-index: 5;
}

.ladiui-select-options > ul {
    display: none;
    position: absolute;
    top: 41px;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    background: #fff;
    border-radius: 4px;
    z-index: 5;
    min-width: 90px;
    width: 100%;
}

.ladiui-select-options > .ladiui-select-init {
    display: block;
    cursor: pointer;
    width: 100%;
    text-indent: 15px;
    border-radius: 4px;
    padding-right: 25px;
    line-height: 38px;
    -webkit-user-select: none;
    user-select: none;
}

.ladiui-select-options > .ladiui-select-init:hover,
.ladiui-select-options > .ladiui-select-init:focus,
.ladiui-select-options:hover,
.ladiui-select-options:focus {
    border-color: #1853fa;
    outline: 0;
    transition: border-color 0.15s ease-out;
}

.ladiui-select-options > .ladiui-select-init:after {
    content: '\ea4a';
    font-family: 'LadiIcons';
    font-size: 12px;
    top: 0px;
    right: 10px;
    position: absolute;
    z-index: -1;
}

.ladiui-dropdown > a {
    position: relative;
}

.ladiui-select-options > ul {
    padding: 0px;
    margin: 0px;
}

.ladiui-select-options > ul > li {
    list-style: none;
    cursor: pointer;
}

.ladiui-select-options > ul > li > a {
    cursor: pointer;
    display: block;
    color: #313942;
    width: 100%;
    padding: 0px 12px;
    line-height: 40px;
}

.ladiui-select-options > ul > li > a:hover {
    background: #f8fafb;
}

/* Animations to fade the snackbar in and out */

@-webkit-keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }

    to {
        bottom: 30px;
        opacity: 1;
    }
}

@keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }

    to {
        bottom: 30px;
        opacity: 1;
    }
}

@-webkit-keyframes fadeout {
    from {
        bottom: 30px;
        opacity: 1;
    }

    to {
        bottom: 0;
        opacity: 0;
    }
}

@keyframes fadeout {
    from {
        bottom: 30px;
        opacity: 1;
    }

    to {
        bottom: 0;
        opacity: 0;
    }
}

.ladiui-row > .col-50:first-child {
    margin-left: 0px;
}

.ladiui-row > .col-50:last-child {
    margin-right: 0px;
    float: right;
}

.ladiui-alert.error {
    font-size: 12px;
    color: #d50000;
    margin-top: 2px;
}

.ladiui-alert.error::before {
    content: '*';
    color: #d50000;
    padding-right: 5px;
}

.ladiui-spinner {
    margin: 2px auto 0;
    width: 70px;
    text-align: center;
}

.ladiui-spinner > div {
    width: 12px;
    height: 12px;
    background-color: #1853fa;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.8s infinite ease-out both;
    animation: sk-bouncedelay 1.8s infinite ease-out both;
}

.ladiui-spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.ladiui-spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1);
    }
}

@keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

/*---------------LADIUI CARD-----------------*/

/* .ladiui.card {
	box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.1);
	background-color: #ffffff;
	border-radius: 4px;
}

.ladiui.card .card-header {
	padding: 15px 20px;
	border-bottom: 1px solid #eaedf0;
	background: rgba(169, 194, 209, 0);
	;
	position: relative;
}

.ladiui.card .card-header h3 {
	padding: 0px;
	margin: 0px;
	font-size: 15px;
	font-weight: 500;
} */

.flex {
    display: flex;
}

.ladiui-row > .col {
    width: 100%;
    margin: 24px;
}

.ladiui-row > .col:first-child {
    margin-left: 0px;
}

.ladiui-row > .col:last-child {
    margin-right: 0px;
}

.fluid {
    padding: 0px !important;
    margin: 0px;
}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/* [type="radio"]:checked,
[type="radio"]:not(:checked) {
	position: absolute;
	left: -9999px;
}

[type="radio"]:checked+label,
[type="radio"]:not(:checked)+label {
	position: relative;
	padding-left: 28px;
	cursor: pointer;
	line-height: 20px;
	display: inline-block;
} */

/* [type="radio"]:not(:checked)+label:before {
	content: '';
	position: absolute;
	left: -1px;
	top: -1px;
	width: 18px;
	height: 18px;
	border: 1px solid #ddd;
	border-radius: 100%;
	background: #fff;
	color: #fff;
} */

/* [type="radio"]:checked+label:before {
	content: '';
	position: absolute;
	left: -1px;
	top: -1px;
	width: 18px;
	height: 18px;
	border: 1px solid #2977dd;
	border-radius: 100%;
	background: #2977dd;
	color: #fff;
} */

/* [type="radio"]:checked+label:after,
[type="radio"]:not(:checked)+label:after {
	content: '';
	width: 5px;
	height: 5px;
	position: absolute;
	background: #fff;
	top: 6px;
	left: 7px;
	border-radius: 100%;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
} */

/* [type="radio"]:not(:checked)+label:after {
	opacity: 0;
	-webkit-transform: scale(0);
	transform: scale(0);
}

[type="radio"]:checked+label:after {
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
} */

.loader {
    position: relative;
}

.loader:after {
    content: '';
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 200;
}

body.loader::before {
    top: calc(50vh - 20px);
    position: fixed;
    z-index: 100;
}

.ladiui-modal-body {
    padding: 15px 20px;
}

.ladiui-modal-content.confirm {
    max-width: 400px;
}

.ladiui-modal-content.confirm .button {
    min-width: 90px;
}

.ladiui-page-loader .background {
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: placeHolderShimmer;
    -webkit-animation-timing-function: linear;
    background: #f6f7f9;
    background-image: linear-gradient(to right, #f6f7f9 0%, #e9ebee 20%, #f6f7f9 40%, #f6f7f9 100%);
    background-repeat: no-repeat;
    background-size: 800px 104px;
    height: 104px;
    position: relative;
    margin-bottom: 15px;
}

.ladiui-page-loader {
    padding: 12px;
    max-width: 100%;
    height: auto;
    background-color: #fff;
    border-radius: 3px;
    padding-bottom: 20px;
}

.ladiui-page-loader .background div {
    background: #fff;
    height: 6px;
    left: 0;
    position: absolute;
    right: 0;
}

.ladiui-page-loader div.a {
    height: 40px;
    left: 40px;
    right: auto;
    top: 0;
    width: 8px;
}

.ladiui-page-loader div.b {
    height: 8px;
    left: 48px;
    top: 0;
}

.ladiui-page-loader div.c {
    left: 90%;
    top: 8px;
}

.ladiui-page-loader div.d {
    height: 12px;
    left: 48px;
    top: 14px;
}

.ladiui-page-loader div.e {
    left: 95%;
    top: 26px;
}

.ladiui-page-loader div.f {
    height: 10px;
    left: 48px;
    top: 32px;
}

.ladiui-page-loader div.g {
    height: 20px;
    top: 40px;
}

.ladiui-page-loader div.h {
    left: 85%;
    top: 60px;
}

.ladiui-page-loader div.i {
    height: 13px;
    top: 66px;
}

.ladiui-page-loader div.j {
    left: 440px;
    top: 79px;
}

.ladiui-page-loader div.k {
    height: 13px;
    top: 85px;
}

.ladiui-page-loader div.l {
    left: 178px;
    top: 98px;
}

@-webkit-keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}

.ladiui.icon::before {
    font-family: 'LadiIcons';
    font-size: 12px;
    padding-right: 5px;
    top: 1px;
    position: relative;
}

/* .ladiui-link {
	color: #1853fa !important;
}

.ladiui-link:hover {
	text-decoration: underline;
} */

.no-shadow {
    box-shadow: none;
    -webkit-box-shadow: none;
}

::-webkit-input-placeholder {
    color: #b3b3b3;
    opacity: 1;
    font-weight: 300;
}

::placeholder {
    color: #b3b3b3;
    opacity: 1;
    font-weight: 300;
}

:-ms-input-placeholder {
    color: #b3b3b3;
    font-weight: 300;
}

::-ms-input-placeholder {
    color: #b3b3b3;
    font-weight: 300;
}

.btn-order-table {
    padding: 0px 10px;
    font-size: 14px;
    line-height: 32px;
}

.btn-add-product {
    color: var(--main-primary);
    font-size: 14px;
    line-height: 20px;
}

.center-btn {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 200px;
}

.footer-order {
    padding-top: 24px;
    border-top: 1px solid #F1F3F4;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.footer-order .item-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0px;
}

.w-49-p {
    width: 49% !important;
}

.title-lable {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.footer-lable {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-left: 10px;
}

.item-product-add-new {
    padding-top: 20px;
    cursor: pointer;
}

.item-product-add-new:first-child {
    padding-top: 0px;
}

.item-product-add-new .product-name:hover {
    color: var(--main-primary);
}

.list-product {
    overflow: auto;
    max-height: 450px;
    padding: 5px;
}

.w-70 {
    width: 70px;
}

.w-310 {
    width: 310px;
}

.w-120 {
    width: 120px;
}

.item-variant {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    border-top: solid 1px #F1F3F4;
    padding: 24px 0px;
}

.item-variant:first-child {
    padding-top: 0px !important;
}

.item-variant-name-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 82px;
}

.ladiui-order-left .item-variant-name-info .item-title {
    margin-bottom: 14px;
}

.item-variant-price {
    display: flex;
    border: solid 1px #f1f3f5;
    margin-top: -10px;
}

.item-variant-price-input {
    text-align: center;
    border: none !important;
    width: 53px !important;
}

.item-variant-list-option {
    display: flex;
    flex-direction: column;
}

.info-customer {
    background: #FFFFFF;
    border: 1px solid #F1F3F4;
    border-radius: 2px;
    /* padding: 20px !important; */
}

.order-status {
    background: #41B983;
    border-radius: 2px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    font-size: 12px;
    padding: 2px 8px;
    color: #FFFFFF;
}

.btn-action-bc {
    border: 1px solid var(--main-primary);
    width: 32px;
    height: 32px;
    text-align: center;
}

.title-16 {
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: var(--main-default) !important;
}

.order-status.Canceled {
    background: #dfe4e8 !important;
    color: #000000;
}

.order-status.refund-all {
    background: #dfe4e8 !important;
    color: #000000;
}

.ladiui-order-right {
    width: 330px;
    position: absolute;
    /* top: 199px; */
    right: 24px;
}

.ladiui-order-right .more-info {
    display: grid;
}

.w-name-product {
    width: calc(100% - 610px) !important;
}

.email-check-send {
    position: absolute !important;
    left: 30px !important;
}

.list-product-type {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.list-product-type .item-product {
    width: 49%;
    background: #FFFFFF;
    border: 1px solid #F1F3F4;
    border-radius: 2px;
    padding: 16px;
    margin-bottom: 16px;
}

.list-product-type .item-product .des-product {
    font-size: 13px;
    line-height: 19px;
    color: #6D6D6D;
}

.input-price::after {
    background-size: cover;
    content: "đ";
    height: 8px;
    margin: 14px 14px 14px 16px;
    position: absolute;
    right: 0;
    width: 8px;
}

.product-create {
    width: 620px;
    margin: auto;
    margin-top: 80px;
}

.sub-icon-group {
    position: absolute;
    right: 7px;
    top: 7px;
    color: #727272;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
}

.ladiui.tags {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    background: #FFFFFF;
    border: 1px solid #F1F3F4;
    border-radius: 2px;
    padding: 4px 8px;
    /* padding: 0px 8px; */
}

.tags .tag {
    width: unset;
    margin-right: 10px;
}

.btn-coppy {
    margin-left: 10px;
}
/* style font */
.ladiui .item-title {
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: var(--main-default) !important;
}

.block {
    display: block;
}
.t-145 {
    top: 145px !important;
}
.p-0 {
    padding: 0px !important;
}
.mr-4 {
    margin-right: 4px !important;
}
.pb-4 {
    padding-bottom: 4px!important;
}
.pb-8 {
    padding-bottom: 8px !important;
}
.mb-24 {
    margin-bottom: 24px !important;
}

.mb-27 {
    margin-bottom: 27px!important;
}

.mt-24 {
    margin-top: 24px !important;
}

.w-500 {
    width: 500px !important;
}

.w-200 {
    width: 200px !important;
}

.w-150 {
    width: 150px !important;
}

.w-190 {
    width: 190px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mt-0 {
    margin-top: 0px !important;
}

.w-600 {
    width: 600px !important;
}
.w-800 {
    width: 800px !important;
}
.w-900 {
    width: 900px !important;
}
.pt-16 {
    padding-top: 16px !important;
}
.pl-16 {
    padding-left: 16px !important;
}
.pb-16 {
    padding-bottom: 16px !important;
}
.pd-0 {
    padding: 0px !important;
}

.pd-3 {
    padding: 3px !important;
}
.pb-4 {
    padding-bottom: 4px !important;
}

.m-t-16 {
    margin-top: 16px;
}

.m-r-20 {
    margin-right: 20px;
}

.w-100 {
    width: 100px !important;
}

.w-175 {
    width: 175px !important;
}

.w-576 {
    width: 576px !important;
}

.w-553 {
    width: 553px !important;
}

.w-44 {
    width: 44px !important;
}

.w-400 {
    width: 400px!important;
}
.w-440 {
    width: 440px !important;
}

.w-180 {
    width: 180px !important;
}

.w-220 {
    width: 220px !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.ml-0 {
    margin-left: 0 !important;
}
.ml-8 {
    margin-left: 8px!important;
}

.mr-0 {
    margin-right: 0 !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.pl-16 {
    padding-left: 16px !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.pr-100 {
    padding-right: 100px !important;
}

.m-12 {
    margin: 12px!important;
}

.ml-12 {
    margin-left: 12px !important;
}

.pr-16 {
    padding-right: 16px!important;
}

.pr-18 {
    padding-right: 18px!important;
}

.ml-18 {
    margin-left: 18px!important;
}

.ml-32 {
    margin-left: 32px!important;
}

.mt-16 {
    margin-top: 16px!important;
}

.mr-16 {
    margin-right: 16px !important;
}

.mt-14 {
    margin-top: 14px!important;
}


.mt-10 {
    margin-top: 10px !important;
}

.mr-8 {
    margin-right: 8px !important;
}

.mt-8 {
    margin-top: 8px !important;
}

.ml-24 {
    margin-left: 24px !important;
}

.mr-50 {
    margin-right: 50px!important;
}

.mr-24 {
    margin-right: 24px!important;
}

.mb-10 {
    margin-bottom: 10px!important;
}

.mb-12 {
    margin-bottom: 12px!important;
}

.mb-8 {
    margin-bottom: 8px!important;
}

.mb-16 {
    margin-bottom: 16px !important;
}

.mt-19 {
    margin-top: 19px!important;
}

.mt-22 {
    margin-top: 22px !important;
}

.pt-20 {
    padding-top: 20px !important;
}

.bold-500 {
    font-weight: 500!important;
}

.font-size-24 {
    font-size: 24px !important;
}

.font-size-18 {
    font-size: 18px !important;
}

.font-size-16 {
    font-size: 16px !important;
}

.font-size-14 {
    font-size: 14px !important;
}

.font-size-12 {
    font-size: 12px !important;
}

.font-size-11 {
    font-size: 11px !important;
}

.box-shadow-none {
    box-shadow: unset !important;
}

.display-none {
    display: none!important;
}

.r-0 {
    right: 0px !important;
}

.pd-5 {
    padding: 5px !important;
}

.t-40 {
    top: 40px !important
}

.mr-12 {
    margin-right: 12px !important;
}

.ml-3 {
    margin-left: 3px!important;
}

.t-300 {
    top: 300px !important;
}

.text-left {
    text-align: left !important;
}

.mr-28 {
    margin-right: 28px !important;
}

.pr-15 {
    padding-right: 15px !important;
}

.border-none {
    border: none !important;
}

.m-0 {
    margin: 0px !important;
}

.pos-rel {
    position: relative !important;
}

.bt-0 {
    bottom: 0px !important;
}

.bt-36 {
    bottom: 36px!important;
}

.pointer {
    cursor: pointer;
}

.mr-20 {
    margin-right: 20px !important;
}

.al-item-start {
    align-items: flex-start !important;
}

.wp-50 {
    width: 49% !important;
}

.flex-start {
    justify-content: flex-start!important;
}

.flex {
    display: flex !important;
    align-items: center !important;
    /* justify-content: space-between; */
}
.space-between {
    justify-content: space-between;
}

.items-baseline {
    align-items: baseline !important;
}

.w-unset {
    width: unset !important;
    padding: 0px !important;
}

.mr-10 {
    margin-right: 10px !important;
}

.mr-18 {
    margin-right: 18px;
}

.pb-8 {
    padding-bottom: 8px !important;
}

.pb-24 {
    padding-bottom: 24px !important;
}

.pt-24 {
    padding-top: 24px !important;
}

.w100 {
    width: 100% !important;
}


.pr-30 {
    padding-right: 30px !important;
}

.pl-25 {
    padding-left: 25px !important;
}

.my-24 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
}

.mr-14 {
    margin-right: 14px !important;
}

.py-12 {
    padding: 12px 0px !important;
}

.mt-34 {
    margin-top: 34px !important;
}
.mt-18 {
    margin-top: 18px;
}

.mt-12 {
    margin-top: 12px;
}

.pt-8 {
    padding-top: 8px !important;
}

.mr-12 {
    margin-right: 12px !important;
}

.mr-11 {
    margin-right: 11px;
}

.h-auto {
    height: auto !important;
}

.relative {
    position: relative;
}

.cursor-pointer {
    cursor: pointer!important;
}

.color-default {
    color: var(--main-primary) !important;
}

.py-24 {
    padding: 24px 0px !important;
}

.pl-24 {
    padding-left: 24px !important;
}

.pr-24 {
    padding-right: 24px !important;
}

.items-center {
    align-items: center !important;
}

.transparent {
    background-color: transparent !important;
}

.t-317 {
    top: 317px !important;
}

.mr-12 {
    margin-right: 12px !important;
}
.mr-4 {
    margin-right: 4px !important;
}

.w-50 {
    width: 50px !important;
}

.boder-search {
    background: #FFFFFF;
    border: 1px solid #F1F3F4;
    border-radius: 2px;
}
.px-5 {
    padding: 0px 5px!important;
}
.inline-block {
    display: inline-block !important;
}
.h-36 {
    height: 36px !important;
}
.no-border {
    border: none !important;
}
.justify-content-center {
    justify-content: center !important;
}
.space-nowrap {
    white-space: nowrap !important;
}
.position-initial {
    position: initial !important;
}
.bold-400 {
    font-weight: 400 !important;
}
.valid-input-error {
    border: 1px solid red !important;
}

.text-default {
    color: #6d6d6d;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.mini-dropdown {
    width: 100% !important;
    height: 22px !important;
    min-width: unset !important;
    background: #f1f3f4 !important;
    border-radius: 2px !important;
}

.mini-dropdown.fix {
    width: 100% !important;
}

.mini-dropdown-pd {
    padding: 2px 5px !important;
}

.head-accordion {
    background: #f1f3f4 !important;
    border-radius: 2px !important;
}

.min-max-buy {
    justify-content: space-between !important;
    display: flex !important;
    align-items: center !important;
}

.variant-option-item .variant-option-value {
    width: calc(100% - 300px);
    margin-left: 20px;
}

.btn-add-option {
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
    padding-left: 0px !important;
    cursor: pointer;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.un-hover:hover,
.un-hover:focus {
    border: unset !important;
}

/* .list-image-product .list-image .item-image {
    margin-right: 23px;
} */

.list-image-product .list-image.grid-box {
    /* display: flex;
    align-items: center;
    width: 400px;
    overflow: auto; */
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 24px;
}

.list-image-product .list-image.flex-box {
    display: flex;
}
.list-image-product .list-image.flex-box .item-image {
    margin-right: 24px !important;
}

.list-image-product .list-image .btn-add-image {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #afafaf;
    padding-top: 20px;
}

.list-image-product .image-none .ladiui-button-image {
    position: relative;
    width: 400px;
    height: 400px;
    background: #f1f3f4;
    border-radius: 2px;
}

.list-image-product .image-none .btn-add-image {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #afafaf;
    padding: 150px 80px;
}

.list-image-product .image-none .ladiui-button-image input[type='file'] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.item-variant-list-option .option-name {
    color: #6d6d6d;
}

.item-variant-list-option .option-value {
    color: var(--main-default);
}

.block-custom-interface {
    margin-bottom: 30px;
}

.ladiui.iframe-checkout {
    text-align: center;
    background: #d9d9d9 !important;
    padding: 20px 0px;
}

#iframe-checkout-page {
    height: calc(100vh - 340px);
}

.tox-editor-container .tox-editor-header .tox-menubar {
    display: none;
}

/** @format */

.ldicon-note {
    background-color: var(--main-secondary);
    display: inline-block;
    box-sizing: unset;
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-new-file.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-new-file.svg);
    width: 16px;
    height: 16px;
}

/** @format */

body {
    /* color: #313942; */
    color: var(--main-default);
}

.ladiui.wrapper {
    font-family: 'Roboto', sans-serif;
    width: 100%;
    min-width: 1366px;
    height: 0;
}

.ladiui.ladiui.my-account-avata img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
}

.ladiui.topbar {
    height: 64px;
    border-bottom: 1px solid var(--main-line);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: var(--main-white);
    position: fixed;
    width: 100%;
    z-index: 90;
}

.ladiui.main-custom {
    position: relative;
    margin-left: 70px;
    width: calc(100% - 70px);
    height: 100vh;
    overflow: auto;
}

.ladiui.topbar-menu {
    position: fixed;
    padding: 20px 0px 20px 24px;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: var(--main-secondary);
}

.ladiui.topbar-menu-right {
    position: fixed;
    right: 0;
}

/* .ladiui.btn-group {
  padding: 14px 0px 14px 0px;
} */

.ladiui.app-tools img {
    pointer-events: none;
}

.ladiui.tool-notificiations {
    padding: 19px 12px 19px 24px;
}

.ladiui.noti.dropdown-menu {
    /* left: 30px; */
    position: absolute;
    width: 400px;
    right: 122px;
    margin-top: 15px;
}

.ladiui.noti-content {
    display: flex;
    padding: 16px;
}

.ladiui.noti-content.active {
    background-color: rgba(24, 82, 250, 0.05) !important;
}

.ladiui.noti-content:hover {
    background-color: var(--main-line);
}

.ladiui.noti-header {
    padding: 6px 16px 18px;
    display: flex;
    justify-content: space-between;
}

.ladiui.noti-header-right {
    color: var(--main-primary);
}

.ladiui.noti-header-right i {
    margin-right: 4px;
}

.ladiui.noti-i {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    pointer-events: none;
}

.ladiui.noti-text {
    margin-left: 56px;
    cursor: pointer;
    pointer-events: none;
}

.ladiui.noti-sub {
    margin-top: 4px;
    color: var(--main-secondary);
}

.ladiui.noti-time {
    margin-top: 8px;
    color: var(--main-secondary);
}

.ladiui.tool-help {
    padding: 20px 12px 20px 12px;
}

.ladiui.tool-help .dropdown-menu {
    left: 0;
    position: absolute;
    margin-top: 15px !important;
    /* right: 0; */
}

.ladiui.tool-help .dropdown-item {
    display: flex;
    align-items: center;
}

.ladiui.tool-language {
    padding: 20px 12px 20px 12px;
}

.ladiui.language-img {
    cursor: pointer;
    pointer-events: none;
    color: white;
    font-size: 0;
}

.ladiui.tool-language img {
    border-radius: 20px;
}

.ladiui.tool-language .dropdown-menu {
    right: 0px;
    min-width: 150px;
    position: absolute;
    margin-top: 15px;
    /* right: 0; */
}

.ladiui.my-account {
    cursor: pointer;
    width: 74px !important;
    min-width: auto;
}

.ladiui.my-account.dropdown-toggle::after {
    content: none;
}

.ladiui.dropdown-toggle.nav-bar {
    min-width: 200px;
}

.ladiui.my-account-avata {
    padding: 12px 0px 12px 12px;
    pointer-events: none;
}

.ladiui.my-account-info {
    padding-left: 16px;
    pointer-events: none;
}

.ladiui.my-account-name {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-default);
    pointer-events: none;
}

.ladiui.my-account-type {
    padding: 2px 0px 0px 0.5px;
}

.ladiui.account.dropdown-menu {
    width: 332px;
    /* left: 220px; */
    position: absolute;
    right: 24px;
}

.ladiui.account .dropdown-item {
    padding: 12px 21px;
}

.ladiui.account {
    position: relative;
}

.ladiui.account-header {
    display: flex;
    justify-items: center;
    align-items: center;
    padding-bottom: 24px;
}

.ladiui.account-header-text {
    /* pointer-events: none; */
    /* display: grid; */
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ladiui.account-content {
    padding: 14px 24px 0;
    border-bottom: 1px solid var(--main-line);
}

.ladiui.account-content-footer {
    padding: 0px 24px;
    margin-bottom: 12px;
    border-top: 1px solid var(--main-line);
}

.ladiui.account-avatar {
    margin-right: 16px;
    display: flex;
    position: relative;
}

.ladiui.account-avatar a {
    padding: 0.5px;
    border-radius: 20px;
    background-color: var(--main-white);
    position: absolute;
    top: 42px;
    left: 42px;
}

.ladiui.account-body-content {
    pointer-events: none;
}

.ladiui.account-avatar img {
    pointer-events: none;
}

.ladiui.avt {
    width: 64px;
    height: 64px;
    border-radius: 50%;
}

.ladiui.avartar {
    pointer-events: none;
}

.ladiui.account-body-item {
    display: flex;
    align-items: center;
    padding: 12px 24px;
}

.ladiui.my-account-type i {
    margin-right: 0;
    /* position: relative; */
}

.ladiui.dropdown-item.account-body-item i {
    margin-right: 0;
}

.ladiui.account-tick {
    background-color: inherit;
    position: absolute;
    right: 24px;
}

.ladiui.account-body-item:first-child {
    margin-top: 12px;
}

.ladiui.account-body-item:last-child {
    margin-bottom: 12px;
}

.ladiui.account-body-item:hover {
    background-color: var(--main-line);
}

.ladiui.body-avatar {
    width: 48px;
    height: 48px;
    margin-right: 12px;
    border: 2px solid rgba(0, 0, 0, 0);
    border-radius: 30px;
    pointer-events: none;
}

.ladiui.body-avatar img {
    border: 2px solid rgba(0, 0, 0, 0);
    border-radius: 30px;
}

.ladiui.account-status {
    display: flex;
    justify-items: center;
    margin-top: 4px;
}

.ladiui.account-body-item.active .body-avatar {
    border: 2px solid var(--main-primary);
    border-radius: 30px;
}

.ladiui.account-body-item.active .account-tick {
    background-color: var(--main-primary);
}

.ladiui.account-body-item.active .body-avatar img {
    border: 2px solid var(--main-white);
    border-radius: 30px;
}

.ladiui.account-body-item.active .account-name {
    color: var(--main-primary);
}

/* .ladiui.account-duration {
    margin-left: 8px;
} */

.ladiui.icon-drd {
    padding: 0px 20px 0px 8px;
}

.ladiui.content-navigation .dropdown {
    display: flex;
    flex-direction: column-reverse;
}

.ladiui.new-page.dropdown-menu {
    width: 400px;
    padding: 0;
    margin-bottom: 44px;
}

.ladiui.new-page.dropdown-item {
    display: flex;
    align-items: center;
    padding: 16px;
}

.ladiui.content-sub {
    min-width: 530px;
}

.ladiui.new-page-content {
    margin-left: 8px;
}

/*Hover*/
.aside .ladiui.nav {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 70px;
    height: 100vh;
    background-color: var(--main-line);
    transition: width 0.3s;
}

.ladiui.logo-item.logo {
    padding: 16px 20px;
    position: relative;
    opacity: 1;
}

.ladiui.logo-item.logo-hover {
    position: absolute;
    opacity: 0;
    pointer-events: none;
}

.aside .ladiui.nav:hover .logo-item.logo-hover {
    position: relative;
    opacity: 1;
    transition: opacity 0.5s;
}

.aside .ladiui.nav:hover .logo-item.logo {
    position: absolute;
}

.aside .ladiui.nav:hover {
    display: block;
    width: 215px;
}

.ladiui.menu-text {
    position: absolute;
    pointer-events: none;
    left: 50px;
    opacity: 0;
    width: 0;
    overflow: hidden;
    transition: opacity 0.2s, width 0.3s;
}

@-webkit-keyframes ladiLogo {
    0% {
        opacity: 1;
    }

    90% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes ladiLogo {
    0% {
        opacity: 1;
    }

    90% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.aside .ladiui.nav:hover .menu-text {
    opacity: 1;
    width: 150px;
}

.aside .ladiui.nav:hover .menu-item {
    width: 215px;
}

.ladiui.menu-item {
    padding: 16px 0px 16px 23px;
    width: 70px;
    transition: width 0.3s;
    border-left: 2px solid var(--secondary-color);
}

.ladiui.menu-item a i {
    background-color: var(--main-secondary);
}

.ladiui.menu-item:hover .menu-text {
    color: var(--main-primary);
}

.ladiui.menu-item:hover i {
    background-color: var(--main-primary);
}

.ladiui.logo-item.logo-hover {
    padding: 16px 59px 16px 21px;
}

.ladiui.menu-item.active {
    background-color: var(--main-white);
    border-left: 2px solid var(--main-primary);
}

.ladiui.menu-item.active i {
    background-color: var(--main-primary);
}

.ladiui.menu-item .icon-prev {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.ladiui.menu-item.active .menu-text {
    color: var(--main-primary);
}

.ladiui.menu-item .dropdown-toggle {
    min-width: 50px;
    width: 50px;
}

.ladiui.menu-item .dropdown-toggle:focus {
    border: none;
}

.ladiui.menu-item .dropdown-toggle::after {
    content: none;
}

.ladiui.menu-item .dropdown-menu {
    padding: 0;
    box-shadow: none;
    background-color: inherit;
}

.aside .ladiui.nav:hover .dropdown-toggle {
    min-width: 200px;
    width: 200px;
    transition: width 0.3s;
}

.aside .ladiui.nav:hover .ladiui.menu-child {
    position: relative;
    opacity: 1;
    width: 100%;
}

.ladiui.menu-child {
    position: absolute;
    opacity: 0;
    width: 0;
    transition: opacity 0.2s;
}

.ladiui.menu-child-item {
    padding: 12px 0 12px 34px;
}

.ladiui.menu-child-item:first-child {
    padding: 24px 0 12px 34px;
}

.ladiui.menu-child-item.active a {
    color: var(--main-primary);
}

.ladiui.menu-child-item:last-child {
    padding-bottom: 0px;
}

.ladiui.menu-child li a:hover {
    color: var(--main-primary);
}

.ladiui.menu-child li a.active {
    color: var(--main-primary);
}

.ladiui.menu-child li a {
    color: var(--main-secondary);
}

.ladiui.menu-text {
    white-space: nowrap;
    padding-left: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-secondary);
}

.ladiui.head-noti {
    margin-bottom: 4px;
}

.ladiui.tag-form {
    display: inline-table;
}

.ladiui.dropdown-menu.custom-header {
    min-width: 200px;
}

.facet-container {
    width: 330px;
}

.right {
    float: right;
}

.left {
    float: left;
}

.facet-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-right: 10px;
    background: #eee;
    padding: 5px;
    width: 143px;
    min-height: 1.5em;
    font-size: 0.85em;
}

.facet-list li {
    margin: 5px;
    padding: 5px;
    font-size: 1.2em;
    width: 120px;
}

.facet-list li.placeholder {
    height: 1.2em;
}

.facet {
    border: 1px solid #bbb;
    background-color: #fafafa;
    cursor: move;
}

.facet.ui-sortable-helper {
    opacity: 0.5;
}

.placeholder {
    border: 1px solid orange;
    background-color: #fffffd;
}

/* width */

::-webkit-scrollbar {
    height: 4px;
    width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    background: var(--main-white);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--main-mute);
    border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--main-secondary);
}

.ladiui-aside {
    width: 200px;
    height: 100%;
    position: relative;
    transition: width 0.1s, height 0.1s, opacity 0.1s, -webkit-transform 0.1s;
    transition: width 0.1s, height 0.1s, transform 0.1s, opacity 0.1s;
    transition: width 0.1s, height 0.1s, transform 0.1s, opacity 0.1s, -webkit-transform 0.1s;
}

/*-------------MINI NAVBAR----------------*/

body.mini-navbar .ladiui-aside {
    width: 90px !important;
    transition: width 0.1s, height 0.1s, opacity 0.1s, -webkit-transform 0.1s;
    transition: width 0.1s, height 0.1s, transform 0.1s, opacity 0.1s;
    transition: width 0.1s, height 0.1s, transform 0.1s, opacity 0.1s, -webkit-transform 0.1s;
}

body.mini-navbar .ladiui-main {
    width: calc(100% - 90px) !important;
}

body.mini-navbar .ladiui.nav > .menu > li > a > i {
    font-size: 18px;
    margin: 0px auto;
    position: initial;
    border-radius: 50%;
    line-height: 29px;
    width: 40px;
    height: 40px;
}

body.mini-navbar .ladiui.nav > .menu > li > a {
    text-align: center;
    padding: 10px 6px;
    line-height: 20px;
    font-size: 14px;
    display: grid;
    width: 100%;
}

body.mini-navbar .ladiui.setting-bottom a {
    padding: 14px !important;
    display: grid !important;
    text-align: center;
}

body.mini-navbar .ladiui.nav > .menu > li.open > .ladiui.submenu {
    position: absolute;
    left: 98px;
    top: 0px;
    border-radius: 4px;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    transition: width 0.1s, height 0.1s, opacity 0.1s, -webkit-transform 0.1s;
    transition: width 0.1s, height 0.1s, transform 0.1s, opacity 0.1s;
    transition: width 0.1s, height 0.1s, transform 0.1s, opacity 0.1s, -webkit-transform 0.1s;
    display: none;
    background: #fff;
}

body.mini-navbar .ladiui.nav > .menu > li.open > .ladiui.submenu.show {
    display: block;
}

body.mini-navbar .ladiui.nav > .menu > li.open > .ladiui.submenu > li {
    list-style: none;
}

body.mini-navbar .ladiui.nav > .menu > li.open > .ladiui.submenu > li > a {
    white-space: nowrap;
    padding: 10px 20px;
}

body.mini-navbar .ladiui.nav > .menu > li.open > .ladiui.submenu > li > a::before {
    left: 7px !important;
    display: none;
}

body.mini-navbar .ladiui-nav-toggle {
    width: 91px !important;
    transition: width 0.1s, height 0.1s, opacity 0.1s, -webkit-transform 0.1s;
    transition: width 0.1s, height 0.1s, transform 0.1s, opacity 0.1s;
    transition: width 0.1s, height 0.1s, transform 0.1s, opacity 0.1s, -webkit-transform 0.1s;
}

/*-----------END MINI NAVBAR---------------------*/

.ladiui-logo {
    text-align: left;
    height: 50px;
    line-height: 46px;
    width: 200px;
    float: left;
}

.ladiui-logo img {
    width: 60%;
    vertical-align: middle;
    display: inline-block;
}

.ladiui-main {
    width: 100%;
    float: right;
    transition: width 0.1s, height 0.1s, opacity 0.1s, -webkit-transform 0.1s;
    transition: width 0.1s, height 0.1s, transform 0.1s, opacity 0.1s;
    transition: width 0.1s, height 0.1s, transform 0.1s, opacity 0.1s, -webkit-transform 0.1s;
}

.ladiui-topbar {
    height: 50px;
    background-color: #1853fa;
    border-bottom: 1px solid #eaedf0;
    background: linear-gradient(90deg, rgba(12, 97, 242, 1), rgba(0, 65, 179, 1));
    display: flex;
}

.mini-navbar .ladiui-nav-toggle i {
    margin-left: 0px;
    transition: width 0.1s, height 0.1s, opacity 0.1s, -webkit-transform 0.1s;
    transition: width 0.1s, height 0.1s, transform 0.1s, opacity 0.1s;
    transition: width 0.1s, height 0.1s, transform 0.1s, opacity 0.1s, -webkit-transform 0.1s;
}

.mini-navbar .ladiui-logo {
    margin-left: 18px;
    transition: width 0.1s, height 0.1s, opacity 0.1s, -webkit-transform 0.1s;
    transition: width 0.1s, height 0.1s, transform 0.1s, opacity 0.1s;
    transition: width 0.1s, height 0.1s, transform 0.1s, opacity 0.1s, -webkit-transform 0.1s;
}

.ladiui-nav-toggle {
    width: 48px;
    float: left;
    height: 50px;
    line-height: 50px;
    text-align: center;
}

.ladiui-nav-toggle i {
    font-size: 18px;
    margin-top: 8px;
    color: #fff;
    display: inline-block;
    cursor: pointer;
    border-radius: 50%;
    overflow: hidden;
    padding: 7px;
}

.ladiui-topbar .topbar-left {
    width: 40%;
    float: left;
}

.ladiui-topbar .topbar-right {
    width: 60%;
    float: right;
    text-align: right;
    margin-right: 15px;
}

.ladiui.topbar-searchbox {
    position: relative;
    display: inline-block;
    height: 50px;
    line-height: 49px;
    margin-left: 30px;
}

.ladiui.topbar-searchbox > i {
    position: absolute;
    top: 17px;
    left: 12px;
    color: #8697a8;
}

.ladiui.topbar-search {
    display: inline-block;
    width: 290px;
    height: 36px;
    padding: 0px;
    text-indent: 40px;
    color: #8697a8;
    font-size: 14px;
    background-color: #f8fafb;
    box-shadow: none;
    -webkit-box-shadow: none;
}

.ladiui.topbar-search::before {
    content: '';
    display: inline-block;
    width: 60px;
    height: 60px;
    background: red;
}

.topbar-profile {
    font-size: 15px;
    height: 50px;
    line-height: 45px;
    float: right;
    margin-left: 15px;
}

.topbar-profile a {
    text-decoration: none;
    color: #44566c;
    vertical-align: middle;
    display: block;
}

.topbar-profile li.ladiui-dropdown ul > li > a {
    transition: background-color 0.15s ease-out;
}

.topbar-profile li.ladiui-dropdown ul > li > a:hover {
    background-color: #f6f7f9 !important;
}

.topbar-profile .ladiui.avatar {
    background-color: #1853fa;
    color: white;
    width: 35px;
    height: 35px;
    display: inline-flex;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    margin-top: -7px;
    margin-right: 0px;
    background-size: contain !important;
}

.topbar-profile .ladiui-navbar > li > ul {
    position: absolute;
    right: 0px;
}

.ladiui-topbar .ladiui-navbar > li > ul {
    min-width: 290px;
}

.ladiui-topbar .ladiui-navbar > li > ul > li > a {
    padding: 2px 20px;
}

.topbar-profile .ladiui-navbar > li > ul::before {
    content: '';
}

.topbar-profile .ladiui-navbar > li > ul > li:last-child > a.notification-item {
    border-bottom: 0px;
}

.notifications {
    z-index: 5;
}

.notifications > li > ul {
    padding: 0px !important;
}

.notifications .notification-item:hover {
    background: #ebedf2 !important;
}

.notifications .notification-item {
    display: -webkit-inline-box;
    align-items: center;
    border-bottom: 1px solid #f7f8fa;
    transition: background-color 0.3s ease;
    height: auto;
    line-height: 28px;
}

.notifications .has-message {
    position: relative;
}

.notifications .has-message::after {
    position: absolute;
    width: 5px;
    height: 5px;
    background: red;
    content: '';
    border-radius: 50%;
    top: 1px;
    right: 0px;
}

.notifications .notification-item .notification-time {
    font-size: 12px;
    float: left;
    height: 28px;
    line-height: 28px;
    width: 100%;
    text-align: left;
}

.notifications .notification-title {
    font-size: 14px;
}

.notifications .notification-item .notification-icon {
    display: flex;
    flex: 0 0 2.5rem;
    align-items: center;
}

.notifications .notification-item .notification-details {
    padding-left: 20px;
    -webkit-box-orient: vertical;
}

.notifications .notification-item .notification-icon i {
    font-size: 30px;
    color: #5d78ff;
}

.notifications .notification-item .notification-details .notification-item-title {
    transition: color 0.3s ease;
    font-size: 1rem;
    font-weight: 400;
    color: #6c7293;
}

.notifications .notification-item .notification-details .notification-item-time {
    font-size: 1rem;
    font-weight: 300;
    color: #a7abc3;
}

.topbar-profile .ladiui-navbar.-horizontal > li {
    line-height: 55px;
    text-align: left;
}

.topbar-profile .ladiui-navbar.-horizontal > li:first-child {
    padding-left: 12px;
}

.app-tools li a {
    font-size: 15px;
}

.app-tools li i,
.topbar-profile .ladiui-navbar.-horizontal > li > ul > li > a > i {
    font-size: 14px;
    margin-right: 6px;
    vertical-align: middle;
}

.topbar-profile .ladiui-navbar.-horizontal > li > a {
    height: 50px;
    line-height: 53px;
    color: #fff;
    font-size: 15px;
}

.topbar-profile .ladiui-navbar li:hover > a:first-child,
.topbar-profile .ladiui-navbar li.-active > a:first-child {
    background: transparent;
}

.ladiui-store-list li a i {
    font-size: 30px;
}

.topbar-tools .ladiui.list {
    line-height: 50px;
    height: 50px;
}

.topbar-tools .ladiui.list > li > a {
    text-decoration: none;
    color: #44566c;
    vertical-align: middle;
    display: block;
    padding: 0px 10px;
}

.topbar-right > div {
    display: -webkit-inline-box;
}

.app-packages span {
    display: inline-block;
    margin: 0px 20px;
    color: #fff;
}

.app-packages {
    padding-right: 24px;
    border-right: 1px solid #335de7;
    line-height: 49px;
}

.app-packages .upgrade {
    display: inline-block;
    margin-top: 7px;
}

.ladiui-content {
    padding: 10px 30px 30px 20px;
}

.ladiui-table-actions {
    /* margin-bottom: 24px; */
    margin-bottom: 12px;
    position: relative;
    z-index: 1;
    min-height: 40px;
    display: flex;
    z-index: 50;
    justify-content: space-between;
}

.ladiui-table-actions.reports {
    margin-bottom: 12px !important;
}

.ladiui.search-actions {
    float: left;
    line-height: 39px;
}

.ladiui.actions {
    float: right;
}

.ladiui.actions > button,
.ladiui.actions > div {
    margin: 0px 6px;
}

.ladiui.actions > button,
.ladiui.actions > div:last-child {
    margin-right: 0px;
}

.ladiui.search-keyword {
    width: 290px;
    position: relative;
}

.ladiui.search-keyword .ladiui.input {
    text-indent: 5px;
    padding-right: 35px;
}

.ladiui.search-keyword i {
    position: absolute;
    top: 0px;
    color: #8697a8;
    right: 0px;
    cursor: pointer;
    padding: 9px;
}

/* .ladiui.nav>.menu>li {
  list-style: none;
  position: relative;
} */
/* 
.ladiui.menu {
  margin: 0px;
  padding: 0px;
} */
/* 
.ladiui.nav>.menu>li>a {
  display: inline-flex;
  position: relative;
  cursor: pointer;
  padding: 10px;
  width: 100%;
  font-size: 15px;
  color: #44566c;
  font-style: normal;
  font-stretch: normal;
  line-height: 31px;
  letter-spacing: normal;
  border-radius: 0px 30px 30px 0px;
  transition: color 0.6s ease-out;
}

.ladiui.nav>.menu>li>a>i {
  margin-right: 7px;
  font-size: 17px;
  border-radius: 50%;
  padding: 6px;
}

.ladiui.nav>.menu>li>a:hover {
  color: #1853fa;
}

.ladiui.nav>.menu>li>a:hover,
.ladiui.nav>.menu>li>a:focus,
.ladiui.nav>.menu>li>a:active {
  background-color: #f8fafb;
} */

.ladiui.submenu {
    background-color: #f8fafb;
    display: none;
    padding: 0px;
}

/* 
.ladiui.nav>.menu>li.open>a {
  background: #e8f0fe;
  color: #1853fa;
}

.ladiui.nav>.menu>li.open>a>i {
  border-color: #1853fa;
}

.ladiui.nav>.menu>li.open>.ladiui.submenu {
  display: block;
  padding: 0px;
  transition: width 0.1s, height 0.1s, transform 0.1s, opacity 0.1s;
} */

.ladiui.submenu > li {
    list-style: none;
}

.ladiui.submenu > li > a {
    color: #44566c;
    padding: 10px 0px 10px 45px;
    display: block;
    position: relative;
}

.ladiui.submenu > li > a:hover {
    color: #1853fa;
}

/* 
.ladiui.nav>.menu>li>a>.ladiui.pill {
  background: #ff3d57;
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border-radius: 10px;
  padding: 3px 5px;
  position: absolute;
  right: 18px;
} */

.ladiui.pagination-block {
    width: 100%;
    margin-top: 20px;
}

.ladiui-search-box {
    background: #fff;
    padding: 15px;
    border: 1px solid #f0f0f0;
    border-radius: 10px;
    z-index: 0;
    margin-bottom: 24px;
    display: flex;
}

.ladiui.showing {
    width: 300px;
    color: #8697a8;
    font-size: 14px;
    float: left;
}

.ladiui.showing-total {
    width: 300px;
    color: #8697a8;
    font-size: 14px;
    float: right;
    line-height: 40px;
    text-align: right;
}

/* 
.ladiui.pagination>ul>li {
  display: inline-block;
  margin: 2px;
}

.ladiui.pagination>ul>li>a {
  text-decoration: none;
  width: 30px;
  line-height: 27px;
  display: block;
  padding-top: 2px;
  text-align: center;
  color: #44566c;
  border-radius: 3px;
}

.ladiui.pagination>ul>li.disabled>a {
  cursor: not-allowed;
  color: #c7c6c6 !important;
  background-color: #fff !important;
}

.ladiui.pagination>ul>li:first-child>a {
  border-radius: 5px 0px 0px 5px;
}

.ladiui.pagination>ul>li:last-child>a {
  border-radius: 0px 5px 5px 0px;
} */

*/
/* .ladiui.pagination>ul>li.active>a,
.ladiui.pagination>ul>li>a.active,
.ladiui.pagination>ul>li>a:active,
.ladiui.pagination>ul>li>a:hover {
  color: #fff;
  background-color: #1853fa;
} */

/* .ladiui.pagination>ul>li>a>i {
  font-size: 12px;
} */

.ladiui.setting-bottom {
    position: absolute;
    bottom: 48px;
    width: 100%;
    background: #fafafa;
}

.ladiui.setting-bottom a {
    padding: 15px 23px;
    color: #44566c;
    display: block;
    font-size: 12px;
}

.ladiui.data-limit {
    float: left;
}

.ladiui.data-limit > span {
    display: block;
    float: left;
    margin-right: 10px;
}

.ladiui.per-page {
    width: 85px;
    float: right;
}

.ladiui.form-filter > div:first-child {
    margin-left: 0px;
}

.ladiui.form-filter > div {
    display: inline-block;
    margin: 0px 10px;
}

.ladiui.select.per-page {
    min-width: 75px;
}

.ladiui-modal-body .ladiui-tabs {
    border-bottom: 1px solid rgba(6, 21, 40, 0.05);
}

.ladiui-tabs li.active a {
    padding-bottom: 7px;
}

.group-prices .price-item {
    width: 100%;
}

.product-image-preview {
    width: 100%;
    height: 306px;
    background: #fafafa;
    border: 1px solid #ebedf2;
}

.slider-products .item {
    margin-top: 15px;
    width: 70px;
    height: 70px;
    background: #fafafa;
    text-align: center;
    line-height: 70px;
    float: left;
}

.slider-products .owl-nav {
    position: absolute;
    width: 100%;
    top: 20%;
}

.slider-products .owl-nav .owl-prev {
    position: absolute;
    left: 0px;
}

.slider-products .owl-nav .owl-next {
    position: absolute;
    right: 0px;
}

.slider-products .owl-nav .owl-prev span,
.slider-products .owl-nav .owl-next span {
    font-size: 30px;
}

.slider-products .owl-nav [class*='owl-']:hover {
    background: transparent;
}

.slider-products .owl-nav [class*='owl-']:hover span {
    color: #1853fa;
}

.tox .tox-statusbar {
    display: none !important;
}

.margin-top-15 {
    display: inline-block;
    margin-top: 15px;
}

.btn {
    border: 2px solid gray;
    color: gray;
    background-color: white;
    padding: 8px 20px;
    border-radius: 8px;
    font-size: 20px;
    font-weight: bold;
}

.fixed {
    position: fixed;
}

.ladiui-product-left {
    width: 400px;
    float: left;
}

.ladiui-product-right {
    width: calc(100% - 424px);
    float: right;
    margin-left: 24px;
}

.ladiui-product-course-left {
    width: 40%;
    float: left;
}

.ladiui-product-course-right {
    width: 60%;
    float: right;
    padding-left: 24px;
}

.ladiui-modal-footer > .ladiui.button {
    margin: 0px 5px;
}

.variant-list {
    overflow-y: scroll;
    border-top: 1px solid #dfe3e8;
    max-height: 263px;
}

.aspect-ratio {
    position: relative;
    float: left;
    border: 1px solid rgba(195, 207, 216, 0.3);
    text-align: center;
    background: #fafafa;
    width: 400px !important;
    height: 316px !important;
    float: inherit !important;
    margin: 0px auto;
}

.variant-list .aspect-ratio {
    width: 40px;
    height: 40px;
    margin-right: 15px;
}

.aspect-ratio::before {
    z-index: 1;
    content: '\ea38';
    position: absolute;
    top: -3px;
    right: 0;
    bottom: 0;
    font-family: 'LadiIcons';
    left: 0;
    font-size: 26px;
    color: #dedede;
}

.ladiui-widget-body ul li a:hover,
.ladiui-widget-body ul li a.active {
    color: #1853fa;
}

.ladiui-col-3 {
    width: 30%;
}

.ladiui-col-7 {
    width: 65%;
}

#tab-variants .ladiui-form-group > .ladiui-col {
    width: 31%;
    float: left;
    margin-right: 19px;
    margin-bottom: 20px;
}

.variant-options {
    overflow: hidden;
    margin-bottom: 20px;
    box-shadow: none;
}

.variant-options .ladiui-widget-body {
    padding: 15px 0px;
    overflow: hidden;
    border-bottom: 1px solid #f2f3f4;
}

.ladiui.group-prices {
    clear: both;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 24px;
}

.form-tags .select2-selection__rendered {
    width: 497px !important;
}

.variant-option-list {
    margin-top: 10px;
}

/* .variant-option-item {
    height: 55px;
} */

.variant-option-item > div {
    display: inline-block;
    float: left;
}

.variant-option-item > div:last-child {
    float: right;
    height: 32px;
    line-height: 32px;
}

.ladiui-form-group.variant-option-head {
    margin-top: 24px;
    margin-bottom: 16px;
}

.variant-option-item .variant-option-name {
    width: 160px;
}

.new-variant-option {
    color: inherit;
    display: block;
    margin-top: 15px;
}

.variant-option-item .variant-option-value .select2-search__field {
    width: 310px !important;
}

.variant-option-item .remove-variant-option {
    width: 20px;
}

.variant-option-item .remove-variant-option a {
    color: inherit;
}

.variant-option-head > span:first-child {
    width: 120px;
}

.variant-option-head > span:last-child {
    width: 350px;
    margin-left: 17px;
}

.variant-option-head > span {
    font-weight: 500;
    display: inline-block;
}

.order-stack-total {
    border-bottom: 0px !important;
}

.order-stack-total td {
    padding: 10px 4px;
}

.order-stack-total td strong {
    font-weight: 700;
    font-size: 18px;
}

.order-stack-total td:last-child {
    padding-right: 91px;
    width: 240px;
    text-align: right;
}

.order-customer {
    overflow: hidden;
    background: #fff;
    /* padding: 15px; */
    /* border-radius: 5px; */
    /* margin-bottom: 20px; */
    /* box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.1); */
    overflow: inherit;
    /* min-width: 300px; */
    border-bottom: 1px solid #f1f3f4;
    /* padding: 24px 0px; */
}

.order-customer:last-child {
    border-bottom: 0px !important;
}

.customer-info {
    position: relative;
    padding-bottom: 20px;
}

.customer-info > span {
    display: block;
    line-height: 40px;
}

.customer-info > span:first-child {
    margin-left: 0px;
}

.customer-info > span > i {
    border: 1px solid #eaedf0;
    border-radius: 50%;
    padding: 6px;
    position: relative;
    top: 1px;
    font-size: 12px;
}

.customer-info strong {
    font-weight: 500;
}

.created-at {
    display: block;
    position: absolute;
    top: -38px;
    right: 0px;
    color: inherit;
}

.payment-box:first-child {
    margin-left: 0px;
}

.payment-box {
    width: 30%;
    float: left;
    margin: 0px 15px;
}

.payment-box ul {
    padding: 0px;
    margin-top: 10px;
}

.payment-box ul li {
    line-height: 30px;
    list-style: none;
}

.payment-box .ladiui.select {
    background: #f8fafb;
}

.order-heading h3 {
    font-size: 15px;
    font-weight: 500;
    /* margin-bottom: 10px; */
}

.payment-box .order-heading {
    margin-top: 15px;
}

.ladiui-order-left {
    width: 70%;
    float: left;
    /* position: relative; */
}

.order-head-right .placed-on {
    margin-right: 30px;
}

.order-head-right .placed-on span {
}

.order-head-right .placed-on span:first-child {
    font-weight: 500;
}

.order-head-right .placed-on span:last-child {
}

/* .ladiui-order-right {
  width: 28%;
  float: right;
} */

.ladi-card.order-products .ladiui.table {
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
}

@media (max-width: 1000px) {
    .ladiui-topbar .topbar-right {
        width: 900px;
    }

    .ladiui.actions {
        width: 100vh;
    }
}

/*****************24042019***************/

.ladiui-table-actions .action-item {
    display: block;
    float: left;
    margin-right: 20px;
}

/*--------------------- LOGIN -------------------*/

.ladiui.auth .auth-left {
    float: left;
    width: 50%;
}

.ladiui.auth .auth-right {
    width: 50%;
    float: right;
}

.ladiui.auth .auth-left iframe {
    width: 100%;
    border: 0px;
    height: 100vh;
}

.ladiui.auth {
    background: url(https://builder.ladipage.vn/images/geometry2.png) repeat;
    color: #44566c;
}

.ladiui.auth h1.form-title {
    margin: 0px 0px 20px 0px;
    padding: 0px;
    font-size: 21px;
    text-align: center;
    font-weight: 500;
    color: #44566c;
}

.ladiui.auth .ladiui-login {
    padding: 30px;
    float: none;
    width: 424px;
    background: #fff;
    margin: 30px auto;
    border-radius: 5px;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    display: block;
}

.ladiui.auth .ladiui-logo {
    width: 290px;
    padding: 40px;
    margin: 0px auto;
    border: 0px;
    float: none;
}

.ladiui.auth .ladiui-logo img {
    width: 100%;
}

.ladiui.auth .ladiui-login-form .ladiui-form-group {
    margin-bottom: 15px;
    position: relative;
}

.ladiui.auth .ladiui-login-form label {
    margin: 8px 0px;
    display: block;
}

.ladiui.auth .ladiui-login-form .button {
    width: 100%;
    padding: 15px;
    font-size: 15px;
}

.ladiui.auth .social-login {
    text-align: center;
}

.ladiui.auth .social-login a.login-facebook,
.ladiui.auth .social-login a.login-google {
    display: inline-block;
    width: 150px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 500;
    line-height: 0px;
    padding: 14px 10px;
    margin: 2px;
    line-height: 11px;
}

.ladiui.auth .social-login a.login-facebook {
    background: #4267b2;
    color: #fff;
}

.ladiui.auth .social-login > a.login-google:last-child {
    background: #d93025;
    color: #fff;
}

.ladiui.auth .forgot-password {
    float: right;
    text-align: right;
}

.ladiui.auth .forgot-password a {
    color: #6c7293;
    font-size: 14px;
}

.ladiui.auth .password-box {
    margin-bottom: 20px;
    overflow: hidden;
}

.ladiui.auth span.login-with {
    display: block;
    width: 100%;
    position: relative;
    border-top: 1px solid #ecedee;
    margin-top: 25px;
}

.ladiui.auth span.login-with span {
    margin: 0px auto;
    top: -12px;
    background: #fff;
    padding: 0px 5px;
    position: relative;
}

.ladiui.auth .question-form {
    width: 100%;
    text-align: center;
    margin-top: 20px;
}

.ladiui.auth .question-form a {
    color: inherit;
    font-weight: 600;
}

.ladiui.auth .ladipage-terms {
    text-align: center;
}

.ladiui.auth .ladipage-terms ul {
    margin: 0px;
    padding: 0px;
}

.ladiui.auth .ladipage-terms ul li {
    display: inline-block;
}

.ladiui.auth .ladipage-terms ul li a {
    color: inherit;
    margin: 0px 20px;
    font-size: 14px;
}

.ladiui.auth .ladipage-terms ul li a:hover {
    text-decoration: underline;
}

.ladiui.table .ladiui-navbar.-horizontal li > ul {
    right: 0px;
}

.ladiui.table thead tr th {
    position: relative;
}

.ladiui.table thead tr th.sorting {
    cursor: pointer;
}

.ladiui.table thead tr th.sorting.up::after {
    content: '\ea47';
    font-family: 'LadiIcons';
    font-size: 10px;
    /* top: 20px; */
    /* top: 0px; */
    position: absolute;
    z-index: 0;
    color: #8697a8;
    padding-left: 6px;
}

.ladiui.table thead tr th.sorting::after,
.ladiui.table thead tr th.sorting.down::after {
    content: '\ea4a';
    font-family: 'LadiIcons';
    font-size: 8px;
    /* top: 21px; */
    top: unset;
    position: absolute;
    z-index: 0;
    color: #8697a8;
    padding-left: 6px;
}

.ladiui-topbar.fixed {
    position: fixed;
    width: 100%;
    left: 0px;
    z-index: 10;
}

.ladiui.fixed-sidebar .ladiui-aside {
    z-index: 11;
}

.ladiui-topbar .topbar-left {
    position: relative;
    left: 0px;
}

.ladiui-content {
    padding-top: 48px;
    display: grid;
    position: relative;
}

.ladiui-order-right .order-customer {
    position: relative;
}

.order-head-right {
    position: absolute;
    top: 70px;
    right: 33px;
}

.order-head-right a {
    color: inherit;
}

.order-customer-edit {
    display: block;
    /* position: absolute;
  top: 10px;
  right: 15px; */
    color: var(--main-primary);
}

.row-actions > li > a {
    line-height: inherit;
}

.pagination-block .per-page ul {
    bottom: 50px;
    top: inherit;
}

.ladiui-search-custom::after {
    content: '\ea41';
    font-family: 'LadiIcons';
    font-size: 18px;
    display: block;
    z-index: 9999;
    position: absolute;
    right: 0px;
    padding-right: 10px;
    line-height: 40px;
    top: 0px;
    cursor: pointer;
    z-index: 2;
}

.ladipage-account {
    font-size: 14px;
}

.ladipage-account .ladipage-account-avatar {
    margin-top: 20px;
    width: 60px;
    float: left;
    margin-right: 35px;
}

.ladipage-account .ladipage-account-avatar img {
    width: 74px;
    height: 74px;
    border-radius: 50%;
}

.ladipage-account {
    padding: 0px 20px !important;
}

.ladipage-account-info > span {
    display: block;
    line-height: 24px;
}

.ladipage-account-name {
    font-weight: 500;
}

.ladipage-account-email {
    font-size: 12px;
}

.ladipage-account-info {
    padding: 20px 0px;
}

.ladipage-account-signout {
    text-align: right;
}

.icon-form-account {
    width: 20px;
    height: 20px;
    vertical-align: middle;
    margin-right: 5px;
}

.highcharts-point {
    rx: unset !important;
    ry: unset !important;
}

.text-tooltip {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.point-tolltip {
    width: 13px;
    height: 13px;
    margin-right: 5px;
    /* background: #ff5849; */
}

.highcharts-credits {
    display: none !important;
}

.product-note {
    white-space: pre-line;
}

.ladiui.modal .search-field {
    height: 32px;
}

.ladiui.modal .search-group i {
    top: 3px;
}

/*-----------------------------------------Ladipage Home---------------------------------------*/
.ladiui.content {
    height: 90vh;
    position: relative;
    top: 64px;
    padding: 0px 40px 0px 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.ladiui.content-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    color: var(--main-default);
    margin-bottom: 16px;
}

.ladiui.content-sub {
    max-width: 530px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--content-sub-color);
    vertical-align: baseline;
}

.ladiui.sub-weight {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--content-sub-color);
    vertical-align: baseline;
}

.ladiui.content-navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 32px;
}

.ladiui.content-navigation .dropdown-toggle::after {
    content: none;
}

.ladiui.btn-primary {
    margin: 0px 0px 0px 0px;
}

.ladiui.end {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin: 0px 24px 0px 24px;
}

.ladiui.tutorial a {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-primary);
}

.ladiui.tutorial {
    border-bottom: 1px solid var(--main-primary);
}

/*---------------------------------------------------------------------------------------Landingpage list-------------------------------------------------------------------------------*/
.ladiui.content-main {
    position: absolute;
    top: 64px;
    border-left: 1px solid var(--main-line);
    width: calc(100% - 236px);
    min-width: 1012px;
    height: 91.5vh;
    right: 0px;
    margin: 0px 0px 0px 0px;
    padding: 24px 24px 40px 24px;
}

.ladiui.content-main1 {
    position: absolute;
    top: 64px;
    width: 100%;
    min-width: 1012px;
    /* height: 82vh; */
    right: 0px;
    margin: 0px 0px 0px 0px;
    padding: 24px 20px 40px 24px;
}

.ladiui.content-main-full {
    padding: 64px 0px 5px 0px;
}

.ladiui.main-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
}

.ladiui.content-list-landingpage {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: left;
}

.ladiui.content-main-title {
    justify-content: space-between;
}

.ladiui.content-tag {
    max-width: 236px;
    width: calc(100% - 236px);
    height: 100%;
    padding: 24px 24px 0px 23px;
    position: fixed;
    top: 64px;
    left: 74px;
    word-wrap: break-word;
    background-color: var(--main-white);
    z-index: 99;
}

.ladiui.content-tag-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.ladiui.content-tag-setting {
    position: absolute;
    right: 24px;
}

.ladiui.btn-primary {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ladiui.btn-custom-img {
    padding: 9px 8px 9px 0px;
    pointer-events: none;
}

.ladiui.btn-custom-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-white);
    pointer-events: none;
}

.ladiui.table {
    margin-top: 12px;
    /* min-width: 1012px; */
    width: 100%;
}

/* .ladiui.btn-group.custom-btn-list-landingpage {
    margin-left: 24px;
} */

.ladiui.content-main-tool {
    margin-top: 28px;
}

.ladiui.custom-btn-list-landingpage {
    min-width: 154px;
}
.ladiui.custom-btn-list-landingpage button {
    width: 100%;
}

.ladiui-btn-tag.landingpage-list {
    margin: 4px 4px 4px 0px;
}

.ladiui-btn-tag.landingpage-list:hover {
    background-color: var(--main-primary);
    color: var(--main-white);
    cursor: pointer;
}

.ladiui-btn-tag.landingpage-list:active {
    background-color: var(--main-primary);
    color: var(--main-white);
}

.ladiui.content-main-tool {
    height: 36px;
}

/*--------------------------------------------------------------------------------Ladipage banner-ads-list------------------------------------------------------------------------------*/
.ladiui.dropdown-toggle.custom-pre-page {
    min-width: 64px !important;
}

.ladiui.dropdown-text.custom-pre-page {
    margin: 0;
    padding: 0px 16px 0px 0px;
}

/* .ladiui.dropdown-toggle.custom-pre-page:after {
  margin: 12px 12px 12px 0px;
} */

.ladiui.dropdown-menu.custom-pre-page {
    min-width: 64px;
    bottom: 0;
}

.ladiui.btn-group.custom-pre-page {
    padding: 0;
}

.ladiui.from-filter {
    min-height: 32px;
}

.ladiui.showing {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-left: 20px;
}

.ladiui.showing span {
    vertical-align: baseline;
    -webkit-font-smoothing: antialiased;
    color: var(--main-default);
}

.ladiui.search-action {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
}

.ladiui.col-one.custom-th-banner {
    min-width: 344px;
}

.ladiui.setting-content {
    width: 236px;
    padding: 24px;
    word-wrap: break-word;
    background-color: var(--main-white);
    z-index: 99;
    border-bottom: 1px solid var(--main-line);
}

.ladiui.setting-content:last-child {
    border-bottom: none;
}

.ladiui.ladiui.content-tag-text {
    color: var(--main-default);
}

.ladiui.ladiui.content-tag-text.active {
    color: var(--main-primary);
}

.ladiui.setting-name {
    margin-bottom: 18px;
}

.ladiui.setting-item {
    margin: 32px 0;
}

.ladiui.switch-content.form-vertical {
    margin-bottom: 10px;
}

.ladiui.form-vertical {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
}

.ladiui.form-vertical:last-child {
    margin-bottom: 0;
}

.ladiui.input-name {
    width: 100px;
    margin-right: 24px;
}

.ladiui.setting-input-name {
    width: 150px;
    margin-right: 24px;
}

.ladiui.col-tow.custom-th-banner {
    min-width: 344px;
}

.ladiui.col-three.custom-th-banner {
    min-width: 269px;
}

/* -------------------------------------------------Setting---------------------------------------- */
.ladiui.setting.content-main table {
    margin-top: 18px;
}

.ladiui.setting-content a:hover {
    color: var(pr);
}

.ladiui.setting-content:last-child {
    border-bottom: none;
}

.ladiui.content-parent {
    border-bottom: 1px solid var(--main-line);
}

.ladiui.setting-content-left {
    position: fixed;
    top: 65px;
}

.ladiui.shared.setting-content-right {
    width: 636px;
    padding-bottom: 18px;
}

.ladiui.setting-content-right:last-child {
    border-bottom: none;
}

.ladiui.setting-content-right .btn-group {
    margin: 0 20px 0 0;
}

.ladiui.setting-content-right .btn-group:first-child {
    margin-left: 0px;
}

.ladiui.content-parent .table {
    margin-top: 0;
}

.ladiui.content-parent:last-child {
    border-bottom: none;
    margin-bottom: 0;
}

.ladiui.switch-btn.form-vertical {
    justify-content: end;
    margin-top: 34px;
}

.ladiui.switch-btn.form-vertical button {
    margin-right: 24px;
}

.ladiui.switch-btn.form-vertical button:last-child {
    margin-right: 0;
}

.ladiui.col-six.custom-th-banner {
    min-width: 18px;
}

.ladiui.icon.icon-ldp-list {
    margin-right: 16px;
}

/*--------------------------------------------------------------------------------banner grid----------------------------------------------------------------------------------------*/

.ladiui.card-custom {
    width: 310px;
    height: 250px;
    -webkit-filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
            filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
    border-radius: 2px;
}

.ladiui.card-img-top-custom {
    width: 310px;
    height: 250px;
    position: absolute;
}

.ladiui.card-body-custom {
    background-color: var(--main-white);
    position: relative;
    height: 60px;
    top: 190px;
}

.ladiui.card-title-custom {
    display: inline-block;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--main-default);
    margin: 8px 0px 4px 17.47px;
}

.ladiui.text-time {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: var(--main-secondary);
    margin-left: 8.73px;
}

.ladiui.card-custom-date {
    margin: 0px 0px 0px 17.47px;
}

.ladiui.banner-ads-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    grid-column-gap: 24px;
    -webkit-column-gap: 24px;
            column-gap: 24px;
    grid-row-gap: 24px;
    row-gap: 24px;
    margin-top: 24px;
}

.ladiui.content-main-tools {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start !important;
}

.ladiui.content-main-tool-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 6px;
}

.ladiui.table-show {
    display: none;
}

.ladiui.grid-show {
    display: none;
}

.ladiui.icon-list-view.tab-link,
.ladiui.icon-grid-view.tab-link {
    border-bottom: none;
}

/* ---------------------------------------------------------------------------------------intro---------------------------------------------------------------------------------- */
.ladiui.intro-content {
    padding-top: 99px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ladiui.intro-head {
    margin-bottom: 19px;
}

.ladiui.intro-header {
    text-align: center;
    margin-bottom: 65px;
}

.ladiui.intro-body {
    display: flex;
    flex-direction: row;
    margin-bottom: 48px;
}

.ladiui.intro-body-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 243px;
    text-align: center;
    margin: 0 121px;
}

.ladiui.intro-body-content i {
    margin-bottom: 53px;
}

.ladiui.intro-body-content span {
    margin-bottom: 8px;
}

.ladiui.intro-footer {
    width: 528px;
    margin-bottom: 24px;
    text-align: center;
}

/*----------------------------------------------------------------------------------------custom table dynamic--------------------------------------------------------------------------------------------------------------*/
.ladiui.col-one.custom-dynamic {
    min-width: 324px;
}

.ladiui.col-tow.custom-dynamic {
    min-width: 161px;
}

.ladiui.col-three.custom-dynamic {
    min-width: 161px;
}

.ladiui.col-threes.custom-dynamic {
    min-width: 193px;
}

.ladiui.col-for.custom-dynamic {
    min-width: 132px;
}

.ladiui.col-fors.custom-dynamic {
    min-width: 112px;
}

.ladiui.col-five.custom-dynamic {
    min-width: 112px;
}

/*Custom form dynamic*/
.ladiui.modal-content.custom-dynamic {
    min-width: 824px;
    padding: 24px;
}

.ladiui.modal-header.dynamic {
    padding: 0px 0px 4px 0px;
}

.ladiui.item.dynamic {
    margin-top: 32px;
}

.ladiui.btn-group-dynamic {
    padding: 0px;
}

.ladiui.btn-group.dynamic {
    padding: 0px;
}

.ladiui.item-form.dynamic {
    align-items: center;
}

.ladiui.switch.sw-lg.dynamic {
    margin: 0px;
}

.ladiui.item-form {
    margin: 0px;
}

.ladiui.item-form {
    padding: 0px;
}

.ladiui.search-group .dropdown-menu {
    min-width: 270px;
}

/*----------------------------------------------------------------------------------------Custom modal home----------------------------------------------------------------------------------------*/
.ladiui.modal-dialog.custom-ldp-width {
    width: 600px;
}

.ladiui.custom-ldp {
    padding: 24px 24px 24px 24px;
}

.ladiui.modal-header.custom-ldp {
    padding: 0px 0px 12px 0px;
}

.ladiui.form-group-custom {
    margin-top: 28px;
}

.ladiui.modal-footer.custom-ldp {
    padding: 48px 0px 0px 0px;
}

.ladiui.form-check-inputs-custom {
    margin-top: 32px;
}

.ladiui.radio.custom-ldp-radio {
    margin-top: 8px;
}

.ladiui.dropdown-toggle.custom-main-tool {
    margin-left: 24px;
}

.ladiui.search-width {
    min-width: 300px !important;
}

/*----------------------------------------------------------------------------------------custom modal popup--------------------------------------------------------------------------------------------------------------*/
.ladiui.modal-dialog.custom-popup-width {
    width: 718px;
}

.ladiui.label-embel-code {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--neutral-text-color);
    margin-top: 20px;
}

.ladiui.input-embel-code {
    margin-top: 16px;
}

/*----------------------------------------------------------------------------------------custom dropdown people--------------------------------------------------------------------------------------------------------------*/

.ladiui.dropdown-toggle.custom-people {
    min-width: 168px;
}

.ladiui.dropdown-menu.custom-people {
    position: absolute;
    left: 24px;
}

.ladiui.dropdown-menu.custom-people {
    padding: 0px;
}

/* --------------------------------------------------------------------------------------------Domain---------------------------------------------------------------------------------------------- */
.ladiui.domain .modal-dialog {
    width: 600px;
}

.ladiui.modal .form-group:first-child {
    margin-top: 0;
}

/* .ladiui.modal .btn-group {
  padding: 0;
  width: 552px;
} */

.ladiui.modal .btn-group .dropdown-toggle {
    min-width: 160px;
}

/* .ladiui.modal .btn-group .dropdown-menu {
  width: 552px;
} */

.ladiui.domain .span-text {
    margin-right: 40px;
}

.ladiui.domain .table-vertical td {
    padding: 0;
}

.ladiui.modal .domain-action.btn-group {
    width: 120px;
    margin-right: 16px;
}

.ladiui.modal .domain-action.btn-group .dropdown-toggle {
    min-width: 120px;
    width: 120px;
}

.ladiui.modal .domain-action.btn-group .dropdown-menu {
    min-width: 120px;
    width: 120px;
}

/* -------------------------------------------------------------------------------------------------------------Member------------------------------------------------------------------------------------------------------ */
.ladiui.member .search-field {
    width: 295px;
}

.ladiui.member .custom-btn-list-landingpage {
    width: 188px;
}

.ladiui.member .table {
    margin-top: 44px;
}

.ladiui.member .ladiui.content-main-tool {
    margin-top: 18px;
}

.ladiui.member .col-one {
    min-width: 150px;
}

.ladiui.member .col-three {
    min-width: 150px;
}

.ladiui.member table .btn-group {
    padding: 11.5px 0;
}

.ladiui .modal .btn-group.decent {
    min-width: 222px;
    width: 222px;
}

.ladiui .modal .btn-group.decent .dropdown-toggle {
    min-width: 222px;
    width: 222px;
}

.ladiui .modal .btn-group.decent .dropdown-menu {
    min-width: 222px;
    width: 222px;
}

.ladiui.auto-height.form-control {
    word-break: normal;
    word-wrap: break-word;
}

.ladiui.share-recource {
    display: grid;
    grid-template-columns: auto auto auto;
}

.ladiui.share-recource .span-text {
    margin: 0;
    padding: 8px 0 16px;
}

.ladiui.list-recource {
    display: none;
}

.ladiui.form-group .select-search .btn {
    max-width: none;
    text-overflow: unset;
}

.ladiui.form-group .select-search .dropdown-text {
    overflow: unset;
}

.ladiui.status.form-group .span-text {
    margin: 0 62px;
}

.ladiui.status.form-group .span-text:first-child {
    margin-left: 0;
}

.ladiui.status.form-group .span-text:last-child {
    margin-right: 0;
}

.ladiui.member .list-team .col-one {
    min-width: 220px;
}

.ladiui.member .list-team .col-five {
    min-width: 28px;
}

/*-----------------------------------------------------------------------------------------------------custom modal dropdown----------------------------------------------------------------------------------------*/
.ladiui.custom-ldp-width1 {
    min-width: 450px;
}

.ladiui.text.on-access-key {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-top: 8px;
}

.ladiui.modal-footer.custom-ldp1 {
    padding: 24px 0px 0px 0px;
    justify-content: left;
}

.ladiui.form-check-inputs-custom1 {
    margin-top: 20px;
}

.ladiui.radio.custom-ldp-radio1 {
    margin-top: 16px;
    margin-bottom: 8px;
}

.ladiui.member .member-img-left {
    margin-right: 16px;
}

.ladiui.member .member-img-left img {
    height: 32px;
    width: 32px;
}

.ladiui.member .member-control {
    margin: 8px 0;
}

.ladiui.member .member-control:first-child {
    margin-top: 0;
}

.ladiui.member .member-control:last-child {
    margin-bottom: 0;
}

.ladiui.modal-header.custom-data-backup {
    padding: 0px 0px 4px 0px;
}

.ladiui.modal-footer.custom-ldp-decentralization {
    padding: 54px 0px 0px 0px;
}

.ladiui.help-footer {
    margin-right: 8px;
}

.ladiui.modal-footer-link a {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.ladiui.list-user {
    margin-top: 32px;
}

.ladiui.container-list-user {
    margin: 8px 0px 16px 0px;
}

.ladiui.text-sub-custom {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-secondary);
}

.ladiui.text-title-custom {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-default);
}

.ladiui.container-list-user-left {
    display: flex;
    flex-direction: column;
}

.ladiui.container-list-user,
.ladiui.container-list-user-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

/*----------------------------------------------------------------------------------------custom button popup----------------------------------------------------------------------------------------*/
.ladiui.btn-outline-primary.custom-popup {
    font-weight: 500;
    margin-right: 24px;
}

.ladiui.modal-header.custom-popupX {
    padding: 0px 0px 4px 0px;
}

.ladiui.text-sub-notification {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-secondary);
}

.ladiui.text-sub-bold {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-default);
}

.ladiui.modal-footer.custom-ldp-popupX {
    padding: 48px 0px 0px 0px;
}

/* --------------------------------------------------------------------------------------Access------------------------------------------------------------------------------ */
.ladiui.access.content-main-tool .search-group {
    width: 270px;
}

.ladiui.modal.key-access .modal-dialog {
    width: 963px;
}

.ladiui.modal.key-access .search-group {
    width: 270px;
}

.ladiui.modal.key-access .col-first {
    width: 40px;
}

.ladiui.modal.key-access .col-one {
    min-width: 80px;
    width: 80px;
}

.ladiui.modal.key-access .col-tow {
    min-width: 117px;
    width: 124px;
}

.ladiui.modal.key-access .col-three {
    min-width: 117px;
    width: 124px;
}

.ladiui.modal.key-access .col-four {
    min-width: 116px;
    width: 116px;
    text-align: center;
}

.ladiui.modal.key-access .col-five {
    min-width: 136px;
    width: 130px;
    text-align: center;
}

.ladiui.modal.key-access .col-six {
    min-width: 16px;
    width: 85px;
    text-align: center;
}

.ladiui.modal.key-access .col-seven {
    min-width: 107px;
    width: 107px;
}

.ladiui.modal.key-access .btn-group {
    min-width: 64px;
    width: 64px;
}

.ladiui.modal.key-access .btn-group .dropdown-toggle {
    min-width: 64px;
    width: 64px;
}

.ladiui.modal.key-access table .btn-group .dropdown-menu {
    min-width: 175px;
    width: 175px;
}

.ladiui.modal.key-access .btn-group .dropdown-menu {
    min-width: 65px;
    width: 65px;
}

.ladiui.create-key .dropdown-toggle {
    min-width: 100px;
}

.ladiui.create-key .dropdown-toggle::after {
    content: none;
}

.ladiui.create-key .dropdown-menu {
    min-width: 145px;
    width: 145px;
}

.ladiui.confirm-2 .modal-footer {
    margin-top: 20px;
}

.ladiui.funnel-text {
    margin-left: 50px;
}

.ladiui.funnel-text label {
    margin-left: 246px;
}

.ladiui.funnel-text label:first-child {
    margin-left: 0;
}

.ladiui.funnel-wrapper {
    display: flex;
}

.ladiui.group-input {
    display: flex;
    align-items: center;
    border: 1px solid var(--secondary-color);
    width: 712px;
    border-radius: 2px;
}

.ladiui.group-input .form-control {
    border: 1px solid var(--main-white);
    padding-left: 8px;
}

.ladiui.space-between {
    width: 2px;
    height: 20px;
    background-color: var(--secondary-color);
}

.ladiui.wrapper-group .ladiui-btn-tag {
    width: 34px;
    height: 34px;
    padding: 6px;
    text-align: center;
    margin-right: 8px;
}

.ladiui.sortable-line {
    margin: 8px 0;
}

body {
    counter-reset: section;
}

.ladiui.sortable-line .ladiui-btn-tag::before {
    counter-increment: section;
    content: counter(section);
}

.ladiui.sortable-line:first-child {
    margin-top: 0;
}

.ladiui.sortable-line:last-child {
    margin-bottom: 0;
}

.ladiui.first.form-control {
    width: 552px;
}

.ladiui.group-input .icon-ldp-drag {
    cursor: grab;
    pointer-events: all;
    margin-right: 10px;
}

.ladiui.sortable-line .icon-ldp-delete {
    cursor: pointer;
    pointer-events: all;
    margin-left: 10px;
}

.ladiui.funnel .proviso {
    padding: 16px 0 0 0;
}

.ladiui.funnel td {
    padding-top: 10px;
    padding-bottom: 10px;
}

.ladiui.funnel .col-first {
    width: 16px;
}

.ladiui.funnel .col-one {
    width: 470px;
}

/*----------------------------------------------------------------------------------------dynamic-report----------------------------------------------------------------------------------------*/
.ladiui.dynamic-report-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    color: var(--main-default);
}

.ladiui.dynamic-report {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
}

.ladiui.dynamic-report-type {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.ladiui.dynamic-label {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--main-secondary);
}

.ladiui.dynamic-span {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--main-default);
    margin-left: 8px;
}

.ladiui.dropdown-select-dynamic {
    margin-top: 10px;
}

.ladiui.btn-group.pull-right {
    margin-left: 24px;
}

.ladiui.dynamic-report-total {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 32px;
    margin-bottom: 50px;
}

.ladiui.report-total-item {
    border: 1px solid var(--main-primary);
    border-radius: 10px;
    min-width: 188px;
    height: 106px;
}

.ladiui.report-total-item-lable {
    padding: 24px 0px 0px 24px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-default);
}

.ladiui.report-total-item-number {
    padding: 4px 0px 0px 24px;
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    color: var(--main-default);
}

.ladiui.dynamic-report-bg {
    border: 1px solid var(--main-line);
    margin-top: 50px;
    border-radius: 5px;
}

.ladiui.table.boder {
    margin-top: 0px;
}

.ladiui.table-vertical.header.item th {
    padding: 16px 16px 0px 16px;
}

.ladiui.table-vertical.main.item td {
    padding: 16px 16px 0px 16px;
}

.ladiui.table-vertical.main.end td {
    padding: 16px 16px 25px 16px;
}

.ladiui.search-action.custom {
    margin: 0px 16px 16px 16px;
}

.ladiui.report-convert {
    display: grid;
    grid-template-columns: 8fr 4fr;
    grid-gap: 24px;
    margin-top: 24px;
}

.ladiui.report-convert-left {
    border-radius: 10px;
    border: 1px solid var(--secondary-color);
}

.ladiui.report-convert-right {
    border-radius: 10px;
    border: 1px solid var(--secondary-color);
}

.ladiui.report-convert-title {
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: var(--main-default);
    margin: 16px 0px 16px 0px;
}

.ladiui.report-convert-right {
    text-align: center;
}

.ladiui.report-convert-right img {
    margin-top: 32px;
}

/* ----------------------------------------------------------------------------------conversion-tool--------------------------------------------------------------------------------------- */
.ladiui.conversion-tab {
    height: 80vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.ladiui.conversion-content-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: var(--main-default);
    margin-bottom: 16px;
}

/*custom modal dynamic*/
.ladiui.modal .btn-group.custom-form-dynamic {
    width: 180px;
}

.ladiui.modal .btn-group .dropdown-toggle.custom-form-dynamic,
.ladiui.modal .btn-group .dropdown-menu.custom-form-dynamic {
    width: 180px;
}

.ladiui.item-form.sub1 {
    margin-left: 24px;
}

.ladiui.switch.custom-form-dynamic {
    margin: 0px;
}

.ladiui.item-form.custom-form-dynamic {
    margin-left: 42px;
}

.ladiui.title-text-form-lever2.custom-form-dynamic {
    margin-bottom: 16px;
}

.ladiui.item.flex-row.custom-form-dynamic {
    align-items: flex-start;
}

.ladiui.dropdown-menu.custom-dropdown-dynamic {
    width: 200px !important;
}

.ladiui.btn-group.col-2,
.ladiui.dropdown-toggle.col-2,
.ladiui.dropdown-menu.col-2 {
    width: 320px !important;
    margin-right: 16px;
}

.ladiui.itemss {
    justify-content: flex-start;
}

.ladiui.items-1 {
    width: 310px;
}

.ladiui.items-2 {
    position: relative;
    width: 310px;
    margin-left: 16px;
}

.ladiui.items-3 {
    width: 90px;
    margin-left: 16px;
}

.ladiui.items-4 {
    position: relative;
    width: 704px;
}

.ladiui.flex-row.custom-create-dynamic {
    margin-top: 4px;
}

.ladiui.modal-footer.custom-ldp-popupX.mod {
    padding-top: 36px;
}

.ladiui.infomation-link {
    margin-bottom: 19px;
    margin-left: 4px;
}

.ladiui.infomation-links {
    margin: 0px 0px 12px 4px;
}

.ladiui.dropdown-toggle.custom-main-tool.dynamic,
.ladiui.dropdown-menu.custom-dynamic {
    width: 320px !important;
    margin-left: 16px;
}

.ladiui.btn-group.custom-dynamic {
    width: 336px !important;
}

.ladiui.bg-form-custom.custom-dynamic {
    margin-top: 28px;
}

.ladiui.subtitle-2.custom-dynamic {
    margin-top: 8px;
    font-weight: 400;
    margin-left: 326px;
}

.ladiui.icon-add-input {
    position: absolute;
    top: 8px;
    right: 8px;
}

.ladiui.icon-add-input1 {
    position: absolute;
    top: 5px;
    right: 8px;
}

.ladiui.flex-row.custom-dynamic-s {
    text-align: center;
}

.ladiui.infomation-linkss {
    margin-left: 8px;
}

.ladiui.icon-search.custom {
    display: block;
    position: relative;
    left: 0px;
    margin: 18px 8px 2px 0px;
}

.ladiui.search-tag-fixed {
    align-items: baseline;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-mute);
}

.ladiui.inpud-search-tag-fixed {
    position: relative;
    bottom: 3px;
    width: 165px;
}

.ladiui.delete-right {
    margin-left: 16px;
}

.ladiui.dropdown-group-form {
    max-width: 744px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ladiui.dropdown-toggle.select-dropdown.col-3 {
    width: 280px !important;
}

.ladiui.btn-group.ctm {
    width: 196px !important;
}

.ladiui.btn-group.ctms {
    width: 280px !important;
    margin-left: 16px;
}

.ladiui.dropdown-menu.ctms {
    width: 280px !important;
}

.ladiui.btn-group.ctm.col2 {
    margin-left: 16px;
}

.ladiui.conversion.content-main {
    display: none;
}

.ladiui.conversion.main .tab-link {
    border-bottom: none;
    margin-left: 12px;
}

/* ---------------------------------------------------------------------------------------Tracking global --------------------------------------------------------------------------------------- */
.ladiui.nav-tracking {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
}

.ladiui.tracking-area .ladiui.nav-tracking .tab-link {
    border-bottom: 1px solid var(--main-line);
}

.ladiui.tracking-area .ladiui.nav-tracking .tab-link.active {
    border-bottom: 2px solid var(--main-primary);
}

.ladiui.tracking-area .ladiui.tab-content .tab-pane {
    margin-top: 0;
}

.ladiui.tracking-list .col-four {
    min-width: 200px;
    width: 200px;
}

.ladiui.tracking-list .col-first {
    min-width: 40px;
    width: 40px;
}

.ladiui.account-tracking img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
}

/* ------------------------------------------------------------------------------------------conversion-link-------------------------------------------------------------------------------------------- */
.ladiui.grid-link {
    width: 795px;
    height: 384px;
    display: grid;
    grid-template-columns: 263px 263px 263px;
    grid-template-rows: 136px 136px 136px 136px 136px 136px 136px 136px 136px 136px;
    overflow-y: auto;
}

.ladiui.link-box {
    cursor: pointer;
    width: 250px;
    height: 120px;
    border: 1px solid var(--main-line);
    border-radius: 2px;
}

.ladiui.link-box:hover {
    border: 1px solid var(--main-primary);
}

.ladiui.link-header {
    pointer-events: none;
    margin-bottom: 24px;
    padding: 16px 16px 0 16px;
}

.ladiui.link-header img {
    margin-right: 16px;
}

.ladiui.link-body {
    pointer-events: none;
    padding: 0px 16px 16px 16px;
    color: var(--main-secondary);
}

.ladiui.modal-header img {
    margin-right: 16px;
}

.ladiui.header-gmail {
    width: 36px;
    height: 24px;
}

.ladiui.header-ladisale {
    width: 32px;
    height: 32px;
}

.ladiui.flex.input .ladiui.form-group {
    width: 100%;
    margin: 24px 12px 0;
}

.ladiui.flex.input .ladiui.form-group:first-child {
    margin-left: 0;
}

.ladiui.flex.input .ladiui.form-group:last-child {
    margin-right: 0;
}

.ladiui.modal .btn-group .ladiui.smtp.dropdown-toggle {
    min-width: 100px;
    width: 100px;
}

.ladiui.modal .btn-group .ladiui.smtp.dropdown-menu {
    min-width: 100px;
    width: 100px;
}

.ladiui.modal .smtp.flex .btn-group {
    min-width: 100px;
    width: 100px;
}

.ladiui.smtp-host {
    width: 264px;
}

.ladiui.smtp-port {
    width: 140px;
}

.ladiui.modal .btn-group .ladiui.esms.dropdown-toggle {
    min-width: 264px;
    width: 264px;
}

.ladiui.modal .btn-group .ladiui.esms.dropdown-menu {
    min-width: 264px;
    width: 264px;
}

.ladiui.modal .esms.flex .btn-group {
    min-width: 264px;
    width: 264px;
}

.ladiui.form-group .btn.convert {
    margin-top: 27px;
}

.ladiui.ggform-url .form-group .form-control {
    width: 450px;
}

.ladiui.form-field {
    margin-top: 16px;
    width: 100%;
}

.ladiui.form-field .icon {
    margin: 0 16px;
}

.ladiui.form-field .icon:last-child {
    margin-right: 0;
}

.ladiui.form-field .form-control {
    width: 252px;
}

.ladiui.form-field .btn-group {
    min-width: 220px;
    width: 220px;
}

.ladiui.modal .form-field .btn-group .dropdown-toggle {
    min-width: 220px;
    width: 220px;
}

.ladiui.modal .form-field .btn-group .dropdown-menu {
    min-width: 220px;
    width: 220px;
}

.ladiui.form-field .size-checkbox {
    margin: 0 12px 0 16px;
}

.ladiui.type-account {
    align-items: center;
    text-align: center;
}

.ladiui.type-account img {
    margin: 0 8px 0 16px;
    height: 24px;
    width: 24px;
}

.ladiui.type-account .custom-img {
    height: 16px;
    width: auto;
}

/* ----------------------------------------------------------------------------------Conversion-configuration--------------------------------------------------------------------------------------------- */
.ladiui.config-box .about {
    text-align: center;
    width: 190px;
    margin-top: 8px;
}

.ladiui.config .search-group {
    min-width: 270px;
}

.ladiui.config .step {
    margin: 16px 0 32px;
}

.ladiui.config .step:last-child {
    margin: 40px 0 0;
}

.ladiui.grid-linking-form {
    display: grid;
    align-items: baseline;
    grid-template-columns: 350px auto auto;
    padding: 16px 0;
}

.ladiui.linking-form-group {
    padding-right: 20px;
    margin-right: -20px;
    max-height: 250px;
    overflow-y: auto;
}

.ladiui.setting-config .grid-linking-form {
    display: grid;
    align-items: baseline;
    grid-template-columns: 300px auto auto;
    padding: 16px 0;
}

/* .ladiui.linking-form-group::-webkit-scrollbar {
  width: 3px;
} */

.ladiui.linking-form-group .grid-linking-form {
    border-bottom: 1px solid var(--main-line);
}

.ladiui.grid-linking-form .type-account {
    text-align: start;
}

.ladiui.grid-linking-form .type-account .account-tracking img {
    margin-left: 0;
    width: 16px;
    height: 16px;
}

.ladiui.grid-linking-form .select-button {
    text-align: end;
}

.ladiui.grid-linking-form .select-button .edit-icon {
    margin-right: 10px;
}

/*------------------------------------------------------------------------------------------template----------------------------------------------------------------------------------*/
.ladiui.content-main-tool.custom-template {
    margin-top: 0px;
}

.ladiui.content-main-tab {
    padding-bottom: 16px;
    margin-top: 24px;
    border-bottom: 1px solid var(--main-line);
}

.ladiui.content-main-tab-flex {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.ladiui.content-main-tab-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.ladiui.content-main-item-hover {
    display: none;
    position: absolute;
    top: 20px;
    left: -72px;
    background-color: var(--main-white);
    z-index: 999;
}

.ladiui.content-main-grid {
    min-width: 824px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.ladiui.content-main-item.col4 {
    margin: 24px 24px 24px 24px;
}

.ladiui.content-main-item-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--main-default);
    margin-bottom: 8px;
}

.ladiui.content-main-item-subtext {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-default);
    margin-bottom: 8px;
    width: 250px;
}

.ladiui.content-main-tab-item.one:hover .ladiui.content-main-item-hover {
    display: block;
}

.ladiui.content-main-tab-item.tow:hover .ladiui.content-main-item-hover.tow {
    display: block;
}

.ladiui.content-main-tab-item.three:hover .ladiui.content-main-item-hover.three {
    display: block;
}

.ladiui.content-main-tab-item.for:hover .ladiui.content-main-item-hover.for {
    display: block;
}

.ladiui.content-main-item-hover.tow {
    width: 265px;
}

.ladiui.content-main-tab-item.one:hover .ladiui.content-tab-text {
    color: var(--main-primary);
}

.ladiui.custom-width-tab {
    margin-left: 32px;
}

.ladiui.custom-width-tab-item {
    margin-left: 4px;
}

.ladiui.content-tab-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    pointer-events: none;
    color: var(--main-default);
}

.ladiui.content-main-tab-item.tab-link {
    padding-bottom: 0px;
}

.ladiui.content-main-tab-item.tab-link.active {
    border: 0px;
}

.ladiui.content-tab-icon {
    pointer-events: none;
}

.ladiui.tab-item-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-secondary);
    margin: 24px 0px 4px 0px;
}

.ladiui.tab-item-text-sub {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--main-default);
    margin-bottom: 16px;
}

.ladiui.custom-btn {
    padding: 6px 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    border-radius: 2px;
    color: var(--main-black);
    background-color: var(--main-white);
    border: 1px solid var(--main-line);
    cursor: pointer;
}

.ladiui.custom-btn:hover {
    color: var(--main-white);
    background-color: var(--hover-primary);
}

.ladiui.custom-btn:active {
    color: var(--main-white);
    background-color: var(--main-primary);
}

.ladiui.custom-btn.active {
    color: var(--main-white);
    background-color: var(--main-primary);
    cursor: pointer;
}

.ladiui.group-item-main-sub {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ladiui.custom-left-group-button {
    margin-left: 8px;
}

.ladiui.template-grid {
    min-width: calc(100% / 3 - 20px);
    min-height: 290px;
    border-radius: 2px;
    margin-top: 32px;
    position: relative;
    border: 1px solid var(--main-line);
}

.ladiui.opacity-img {
    width: 100%;
    height: 100%;
}

.ladiui.template-grid:hover .ladiui.opacity-img {
    opacity: 0.09;
}

@media (max-width: 1371px) {
    .ladiui.template-grid {
        width: calc(100% / 2 - 20px);
        min-height: 290px;
        border-radius: 2px;
        margin-top: 32px;
        position: relative;
    }

    .ladiui.opacity-img {
        min-width: calc(100% / 2 - 20px);
        min-height: 250px;
    }
}

.ladiui.template-grid-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--main-default);
    margin-top: 16px;
}

.ladiui.template-grid-type {
    position: absolute;
    top: 8px;
    left: 8px;
}

.ladiui.template-grid-type-hover {
    position: absolute;
    top: 8px;
    left: 8px;
    display: none;
}

.ladiui.template-grid-button {
    width: 100px;
    height: 36px;
    background-color: var(--main-primary);
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-white);
}

.ladiui.group-button-template-grid {
    position: absolute;
    top: 107px;
    left: calc((100% - 216px) / 2);
    display: none;
}

.ladiui.template-grid:hover .ladiui.group-button-template-grid {
    display: block;
}

.ladiui.template-grid:hover .ladiui.template-grid-type-hover {
    display: block;
}

.ladiui.template-grid:hover .ladiui.template-grid-type {
    display: none;
}

.ladiui.template-grid:hover .ladiui.opacity-img {
    background-color: var(--main-white);
}

.ladiui.template-grid-button {
    cursor: pointer;
}

.ladiui.template-grid-button:hover {
    color: var(--main-white);
    background-color: var(--hover-primary);
}

.ladiui.content-main-item-subtext:hover {
    color: var(--main-primary);
}

.ladiui.template-btn-show-more {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-primary);
}

.ladiui.show-more {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
    cursor: pointer;
}

.ladiui.template-btn-show-more {
    margin-bottom: 6px;
}

.ladiui.banner-ads-grid.showcase {
    margin-top: 0px;
}

.ladiui.modal-dialog.showcase {
    width: 824px;
    text-align: center;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    border-radius: 10px;
}

.ladiui.modal-footer.showcase {
    justify-content: center;
}

.ladiui.show-case-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
}

.ladiui.show-case-sub {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin: 31px 0px 48px 0px;
}

.col-50 {
    width: 48%;
    float: left;
    margin: 0px 15px;
}

.ladiui-row > .col-50:first-child {
    margin-left: 0px;
}

.ladiui-row > .col-50:last-child {
    margin-right: 0px;
    float: right;
}

.ladiui-search-dropdown {
    border-radius: 4px;
    position: relative;
    min-width: 200px;
    height: 40px;
    user-select: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.ladiui-search-dropdown > input.ladiui-search-input {
    padding: 0px 15px;
    border: 1px solid #ebedf2;
    border-radius: 4px;
    position: absolute;
    z-index: 2;
    background: #fff;
    cursor: pointer;
    color: #313942;
    top: 0;
    left: 0px;
    width: 100%;
    outline: 0;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.ladiui-search-dropdown > .text {
    display: inline-block;
    transition: none;
    cursor: text;
    position: relative;
    left: 3px;
    z-index: 3;
    line-height: 40px;
    pointer-events: none;
    font-weight: 400;
    color: #313942;
    width: 100%;
    padding: 0px 15px;
}

.ladiui-search-dropdown > .text.open {
    color: #b3b3b3 !important;
    font-weight: 300;
}

.ladiui-search-dropdown .ladiui-search-dropdown-menu {
    border-color: #96c8da;
    /* box-shadow: 0 2px 3px 0 rgba(34, 36, 38, .15); */
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    position: absolute;
    top: 42px;
    left: 0px;
    border-radius: 4px;
    display: none;
    background: #fff;
    height: -webkit-max-content;
    height: max-content;
    max-height: 300px;
    overflow-y: scroll;
    width: 100%;
    z-index: 10;
}

.ladiui-search-dropdown .ladiui-search-dropdown-menu > .item {
    position: relative;
    cursor: pointer;
    white-space: normal;
    word-wrap: normal;
    display: block;
    height: auto;
    padding: 10px 15px;
    text-align: left;
    border-top: none;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 400;
    box-shadow: none;
    background: #fff;
    line-height: 24px;
    border-bottom: 1px solid #f8fafb;
}

.ladiui-search-dropdown .ladiui-search-dropdown-menu > .item:hover {
    background: #f8fafb;
}

.ladiui-search-dropdown > span {
    display: block;
    z-index: 9999;
    position: absolute;
    right: 0px;
    padding-right: 10px;
    line-height: 40px;
    top: -3px;
    cursor: pointer;
}

.ladiui-search-dropdown > span {
    position: relative;
}

.ladiui-search-dropdown > span::after {
    display: block;
    z-index: 2;
    position: absolute;
    right: 0px;
    padding-right: 10px;
    line-height: 40px;
    top: 4px;
    cursor: pointer;
}

.ladiui-search-dropdown::after {
    content: '\ea4a';
    font-family: 'LadiIcons';
    font-size: 12px;
    display: block;
    position: absolute;
    right: 0px;
    padding-right: 10px;
    line-height: 40px;
    top: 0px;
    cursor: pointer;
    z-index: 2;
}

.hide-mt .ladiui.dropdown-toggle:after {
    /* display: none !important; */
    /* background: #ffffff !important; */
    background: unset !important;
}

.ba-c .ladiui.dropdown-toggle {
    min-width: unset !important;
    border: none !important;
}

.ladiui.icon-ldp-fb-group {
    width: 14px !important;
    height: 14px !important;
}

.page-checkout-type::first-letter {
    text-transform: uppercase;
}

/** @format */

body {
    line-height: 1.2em;
    font-size: 14px;
}

a {
    color: var(--main-primary);
}

.variant-active {
    color: #fff;
    background-color: #1853fa;
    border-color: #1853fa;
}

.variant-option-item {
    /* padding-top: 12px; */
    clear: both;
    width: 100%;
}

.ladiui-form-group.variant-option-list .list-item {
    margin-bottom: 12px;
}

.variant-option-item .ladiui-tags {
    border: 1px solid #ebedf2;
    border-radius: 4px;
}

.variant-option-item .ladiui-tags>input {
    border: none;
}

.variant-text {
    color: #1853fa;
    padding-left: 20px;
}

#gallery-create,
#gallery-edit {
    width: 400px;
    height: 430px;
}

.ladi-gallery-container:hover .ladi-gallery-control-item {
    opacity: 0.3;
}

.ladi-gallery-container:hover .close-image {
    opacity: 1;
}

.close-image {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.search-panel li {
    display: inline-block;
    padding: 0px 20px 0px 20px;
    width: 30%;
}

.discount-option {
    position: relative;
    display: initial;
}

.shipping-option {
    position: relative;
    display: initial;
}

.discount-box {
    width: 300px;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    z-index: 7;
    background: #fff;
    padding: 10px;
    border-radius: 4px;
}

.discount-option .discount-reason {
    margin: 15px 0px;
}

.discount-option .discount-reason>label {
    display: inherit;
    text-align: left;
}

.discount-button>button {
    margin-left: 15px;
}

.discount-button-active {
    color: #6c7293 !important;
    border-color: #ebedf2 !important;
    background-color: #f4f5f8 !important;
}

.shipping-box {
    width: 450px;
    left: 440px;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    z-index: 7;
    background: #fff;
    padding: 20px;
    border-radius: 4px;
}

.shipping-item {
    width: 48.5%;
    margin-right: 10px;
    display: inline-block;
}

.shipping-group {
    margin-bottom: 15px;
}

.shipping-group .shipping-item:last-child {
    margin-right: 0px;
}

.shipping-button>button {
    margin-left: 15px;
}

/* .order-address-dropdown {
    cursor: pointer;
    padding: 5px !important;
} */

/* .order-address-dropdown:hover {
    background-color: #f8fafb;
} */

@media (min-width: 1366px) {
    .order-page {
        width: 85%;
        margin: 0px auto;
    }

    .setting-page {
        width: 85%;
        margin: 0px auto;
    }

    .shipping-page {
        width: 85%;
        margin: 0px auto;
    }
}

.customer-info>span {
    display: flex;
    align-items: center;
    line-height: inherit;
    margin-bottom: 16px;
    font-size: 14px;
}

.customer-info>span img {
    margin-right: 8px;
}

.order-custom-field .more-info span i {
    margin-right: 8px !important;
}

.discount-date {
    width: 40%;
}

.ladiui.form-group .discount-item {
    margin-bottom: 16px;
    display: flex;
}

.ladiui.form-group .discount-item:last-child {
    margin-bottom: 0px;
}

.ladi-date-picker-icon {
    z-index: 0 !important;
}

.order-head-right {
    position: absolute;
    top: 28px;
    right: 0px;
}

.order-page {
    position: relative;
}

.ladiui.table .order-table-item {
    color: var(--main-primary);
    font-weight: 400;
}

.ladiui.table .ladiui.checkall {
    width: 40px;
}

.builder-loading {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    position: fixed;
    overflow: hidden;
    background-color: #fafafa;
    z-index: 900000080;
    background-size: 128px;
}

.builder-loading-blur {
    background-color: rgba(12, 97, 242, 0.05);
}

.setting-page {
    position: relative;
}

.setting-content {
    background-color: #fff;
    padding: 15px;
}

.setting-content>ul {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
}

.setting-content>ul::after {
    content: '';
    width: 400px;
}

.setting-item {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    line-height: 1.4;
    font-size: 15px;
}

/* .setting-item:hover {
    background-color: #f9fafb;
    text-decoration: none;
    outline: none;
    cursor: pointer;
    border-radius: 3px;
} */

.setting-item .title {
    font-weight: 500;
    margin: 0;
    color: #1853fa;
}

.setting-item .description {
    color: #637381;
}

.setting-item .icon {
    width: 40px;
    height: 40px;
    margin-right: 15px;
    text-align: center;
    background-color: #dfe3e8;
    border-radius: 3px;
}

.setting-item .icon .ladi-icon {
    margin-top: 12px;
}

.setting-item .text {
    width: 315px;
}

.account-content {
    margin-top: 5px;
    font-size: 14px;
}

/* .account-table .ladiui.table>tbody>tr>td:first-child {
    padding: 10px !important;
} */

.account-table .avatar {
    border-radius: 50%;
}

.account-body .ladiui-table-actions {
    min-height: 62px;
    border-radius: 5px;
    padding: 10px;
}

.account-body .ladiui-table-actions .ladiui.form-group {
    display: flex;
    align-items: center;
}

.account-body .ladiui-table-actions .ladiui.form-group .ladiui-label {
    margin-right: 15px;
}

.account-body .ladiui-table-actions .ladiui.form-group .data-permission {
    width: 200px;
}

.scope-group {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

.scope-group .scope-parent {
    margin-right: 50px;
    margin-bottom: 5px;
}

.scope-group .scope-parent>label {
    font-weight: 400;
}

.scope-group .scope-children {
    margin-left: 20px;
}

.scope-group .scope-children>label {
    margin-right: 15px;
}

.upgrade-heading {
    font-weight: 500;
    margin: 20px 0px;
}

.upgrade-content {
    width: 650px;
    margin: 0px auto;
    background-color: #fff;
    padding: 15px;
    border-radius: 3px;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
}

.upgrade-package>span {
    display: inline-block;
    margin-right: 30px;
}

.upgrade-package label {
    font-weight: 500;
    font-size: 14px;
}

.upgrade-package-time li {
    display: inline-block;
    background: #fafafa;
    padding: 10px 15px;
}

.upgrade-package-time li.active {
    background-color: #1853fa;
    color: #fff;
}

.upgrade-checkout {
    margin-top: 50px;
}

.upgrade-total {
    display: block;
    float: right;
    margin-top: -22px;
    font-weight: 500;
}

.product-variants small {
    font-size: 15px;
    /* font-style: italic; */
}

.ladiui-product-right .ladiui.textarea {
    min-height: 100px;
}

.condition-group>label {
    position: relative;
    top: -10px;
    background: #fff;
    display: inline-block;
    padding-right: 10px;
}

.condition-group {
    border-top: 1px solid #ebedf2;
    clear: both;
}

#modal-discount .ladiui.form-group {
    display: inline-table;
}

.ls-dashboard-chart {
    margin: 20px 0px;
}

.ls-dashboard-chart>div {
    height: 250px !important;
}

.ls-dashboard-chart .highcharts-container {
    height: 250px !important;
}

.search-variant .variant {
    display: inline-block;
}

.search-variant .image {
    width: 20%;
}

.search-variant .name {
    width: 60%;
}

.shipping-not-delivery .title .icon-delivery {
    font-size: 20px;
    margin-right: 10px;
}

.shipping-not-delivery .title h3 {
    text-transform: uppercase;
    display: inline-block;
}

.shipping-not-delivery .title button {
    float: right;
}

#modal-shipping-form .ladiui-row {
    display: flex;
}

#modal-shipping-form .left {
    flex: 0.6 1;
    margin-right: 50px;
}

#modal-shipping-form .right {
    flex: 0.4 1;
    position: relative;
}

#modal-shipping-form .shipping-footer {
    display: flex;
    justify-content: space-between;
}

#modal-shipping-form .shipping-footer .button {
    margin-left: 10px;
}

#modal-shipping-form .ladiui-row .ladiui.form-group {
    margin-right: 15px;
}

#modal-shipping-form .ladiui-row .ladiui.form-group:last-child {
    margin-right: 0px;
}

#modal-shipping-form .ladiui-row .coupon {
    flex: 0.4 1;
}

#modal-shipping-form .ladiui-row .size {
    flex: 1 1;
}

#modal-shipping-form .order-info {
    overflow: hidden;
    background: #fff;
    padding: 24px 0px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.order-info .heading h3 {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 24px;
}

#modal-setting-shipping #tab-service .item {
    margin-bottom: 15px;
    border-radius: 10px;
}

#modal-setting-shipping #tab-service .item:last-child {
    margin-bottom: 0px;
}

#modal-setting-shipping #tab-service .item .shipping-partner-logo {
    height: 25px;
}

#modal-setting-shipping #tab-service .item .description {
    margin-top: 15px;
    padding-bottom: 15px;
    line-height: 22px;
    font-size: 15px;
}

#modal-setting-shipping #tab-service .item .partner-account {
    margin-top: 15px;
}

#modal-setting-shipping #tab-service .item .partner-footer {
    display: flex;
    padding-bottom: 15px;
    border-bottom: 1px solid #ebedf2;
    justify-content: flex-end;
}

#modal-setting-shipping #tab-service .item .partner-footer .right button {
    margin-left: 10px;
}

#modal-setting-shipping #tab-service .item .partner-footer .left button {
    margin-left: 10px;
}

#modal-shipping-form #tab-service .select-hub {
    display: inline-block;
    width: 210px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.shipping-delivered .title {
    display: flex;
    justify-content: flex-start;
}

.shipping-delivered .title i {
    margin-right: 15px;
    color: #16bf0e;
    font-size: 20px;
}

.shipping-detail {
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    padding: 15px;
    border-radius: 4px;
    overflow: inherit;
    min-width: 325px;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    background-color: #fff;
}

.shipping-detail .title {
    display: flex;
}

.shipping-detail .title h3 {
    font-weight: 500;
    margin-right: 30px;
}

.shipping-detail .badge-status {
    background-color: #feaf9a;
    border-radius: 20px;
    padding: 6px 10px 0px 10px;
}

.shipping-detail .badge-status label {
    font-size: 13px;
}

.shipping-detail .shipping-action {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #ebedf2;
    margin-top: 15px;
}

.shipping-detail .shipping-action button {
    margin-top: 15px;
}

.ladiui.button.disabled {
    color: #6c7293;
    border-color: #ebedf2;
    background-color: #f4f5f8;
}

.ladiui-shipping-left {
    width: 70%;
    float: left;
    position: relative;
}

.ladiui-shipping-right {
    width: 28%;
    float: right;
}

.shipping-page {
    position: relative;
}

.shipping-head-right {
    position: absolute;
    top: 28px;
    right: 0px;
}

.shipping-head-right .placed-on {
    margin-right: 30px;
}

.shipping-head-right .placed-on span:first-child {
    font-weight: 500;
}

.ladiui-shipping-right .order-customer {
    position: relative;
}

.shipping-info {
    border-bottom: 1px solid #f1f3f4;
    padding-bottom: 20px;

    /* overflow: hidden;
    background: #fff;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 20px;
    box-shadow: 0 0px 11px 0 rgba(0, 0, 0, .1); */
}

.shipping-info .shipping-info-heading h3 {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
}

.shipping-info .item {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    align-items: center;
}

.shipping-info .item.status {
    border-top: 1px solid #ebedf2;
    padding-top: 15px;
}

.shipping-info .item.status .ladiui.button {
    padding: 2px 15px;
}

.shipping-stack-total.hide-border {
    border: none !important;
}

.ladiui-shipping-left {
    background: #fff;
}

.ladiui-shipping-left .shipping-item-box {
    background: #fff;
    display: flex;
    justify-content: space-between;
    padding: 20px 15px 20px 15px;
    border-bottom: 1px solid #efefef;
    border-top: 1px solid #efefef;
}

.ladiui-shipping-left .shipping-item-box h3 {
    font-weight: 500;
    font-size: 17px;
}

.ladiui-shipping-left .shipping-item-box h3 i {
    margin-right: 20px;
}

.ladiui-shipping-left .actions {
    padding: 20px 15px 0px 15px;
    justify-content: space-between;
}

#modal-image-variant .image-variant .item {
    padding: 3px;
    border: 1px solid rgba(195, 207, 216, 0.3);
    cursor: pointer;
    display: inline-block;
    margin: 5px;
}

.selected-image-variant {
    border: 1px solid #0b61f2 !important;
    border-radius: 3px;
}

#modal-image-variant .image-variant .item img {
    display: block;
    margin: auto;
}

.topbar-profile .ladiui.avatar {
    background-color: #1853fa;
    color: white;
    width: 35px;
    height: 35px;
    display: inline-flex;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    margin-top: -7px;
    margin-right: 0px;
    background-size: contain !important;
}

#modal-new-product .info {
    display: flex;
}

#modal-new-product .info>div {
    margin-right: 15px;
}

#modal-new-product .info>div:first-child {
    flex: 0.5 1;
}

#modal-new-product .info>div:nth-child(2) {
    flex: 0.3 1;
}

#modal-new-product .info>div:nth-child(3) {
    flex: 0.2 1;
}

#modal-new-product .info>div:last-child {
    margin-right: 0px;
}

.custom-input-autocomplete {
    cursor: pointer;
}

.async-auto-complete.menu {
    margin-left: -13px;
    margin-top: 10px;
    max-height: 175px !important;
    min-height: 100px !important;
    /* min-width: 552px!important; */
    width: 100%;
    z-index: 999999;
}

.async-auto-complete.menu .item {
    overflow: hidden;
    padding: 8px 16px;
    text-overflow: ellipsis;
    white-space: nowrap;

    position: relative;
    display: table;
    width: 100%;
    cursor: pointer;
}

.async-auto-complete.menu .item .image {
    text-align: left;
    width: 55px;
    display: table-cell;
}

.async-auto-complete.menu .item .name {
    display: table-cell;
    vertical-align: middle;
    text-align: left;
}

.async-auto-complete.menu .item .price {
    text-align: right;
    display: table-cell;
    vertical-align: middle;
}

#autocomplete-customer .item .name span {
    font-weight: 500;
}

.condition-group {
    border-top: 1px solid #ebedf2;
    clear: both;
    margin: 10px 0px;
}

.ladiui-tabs li.active a {
    padding-bottom: 8px;
}

.ladiui-modal-content .ladiui-tabs {
    margin: 0px -15px;
}

.ladiui-tab-item .ladiui.table {
    box-shadow: none;
}

.ladiui-tab-content .ladiui.input,
.ladiui-tab-content .ladiui.select {
    box-shadow: none;
    -webkit-box-shadow: none;
}

.ladiui-label,
.variant-option-head>span {
    /* font-size: 15px; */
    font-size: 14px;
    font-weight: 500;
}

.discount-from-date,
.discount-to-date {
    width: 200px;
}

.discount-from-date {
    margin-right: 20px;
}

.discount-check-endate {
    width: 196px;
    vertical-align: middle;
    display: block;
    overflow: hidden;
    line-height: 93px;
}

.discount-from-date,
.discount-check-endate {
    float: left;
}

.discount-to-date {
    float: left;
    margin-right: 20px;
}

.discount-to-date label,
.discount-from-date label {
    margin: 5px 0px;
    display: block;
}

.ladiui-input-group-prepend button {
    height: 40px;
}

.discount-button-active {
    background: var(--main-primary) !important;
    color: #fff !important;
    border-top: 0px !important;
    border-bottom: 0px !important;
}

.ladiui-tag-list {
    display: flex;
    /* flex-wrap: wrap; */
}

.ladiui-tag-list .ladiui.dropdown-toggle {
    min-width: unset !important;
    border: none !important;
}

.ladiui-tag-list .ladiui.dropdown-toggle:after {
    /* display: none !important; */
    /* background: #ffffff !important; */
    background: unset !important;
}

.ladiui-tag-list .dropdown-text {
    color: var(--main-primary) !important;
}

.ladiui-tags-label {
    display: flex;
    justify-content: space-between;
}

.ladiui-tags-label .all-tag {
    color: rgb(26, 115, 232);
    cursor: pointer;
    font-weight: 400;
}

.modal-all-tag .ladiui.form-group {
    min-height: 70px;
}

.modal-all-tag .ladiui-tags {
    border: 0px;
}

.modal-all-tag .tag {
    cursor: pointer;
}

.disabled-tag {
    background: #1853fa59 !important;
}

.ladiui-tags {
    max-width: 100%;
    border: 1px solid #f1f3f4;
    border-radius: 2px;
    min-height: 35px !important;
    display: flex !important;
    flex-wrap: wrap !important;
    padding: 5px 4px;
}

.ladiui-tags .tag {
    position: relative;
    display: flex;
    align-items: center;
    float: left;
    color: var(--main-default);
    background: #f1f3f4;
    border-radius: 2px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding: 2px 8px;
    margin-right: 8px;

    /* padding: 8px 18px 8px 12px; */
    /* margin: 4px; */
    transition: all 0.3s ease-out;
}

.ladiui-tags .tag .close {
    /* position: absolute;
    top: 0px;
    right: 0;
    width: 14px;
    height: 100%; */
    /* background: var(--main-primary); */
    line-height: 20px;
    cursor: pointer;
    border-radius: 0 2px 2px 0;
    transition: background 0.3s;
}

.table-address .default {
    background-color: #f8fafb;
}

.ladiui-order-left .ladi-card.order-products {
    /* box-shadow: 0 0px 5px 0 rgba(0, 0, 0, .1); */
    border-radius: 4px;
    background: #fff;
}

#autocomplete-variant .async-auto-complete.menu {
    top: 50px !important;
    max-height: 300px !important;
}

#autocomplete-variant .async-auto-complete.menu .item .image img {
    vertical-align: middle;
    border-radius: 3px;
}

.ladiui-order-left .ladiui.input,
.ladiui-order-left .ladiui.select {
    box-shadow: none;
    -webkit-box-shadow: none;
}

/* #autocomplete-variant .ladiui.input {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    height: 50px;
    border-bottom: 0px;
} */

.ladiui-order-left .ladiui-search-custom::after {
    line-height: 50px;
}

.ladiui-order-left .ladi-card.order-products .ladiui.table {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    box-shadow: none;
    -webkit-box-shadow: none;
    margin-bottom: 30px;
}

.order-stack-total:first-child td {
    padding-top: 30px !important;
}

.discount-box .ladiui-input-group .ladiui-input-group-prepend button {
    border-radius: 4px;
    border-left: 0px;
}

button.discount-percent {
    border-radius: 0px !important;
}

button.discount-fixed {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.discount-box .ladiui-input-group .ladiui-input-group-prepend {
    margin: 0px !important;
}

.discount-box .ladiui-input-group .ladiui.input {
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
}

/* .ladiui.table tbody tr td {
    padding: 10px 15px;
    line-height: 24px;
} */

.price-option {
    position: relative;
}

.price-box {
    width: 300px;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    z-index: 7;
    background: #fff;
    padding: 20px;
}

.price-option .discount-reason>label {
    display: inherit;
    text-align: left;
}

.price-option .discount-reason {
    margin: 15px 0px;
}

.discount-option .discount-code {
    margin: 15px 0px;
}

.shipping-group>span {
    display: inline-block;
    margin-right: 30px;
}

.discount-fixed {
    border-left: 0px;
}

.order-customer .async-auto-complete .item .info span {
    font-weight: 500;
    margin-bottom: 5px;
    display: block;
}

.discount-box::before {
    content: '';
    width: 0px;
    height: 0px;
    border-left: 10px solid #ba222200;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fafafa;
    top: -10px;
    position: absolute;
}

.price-option {
    display: initial;
}

.ladiui.input,
.ladiui.select {
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    -webkit-box-shadow: 0 2px 6px 0 rgba(169, 194, 209, 0.1);
}

.ladiui-modal-content .ladiui-select-options {
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    -webkit-box-shadow: 0 1px 2px 0 rgba(169, 194, 209, 0.1);
}

.ladiui-modal-content .order-customer,
.ladiui-modal-content .order-info {
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
}

.order-customer #autocomplete-customer .async-auto-complete {
    top: 45px !important;
    min-width: 325px !important;
    left: -15px !important;
}

.ladiui-search-custom.has-default .async-auto-complete>div:first-child {
    padding: 8px 10px;
}

#autocomplete-product-tag .item .name {
    line-height: 40px;
}

.async-auto-complete .item .tag-item:hover {
    background: #fafafa;
}

.shipping-rate-name {
    font-weight: 500;
    font-size: 14px;
}

.shipping-rate-table.ladiui.table tbody tr {
    border-bottom: 0px;
}

.shipping-rate-table.ladiui.table tbody tr.last-row {
    border-bottom: 1px solid #efefef;
}

.shipping-rate-table.ladiui.table tbody tr.last-row:last-child {
    border-bottom: 0px;
}

#modal-shipping-rate-detail .item-district {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#modal-shipping-rate-detail .item-district .ladiui.form-group {
    margin-right: 25px;
}

#modal-shipping-rate-detail .item-district .ladiui.form-group:first-child {
    flex: 0.3 1;
}

#modal-shipping-rate-detail .item-district .ladiui.form-group:nth-child(2) {
    flex: 0.45 1;
}

#modal-shipping-rate-detail .item-district .ladiui.form-group:nth-child(3) {
    flex: 0.25 1;
}

.account-table .ladiui.table {
    box-shadow: none;
}

#modal-customer .condition-group>label {
    font-size: 16px;
}

.ladiui-label {
    color: var(--main-default);
    /* color: var(--main-secondary); */
    padding-bottom: 8px;
    display: block;
}

.ladiui-label>i {
    margin-left: 5px;
    font-size: 10px;
    font-weight: 600;
    color: #71a3e6;
}

strong {
    font-weight: 500;
}

#modal-shipping-form .suggestion {
    line-height: normal;
    line-height: initial;
    font-style: italic;
    background-color: var(--secondary-color);
    padding: 10px 5px 10px 8px;
    border-radius: 4px;
    font-size: 15px;
}

#modal-shipping-form .suggestion::before {
    content: '\ea55';
    font-family: 'LadiIcons';
    margin-right: 10px;
    color: cornflowerblue;
    font-style: initial;
}

.welcome .init-store {
    margin-top: 100px;
    font-size: 40px;
    margin-bottom: 30px;
}

.welcome {
    width: 760px;
    margin: 0 auto;
    line-height: 20px;
    height: 100vh;
}

.welcome .btn-create-store {
    clear: both;
    float: right;
}

#modal-discount .overview-item {
    display: block;
    line-height: 20px;
    font-size: 14px;
    margin-bottom: 8px;
    /* font-style: italic; */
}

#modal-discount .overview-content {
    padding-left: 12px;
}

.action-item .date {
    width: 135px;
}

.order-customer .avatar {
    top: 45px;
}

.order-table .order-assign {
    padding: 5px;
    background-color: #feaf9a82;
    border-radius: 7px;
    cursor: pointer;
}

.customer-order .order-overview {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(6, 21, 40, 0.05);
    padding-bottom: 16px;
}

.customer-order .order-overview .item p {
    color: var(--main-secondary);
    font-size: 14px;
}

.customer-order .order-overview .item h3 {
    font-weight: 500;
    text-align: center;
}

.customer-order .heading {
    margin-bottom: 15px;
}

.customer-order .heading label {
    display: inline-block;
}

.customer-order .heading a {
    float: right;
}

.payment-shipping {
    /* height: 60px; */
    /* border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef; */
    /* display: table; */
    width: 100%;
    margin-bottom: 24px;
}

.payment-shipping .title-payment {
    /* width: calc(100% - 150px);
    display: table-cell;
    vertical-align: middle;
    padding-left: 15px; */
}

.payment-shipping .title-payment i {
    /* margin-right: 15px; */
    color: #16bf0e;
    font-size: 10px;
    border: 1px solid;
    border-radius: 50%;
    padding: 3px;
}

.payment-shipping button {
    /* float: right;
    margin-top: 10px;
    margin-right: 15px; */
}

.ladiui-order-left {
    clear: both;
}

.tag-color .ladiui-tags {
    border: none;
    float: left;
}

.tag-color .add-tag {
    cursor: pointer;
    display: inline-block;
    margin: 4px;
    border-radius: 2px;
    padding: 6px 8px;
}

.tag-color .add-tag i {
    color: #42526e;
    position: relative;
    top: 2px;
}

.tag-color .selected-tag {
    margin-top: 30px;
    padding: 5px;
    display: inline-block;
    border-radius: 3px;
}

.tag-color .ladiui-tags .tag .close:after {
    /* position: absolute; */
    content: '\00d7';
    /* top: 0px; */
    /* left: 3px; */
    font-weight: 900;
}

.tag-color .ladiui-tags .tag-name {
    padding: 0px 10px 0px 8px;
    position: relative;
    text-align: left;
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
}

.tag-color .ladiui-tags .tag {
    position: relative;
    margin: 0px 4px;
    line-height: 20px;
    height: 24px;
    border-radius: 2px;
    font-size: 14px;
    transition: all 0.3s ease-out;
    padding: 1px 8px;
    white-space: nowrap;
}

.order-table .order-cancel td:nth-child(2) a {
    color: #6c72938f !important;
    text-decoration: line-through;
}

.shipping-table .shipping-cancel td:nth-child(2) a {
    color: #6c72938f !important;
    text-decoration: line-through;
}

.ladiui-order-right .btn-group-order {
    position: absolute;
    display: inline;
    top: -55px;
    right: 0;
}

.ladiui-order-right .btn-group-order button {
    margin-left: 10px;
}

#modal-setting-shipping .ladiui.table tbody tr td {
    padding: 6px 15px;
    line-height: inherit;
}

/* .ladiui.table thead tr th.sorting::after,
.ladiui.table thead tr th.sorting.down::after,
.ladiui.table thead tr th.sorting.up::after {
    top: 17px;
} */

input[disabled] {
    background-color: rgb(6 21 40 / 29%) !important;
}

.item-district input[type='checkbox'] {
    display: inline-block;
    position: relative;
    top: 2px;
}

.form-tags .tag-item {
    color: var(--main-black);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.form-tags .recent-header {
    border-bottom: 1px solid #eaedf0;
    width: 100%;
    padding-left: 9px;
    padding-top: 8px;
}

.order-stack-total td {
    padding: 5px 15px !important;
}

.order-stack-total td strong {
    font-weight: 500;
    font-size: 15px;
    color: #44566c;
}

.customer-info {
    padding-bottom: 0px;
}

.customer-info>span:last-child {
    margin-bottom: 0px;
}

.async-auto-complete.menu .icon-c-add {
    position: relative;
    top: 2px;
    display: inline-block;
}

.ladiui.table {
    font-size: 15px;
}

.ladiui-order-left {
    width: 100%;
    float: left;
    /* position: relative; */
    padding-right: 354px;
}

.tag-color {
    width: 100%;
    float: left;
    /* margin: 4px 0px; */
    display: flex;
}

.tag-color .ladiui-tags .ladiui-tag-list .dropdown-tag button {
    padding: 0px !important;
    height: 24px !important;
}

.order-actions {
    width: 25%;
    float: right;
    text-align: right;
}

.order-actions>button {
    display: inline-block;
    margin-left: 15px;
}

.tag-color .ladiui-tags .tag .close {
    line-height: 20px;
    right: 8px;
}

.page-title {
    padding: 26px 0px 20px 0px;
    color: var(--main-default);
    font-weight: 500;
    font-family: 'Roboto';
    font-size: 18px;
    margin-right: 27px;
}

.head-name-screen {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: var(--main-secondary);
}

.tag-color .ladiui-tag-list .tag:first-child {
    margin-left: 0px;
}

.order-page .discount-note {
    font-style: italic;
    font-size: 14px;
}

.payment-shipping span {
    /* text-transform: uppercase; */
    font-size: 14px;
}

.order-page .order-assignee {
    background: #dfe4e8;
    border-radius: 28px;
    font-size: 12px;
    padding: 6px 10px;
    margin-right: 15px;
    min-width: 65px;
    display: inline-block;
    text-align: center;
}

.payment-shipping .btn-status {
    float: right;
    margin-top: 10px;
    margin-right: 15px;
}

/* .ladiui.table thead tr th {
    font-size: 14px;
} */

.ladiui-table-actions .action-item {
    margin-right: 24px;
}

.shipping-status {
    padding: 2px 10px !important;
    border: none !important;
    font-size: 12px !important;
    border-radius: 20px !important;
    line-height: 24px;
}

.shipping-status-1 {
    background-color: #ffc58b !important;
    color: #222 !important;
}

.shipping-status-2 {
    background-color: #47c1bf !important;
    color: #fff !important;
}

.shipping-status-3 {
    background-color: #06a95f !important;
    color: #fff !important;
}

.shipping-status-4 {
    background-color: #f7c85a !important;
    color: #222 !important;
}

.shipping-status-5 {
    background-color: #6849c1 !important;
    color: #fff !important;
}

.shipping-status-6 {
    background-color: #c4cdd5 !important;
    color: #222 !important;
}

.order-shipping-package {
    border: 1px solid #f1f3f4;
}

.order-shipping-package strong:first-child {
    display: block;
    background-color: #fff;
    padding: 15px;
    /* border-bottom: 1px solid #efefef; */
    background: #f8fafb;
}

.ladiui-input-group>.ladiui.input:not(:first-child) {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding-left: 35px;
}

.ladiui-money .prefix {
    border: 0px;
    padding: 1px 6px;
}

.ladiui-money {
    margin-left: 0px;
    position: absolute;
    right: 1px;
    z-index: 1;
    top: 3px;
}

.ladiui-modal-body {
    max-height: 75vh;
    overflow: scroll;
}

.ladiui-tab-content {
    max-height: none !important;
    overflow-y: unset;
    overflow-x: unset;
}

.unset-modal {
    overflow: unset !important;
}

.ladiui.table tbody tr.order-create:last-child td {
    padding-bottom: 20px !important;
}

span[disabled] {
    background-color: rgba(6, 21, 40, 0.05) !important;
}

.ladiui.table {
    font-size: 14px;
}

/* .group-prices > div {
    width: 30.5%;
} */

.group-weight .ladiui.input {
    display: inline-block;
    width: 75%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.group-weight .ladiui-select-options {
    display: inline-block;
    min-width: 25%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0px;
    border-color: #ebedf2;
}

.group-weight .ladiui-select-options>ul {
    min-width: 25%;
}

.order-page .select-assignee,
.order-page .select-checkout-config {
    /* position: absolute; */
    /* right: 345px; */
    /* width: 200px; */
    /* margin-top: 9px; */
    margin-right: 15px;
    box-shadow: none;
    webkit-box-shadow: none;
}

.order-page .select-assignee {
    z-index: 6;
}

#modal-confirm-payment-order .ladiui-modal-footer .send-email {
    display: inline-block;
    float: left;
}

#modal-confirm-payment-order .ladiui-modal-footer .button {
    display: inline-block;
}

#modal-confirm-payment-order .ladiui-modal-footer .button>.ladiui.button {
    margin: 0px 5px;
}

#modal-confirm-payment-order .ladiui-modal-body {
    font-size: 14px;
    line-height: 20px;
}

#modal-confirm-payment p {
    line-height: 24px !important;
}

#modal-export-order .item,
#modal-export-data .item {
    margin-bottom: 15px;
}

li.custom-menu-dropdown .dropdown-content {
    left: -216% !important;
    padding: 20px 3px 4px 10px;
    width: 810px;
    margin-top: 12px !important;
    /* box-shadow: 0 2px 6px rgba(0, 0, 0, .05), 0 0 0 1px rgba(0, 0, 0, .07) !important; */
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
}

li.custom-menu-dropdown .dropdown-content>li {
    display: block;
    width: 33%;
    float: left;
}

li.custom-menu-dropdown .dropdown-content>li.search {
    padding: 0px 0px 12px 12px;
    float: right;
}

li.custom-menu-dropdown .dropdown-content>li.search button {
    margin-top: 15px;
    min-width: 120px;
    margin-right: 20px;
    float: right;
}

li.custom-menu-dropdown .dropdown-content>li.title {
    width: 100%;
}

li.custom-menu-dropdown .dropdown-content>li.separator {
    width: 100%;
    border-top: 1px solid #efefef;
}

/* .order-tab {
    border-radius: 4px 4px 0px 0px;
    box-shadow: 0 8px 16px 0 rgba(169, 194, 209, .1);
    font-size: 14px;
    color: #6c7293;
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #efefef;
} */

.order-table {
    border-radius: 0px 0px 4px 4px !important;
}

.ladiui-select-options>ul>li:hover {
    background: #f8fafb;
}

li.custom-menu-dropdown li.title {
    width: 100%;
    margin-bottom: 15px;
}

li.custom-menu-dropdown .ladiui-select-options {
    box-shadow: none;
    -webkit-box-shadow: none;
}

li.custom-menu-dropdown {
    font-size: 14px;
}

li.custom-menu-dropdown label {
    font-size: 14px;
}

.order-tab .ladiui-tabs li a {
    color: #747577;
}

.order-tab .ladiui-tabs li.active a {
    color: #1853fa;
}

.DateRangePickerInput {
    background-color: #fff !important;
    background-clip: padding-box !important;
    border: 1px solid var(--secondary-color) !important;
    border-radius: 4px !important;
}

.DateInput_input__focused {
    border-color: #fff !important;
}

.DateInput_fang {
    top: 38px !important;
}

.CalendarMonth_table {
    margin-top: 10px;
}

.CalendarMonth_caption::first-letter {
    text-transform: capitalize !important;
}

.CalendarMonth_caption {
    font-size: 16px !important;
}

.CalendarDay__selected_span {
    background: #5578eac7 !important;
    border: 1px double #ffffff !important;
    color: #fff !important;
}

.CalendarDay__selected {
    background: #0b61f2 !important;
    border: 1px double #ffffff !important;
    color: #fff !important;
}

.CalendarDay__selected:hover {
    background: #5578eac7 !important;
    border: 1px double #ffffff !important;
    color: #fff !important;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
    background: #5578eac7 !important;
    border: 1px double #ffffff !important;
    color: #fff !important;
}

.CalendarDay {
    vertical-align: middle !important;
}

.DateInput_input::-webkit-input-placeholder {
    font-size: 13px !important;
}

.DateInput_input::-webkit-input-placeholder,
.DateInput_input::placeholder {
    font-size: 13px !important;
}

.DateRangePickerInput_calendarIcon i {
    vertical-align: middle !important;
}

.DateInput_input {
    font-weight: inherit !important;
}

.ladiui.submenu>li>a.active {
    color: #1853fa;
}

.ladiui-topbar.fixed {
    z-index: 15;
}

.order-tab .ladiui-tabs li {
    padding: 10px 20px 10px 15px;
}

.custom-menu-dropdown .dropdown-content::before {
    content: '';
    display: block;
    position: absolute;
    top: -7px;
    left: 48%;
    width: 12px;
    height: 12px;
    background: #ffffff;
    border-right: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    -moz-transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.timeline {
    /* padding: 0px calc(18% - 100px); */
}

.timeline .container {
    max-width: 350px;
    max-height: 630px;
    overflow: hidden;
    margin: 30px auto 0;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    font-family: 'Open Sans', sans-serif;
}

.timeline .navbar {
    background: #b97cfc;
    color: #fff;
    padding: 1em 0.5em;
}

.timeline .navbar a {
    color: #fff;
    text-decoration: none;
    font-size: 1.3em;
    float: left;
}

.timeline .navbar span {
    font-size: 1.1em;
    font-weight: 300;
    display: block;
    text-align: center;
}

.timeline .profile-pic {
    width: 30px;
    height: 30px;
    display: inline-block;
    float: right;
    position: relative;
}

.timeline .profile-pic img {
    width: 100%;
    border-radius: 50%;
}

.timeline .notification {
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    top: 2px;
    right: 2px;
    background: #f93b69;
}

.timeline .header {
    background: url(https://unsplash.it/1080/720?image=1044);
    background-size: cover;
    color: #fff;
    position: relative;
}

.timeline .color-overlay {
    padding: 3em 2em;
    box-sizing: border-box;
    background: rgba(123, 94, 155, 0.5);
}

.timeline .actionbutton {
    position: absolute;
    background: #f93b69;
    width: 50px;
    height: 50px;
    font-size: 3em;
    font-weight: 300;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: -25px;
    right: 20px;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
}

.timeline .day-number {
    font-size: 4em;
    display: inline-block;
    margin-right: 15px;
}

.timeline .date-right {
    display: inline-block;
}

.timeline .day-name {
    font-size: 1.6em;
}

.timeline .month {
    text-transform: uppercase;
    font-weight: 300;
    font-size: 0.6em;
    letter-spacing: 2px;
    margin-top: 2px;
}

.timeline .content>ul {
    padding: 1em 0 0 0;
    margin: 0;
    list-style: none;
    position: relative;
}

.timeline .content>ul li {
    padding: 16px 0px;
}

.timeline .content>ul::before {
    content: ' ';
    height: 100%;
    width: 1px;
    background-color: #f1f3f4;
    position: absolute;
    top: 0;
    left: 17px;
    z-index: 1;
}

.timeline li>div {
    display: inline-block;
    /* margin: 1em 0; */
    vertical-align: middle;
    margin-right: 5px;
    /* z-index: 2; */
    position: relative;
    /* border: 3px solid #ffffff; */
}

.timeline .bullet {
    width: 1em;
    height: 1em;
    box-sizing: border-box;
    border-radius: 50%;
    background: #fff;
    z-index: 2;
    margin-right: 1em;
    position: relative;
}

.timeline .bullet.pink {
    border: 2px solid #f93b69;
}

.timeline .bullet.green {
    border: 2px solid #b0e8e2;
}

.timeline .bullet.orange {
    border: 2px solid #eb8b6e;
}

.timeline .time {
    /* width: 15%; */
    font-size: 12px;
    padding-top: 0.25em;
    color: var(--main-secondary);
}

.timeline .desc {
    width: 100%;
}

.timeline h3 {
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    padding-bottom: 2px;
}

.timeline h4 {
    margin: 0;
    font-size: 14pz;
    font-weight: 400;
    color: #808080;
}

.timeline .avatar img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
}

.timeline .credits,
.timeline .video {
    position: absolute;
    bottom: 10px;
    color: #808080;
    font-size: 100%;
    text-decoration: underline;
}

.timeline .credits {
    left: 10px;
}

.timeline .video {
    right: 10px;
}

.timeline .top div {
    display: inline-block;
}

.timeline .top .avatar {
    /* margin: 1em 0.5em 1em -2px; */
    margin: 0px 5px 5px -2px;
    vertical-align: middle;
    display: inherit;
    width: 35px;
    height: 35px;
}

/* .timeline .top {
    padding: 0 0 0 1.5em;
} */

.timeline .top .input-text {
    width: 70%;
    /* margin-right: 15px; */
    margin-left: 7px;
}

.timeline .top input {
    vertical-align: middle;
}

.timeline .showmore {
    text-align: center;
    margin-top: 15px;
}

.timeline .ladiui.button[disabled] {
    color: #6c7293;
    border-color: #ebedf2;
    background-color: #f4f5f8;
}

.ladiui-topbar-noavatar {
    background: #1c00c2;
    color: #fff;
    width: 75px;
    height: 75px;
    display: block;
    border-radius: 50%;
    text-align: center;
    padding-top: 15%;
}

.timeline .noavatar {
    background: #1c00c2;
    color: #fff;
    width: 35px;
    height: 35px;
    display: block;
    border-radius: 50%;
    text-align: center;
    padding-top: 25%;
    font-size: 14px;
}

.timeline .content {
    max-height: 400px;
    overflow-y: scroll;
}

.timeline .avatar {
    width: 35px;
    height: 35px;
    border-radius: 50%;
}

.timeline .top .noavatar {
    width: 40px;
    height: 40px;
}

.timeline .ladiui-input-group {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ebedf2;
    border-radius: 4px;
    display: inline-block;
}

.timeline .ladiui-input-group input {
    width: 100% !important;
    border-right: 0px;
    border-radius: 4px 0px 0px 4px;
    border: 0px;
}

.timeline .ladiui-input-group .wrapper-input {
    display: inline-block;
    width: 100%;
}

/* .timeline .ladiui-input-group .ladiui-input-group-prepend {
    padding: 5px;
    display: inline-block;
    width: 100%;
} */

.timeline .ladiui-input-group .ladiui-input-group-prepend span {
    float: right;
}

.timeline .ladiui-input-group .ladiui-input-group-prepend span .action {
    margin-right: 8px;
    width: 50px;
}

.timeline .ladiui-input-group .ladiui-input-group-prepend span .action:last-child {
    margin-right: 0px;
}

.timeline .top .input-text>input::-webkit-input-placeholder {
    font-size: 13px;
    /* font-style: italic; */
}

.timeline .top .input-text>input::placeholder {
    font-size: 13px;
    /* font-style: italic; */
}

[contenteditable='true'] {
    -moz-box-sizing: border-box;
    border: 0px;
    max-height: 70px;
    max-width: 450px;
    word-wrap: break-word;
    margin: 0;
    min-height: 20px;
    width: 100%;
    overflow: hidden;
    position: relative;
    padding-left: 5px;
    vertical-align: top;
    box-sizing: border-box;
    font-size: 13px;
}

.timeline .expand .item {
    display: none;
    height: 100%;
    padding: 10px;
}

.timeline .top .comment {
    float: right;
    margin-top: 1em;
}

.timeline .expand {
    cursor: pointer;
}

.timeline .expand .title i {
    vertical-align: middle;
    margin-left: 5px;
    cursor: pointer;
}

.timeline .expand .item .key {
    font-size: 0.9em;
    font-weight: 500;
}

.timeline .expand .item .value {
    font-size: 0.7em;
    font-weight: 400;
    color: #808080;
}

.timeline .ladiui-input-group .ladiui-input-group-prepend button {
    height: 30px;
    padding: 2px 5px;
}

.timeline .row-actions {
    vertical-align: bottom;
}

.timeline .row-actions ul {
    right: 0px;
}

.order-timeline {
    font-weight: 500;
    /* border-bottom: 1px solid #efefef; */
    background-color: #fff;
    border-radius: 5px 5px 0px 0px;
    padding: 8px 0px;
    margin-top: 24px;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px; */
    /* background: #f8fafb; */
}

.order-page .timeline {
    background-color: #fff;
    border-radius: 0px 0px 5px 5px;
    padding-bottom: 15px;
}

.order-page .timeline .content {
    overflow-y: none;
    height: 100%;
}

#modal-store .welcome {
    height: 100%;
}

.dont-check-inventory {
    text-align: center;
    cursor: pointer;
}

[data-tooltip]:before {
    content: attr(data-tooltip);
}

[data-tooltip] {
    display: inline-block;
    position: relative;
    cursor: pointer;
}

/* Tooltip styling */
[data-tooltip]:before {
    content: attr(data-tooltip);
    display: none;
    position: absolute;
    background: #6c7293;
    color: #fff;
    padding: 4px 8px;
    font-size: 12px;
    line-height: 1.4;
    min-width: 100px;
    text-align: center;
    border-radius: 4px;
}

/* Dynamic horizontal centering */
[data-tooltip-position='top']:before,
[data-tooltip-position='bottom']:before {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

/* Dynamic vertical centering */
[data-tooltip-position='right']:before,
[data-tooltip-position='left']:before {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

[data-tooltip-position='top']:before {
    bottom: 100%;
    margin-bottom: 6px;
}

[data-tooltip-position='right']:before {
    left: 100%;
    margin-left: 6px;
}

[data-tooltip-position='bottom']:before {
    top: 100%;
    margin-top: 6px;
}

[data-tooltip-position='left']:before {
    right: 100%;
    margin-right: 6px;
}

/* Tooltip arrow styling/placement */
[data-tooltip]:after {
    content: '';
    display: none;
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}

/* Dynamic horizontal centering for the tooltip */
[data-tooltip-position='top']:after,
[data-tooltip-position='bottom']:after {
    left: 50%;
    margin-left: -6px;
}

/* Dynamic vertical centering for the tooltip */
[data-tooltip-position='right']:after,
[data-tooltip-position='left']:after {
    top: 50%;
    margin-top: -6px;
}

[data-tooltip-position='top']:after {
    bottom: 100%;
    border-width: 6px 6px 0;
    border-top-color: #6c7293;
}

[data-tooltip-position='right']:after {
    left: 100%;
    border-width: 6px 6px 6px 0;
    border-right-color: #6c7293;
}

[data-tooltip-position='bottom']:after {
    top: 100%;
    border-width: 0 6px 6px;
    border-bottom-color: #6c7293;
}

[data-tooltip-position='left']:after {
    right: 100%;
    border-width: 6px 0 6px 6px;
    border-left-color: #6c7293;
}

/* Show the tooltip when hovering */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
    display: block;
    z-index: 50;
}

.group-adjust-quantity {
    border-radius: 5px;
    margin: 0px auto;
}

.group-adjust-quantity input {
    display: inline-block !important;
    width: 150px !important;
    border-radius: 0px !important;
    height: 36px !important;
}

.group-adjust-quantity input:focus {
    border-color: #ebedf2 !important;
}

.group-adjust-quantity button {
    border-radius: 0px !important;
}

.group-adjust-quantity item:first-child {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}

.group-adjust-quantity .mode {
    background-color: #fff;
}

.group-adjust-quantity .mode.active {
    color: #6c7293 !important;
    border-color: #ebedf2 !important;
    background-color: #f4f5f8 !important;
}

.group-adjust-quantity .item:last-child {
    line-height: 20px !important;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

#modal-update-batch .group-adjust-quantity input {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

.group-adjust-quantity .remove-right {
    border-right: none !important;
}

.inventory-adjust-triagle {
    margin: 0px 10px;
    font-size: 10px;
    color: var(--main-primary);
}

.inventory-page button.primary[disabled] {
    background-color: #759ee4;
    border-color: #759ee4;
}

textarea {
    resize: none;
}

#modal-refund .ladiui.form-group {
    display: inline-block;
    position: relative;
    width: 100%;
}

#modal-refund .ladiui.form-group .ladiui-row .col-50:first-child {
    top: 20%;
    position: absolute;
}

#modal-refund button[disabled] {
    background-color: #759ee4;
    border-color: #759ee4;
}

.ladiui-user-type {
    position: relative;
    display: inline-block;
    top: 13px;
    padding: 4px 15px;
    color: #fff;
    font-weight: 700;
    border-radius: 50px;
    text-align: center;
    cursor: pointer;
    background: #1853fa;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.ladiui-logo {
    width: 135px;
}

.ladiui-logo img {
    width: 120px;
}

.pkg-STARTER {
    background: linear-gradient(90deg, #57a900, #bfe500);
}

.pkg-PREMIUM {
    background: linear-gradient(90deg, #f22c29, #ff7b4a);
}

.package-table-info tr {
    margin: 10px;
    display: inline-block;
    width: 100%;
}

.package-table-info tr td:first-child {
    width: 200px;
}

/* li.order-address-dropdown {
    line-height: 22px;
    border-bottom: 1px solid #f6f7f9;
    font-weight: 300;
}

ul li.order-address-dropdown:last-child {
    border-bottom: none;
} */

.order-tag {
    color: var(--main-default);
    background: #1c00c2;
    border-radius: 2px;
    font-size: 13px;
    transition: all 0.3s ease-out;
    padding: 4px 15px;
    cursor: pointer;
}

.custom-menu-dropdown .ladiui-modal-footer .icon {
    width: auto;
    height: auto;
}

.custom-menu-dropdown #modal-all-tag {
    height: 100%;
    min-height: 50vh;
}

.custom-menu-dropdown #modal-all-tag .ladiui-modal-content {
    width: 600px;
}

.customer-page li.custom-menu-dropdown .dropdown-content,
.product-page li.custom-menu-dropdown .dropdown-content {
    width: 500px;
    left: -112% !important;
}

.customer-page #modal-all-tag .ladiui-modal-content,
.product-page #modal-all-tag .ladiui-modal-content {
    width: 400px;
}

.ladiui-modal {
    overflow: unset;
}

.ladiui-modal.visible {
    overflow: scroll;
}

.ladiui-modal-body {
    overflow: unset;
    max-height: inherit;
}

.dropdown-tag {
    float: left;
}

.dropdown-tag .item {
    padding: 5px !important;
    border-radius: 4px;
    margin: 8px 10px;
}

.dropdown-tag .item:first-child {
    margin-top: 2px;
}

.dropdown-tag .item:hover {
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
}

.dropdown-tag .ladiui-search-dropdown-menu {
    overflow-y: unset;
}

.dropdown-tag .ladiui-search-input {
    background: transparent !important;
    border: 0px !important;
    box-shadow: none !important;
}

.dropdown-tag.ladiui-search-dropdown::after {
    content: '' !important;
}

.dropdown-tag .ladiui.text::before {
    content: '\ea05';
    font-family: 'LadiIcons';
    font-size: 12px;
    display: block;
    position: absolute;
    left: 0px;
    padding-right: 10px;
    line-height: 40px;
    top: 0px;
    cursor: pointer;
    z-index: 2;
}

.form-default-color {
    margin-top: 24px;
}

.form-default-color .item {
    float: left;
    height: 30px;
    padding: 0;
    width: 64px;
    cursor: pointer;
    text-align: center;
    line-height: 32px;
    border-radius: 2px;
}

.form-default-color .item i {
    color: var(--main-primary);
}

.order-tag-item {
    padding: 5px 15px;
    display: inline-block;
    cursor: pointer;
    border-radius: 2px;
}

.ladiui.table.scope tr td {
    width: 1%;
    white-space: nowrap;
}

#modal-edit-account .ladiui-modal-content {
    max-width: 1200px;
}

.onoffswitch {
    position: relative;
    width: 70px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    margin-top: 14px;
}

.onoffswitch.m-t-0 {
    margin-top: 0px !important;
}

.onoffswitch-checkbox {
    display: none;
}

.onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0px solid #ffffff;
    border-radius: 20px;
}

.onoffswitch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 20px;
    padding: 0;
    line-height: 20px;
    font-size: 10px;
    color: white;
    box-sizing: border-box;
}

.onoffswitch-inner:before {
    content: 'ONLINE';
    padding-left: 10px;
    background-color: var(--main-primary);
    color: #ffffff;
    text-align: left;
}

.onoffswitch-inner:after {
    content: 'OFFLINE';
    padding-right: 10px;
    background-color: #eeeeee;
    color: #999999;
    text-align: right;
}

.onoffswitch-switch {
    display: block;
    width: 8px;
    height: 8px;
    margin: 5px;
    background: #ffffff;
    position: absolute;
    top: 0px;
    bottom: 0;
    right: 49px;
    border: 2px solid #ffffff;
    border-radius: 16px;
    transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-switch {
    right: 3px;
    top: 1px;
}

.basic-rest-weight {
    font-size: 12px;
    font-weight: 400;
    font-style: italic;
    margin-left: 5px;
}

#modal-assign .onoffswitch {
    float: right;
    top: -15px;
}

#modal-assign .onoffswitch-inner::before {
    content: 'ON';
}

#modal-assign .onoffswitch-inner:after {
    background-color: #484848;
    content: 'OFF';
}

.rule-group {
    position: relative;
    display: flex;
    flex-direction: column;
}

.rule-group .rule-content {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    border: 1px solid #f1f3f4;
    border-radius: 2px;
    padding: 16px;
}

.rule-group .rule-item .item {
    display: inline-block;
    min-width: 20%;
    position: relative;
}

.rule-group .select-assignee .ladiui-select-options {
    z-index: inherit;
}

.rule-group .select-assignee .ladiui-select-options>.ladiui-select-init:after {
    z-index: 0;
    right: 0px;
}

.rule-group.other .ladiui-select-options>.ladiui-select-init:after {
    z-index: 0;
}

.rule-group .rule,
.rule-group .assign {
    display: block;
    width: 100%;
    float: left;
    /* padding: 0px 15px; */
}

/* .rule-group .assign {
    margin-bottom: 15px;
} */

.rule-group .assign .apply-to {
    display: inline-block;
    min-width: 25%;
    font-weight: 500;
}

.rule-group .assign .select-assignee {
    display: inline-block;
    width: 100%;
}

.rule-group .assign .ladiui-select-options {
    display: block;
    float: left;
    width: 150px;
    margin-right: 10px;
}

.rule-group.other .select-assignee .ladiui-select-options>.ladiui-select-init {
    text-indent: 15px;
}

.rule-group.other .ladiui-select-options {
    width: 250px;
    z-index: inherit;
}

.rule-group.other .select-assignee .ladiui-select-options {
    width: 240px;
}

.rule-group .rule .ladiui-tags {
    position: relative;
}

.rule-group .ladiui-tags {
    border: none;
    display: block;
    float: left;
    max-width: 600px !important;
}

.rule-group .rule .rule-item .ladiui-select-options,
.rule-group .rule .rule-item .custom-input-autocomplete,
.rule-group .assign .ladiui-select-options {
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
    box-shadow: none;
    border-radius: 0px;
    padding-bottom: 7px;
}

.rule-group .ladiui-tag-list {
    /* margin-top: 10px; */
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.rule-group .flex-tag {
    display: flex;
    flex-wrap: wrap;
    padding: 4px 8px !important;
}

.rule-group .ladiui-tag-list .tag {
    background: #f1f3f4;
    border-radius: 2px;
    padding: 2px 8px;
    margin: 4px 4px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.rule-group .ladiui-tag-list .tag img {
    margin-left: 9px;
}

.rule-group .rule .rule-item .input {
    height: 32px !important;
}

.rule-group .ladiui-tags .tag {
    position: relative;
    display: block;
    float: left;
    color: #191919;
    background: #fafafa;
    padding: 4px 23px 4px 16px;
    margin: 4px;
    border-radius: 20px;
    font-size: 14px;
    transition: all 0.3s ease-out;
}

.rule-group .ladiui-tags .close {
    color: #191919;
    background: #fafafa;
    right: 5px;
    top: 0px;
    height: 26px;
    border-radius: 10px;
    line-height: 13px;
}

.rule-group .icon-c-add {
    clear: both;
    position: absolute;
    top: -10px;
    right: -21px;
    cursor: pointer;
    color: #033c9d;
}

.rule-group .icon-c-add::before {
    font-size: 15px;
}

.rule-group .icon-bin {
    font-size: 13px;
    top: 0px;
    right: 5px;
    position: absolute;
    cursor: pointer;
    margin-left: 10px;
}

.rule-group .ladiui.input:focus,
.rule-group .select-assignee .ladiui-select-options>.ladiui-select-init:hover,
.rule-group .select-assignee .ladiui-select-options>.ladiui-select-init:focus,
.rule-group .select-assignee .ladiui-select-options:hover,
.rule-group .select-assignee .ladiui-select-options:focus {
    border-color: #ebedf2;
}

.rule-group .select-assignee .ladiui-select-options>.ladiui-select-init {
    text-indent: 0px;
}

.rule-group .rule .rule-item {
    position: relative;
    margin-bottom: 24px;
}

.rule-group .rule .rule-item:last-child {
    margin-bottom: 0;
}

.rule-group .ladiui-search-dropdown {
    /* position: absolute; */
    /* clear: both;
    top: -20px;
    right: -33px; */
    background: #fff;
    min-width: 150px;
}

.rule-group .dropdown-tag .item {
    margin: 0px !important;
}

.rule-group .dropdown-tag .item:hover {
    box-shadow: none;
}

.ladiui-table-responsive {
    overflow-x: inherit;
}

.order-table .tag-color .ladiui-tags .tag {
    padding: inherit;
    position: relative;
    display: flex;
    align-items: center;
    color: #ffffff;
    background: #1853fa;
    border-radius: 2px;
    font-size: 12px;
    transition: all 0.3s ease-out;
    padding: 4px 2px;
    margin: 1px;
    line-height: 1.2;
}

.rule-group .place-holder {
    position: relative;
    cursor: pointer;
    margin-top: 15px;
    margin-right: 20px;
    float: left;
}

.rule-group .item .title label {
    cursor: pointer;
}

.rule-group .item .title {
    position: relative;
    width: 160px;
    cursor: pointer;
    float: left;
    min-height: 40px;
    margin-top: 15px;
}

.rule-group .item .title::after {
    content: '\ea05';
    font-family: 'LadiIcons';
    font-size: 12px;
    display: block;
    position: absolute;
    right: 0px;
    padding-right: 10px;
    line-height: 40px;
    top: -11px;
    cursor: pointer;
    z-index: 2;
}

.rule-group .search-panel {
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    width: 331px;
    min-height: 250px;
    position: absolute;
    top: 40px !important;
    padding: 15px;
    z-index: 3;
    background: #fff;
    border-radius: 5px;
    overflow: auto;
}

.rule-group .search-panel .ladiui-search-custom {
    width: 300px;
    border-radius: 3px;
    border: 1px solid #ebedf27a;
}

.rule-group .search-panel .ladiui-search-custom input {
    border-bottom: 0px;
}

.rule-group .search-panel .ladiui-search-custom .menu {
    top: 111% !important;
    box-shadow: none !important;
}

.rule-group .search-panel .ladiui-search-custom::after {
    content: '\ea44';
    font-family: 'LadiIcons';
    font-size: 18px;
    display: block;
    z-index: 9999;
    position: absolute;
    right: 0px;
    padding-right: 10px;
    line-height: 40px;
    top: 0px;
    cursor: pointer;
    z-index: 2;
}

.rule-group .popover {
    float: left;
}

.rule-group .search-panel.utm {
    min-height: 100px;
}

.rule-group .search-panel .add {
    margin-top: 15px;
    float: right;
}

.ladiui.search-keyword {
    width: 400px;
}

.badge {
    display: inline-block;
    font-size: 10px;
    font-weight: 700;
    line-height: 18px;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border-radius: 2px;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    width: 20px;
    height: 18px;
    /* position: absolute; */
    /* left: 0px;
    bottom: -4px; */
    cursor: pointer;
    /* padding: 0px 8px 0px 18px; */
    padding: 0px 8px;
}

.badge-light {
    color: #fff;
    /* background-color: rgba(243, 83, 83, 1); */
    background-color: var(--active-warning-color);
}

.badge-child-light {
    color: #fff;
    /* background-color: #d94f45; */
    background-color: var(--active-warning-color);
}

/* 
.badge > i {
    position: absolute;
    left: 4px;
    top: 4px;
} */

/* .order-table .duplicate {
    background-color: rgb(248, 250, 251);
} */

.payment-shipping .order-cancel {
    color: #313942 !important;
}

.order-page .more-info i {
    float: left;
    margin-right: 5px;
    cursor: pointer;
}

.order-custom-field .more-info span {
    font-size: 14px;
    /* margin-bottom: 10px; */
    margin-right: 5px;
    margin-left: 0px;
    display: inline-block;
    /* width: 100%; */
}

.order-custom-field .more-info [data-tooltip-position='bottom']:before {
    left: 90px !important;
}

.order-custom-field .more-info [data-tooltip-position='bottom']:after {
    left: 90px !important;
}

#order-custom-field .order-page .more-info span {
    margin-right: 0px !important;
}

.order-page .more-info span h3 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.order-page .more-info b {
    font-weight: 500;
    margin-right: 1px;
    display: inline-block;
    float: left;
    margin-right: 5px;
}

.DateRangePicker .custom-btn-date-panel {
    padding: 0px 22px 11px 22px;
}

.DateRangePicker .custom-btn-date {
    position: relative;
    height: 100%;
    text-align: center;
    background: none;
    border: 1px solid #0b61f2;
    border-radius: 5px;
    color: #0b61f2;
    padding: 4px 9px;
    margin-right: 5px;
    margin-bottom: 5px;
    font-weight: 500;
    line-height: normal;
    overflow: visible;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 14px;
}

.DateRangePicker .custom-btn-date:active {
    outline: 0;
}

.DateRangePicker .custom-btn-date.selected {
    color: #fff;
    background: var(--main-primary);
}

.more-filter {
    width: 380px;
    right: -380px;
    position: fixed;
    background: #fff;
    z-index: 16;
    height: 100vh;
    top: 0px;
    /* box-shadow: 0 31px 41px 0 rgba(32, 42, 53, .2), 0 2px 16px 0 rgba(32, 42, 54, .08);
     */
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    transition: right 1s;
}

.more-filter.show-filter {
    right: 0px;
}

.more-filter.hide-filter {
    right: -380px;
}

.more-filter .heading {
    border-bottom: 1px solid #efefef;
    padding: 20px 15px;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
}

.more-filter .main-content {
    height: calc(100vh - 130px);
    overflow-y: scroll;
}

.more-filter .footer {
    position: absolute;
    bottom: 0;
    right: 0px;
    width: 380px;
    text-align: right;
    padding: 15px 15px;
    clear: both;
    border-top: 1px solid #ebedf2;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
}

.close-more-filter {
    position: absolute;
    top: 12px;
    right: 0;
    font-size: 18px;
    padding: 11px;
    cursor: pointer;
    opacity: 1;
    transition: opacity 150ms linear;
    background: 0 0;
    border: 0;
}

.more-filter .title:hover {
    background-image: linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3));
}

.more-filter .item .title {
    position: relative;
    cursor: pointer;
    padding: 15px 0px 10px 15px;
}

.more-filter .item .title label {
    cursor: pointer;
    font-size: 14px;
}

.more-filter h3 {
    font-size: 18px;
    font-weight: 500;
}

.more-filter .main-content .item .title:focus {
    /* box-shadow: inset 0.2rem 0 0 #5478ec; */
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
}

.more-filter .main-content .item .content {
    display: block;
    max-height: 0px;
    margin: 0px 15px 5px 15px;
    overflow: hidden;
    transition: max-height 0.15s ease-out;
}

.more-filter .main-content .item .content.full.tag {
    overflow: unset;
}

.more-filter .main-content .item .content.full {
    max-height: 300px;
    overflow: scroll;
    overflow-x: hidden;
}

.more-filter .main-content .item .content ul li {
    font-size: 14px;
    margin: 0px 0px 5px 1px;
}

.more-filter .main-content .item .short-content {
    background-color: #dfe3e8;
    color: #212b36;
    padding: 1px 9px;
    border-radius: 34px;
    font-size: 12px;
    font-weight: 400;
    margin-right: 5px;
    display: inline-block;
    margin-bottom: 4px;
}

.more-filter .main-content .item .short-content:last-child {
    margin-right: 0px;
}

.more-filter .main-content .item.collapse {
    border-bottom: 1px solid #efefef;
    padding-bottom: 15px;
}

.more-filter .main-content>div.item.collapse:last-child {
    border-bottom: none;
}

.more-filter .main-content .item .clear {
    display: block;
    cursor: pointer;
    margin-top: 15px;
    color: #006fbb;
    font-size: 14px;
}

.more-filter .main-content .item .arrow {
    position: absolute;
    top: 3px;
    right: 0;
    font-size: 14px;
    padding: 11px;
    cursor: pointer;
    opacity: 1;
    transition: opacity 150ms linear;
    background: 0 0;
    border: 0;
}

.ladiui-search-dropdown .ladiui-search-dropdown-menu {
    max-height: 3000x;
}

.no-scroll {
    overflow: hidden;
}

.mt-6 {
    margin-top: 6px;
}

button.loader::before {
    margin: 0px auto;
    position: absolute;
    border-top: 2px solid var(--main-line);
    border-right: 2px solid var(--main-line);
    border-bottom: 2px solid var(--main-line);
    border-left: 2px solid var(--main-primary);
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
    display: inline-block;
    content: '' !important;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    top: calc(50% - 10px);
    left: 6px;
    z-index: 10;
}

button.loader {
    padding-left: 30px !important;
    opacity: 0.6 !important;
}

.glossary.ladiui.table tbody .header {
    font-weight: 500;
    text-align: center;
}

.glossary.ladiui.table tbody tr {
    border: 1px solid #efefef;
}

.glossary.ladiui.table tbody tr td {
    border-right: 1px solid #efefef;
    padding: 10px;
}

.glossary.ladiui.table tbody tr td:last-child {
    border-right: none;
}

a.link {
    color: #1853fa;
}

.variant-option-item .adjust {
    height: 32px;
    line-height: 32px;
    margin-left: 15px;
}

.variant-option-item .adjust a {
    font-size: 13px;
}

#modal-adjust .swatch-text .ladiui.form-group {
    /* width: 500px;
     */
    display: flex;
    align-items: center;
}

#modal-adjust .visual-swatch .ladiui.form-group {
    width: 500px;
}

#modal-adjust .swatch-text .ladiui.form-group .ladiui.input,
#modal-adjust .visual-swatch .ladiui.form-group .ladiui.input {
    display: inline-block;
    width: 70%;
}

#modal-adjust .swatch-text .ladiui.form-group .ladi-icon,
#modal-adjust .visual-swatch .ladiui.form-group .ladi-icon {
    display: inline-block;
    margin-left: 15px;
}

.swatch-picker {
    width: 40px;
    height: 40px;
    display: inline-block;
    border: 1px solid #efefef;
    border-radius: 6px;
    margin-right: 15px;
    vertical-align: middle;
    cursor: pointer;
    background-color: #ffff;
    position: relative;
}

.swatch-picker div {
    margin: 4px;
    background-color: #dedede;
    width: 30px !important;
    height: 30px;
    border-radius: 4px;
    position: absolute;
}

.swatch-picker div.image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.visual-swatch .ladiui-navbar.-horizontal>li {
    float: inherit;
}

.visual-swatch .ladiui-navbar.-horizontal,
.visual-swatch ul.ladiui-navbar.-vertical {
    margin-left: -12px;
}

.visual-swatch .popover-picker {
    z-index: 1;
    top: 45px !important;
}

.variant-image {
    cursor: pointer;
}

.order-customer .total-order {
    margin-left: 5px;
    font-size: 12px;
    font-style: italic;
    color: #6c7293;
    font-weight: 500;
}

.ladiui.showing {
    min-width: 300px;
    width: auto;
}

.group-date {
    clear: both;
}

.group-date .date-item {
    width: 50%;
    display: inline-block;
}

.group-date .date-item:first-child {
    margin-right: 24px;
}

.group-date .date-item:last-child {
    right: 0px;
}

#modal-setting-payment .item {
    margin-bottom: 15px;
    border-radius: 10px;
}

#modal-setting-payment .item:last-child {
    margin-bottom: 0px;
}

#modal-setting-payment .item .shipping-partner-logo {
    height: 25px;
}

#modal-setting-payment .item .shipping-partner-logo.shopee-pay {
    height: 45px !important;
}

#modal-setting-payment .item .description {
    margin-top: 15px;
    padding-bottom: 15px;
    line-height: 22px;
    font-size: 15px;
}

#modal-setting-payment .item .partner-account {
    margin-top: 15px;
}

#modal-setting-payment .item .partner-footer {
    display: flex;
    padding-bottom: 15px;
    justify-content: flex-end;
}

#modal-setting-payment .item .partner-footer .right button {
    margin-left: 10px;
}

#modal-setting-payment .item .partner-footer .left button {
    margin-left: 10px;
}

#tab-checkout .col-33 {
    width: 31.5%;
    margin-left: 0px;
}

.col-33 {
    width: 33%;
    float: left;
    margin: 0px 15px;
}

#tab-checkout .publish div,
#tab-checkout-config .publish div {
    clear: both;
}

#tab-checkout .publish div label.border-bottom,
#tab-checkout-config .publish div label.border-bottom {
    display: inline-block;
    border-radius: 0 !important;
    border: 0 !important;
    border-bottom: 1px solid rgba(6, 21, 40, 0.1) !important;
    padding: 5px 12px 5px 0px;
    font-weight: 400;
    height: 35px;
    font-style: italic;
}

#tab-checkout .publish div input,
#tab-checkout-config .publish div input {
    display: inline-block;
    width: 21%;
    margin-right: 15px;
    border-radius: 0 !important;
    /* border: 0 !important;
    border-bottom: 1px solid rgba(6, 21, 40, .1) !important; */
}

#tab-checkout .publish div button,
#tab-checkout-config .publish div button {
    display: inline-block;
}

#modal-store .onoffswitch {
    float: left;
    top: -15px;
}

#modal-store .onoffswitch-inner::before {
    content: 'ON';
}

#modal-store .onoffswitch-inner:after {
    background-color: #484848;
    content: 'OFF';
}

.checkout-config-item {
    margin-left: 20px;
}

.checkout-config-item.disabled {
    color: #6c72938f !important;
    text-decoration: line-through;
}

.ladiui-row.product-service {
    clear: both;
}

.ladiui-row.product-service .ladiui.form-group {
    width: 32.1%;
    display: inline-block;
    margin-right: 15px;
}

.ladiui-row.product-service .ladiui.form-group:last-child {
    margin-right: 0px;
}

#autocomplete-price-unit.ladiui-search-custom::after {
    content: '' !important;
}

.package-price-description {
    margin-top: 5px;
    display: block;
    font-size: 13px;
    font-style: italic;
}

.group-store-logo {
    width: 70%;
    display: flex;
    padding-right: 20px;
    margin-bottom: 20px;
}

.group-store-logo .image {
    width: 100%;
    display: flex;
}

.group-store-logo img {
    height: 150px;
}

.group-store-logo img {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 1px;
}

.checkout-config .ladiui.table thead {
    border-bottom: none;
}

.checkout-config .ladiui.table tbody tr {
    border-bottom: none;
}

.checkout-config .ladiui.table tbody tr td {
    padding: 8px 16px;
}

.checkout-config .ladiui.table tbody tr:last-child td {
    padding-bottom: 16px;
}

.checkout-config .ladiui.table thead tr th {
    padding: 5px 0px 10px 15px;
}

.ladiui.table tbody tr.default {
    background-color: #f8fafb;
}

.panel-setting-checkout.preview,
.panel-setting-checkout.preview .ladiui.table {
    padding: 10px;
    border-radius: 4px;
}

.order-page.order-edit .btn-link-checkout {
    z-index: 5;
}

.order-page .link-checkout {
    margin-left: 33px;
    display: block;
    width: 500px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    left: 15px;
    position: absolute;
}

.payment-shipping .title-payment i.copy {
    margin-left: 5px;
    color: inherit;
    cursor: pointer;
    border: none;
    font-size: 14px;
}

.checkout-page .ladiui-order-right {
    top: 275px;
}

.email-confirm {
    background-color: #f1f3f4;
    border: 1px solid #f1f3f4;
    border-radius: 4px;
    padding: 15px;
    margin-bottom: 15px;
    min-height: 148px;
    margin-top: 20px;
}

/* .email-confirm.sent {
    background-color: #1853fa2e;
} */

.email-confirm button,
.email-confirm input {
    margin-top: 10px;
}

.ladiui-select-options>ul>li.header-item {
    cursor: pointer;
    display: block;
    color: #313942;
    width: 100%;
    padding: 0px 12px;
    line-height: 40px;
}

.ladiui.table .badge.badge-child-light.sold-out,
.ladiui.table .badge.badge-child-light.sold-out {
    left: 0px;
    position: relative;
    display: block;
    margin-top: -3px;
    margin-bottom: -13px;
}

.ladiui.table tr.sold-out,
.ladiui.table tr.sold-out {
    background-color: rgb(248, 250, 251) !important;
}

.ladiui.table tr.sold-out>td:first-child,
.ladiui.table tr.sold-out>td:first-child {
    position: relative;
}

.DateRangePickerInput__showClearDates {
    padding-right: 0px !important;
}

span.buy-information {
    font-weight: 400;
    font-size: 12px;
    margin-left: 5px;
    font-style: italic;
}

.onoffswitch.ticket-seat {
    width: 92px;
}

.onoffswitch.ticket-seat .onoffswitch-inner:before {
    content: 'Đã tham gia';
}

.onoffswitch.ticket-seat .onoffswitch-inner:after {
    content: 'Chưa tham gia';
}

.onoffswitch.ticket-status .onoffswitch-inner:before {
    content: 'Đã bán';
}

.onoffswitch.ticket-status .onoffswitch-inner:after {
    content: 'Chưa bán';
}

.onoffswitch.ticket-seat .onoffswitch-switch {
    right: 73px;
}

.ladiui-select-options.select-option-checkout-config ul {
    max-height: 515px;
    overflow: scroll;
}

.ladi-card.ladiui-table-responsive.checkout-config tr th {
    font-weight: 400;
}

.scope-description {
    white-space: break-spaces;
    display: block;
    font-weight: 400;
    font-style: italic;
    font-size: 11px;
    line-height: 12px;
}

.price-before-discount {
    color: #6d6d6d !important;
    text-decoration: line-through;
    margin-right: 9px;
    display: block;
}

/* .ladiui.menu {
    overflow-y: scroll;
    max-height: calc(100vh - 100px);
}

.ladiui.menu::-webkit-scrollbar {
    width: 0px;
} */

.ladiui-toast {
    position: relative;
}

.ladiui-toast .ladi-icon.icon-e-remove {
    position: absolute;
    top: 3px;
    right: 0;
    font-size: 18px;
    padding: 11px;
    cursor: pointer;
    opacity: 1;
    transition: opacity 150ms linear;
    background: 0 0;
    border: 0;
}

/* .qr-code-publish {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: 9000000080;
    background: rgba(0, 0, 0, .5);
} */

/* .qr-code-publish img {
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    object-fit: scale-down;
    max-width: 90%;
    max-height: 90%;
} */

#tab-checkout-config .publish div,
#tab-checkout .publish div {
    clear: both;
}

.qr-code-publish,
.qr-code-publish img {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
}

.qr-code-publish {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9000000080;
    background: rgba(0, 0, 0, 0.5);
}

.qr-code-publish img {
    position: absolute;
    object-fit: scale-down;
    max-width: 90%;
    max-height: 90%;
}

/* .panel-publish a {
    width: 78%;
    border-bottom: 1px solid rgba(6, 21, 40, .1) !important;
    padding-bottom: 7px;
} */

.panel-publish .item {
    margin-right: 5px;
    border-top: none;
    border-left: none;
    border-right: none;
    /* border-bottom: 1px solid rgba(6, 21, 40, .1) !important; */
    border-radius: 0px;
    box-shadow: none;
}

.panel-publish .group {
    clear: both;
    display: flex;
    /* width: 83%; */
    align-items: center;
}

.panel-publish .group .item.ladiui-select-options>.ladiui-select-init {
    margin-left: 30px;
    text-indent: 5px;
}

.panel-publish .group .item.ladiui-select-options>.ladiui-select-init:after {
    right: inherit;
    left: 10px;
}

.qr-code-publish-close {
    width: 30px;
    height: 30px;
    position: absolute;
    z-index: 9000000090;
    background: url(https://w.ladicdn.com/v2/source/ladi-icons.svg) hsla(0, 0%, 100%, 0.2) no-repeat;
    background-position: -108px;
    transform: scale(0.7);
    -webkit-transform: scale(0.7);
    cursor: pointer;
    top: calc(50vh - 155px);
    right: calc(50vw - 155px);
}

#tab-checkout-config .publish div,
#tab-checkout .publish div {
    clear: both;
}

.ladiui-search-custom::after {
    content: '\ea44';
}

/* .ladiui.nav>.menu>li>a {
    font-size: 16px;
} */

.ellipsis {
    display: inline-block;
    width: 180px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.ladiui-modal-header h3 {
    display: inline-block;
}

.ladiui-modal-header .modal-hight-light-title {
    color: #1853fa;
    margin-left: 106px;
    font-weight: 500;
}

.prevent-click {
    pointer-events: none;
}

#confirm-order-not-show .ladiui-modal-content.confirm {
    top: 40%;
}

/* #tab-tracking-custom {
    max-height: 395px;
    overflow: auto;
} */

#tab-tracking-custom .tracking-custom-item {
    margin-bottom: 15px;
}

.tr-disable {
    background-color: #f8fafb;
}

/* .detail-checkout-config {
    margin-left: 20px;
    position: relative;
    top: -15px;
} */

#tab-checkout {
    margin-left: 5px;
}

.ladi-card.ladiui-table-responsive.form-group.checkout-config {
    border-radius: 4px;
    font-size: 14px;
    color: #6c7293;
    background-color: #fff;
    min-width: 250px;
}

.ladi-card.ladiui-table-responsive.form-group.checkout-config>.ladiui.table {
    box-shadow: none;
}

#autocomplete-variant .badge.sold-out {
    left: 0px;
}

#tab-product .ladiui-select-page-checkout {
    display: block;
    width: 30%;
    margin-bottom: 10px;
}

#tab-product .ladiui-select-page-checkout .ladiui-select-options {
    border-radius: 0px !important;
}

#autocomplete-product-page-checkout {
    display: block;
    float: left;
    width: 100%;
}

#autocomplete-product-page-checkout .badge {
    left: 1px;
}

.form-config-no-account {
    line-height: 20px;
    width: 100%;
    text-align: center;
    margin-top: 10px;
    font-weight: 100;
    margin-bottom: 15px;
}

.text-toggle-input-type {
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    position: relative;
    right: 5px;
    top: -31px;
    /* border-radius: 2px; */
    float: right;
    /* font-size: 12px; */
    /* background: #f1f1f1; */
    padding: 1px 5px;
    /* color: #353535; */
}

.mapping-fields .btn-add-custom-fields {
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
}

.mapping-fields tr {
    border-bottom: none !important;
}

.mapping-fields tr td:first-child {
    padding-left: 0px !important;
}

.mapping-fields tr td:last-child {
    padding-right: 0px !important;
}

.margin-left-15 {
    margin-left: 15px;
}

.margin-top-20 {
    margin-top: 20px;
}

.button-link {
    color: #0c61f2;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.a-link {
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.m-bt-10 {
    margin-bottom: 10px;
}

.label-custom-fields {
    font-weight: 400;
    font-size: 14px;
}

.form-config-account.google-sheet .ladiui-search-dropdown::after {
    content: '\ea44';
    font-size: 15px;
}

.form-config-account.google-sheet .google-sheet-sheet {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.form-config-account.google-sheet .google-sheet-sheet .ladiui.dropdown {
    width: 80% !important;
}

.form-config-account.google-sheet .google-sheet-sheet .ladiui-search-dropdown {
    flex: 1 1;
    margin-right: 15px;
}

#modal-tag-edit .ladiui.search-keyword {
    width: 195px;
    position: absolute;
    right: 27px;
    z-index: 1;
}

/* #modal-tag-edit .ladiui.search-keyword input#keyword {
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    box-shadow: none;
    border-radius: initial;
    padding-top: 15px;
} */

#modal-tag-edit .ladiui.search-keyword i {
    padding-top: 11px;
}

#modal-tag-edit table tr:hover i {
    display: inline-block;
    transition: display 5s ease-out;
}

#modal-tag-edit table tr i {
    display: none;
    margin-top: 4px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}

.order_payment_transaction {
    background-color: #fffcf6;
    border: 1px solid #f6eee0;
    border-radius: 4px;
    padding: 5px;
    margin-left: 33px;
    max-width: 400px;
    font-style: italic;
}

.order_payment_transaction span {
    display: block;
    text-transform: inherit;
    line-height: 22px;
}

.order_payment_transaction .order_transaction_id {
    text-transform: uppercase !important;
}

.ladiui.form-group .ladiui-checkout-payment-body .ladiui-checkout-payment-header-show-2 {
    margin-bottom: 16px;
}


.ladiui.form-group .ladiui-label.payment-method-title {
    margin-bottom: 4px;
}

.client-order-code [data-tooltip]::before {
    width: 300px !important;
    text-align: left;
}

.pc_description .ladiui-label,
.mb_description .ladiui-label {
    display: flex;
    align-items: center;
}

.pc_description .ladiui-label>i,
.mb_description .ladiui-label>i {
    font-size: 15px;
    color: #333;
    margin-right: 8px;
}

.form-group .builder-modal-input-label {
    position: relative;
}

.form-group .builder-modal-input-label label {
    position: absolute;
    top: -8px;
    left: 10px;
    padding: 0 5px;
    background: #fff;
    z-index: 1;
    font-size: 13px;
    font-weight: 500;
}

.form-group .builder-modal-input-label input {
    font-size: 13px;
    padding: 24px 12px;
}

.form-group.input-image {
    display: inline-block;
    clear: both;
}

.form-group .builder-modal-input-label.show-link-img {
    width: 80%;
    float: left;
}

.ladiui-button-upload {
    position: relative;
    width: 82px;
    height: 82px;
    background: #f1f3f4;
    border-radius: 2px;
}

.ladiui-button-upload button {
    margin: 0px auto !important;
    cursor: pointer;
}

.ladiui-button-upload button .icon-data-upload {
    padding-right: 5px;
}

.ladiui-button-upload.link-img {
    margin-top: 0px;
    float: right;
    border-radius: 5px;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
}

.ladiui-button-upload.link-img button {
    background-color: #fbfafa;
}

.block-change-language {
    max-width: 250px;
}

.order-cancel .reference-no a {
    color: rgba(108, 114, 147, 0.56) !important;

    text-decoration: line-through;
}

.code-ticket-canceled {
    text-decoration: line-through;
}

#modal-setting-custom-field .ladiui-modal-body .pull-right {
    display: block;
    width: 100%;
    float: right;
    text-align: right;
}

.is-Show-Advanced {
    display: block;
    clear: both;
    margin-bottom: 20px;
}

.is-Show-Advanced.product {
    margin-top: 10px;
}

#modal-setting-custom-field .ladiui-modal-body .block-search .input-search {
    display: inline;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
}

#modal-setting-custom-field .ladiui-modal-body .block-search label {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 10px;
    display: block;
}

#modal-setting-custom-field .ladiui-modal-body .block-search button {
    background-color: #0341ac;
    color: #fff;
    padding: 10px;
    cursor: pointer;
}

#modal-setting-web-hook .ladiui-modal-footer {
    text-align: left;
    font-style: italic;
    font-size: 13px;
}

#order-custom-field {
    background-color: #fff;
    /* padding: 10px; */
    position: relative;
    /* margin-bottom: 20px; */
    border-radius: 5px;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
}

#order-custom-field .title {
    display: flex;
    justify-content: space-between;
    /* margin-bottom: 10px; */
}

.btn-custom-field-add {
    float: right;
}

#modal-setting-custom-field .block-search {
    clear: both;
}

.customer-info.customer-edit-custom-field {
    text-align: right;
    text-align: right;
    position: absolute;
    right: 33px;
    top: 10px;
}

.customer-info.customer-edit-custom-field a {
    margin: 0px 5px;
}

.customer-info.customer-edit-custom-field a:last-child {
    margin-right: 0px;
}

.ladiui.form-group .custom-field-content {
    /* max-height: 320px;
    overflow: scroll; */
    /* padding: 0px 5px; */
    overflow-x: hidden;
    width: 100%;
}

.ladiui.form-group .custom-field-content.custom {
    max-height: none;
    overflow: none;
}

.ladiui.form-group .hidden-custom-field {
    position: absolute;
    top: 10px;
    right: 10px;
}

.hidden-custom-field .icon-down-arrow,
.order-customer-edit .icon-e-remove {
    font-size: 12px;
    color: #585f67;
}

.show-error-text {
    color: #ff0000;
    font-size: 13px;
    margin-top: 8px;
    display: block;
}

.order-customer-edit-btn {
    top: 5px !important;
}

.panel-setting-checkout .config-payments table tr td {
    position: relative;
}

.panel-setting-checkout .config-payments table tr td .icon-onpin {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    transition: ease-in-out;
}

.panel-setting-checkout .config-payments table tr:hover .icon-onpin {
    display: inline !important;
    box-sizing: border-box;
}

#modal-checkout-config .child-content {
    padding-left: 20px;
}

#modal-checkout-config .child-content div {
    padding-bottom: 12px;
}

#modal-checkout-config .child-content div:first-child {
    padding-top: 12px !important;
}

#modal-checkout-config .child-content div:last-child {
    padding-bottom: 0;
}

#modal-checkout-config .child-content p .icon-move-2 {
    float: right;
    display: none;
}

#modal-checkout-config .child-content p:hover .icon-move-2 {
    display: block;
}

.panel-setting-checkout .config-payments .table.list-active-payment-method {
    display: block;
}

#modal-setting-web-hook .ladi-card.account-table .ladiui.table .show-name {
    max-width: 140px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

#modal-form-account .connect {
    position: relative;
    line-height: 40px;
}

#modal-form-account .connect .button-connect {
    margin: 0px 15px;
}

input[disabled] {
    background-color: rgba(6, 21, 40, 0.05) !important;
}

.input-padding-left {
    padding: 7px 28px !important;
}

.input-padding-left.pl-10 {
    padding-left: 10px !important;
}

.page-content {
    padding: 24px;
    background-color: #ffffff;
    overflow: auto;
    min-height: 100vh;
}

.page-head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.align-item-baseline {
    align-items: baseline !important;
}

.overflow-hide {
    overflow: hidden !important;
}

.icon-add-border {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: solid 0.5px var(--main-primary);
    padding: 2px;
    margin-right: 5px;
}

/* .flex-center {
    display: flex !important;
    align-items: center !important;
} */

.flex-center-left {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
}

a {
    cursor: pointer;
}

.flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.flex-top-start {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.flex-space-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ladiui.modal.show {
    display: block !important;
}

.ladiui.modal-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: #3e526a;
}

.text-end {
    text-align: right !important;
}

.ladiui.modal-content .description-header {
    padding: 24px 24px 0px !important;
}

.ladiui.modal-content .description-header p {
    font-weight: 400;
    font-size: 14px;
    color: #6e7d8f;
    line-height: 20px;
}

.ladiui.modal-content .block-account-type {
    padding: 24px 24px 0px !important;
    display: flex;
    align-items: center;
    color: var(--main-default);
}

.ladiui.modal-content .block-account-type strong {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.ladiui.modal-content .block-account-type span {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.timeline .input-history {
    width: 100% !important;
    position: relative;
    height: 60px;
    border: 1px solid #f1f3f4;
}

.timeline .input-history .input-custom {
    border: none;
}

.timeline .input-history .comment {
    width: 24px;
    height: 24px;
    border-radius: 50px;
    background-color: var(--main-primary);
    cursor: pointer;
    position: absolute;
    right: 10px;
    bottom: 10px;
    margin-top: 0px !important;
    text-align: center;
}

.timeline .input-history .comment span {
    display: block;
    margin-top: 2px;
}

.timeline .list-comment-history ul li {
    padding: 0px !important;
}

.timeline .top {
    display: flex !important;
    align-items: center;
}

.custom-info .order-customer .order-heading {
    padding: 10px 24px;
    background-color: #f1f3f4;
}

.custom-info .order-customer .customer-info {
    margin: 24px;
}

.info-customer .block-shipping-address {
    padding: 24px !important;
}

.order-customer .order-heading.title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.order-custom-field.custom.bottom {
    padding: 24px !important;
    margin-bottom: 24px;
}

.custom-info .order-customer .customer-info .info span {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.custom-info .order-customer .customer-info .info span img {
    margin-right: 8px;
}

.custom-info .order-customer .customer-info .info span:last-child {
    margin-bottom: 0px !important;
}

.custom-info .order-customer .customer-info {
    /* display: flex;
    justify-content: space-between; */
}

.create-order-field-right .order-custom-field {
    padding: 10px 24px !important;
}

.custom-info .order-customer .customer-info.draft-order {
    display: block !important;
}

.custom-dropdown-staff {
    min-width: auto !important;
    width: auto !important;
    padding-right: 30px !important;
}

.dropdown-tag-custom:hover {
    box-shadow: none !important;
}

.ladiui.btn.btn-outline-light.ellipsis {
    width: auto !important;
    padding-right: 30px;
}

.ladiui-tag-list .tag-product-item {
    background-color: #f1f3f4;
    padding: 2px 8px;
    border-radius: 6px;
    margin-right: 5px;
}

.info-customer.info-customer-shipping {
    padding: 24px !important;
}

.info-customer-shipping .shipping-info-customer {
    padding-top: 24px !important;
}

.info-customer-shipping .shipping-info-customer .order-customer .order-heading {
    padding-bottom: 24px !important;
}

.info-customer-shipping .shipping-info-customer .order-customer .customer-info {
    padding-bottom: 24px;
}

.info-customer-shipping .shipping-info-customer .order-customer .order-heading.create-customer-shipping {
    padding-top: 24px !important;
}

.info-customer-shipping .shipping-info-customer .shipping-address .customer-info {
    padding-bottom: 0px !important;
}

.ladiui.status-premium {
    width: unset !important;
}

.ladiui.menu-item .ladiui.dropdown-toggle {
    position: inherit !important;
}

#modal-billing-address .action-item .ladiui.btn-group .ladiui.dropdown-toggle:after {
    top: -3px !important;
}

.modal-shipping-address-custom .action-item .ladiui.btn-group .ladiui.dropdown-toggle:after {
    top: -3px !important;
}

.ladiui.block-item-district {
    max-height: 350px;
    overflow: auto;
    border: 1px solid #f1f3f4;
    padding: 16px 0px 16px 16px;
}

.ladiui-product-right.event-custom .group-date .date-item {
    width: 50% !important;
}

.package-content-info ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0px;
    border-bottom: 1px solid #f1f3f4;
}

.ladiui.btn.btn-primary[disabled] {
    opacity: 0.65 !important;
    background-color: var(--main-primary) !important;
}

.ladiui.btn.btn-outline-primary[disabled] {
    opacity: 0.65 !important;
    border: 1px solid var(--main-primary) !important;
}

.ladiui.btn.btn-outline-light[disabled] {
    opacity: 0.65 !important;
    border: 1px solid var(--main-primary) !important;
    color: var(--main-primary);
}

.block-adjustBatch .list .item {
    padding: 10px;
    border-bottom: 1px solid #f1f3f4;
}

.block-adjustBatch .list .item:last-child {
    border-bottom: unset;
}

.block-help-ladisales-top li a i {
    width: 14px !important;
}

.ladiui.block-avatar.custom {
    margin-right: 12px;
}

.ladiui.show-avata-name {
    border: 2px solid var(--main-primary);
    border-radius: 50%;
    height: 40px;
    width: 40px;
}

.ladiui.show-text-avata {
    align-items: center;
    color: var(--main-white);
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: 500;
    height: 36px;
    justify-content: center;
}

.ladiui.role-text {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.25px;
    line-height: 16px;
}

.ladiui.show-avata-name.custom {
    border: none !important;
}

.block-content-product-tag .list .list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 18px 12px 0px;
}

.block-content-product-tag .list .list-item:first-child {
    padding-top: 0px;
}

.block-content-product-tag .list {
    max-height: 350px;
    overflow-y: auto;
}

.ladiui.account-body {
    max-height: 200px;
    overflow: auto;
}

#tab-terms-of-use .form-group .ladiui-label {
    color: var(--main-secondary);
}

#tab-privacy-policy .form-group .ladiui-label {
    color: var(--main-secondary);
}

#tab-refund-policy .form-group .ladiui-label {
    color: var(--main-secondary);
}

.ladiui.form-control {
    padding: 7px 12px;
}

.action-record-table {
    position: absolute;
    top: 5px;
    z-index: 10;
    /* z-index: 999; */
}

.table-custom thead th {
    padding: 12px 18px !important;
}

.table-custom tbody td {
    padding: 12px 18px !important;
}

.ladiui.form-check-input-checkbox.vertical-middle {
    vertical-align: middle;
}

.ladiui-tag-list .tag .close-tag-icon {
    display: flex;
    cursor: pointer;
}

.ladiui.table-vertical.main {
    border-bottom: 1px solid var(--secondary-color);
}

/* .ladiui.table-vertical.main:last-child {
    border-bottom: none;
} */

.ladiui.table-custom .table-vertical.header th {
    white-space: nowrap;
}

.ladiui.table-vertical.main:last-child td {
    padding-bottom: 24px !important;
}

.table-custom thead tr th:first-child {
    padding-left: 0px !important;
}

.table-custom tbody tr td:first-child {
    padding-left: 0px !important;
}

.icon-close-no-bg-16 {
    background-color: var(--status-mute-color);
    width: 8px;
    height: 8px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-close-no-bg.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-close-no-bg.svg) no-repeat 50% 50%;
}

.ladiui-btn-dropdown.dropdown-toggle .ladiui.icon.icon-ldp-dot {
    vertical-align: middle;
}

.ladi-icon.icon-bin {
    background-color: var(--status-mute-color);
    width: 16px;
    height: 16px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-delete.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-delete.svg) no-repeat 50% 50%;
}

.ladi-icon.icon-bin.red-color {
    background-color: #D94F45 !important;
}

.ladi-icon.icon-qrcode {
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-qrcode.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-qrcode.svg) no-repeat 50% 50%;
}

.ladi-icon.icon-copy {
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-coppy.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-coppy.svg) no-repeat 50% 50%;
}

.icon-calendar-date-2 {
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-calendar.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-calendar.svg) no-repeat 50% 50%;
}

.icon-tag-footer-modal {
    background-color: #1c00c2;
    width: 16px;
    height: 16px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-tag.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-tag.svg) no-repeat 50% 50%;
}

.list-image-product .list-image .item-image:hover .ladi-icon.icon-bin {
    background-color: var(--main-white);
}

.ladiui.table-vertical.custom-tag td {
    padding-top: 0px !important;
}

.tag-color .ladiui-tags {
    padding: 0px !important;
    min-height: auto !important;
}

.email-address-store-custom {
    overflow-wrap: anywhere;
}

#block-dropdown-tag-custom .item:first-child {
    padding-top: 10px;
}

.url-checkout .ladiui.btn-group .dropdown button span {
    width: 100%;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 0 !important;
}

.checkout-url-custom .more-info .item-tracking .show-content {
    width: 100%;
    max-width: 200px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
}

.block-content-product-tag .list .list-item .image {
    position: relative;
}

.block-content-product-tag .list .list-item .image .badge-child-light {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: -8px;
    right: initial;
    left: 63px;
}

.ladiui.my-account-avata .show-avt-name {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #fff;
    border-radius: 50%;
}

.ladiui.account-avatar.block-info .show-avt-name {
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #fff;
    border-radius: 50%;
}

.block-shipping-address.checkout .order-customer {
    padding-bottom: 0px !important;
}

.ladiui.account-header-text .account-email.subtitle-2 {
    overflow-wrap: anywhere !important;
}

.block-product-content-left .list-image-product .image-select {
    position: relative;
}

.block-product-content-left .list-image-product .image-select .block-delete-img {
    position: absolute;
    background-color: rgba(32, 32, 32, 0.7);
    top: 8px;
    padding: 3px 5px;
    display: none;
    border-radius: 2px;
    right: 8px;
}

.block-product-content-left .list-image-product .image-select:hover .block-delete-img {
    display: block;
}

.block-product-content-left .list-image-product .image-select:hover .ladi-icon.icon-bin {
    background-color: var(--main-white);
}

.ladiui.modal-body {
    max-height: calc(100vh - 300px);
    overflow: auto;
}

#modal-event .input-upload-file {
    display: block;
    margin-bottom: 24px;
    clear: both;
}



/* -----------------Icon CTA---------------------- */
.cta-add {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-add.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-add.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
}

.cta-calendar {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-calendar.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-calendar.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
}

.cta-cart {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-shopping-cart.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-shopping-cart.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
}

.cta-add-member {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-add-member.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-add-member.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
}

.cta-download {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-import.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-import.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
}

.cta-discount-coupon {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-discount-coupon.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-discount-coupon.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;

}

.ldicon-subcategory {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-subcategori.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-subcategori.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;

}

.ldicon-discount-coupon {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-discount-coupon.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-discount-coupon.svg);
    background-color: var(--main-secondary);
    width: 24px;
    height: 24px;
    display: inline-block;
}

.ldicon-buying-time {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-buying-time.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-buying-time.svg);
    background-color: var(--main-secondary);
    width: 24px;
    height: 24px;
    display: inline-block;
}

.cta-tag {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-tag.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-tag.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
}

.icon-export {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-export.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-export.svg);
    background-color: var(--main-primary);
    width: 16px;
    height: 16px;
    display: block;
}

#modal-create-version-digital .block-upload-file {
    padding: 16px;
    border: 1px solid #EEE;
    border-radius: 2px;
    margin-bottom: 24px;
}

#modal-create-version-digital .block-upload-file .ladiui-button-upload {
    display: flex;
    cursor: pointer;
    margin-left: 24px;
}

#modal-create-version-digital .block-upload-file .ladiui-button-upload button {
    padding: 4px;
    margin: 4px !important;
    display: flex;
    width: 80px;
    justify-content: center;
    background: transparent;
    border: 1px solid var(--main-primary);
    color: var(--main-primary);
    cursor: pointer;
}

#modal-create-version-digital .block-upload-file .line-bar {
    border-bottom: 1px solid #eee;
    margin: 24px 0;
    position: relative;
}

#modal-create-version-digital .block-upload-file .line-bar span {
    position: absolute;
    top: -9px;
    left: 0;
    padding-right: 16px;
    background-color: #fff;
}

#modal-create-version-digital .block-selected-files {
    border: 1px solid #eee;
}

#modal-create-version-digital .block-selected-files .title {
    padding: 12px 16px;
    background-color: #eee;
}

#modal-create-version-digital .block-selected-files .list-files {
    padding: 16px;
}

#modal-create-version-digital .block-selected-files .list-files ul .file-item {
    margin-top: 16px;
}

#modal-create-version-digital .block-selected-files .list-files ul .file-item:first-child {
    margin-top: 0px;
}

#modal-create-version-digital .request-format-file {
    display: block;
    font-size: 12px;
    line-height: 16px;
    color: var(--main-secondary);
}

input[type="file"] {
    cursor: pointer;
}

#modal-create-version-digital .input-upload-file .limit-text-upload {
    padding-right: 100px;
    display: block;
    text-overflow: ellipsis;
    overflow-wrap: anywhere;
}

#modal-create-version-digital .input-upload-file .limit-text-link {
    display: block;
    text-overflow: ellipsis;
    overflow-wrap: anywhere;
}


.block-seo-social .input-upload-file .ladiui-button-upload {
    background-color: transparent !important;
}

.ghn-form .list-options .ladiui.form-group .item {
    margin-bottom: 8px;
}

.ghn-form .list-options .ladiui.form-group .item:last-child {
    margin-bottom: 0;
}

.ghn-form .block-required-note {
    margin-bottom: 24px;
}

.ghn-form .block-required-note .item {
    margin-bottom: 8px;
}

.ghn-form .block-required-note .item:last-child {
    margin-bottom: 0px;
}

.ldicon-chevron-left {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-chevron-left.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-chevron-left.svg);
    background-color: var(--main-default);
    width: 12px;
    height: 12px;
    display: inline-block;
}

.ldicon-chevron-right {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-chevron-right.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-chevron-right.svg);
    background-color: var(--main-default);
    width: 12px;
    height: 12px;
    display: inline-block;
}

.ldicon-forward {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-forward.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-forward.svg);
    background-color: var(--main-default);
    width: 12px;
    height: 12px;
    display: inline-block;
}

.ldicon-backward {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-backward.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-backward.svg);
    background-color: var(--main-default);
    width: 12px;
    height: 12px;
    display: inline-block;
}

.ldicon-link {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-link.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-link.svg);
    background-color: var(--main-default);
    width: 12px;
    height: 12px;
    display: inline-block;
}

.ldicon-delete {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-delete.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-delete.svg);
    background-color: var(--main-default);
    width: 12px;
    height: 12px;
    display: inline-block;
}

.ldicon-save {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-save.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-save.svg);
    background-color: var(--main-default);
    width: 12px;
    height: 12px;
    display: inline-block;
}

.ldicon-image {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-image.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-image.svg);
    background-color: var(--main-secondary);
    width: 24px;
    height: 24px;
    display: inline-block;
}

.ldicon-menu {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-menu.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-menu.svg);
    background-color: var(--main-secondary);
    width: 24px;
    height: 24px;
    display: inline-block;
}

.ldicon-user {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-user.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-user.svg);
    background-color: var(--main-secondary);
    width: 24px;
    height: 24px;
    display: inline-block;
}

.ldicon-circle-check {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-circle-check.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-circle-check.svg);
    background-color: var(--main-secondary);
    width: 24px;
    height: 24px;
    display: inline-block;
}

#modal-tag-edit .ladiui .modal-content {
    -webkit-animation: none !important;
            animation: none !important;
}

.ladiui .dropdown-input-search-custom {
    position: -webkit-sticky;
    position: sticky;
    padding: 16px 16px 8px 16px;
    top: 0;
    z-index: 999999;
    margin: 0;
    background-color: #fff;
    border-bottom: 1px solid #eee;

}

.ladiui .dropdown-input-search-custom input {
    padding-left: 40px !important;
    background-color: #eee;
}

.ldicon-search {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-search.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-search.svg);
    background-color: var(--main-default);
    width: 16px;
    height: 16px;
    display: inline-block;
}

.ladiui .dropdown-input-search-custom i {
    position: absolute;
    bottom: 18px;
    left: 30px;
}

.ladiui.dropdown-menu.custom-form-dynamic {
    padding: 0 !important
}

.ladiui.dropdown-menu.custom-form-dynamic .ladiui.dropdown-item:first-child {
    padding-top: 10px !important;
}

.icon-page-checkout-ladisales {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-brand-ladisales.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-brand-ladisales.svg);
    background-color: var(--main-default);
    width: 28px;
    height: 28px;
    display: inline-block;
}

.icon-other-page-checkout {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-integration.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-integration.svg);
    background-color: var(--main-default);
    width: 28px;
    height: 28px;
    display: inline-block;
}

.ladiui .block-new-page-checkout li .title-description h3 {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-default);
}

.ladiui .block-new-page-checkout li .title-description span {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--main-secondary);
}

#modal-info-ticket-seat .ladiui.form-group .ladiui-label {
    width: 30%;
    float: left;
}

#modal-info-ticket-seat .ladiui.form-group .status-checkin-ticket {
    padding: 2px 8px;
    border-radius: 2px;
    color: #fff;
}

.ladiui.modal {
    overflow-x: auto !important;
}

.sdk-script-content {
    height: 70px;
    background-color: var(--main-line);
    margin-bottom: 20px;
    width: 100%;
    border-radius: 5px;
    padding: 13px;
    font-size: 13px;
    color: var(--main-default);
}

#modal-discount .block-preview-discount {
    padding: 32px;
    border: 1px solid #eee;
    border-radius: 2px;
}

#modal-discount .block-preview-discount h3 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 16px;
    color: var(--main-black);
}

#modal-discount .block-preview-discount .block-overview .block-item {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 8px;
}

#modal-discount .block-preview-discount .block-overview .block-item label {
    padding-bottom: 0px !important;
    max-width: 130px;
    width: 100%;
    line-height: 24px;
}

#modal-discount .block-preview-discount .line-bar {
    border-bottom: 1px solid #eee;
    margin: 12px 0;
}

#modal-discount .promotion-conditions-apply {
    display: inline-flex;
    margin-top: 24px;
    width: 100%;
    justify-content: space-between;
}

#modal-discount .promotion-conditions-apply .item-condition {
    width: 100%;
    max-width: 250px;
}

.page-checkin-content {
    background-color: #e5e5e5;
}

.page-checkin-content .block-header-checkin {
    background-color: #fff;
    padding: 10px;
}

.page-checkin-content .block-header-checkin .content-header {
    width: 980px;
    margin: 0px auto;
    display: flex;
    align-items: center;
    padding: 0px 24px;
}

.page-checkin-content .block-header-checkin .qr-code {
    width: 32px;
    height: 32px;
    cursor: pointer;
}

.page-checkin-content .block-header-checkin .qr-code img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
}

.page-checkin-content .block-guest-list .content {
    width: 980px;
    margin: 0px auto;
}

.page-checkin-content .block-guest-list .content h3 {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    margin: 30px 0px;
    line-height: 28px;
    padding: 0px 24px;
    color: var(--main-black);
}

.page-checkin-content .block-guest-list .content .list-guest {
    background-color: #fff;
    padding: 24px;
}

.page-checkin-content .block-guest-list .content .list-guest .title {
    color: var(--main-secondary);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    display: block;
    margin-bottom: 24px;
}

.page-checkin-content .block-guest-list .content .list-guest .list {
    display: block;
    width: 100%;
}

.page-checkin-content .block-guest-list .content .list-guest .list .list-guest-item {
    display: flex;
    align-items: center;
    padding: 12px 0px;
    border-bottom: 1px solid #f1f3f4;
}

.page-checkin-content .block-guest-list .content .list-guest .list .list-guest-item:first-child {
    padding-top: 0px !important;
}

.list-guest .list .list-guest-item .block-icon-check {
    display: flex;
    margin-right: 24px;
    align-items: center;
    justify-content: center;
}

.list-guest .list .list-guest-item .block-icon-check .icon-circle-check {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-circle-check.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-circle-check.svg);
    background-color: var(--main-secondary);
    width: 35px;
    height: 35px;
    display: inline-block;
}

.list-guest .list .list-guest-item .block-icon-check .icon-circle-check.checked-in {
    background-color: #41B983;
}

.list-guest .list .list-guest-item .info {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.list-guest .list .list-guest-item .info .info-item {
    display: block;
    width: 100%;
    margin-bottom: 8px;
}

.block-guest-list .powered-by .ladi-icon.icon-ladisales {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-colored-brand-ladisales.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-colored-brand-ladisales.svg);
    background-color: var(--main-default);
    width: 20px;
    height: 20px;
    display: inline-block;
}

.block-guest-list .powered-by span {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    margin-bottom: 24px;
}

.page-checkin-info {
    max-width: 375px;
    padding: 16px;
    width: 100%;
    margin: 0px auto;
    background-color: #e5e5e5;
}

.list-guest .list .list-guest-item .info .block-icon-check {
    display: none;
}

.list-guest .list .list-guest-item .info .block-group-btn-checkin {
    display: block;
}

.list-guest .list .list-guest-item .block-group-btn-checkin {
    display: none;
}

@media screen and (max-width: 768px) {
    .page-checkin-content .block-guest-list .content {
        width: auto;
    }

    .page-checkin-content .block-header-checkin .content-header {
        width: auto;
    }

    .page-checkin-info {
        max-width: 100%;
    }

    .list-guest .list .list-guest-item .block-icon-check {
        display: none;
    }

    .list-guest .list .list-guest-item .info .block-icon-check {
        display: block;
        margin-right: 0px;
    }

    .list-guest .list .list-guest-item .info .block-group-btn-checkin {
        display: none;
    }

    .list-guest .list .list-guest-item {
        flex-direction: column;
    }

    .list-guest .list .list-guest-item .block-group-btn-checkin {
        display: block;
        width: 100%;
        margin-top: 16px;
    }

    .list-guest .list .list-guest-item .block-group-btn-checkin .btn-checkin button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .list-guest .list .list-guest-item .block-group-btn-checkin .group-btn-checkin {
        justify-content: space-between;
    }

    .page-checkin-content .block-guest-list .content .list-guest .pagination-checkin {
        flex-direction: column-reverse;
    }

    .page-checkin-content .block-guest-list .content .list-guest .pagination-checkin .ladiui.pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 16px;
    }

    .page-checkin-content .block-guest-list .content .list-guest .ladiui.search-action {
        align-items: unset;
    }
}

.page-checkin-info .block-actions-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.page-checkin-info .block-actions-top button {
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: transparent;
}

.page-checkin-info .block-info-checkin {
    margin-top: 16px;
    background-color: #fff;
    padding: 16px;
    border-radius: 2px;
}

.page-checkin-info .block-info-checkin .title {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    color: var(--main-black);
    text-align: center;
}

.page-checkin-info .block-info-checkin .info {
    display: block;
    width: 100%;
}

.page-checkin-info .block-info-checkin .info .item {
    display: block;
    width: 100%;
    margin-bottom: 16px;
    font-size: 14px;
    color: var(--main-secondary);
}

.page-checkin-info .block-info-checkin .info .item:first-child {
    margin-top: 16px;
}

.page-checkin-info .block-info-checkin .info .item strong {
    font-size: 14px;
    font-weight: 500;
    color: var(--main-black);
}

.page-checkin-info .block-info-checkin .info .item .status {
    padding: 2px 8px;
    border-radius: 2px;
}

.page-checkin-info .block-info-checkin .info .item .status.checkin {
    background-color: #E89D35;
    color: #fff;
}

.page-checkin-info .block-info-checkin .info .item .status.checked-in {
    background-color: #41B983;
    color: #fff;
}

.page-checkin-info .block-info-checkin .btn-checkin {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
}

.page-checkin-info .block-history-checkin {
    margin-top: 24px;
    background-color: #fff;
    padding: 16px;
    border-radius: 2px;
}

.page-checkin-info .block-history-checkin .title {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    color: var(--main-black);
    text-align: center;
}

.page-checkin-info .block-history-checkin .list-history {
    display: block;
    max-height: 200px;
    overflow: auto;
}

.page-checkin-info .block-history-checkin .list-history .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
}

.page-checkin-info .powered-by span {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    margin-bottom: 24px;
}

.page-checkin-info .block-info-checkin .actions-checkin-cancel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;
}

.page-checkin-info .block-info-checkin .actions-checkin-cancel .cancel-checkin {
    color: #D94F45;
    cursor: pointer;
}

#block-custom-btn-checkout {
    display: block;
    max-width: 900px;
}

#block-custom-btn-checkout .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.block-custom-html-selector {
    display: block;
    max-width: 900px;
}

.block-custom-html-selector .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

#block-content-product-course .list-chapters {
    display: block;
}

#block-content-product-course .list-chapters .chapter-list-item {
    border: 1px solid var(--main-line);
    border-radius: 2px;
    margin-bottom: 24px;
}

#block-content-product-course .list-chapters .chapter-list-item .title-chapter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    background-color: var(--main-line);
}

#block-content-product-course .list-chapters .chapter-list-item .title-chapter .icon-edit-chapter {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-edit.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-edit.svg);
    background-color: var(--main-default);
    width: 16px;
    height: 16px;
    display: none;
    cursor: pointer;
}

#block-content-product-course .list-chapters .chapter-list-item .title-chapter:hover .icon-edit-chapter {
    display: inline-block;
}

#block-content-product-course .list-chapters .chapter-list-item .add-lesson .btn-add-lesson {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0px;
    font-weight: 500;
    font-size: 14px;
    color: var(--main-secondary);
    cursor: pointer;
}

#block-content-product-course .list-chapters .chapter-list-item .list-lesson {
    display: block;
}

#block-content-product-course .list-chapters .chapter-list-item .list-lesson .lesson-item {
    display: flex;
    align-items: center;
    padding: 12px 16px 12px 24px;
    justify-content: space-between;
    border-bottom: 1px solid var(--main-line);
}

#block-content-product-course .list-chapters .chapter-list-item .list-lesson .lesson-item.selected {
    color: #1c00c2;
}

#block-content-product-course .list-chapters .chapter-list-item .list-lesson .lesson-item.selected .ldicon-lesson-post {
    background-color: #1c00c2;
}

#block-content-product-course .list-chapters .chapter-list-item .list-lesson .lesson-item.selected .ldicon-lesson-video {
    background-color: #1c00c2;
}

#block-content-product-course .ladiui-product-course-right .choose-content {
    padding: 50px 0px;
    border-radius: 2px;
    border: 1px solid #eee;
    margin-top: 24px;
}

#block-content-product-course .ladiui-product-course-right .choose-content .title {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: var(--main-secondary);
}

.ladiui-product-course-right .choose-content .list-box-content .box-content {
    width: 100px;
    height: 100px;
    border: 1px solid var(--main-line);
    box-shadow: 0px 1px 4px rgba(186, 186, 186, 0.3);
    border-radius: 4px;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.ladiui-product-course-right .choose-content .list-box-content .box-content:hover {
    border: 1px solid var(--main-primary);
}

.ladiui-product-course-right .choose-content .list-box-content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

/* .ladiui-product-course-right .block-add-content .choose-content .list-box-content .box-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
} */
.ladiui-product-course-right .choose-content .list-box-content .box-content .box-name {
    margin-top: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--main-secondary);
}

.ladiui-sub-title {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: var(--main-secondary);
    margin-bottom: 8px;
    display: block;
}

#block-content-product-course .ladiui-product-course-right .block-content-video {
    display: block;
}

#block-content-product-course .ladiui-product-course-right .block-content-video .show-link-video {
    padding: 8px 12px;
    background-color: #e5e5e5;
    overflow: hidden;
}
#block-content-product-course .ladiui-product-course-right .block-content-video .show-link-video span {
    display: block;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 8px;
}

#tabProductCourseEdit .ladiui-product-course-right .thumbnail {
    width: 253px;
    height: 100%;
    max-height: 180px;
    border-radius: 2px;
    position: relative;
}

#tabProductCourseEdit .ladiui-product-course-right .block-content-video .group-input {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    width: calc(100% - 277px);
}

#tabProductCourseEdit .ladiui-product-course-left .thumbnail {
    width: 100%;
    height: 316px;
    border-radius: 2px;
    position: relative;
    background-color: #f1f3f4;
}

#tabProductCourseEdit .ladiui-product-course-right .image {
    width: 100%;
    object-fit: cover;
    height: 100%;
    opacity: 1;
    display: block;
    transition: .5s ease;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}

#tabProductCourseEdit .ladiui-product-course-left .image {
    width: 100%;
    height: 100%;
    opacity: 1;
    display: block;
    transition: .5s ease;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}

#tabProductCourseEdit .thumbnail .block-upload-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    color: #fff;
    cursor: pointer;
}

#tabProductCourseEdit .thumbnail:hover .block-upload-image {
    display: block;
    background: rgba(0, 0, 0, 0.6);
}

#tabProductCourseEdit .thumbnail:hover .block-upload-image .block-overlay {
    margin-top: 60px;
    text-align: center;
}

#tabProductCourseEdit .ladiui-product-course-left .thumbnail:hover .block-upload-image .block-overlay {
    margin-top: 130px;
    text-align: center;
}

#tabProductCourseEdit .thumbnail:hover .block-upload-image .block-overlay .text {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

#tabProductCourseEdit .thumbnail:hover .block-upload-image .block-overlay .ldicon-upload {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-upload.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-upload.svg);
    background-color: var(--main-white);
    width: 16px;
    height: 16px;
    display: inline-block;
}

.ldicon-stack {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-stack.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-stack.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
    display: inline-block;
}

.ldicon-file {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-new-file.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-new-file.svg);
    background-color: var(--main-secondary);
    width: 12px;
    height: 12px;
    display: inline-block;
}

.block-list-path {
    padding: 16px 0px;
    border-top: 1px solid #f1f3f4;
    margin-top: 24px;
}

.block-list-path .path-item {
    margin-bottom: 12px;
}

.block-list-path .path-item .path-name {
    margin: 0;
    max-width: 100%;
    line-height: 22px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.ldicon-lesson-post {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-new-file.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-new-file.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
    display: inline-block;
}

.ldicon-lesson-video {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-video.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-video.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
    display: inline-block;
}

#modal-create-category .block-upload-image-category {
    display: block;
    width: 100%;
    height: 180px;
    background-color: var(--main-line);
    border-radius: 2px;
    cursor: pointer;
    position: relative;
}

#modal-create-category .block-upload-image-category .block-upload-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    color: #fff;
    cursor: pointer;
}

#modal-create-category .block-upload-image-category .image {
    width: 100%;
    object-fit: cover;
    height: 100%;
    opacity: 1;
    display: block;
    transition: .5s ease;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}

#modal-create-category .block-upload-image-category:hover .block-upload-image {
    display: block;
    background: rgba(0, 0, 0, 0.6);
}

#modal-create-category .block-upload-image-category:hover .block-upload-image .block-overlay {
    margin-top: 45px;
    text-align: center;
}

#modal-create-category .block-upload-image-category:hover .block-upload-image .block-overlay .ldicon-upload {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-upload.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-upload.svg);
    background-color: var(--main-white);
    width: 16px;
    height: 16px;
    display: inline-block;
}

#modal-create-category .ladiui.modal-content {
    -webkit-animation: none !important;
            animation: none !important;
}

.setting-content-menu-banner {
    max-width: 900px;
    height: 100vh;
    background: #fff;
}

.setting-content-menu-banner .block-category-top-menu h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.setting-content-menu-banner .block-category-top-menu .sub-title {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #4d4d4d;
    padding-top: 4px;
    padding-bottom: 24px;
    display: block;
}

.config-panel .panel {
    width: 100%;
    margin-bottom: 24px;
    display: block;
}

.config-panel .panel .panel-line {
    display: flex;
    margin: 8px 0;
}

.config-panel .panel .panel-line .main-description {
    color: var(--main-black);
    width: 250px;
    padding-top: 10px;
    font-weight: 500;
}

.config-panel .panel .panel-line .content {
    width: 100%;
    text-align: left;
    padding-left: 10px;
}

.config-panel .panel .panel-line .content .color-picker {
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    z-index: 10;
}

.config-panel .panel .panel-line .main-description .sub-description {
    line-height: 16px;
    margin-top: 6px;
    color: var(--main-mute);
    font-size: 12px;
    font-weight: 400;
}

.setting-content-menu-banner .block-category-show-home {
    margin-top: 24px;
}

.block-category-show-home .btn-add-category button {
    font-weight: 500;
    font-size: 14px;
}

.block-category-show-home .block-list-category {
    display: block;
}

.block-list-category .category-list-item {
    border: 1px solid #eee;
    margin-bottom: 16px;
}

.block-list-category .category-list-item .block-add-featured-course {
    padding: 0px 24px 16px 24px;
}

.block-list-category .category-list-item .content-top-item {
    display: flex;
    padding: 16px 12px;
    align-items: center;
    justify-content: space-between;
    background-color: #eee;
}

.block-list-category .category-list-item .content-top-item .icon-name span {
    display: -webkit-box;
    width: 450px;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.block-list-category .category-list-item .content-top-item .ladi-icon.icon-bin {
    background-color: #d94f45;
}

.block-list-category .category-list-item .content-top-item .action-right {
    display: flex;
    align-items: center;
    grid-column-gap: 24px;
    -webkit-column-gap: 24px;
            column-gap: 24px;
    justify-content: space-between;
}

.setting-content-menu-banner .block-custom-interface {
    margin-top: 32px;
}

.setting-content-menu-banner .block-custom-interface .title h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.setting-content-menu-banner .block-custom-interface .title .sub-title {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    padding-top: 4px;
    color: #6d6d6d;
}

#modal-interactive-chanel .block-list-chanels .title {
    font-weight: 500;
    font-size: 14px;

}

.setting-content-domain-course .content {
    max-width: 900px;
}

.setting-content-domain-course .content .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--main-secondary);
}

.setting-content-domain-course .content .block-seo-social {
    max-width: 900px !important;
}

.setting-content-domain-course .content .block-seo-social h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--main-secondary);
}

.setting-content-domain-course .ladiui.form-group .link-default {
    padding: 8px 12px;
    border: 1px solid #e5e5e5;
    border-radius: 2px;
}

/* .setting-content-domain-course .preview-store-logo #store-logo {
    position: relative;
    width: 100%;
    height: 64px;
    max-width: 106px;
    overflow: hidden;
}
.setting-content-domain-course .preview-store-logo #store-logo .store-logo {
    width: 100%;
    height: 100%;
    border-radius: 8px;
} */

.page-course-welcome .content {
    padding: 100px 40px;
    display: flex;
    align-items: center;
}

.page-course-welcome .content .left-content {
    width: 50%;
}

.page-course-welcome .content .left-content .title {
    font-weight: 400;
    font-size: 28px;
    line-height: 26px;
    color: var(--main-black);
}

.page-course-welcome .content .left-content .block-list-functions {
    display: block;
    margin-top: 24px;
    margin-bottom: 34px;
}

.page-course-welcome .content .left-content .block-list-functions li {
    list-style-type: none;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.page-course-welcome .content .left-content .block-list-functions li:last-child {
    margin-bottom: 0;
}

.page-course-welcome .content .left-content .block-list-functions li span {
    font-weight: 400;
    font-size: 14px;
    color: var(--main-black);
    line-height: 16px;
}

.page-course-welcome .content .left-content .block-list-functions li .ldicon-circle-check {
    background-color: #4AC000;
}

.page-course-welcome .content .right-content {
    width: 50%;
}

.page-course-welcome .content .right-content .block-image img {
    width: 100%;
    object-fit: cover;
}

.block-suggestion-category h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.block-suggestion-category .sub-title {
    font-weight: 400;
    font-size: 12px;
    color: #4d4d4d;
    display: block;
    padding-top: 4px;
    padding-bottom: 24px;
}

.setting-content-domain-course .preview-store-logo #store-logo {
    width: -webkit-max-content;
    width: max-content;
}

.setting-content-domain-course .preview-store-logo #store-logo .store-logo {
    width: 64px;
    height: 64px;
    border-radius: 2px;
}

.setting-content-domain-course .preview-store-logo #store-logo:hover #store-logo-overlay {
    width: 64px;
    height: 64px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.setting-content-domain-course .preview-store-logo #store-logo #store-logo-overlay .icon {
    margin-left: 0px;
}

.block-content-product-course .ladiui-product-course-left .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #4D4D4D;
    margin-bottom: 24px;
}

.block-content-product-course .ladiui-product-course-right .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #4D4D4D;
}

.dropdown-custom-lds .ladiui.dropdown-text {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
}

#dropdown-domain-custom {
    width: calc(100% - 48px) !important;
}

#dropdown-active-chanel-custom {
    width: calc(100% - 48px) !important;
}

#dropdown-confirm-payment {
    width: calc(100% - 48px) !important;
}


.ladiui.table-vertical.main {
    min-height: 57px;
    border-bottom: 1px solid var(--secondary-color);
}

.ladiui.table-vertical.category-item {
    position: relative;
    display: flex;
    align-items: center;
}


.ladiui.drag-vertical.category {
    align-items: center;
    display: flex;
    width: 100%;
    height: 56px;
}
.ladiui.drag-vertical .ladiui.col-first {
    font-weight: 500;
    width: 3%;
}
.ladiui.drag-vertical .ladiui.col-name {
    font-weight: 500;
    width: 40%;
}
.ladiui.drag-vertical .ladiui.col-five {
    font-weight: 500;
    width: 24%;
}

.ladiui.table-vertical.category-item {
    position: relative;
    display: flex;
    align-items: center;
}

.ladiui.table-vertical.category-item .category-name {
    margin-top: 4px;
    width: 42%;
}

.ladiui.table-vertical.category-item .category-name a {
    overflow: hidden;
    padding-right: 12px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
}

.ladiui.table-vertical.category-item .td-draghandle {
    width: 24px;
    display: flex;
}

.ladiui.category-count {
    width: 24%;
}

.category-item .ladiui.end-table-option {
    position: absolute;
    right: 24px;
}

.ladiui.end-table-option {
    width: 33px;
}
.ladiui.table-vertical.category-item:hover .td-draghandle .icon-draggable {
    display: block !important;
}
.ladiui.table-vertical.category-item:hover .td-draghandle .ldicon-subcategory {
    display: none !important;
}
.block-seo-social .panel .preview-store-logo #store-logo .store-logo {
    width: 64px;
    height: 64px;
    border-radius: 2px;
}
.block-seo-social .panel .preview-store-logo #store-logo:hover #store-logo-overlay {
    width: 64px;
    height: 64px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.block-seo-social .panel .preview-store-logo #store-logo #store-logo-overlay .icon {
    margin-left: 0px;
}
.block-seo-social .panel .preview-store-logo #store-logo {
    width: -webkit-max-content;
    width: max-content;
}

#modal-add-video .list-videos {
    display: block;
}
#modal-add-video .list-videos .list-video-item {
    margin-bottom: 16px;
}
#modal-add-video .list-videos .list-video-item label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
#modal-add-video .list-videos .list-video-item:last-child {
    margin-bottom: 0px;
}
#modal-add-video .list-videos .list-video-item .video-info {
    display: flex;
    align-items: center;
}
#modal-add-video .list-videos .list-video-item .video-info img {
    width: 80px;
    height: auto;
    object-fit: cover;
    border-radius: 2px;
}
#modal-add-video .list-videos .list-video-item .video-info span {
    margin-left: 16px;
    margin-right: 16px;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
#modal-add-video .list-videos .list-video-item .date-time {
    display: flex;
    align-items: center;
}
#modal-add-video .list-videos .list-video-item .date-time span {
    display: block;
    white-space: nowrap;
}
#modal-add-video .list-videos .list-video-item .date-time .time-video {
    margin-right: 24px;
}
#modal-add-video .ladiui.modal-footer-custom {
    padding: 0px 24px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#modal-add-video .ladiui.modal-footer-custom .btn-upload-video {
    display: flex;
    cursor: pointer;
    align-items: center;
}
#modal-add-video .ladiui.modal-footer-custom span {
    font-weight: 500;
    color: var(--main-primary);
}
#modal-add-video .ladiui.modal-footer-custom .ldicon-upload{
    -webkit-mask-size: cover;
mask-size: cover;
-webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-upload.svg);
mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-upload.svg);
background-color: var(--main-primary);
width: 16px;
height: 16px;
display: inline-block;
}
.ldicon-status-success {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #4AC000;
}
.ldicon-status-error {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #E01A1A;
}
.position-initial {
    position: initial!important;
}
#dropdown-customer-field {
    width: calc(100% - 48px)!important;
}
#dropdown-choose-customer {
    width: calc(100% - 48px) !important;
}
.limit-text-vertical {
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
}
#modal-add-category-child .block-list-cates ul {
    padding: 16px;
    border-radius: 2px;
    border: 1px solid #e5e5e5;
    max-height: 350px;
    overflow: scroll;
}
#modal-add-category-child .block-list-cates ul li {
    margin-bottom: 16px;
    cursor: pointer;
}
#modal-add-category-child .block-list-cates ul li:last-child {
    margin-bottom: 0;
}
#modal-preview-video-bunny .block-preview-video .block-progress-video {
    display: block;
    width: 100%;
    background-color: #4d4d4d;
    padding: 64px;
}
#modal-preview-video-bunny .block-preview-video .block-progress-video .content {
    padding: 24px;
    background: rgba(0, 0, 0, 0.3);
    color: #fff;
    border-radius: 2px;
    text-align: center;
}
#modal-preview-video-bunny .block-preview-video .block-progress-video .content h3 {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 16px;
}
#modal-preview-video-bunny .block-preview-video .block-progress-video .content p {
    font-weight: 400;
    font-size: 11px;
}
@media (max-width: 768px) {
    .ladiui.fixed-sidebar .ladiui-main {
        width: 100%;
    }

    .ladiui-topbar .topbar-right {
        position: absolute;
        right: 0px;
        width: 90px;
    }

    .ladiui-aside {
        display: none;
    }

    body.mini-navbar .ladiui-aside {
        display: block !important;
    }

    .ladiui-user-type {
        padding: 2px 8px;
        font-size: 9px;
        top: 16px;
    }

    body.mini-navbar .ladiui-user-type {
        display: none;
    }

    .dashboard {
        display: block;
    }

    .dashboard .card {
        width: 100%;
        display: block;
        margin: 0px;
    }

    .ladiui-topbar.fixed {
        width: 100%;
        display: block;
    }

    .ladiui-topbar .topbar-left {
        width: 100%;
        display: block;
        height: 50px;
    }

    .ladiui-content {
        padding: 45px 10px 0px 10px;
        display: block;
    }

    .topbar-profile .ladiui-navbar.-horizontal>li:first-child {
        display: none;
        top: 1px;

    }

    .ladiui-order-left {
        padding: 0px;
    }

    .ladiui-order-right {
        position: relative;
        width: 100%;
        top: inherit;
        right: inherit;
    }

    .ladiui-table-responsive {
        overflow-x: scroll;
    }

    .ladiui-table-responsive table {
        width: 1280px !important;
    }

    /* .ladiui.pagination {
        width: 100%;
        text-align: center;
    } */

    .ladiui.pagination-block {
        text-align: center
    }

    /* .ladiui.pagination>ul>li>a {
        width: 25px;
    } */

    .order-tab {
        clear: both;
    }

    .ladiui.data-limit {
        float: none;
    }

    .order-tab .ladiui-tabs li {
        padding: 10px;
    }
}
/** @format */

.setting-page {
    width: 100%;
    display: flex;
}

.setting-content {
    width: 100%;
    max-width: 236px;
    padding: 0px;
    /* overflow: auto; */
    overflow: hidden;

    /* max-height: calc(100vh - 64px); */
}

.setting-content > ul {
    display: block;
}

.setting-content > ul .setting-item {
    display: flex;
    justify-content: flex-start;
    padding: 24px 0px 24px 24px;
    cursor: pointer;
    border-bottom: 1px solid #f1f3f4;
    margin: 0px;
}

.setting-content > ul .setting-item .title {
    font-weight: 500;
    font-size: 14px;
    color: var(--main-default);
}

.setting-page .right-content {
    width: 100%;
    background-color: #fff;
    padding-left: 24px;
    padding-right: 24px;
    border-left: 1px solid #f1f3f4;
}

.setting-page .right-content .ladiui-tabs {
    border-bottom: 1px solid #f1f3f4;
}

.setting-page .right-content .ladiui-tab-content {
    padding-left: 0px;
}

.setting-page .right-content .ladiui-tabs.top-menu li:first-child {
    padding-left: 0px !important;
}

.setting-page .right-content .ladiui-tab-content.form-setting {
    max-width: 770px;
}

.ladiui.table thead {
    border-bottom: none;
}

/* .ladiui.table tbody tr {
    border-bottom: none;
} */

.page-setting .ladiui-table-actions {
    margin-top: 24px;
}

.page-setting table {
    box-shadow: none !important;
}

#page-setting-domain .ladiui-table-actions .create-domain {
    padding-right: 20px;
}

.page-setting .block-title-action {
    display: flex;
    justify-content: space-between;
    padding-top: 24px;
    align-items: center;
    margin-bottom: 24px;
}

.page-setting .block-title-action label {
    display: flex;
}

.page-setting .block-title-action .title {
    font-size: 18px;
    font-weight: 500;
    color: var(--main-default);
}

.page-setting .block-title-action .btn-action {
    /* padding-right: 20px; */
    display: flex;
}

/* .page-setting .block-title-action .btn-action button {
    font-weight: 500;
} */

.page-setting .block-title-action .btn-action .back-default {
    display: flex;
    align-items: center;
}

.page-setting .block-title-action .btn-action .back-default svg {
    margin-right: 9px;
}

#page-setting-payment .list-payment {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

#page-setting-payment .list-payment .item {
    padding: 24px;
    border: 1px solid #f1f3f4;
    flex-basis: 49.2%;
    margin-bottom: 24px;
}

#page-setting-payment .list-payment .item img {
    object-fit: cover;
    max-height: 24px;
}

#page-setting-payment .list-payment .item .sub-name {
    font-weight: 500;
    font-size: 14px;
    color: #3e526a;
    line-height: 21px;
    display: block;
    margin-top: 8px;
    margin-bottom: 4px;
}

#page-setting-payment .list-payment .item .action {
    display: flex;
    justify-content: space-between;
}

.ml-16 {
    margin-left: 16px !important;
}

#panel-setting-checkout {
    margin-top: 0px !important;
}

#panel-setting-checkout .flex-basis {
    flex-basis: 48%;
}

#panel-setting-checkout .checkout-config table {
    border: 1px solid #f1f3f4;
    margin-top: 8px;
    min-width: auto !important;
}

#panel-setting-checkout .checkout-config .title-block {
    margin-top: 24px;
    display: flex;
    align-items: center;
}

#panel-setting-checkout input.checkbox,
.config-store-checkout input.checkbox {
    border: 1px solid #727272;
    /* background-color: #fff; */
}

#panel-setting-checkout input.checkbox:checked,
.config-store-checkout input.checkbox:checked {
    background-color: var(--main-primary);
}

/* #panel-setting-checkout .checkout-config .ladiui.table tbody tr td {
    padding: 8px 16px !important;
} */

#panel-setting-checkout .checkout-config .ladiui.table thead tr th {
    padding: 16px 0px 10px 15px !important;
}

.panel-setting-checkout .config-payments .table.list-active-payment-method {
    padding-top: 8px !important;
}

/* .onOffSetting {
    position: relative;
    display: inline-block;
    width: 28px;
    height: 16px;
    margin-left: 16px;
}

.onOffSetting input {
    opacity: 0;
    width: 0;
    height: 0;
} */

/* .onoffswitch-setting {
    position: absolute;
    cursor: pointer;
    border-radius: 34px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.onoffswitch-setting:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
} */

/* .onOffSetting input:checked+.onoffswitch-setting {
    background-color: var(--main-primary);
}

.onOffSetting input:focus+.onoffswitch-setting {
    box-shadow: 0 0 1px var(--main-primary);
}

.onOffSetting input:checked+.onoffswitch-setting:before {
    -webkit-transform: translateX(12px);
    -ms-transform: translateX(12px);
    transform: translateX(12px);
} */

.page-setting .block-title-action.credit {
    justify-content: flex-start;
    align-items: center;
}

.page-setting .block-title-action.credit .title {
    margin-right: 16px;
}

.ladiui.search-keyword .ladiui.input {
    text-indent: 5px;
    padding-right: 35px;
}

#page-setting-domain .ladiui-table-actions .ladiui.search-keyword {
    width: 270px;
}

.ladiui.input {
    display: block;
    width: 100%;
    padding: 6px 12px;
    font-weight: 400;
    height: 40px;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    /* border: 1px solid #F1F3F4; */
    border-radius: 2px;
    transition: border-color 0.15s ease-out;
}

.setting-page .right-content .ladiui-tabs > li:first-child {
    padding-left: 0px;
}

.page-setting .content-editor .title-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
}

.page-setting .content-editor .title-header h4 {
    font-weight: 500;
    font-size: 14px;
    color: #6d6d6d;
}

.page-setting .content-editor .title-header span {
    font-weight: 400;
    font-size: 14px;
    color: var(--main-primary);
}

.page-setting .list-payment .item .action .btn-action {
    display: flex;
}

#modal-setting-template-print table {
    min-width: auto !important;
}

#modal-setting-template-email table {
    min-width: auto !important;
}

.ladiui.form-filter {
    display: flex;
    align-items: center;
}

.page-setting .max-width-content {
    max-width: 900px;
}

#modal-edit-web-hook table {
    min-width: auto !important;
}

.ladiui.checkbox {
    /* background-color: #fff; */
    border: 1px solid #afafaf;
}

.ladiui.checkbox:checked {
    accent-color: var(--main-primary) !important;
}

.ladiui.checkbox {
    width: 16px;
    height: 16px;
}

/* .ladiui.checkbox:checked::before {
    left: 3px !important;
    top: -2px !important;
} */

.ladiui.modal-footer {
    justify-content: flex-end !important;
}

.page-setting #modal-checkout-config .ladiui.modal-content {
    -webkit-animation: none;
            animation: none;
}

.setting-page-general .setting-content .ladiui.setting-item.active .title {
    color: var(--main-primary);
}

/* #page-setting-shipping #tab-service {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: auto auto auto;
    margin-top: 30px;
} */

#page-setting-shipping #tab-service .item {
    padding: 16px;
    border: 1px solid #f1f3f4;
}

#page-setting-shipping .ladiui-tab-content .list-shipping {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: auto auto auto;
}

#page-setting-shipping .ladiui-tab-content .list-shipping .item .action {
    display: flex;
    justify-content: space-between;
}

#page-setting-shipping .ladiui-tab-content .list-shipping .item .action .btn-action {
    display: flex;
}

#page-setting-shipping .ladiui-tab-content .list-shipping .item .action .sub-name {
    font-weight: 500;
    font-size: 14px;
    color: #3e526a;
    margin-top: 8px;
    margin-bottom: 16px;
    display: block;
}

#page-setting-shipping .ladiui-tab-content .list-shipping .item img {
    max-height: 24px;
    display: block;
}

#page-setting-shipping .list-shipping-content .block-list-shipping table {
    width: 100%;
}

#page-setting-shipping .list-shipping-content .block-list-shipping table thead th {
    padding: 16px 16px 8px 16px;
    font-size: 14px;
    color: #6d6d6d;
}

#page-setting-shipping .list-shipping-content .block-list-shipping table tbody td {
    padding: 12px 12px 12px 16px;
}

#page-setting-shipping .list-shipping-content .block-list-shipping {
    margin-bottom: 24px;
    border-radius: 2px;
    border: 1px solid #f1f3f4;
}

#page-setting-shipping .list-shipping-content .ladiui-navbar.-horizontal li > ul {
    text-align: center;
    left: -80px;
}

.rule-item .title-rule {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
}

.list-shipping-content .title-heading-shipping {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-default);
}

.text-toggle-input-type {
    margin: 4px;
}

.ladiui-search-custom {
    width: 100%;
}

.list-pick-color-tag-customer {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    grid-gap: 8px;
}

/* .modal-add-customer .custom-modal-add-customer {
    margin-top: 11px !important;
} */

#page-setting-shipping .block-title-action {
    padding-top: 0px !important;
    margin-top: 24px;
    height: 32px;
}

.ladiui.nav {
    overflow-y: hidden !important;
}

#data-tooltip-guide label {
    display: flex;
    align-items: center;
}

#data-tooltip-guide label .tooltip-icon {
    display: flex;
    align-items: center;
    margin-left: 7px;
}

/* .ladiui-tags .tag .close::after {
    position: absolute;
    content: '×';
    top: 6px;
    left: 3px;
    font-weight: 900;
} */

.ladiui.modal-footer.custom-footer {
    display: flex;
    align-items: center;
    justify-content: space-between !important;
}

.ladiui.modal-footer.custom-footer .help-integrate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 14px;
    color: var(--main-default);
}

/* .block-filter-condition {
    padding-top: 24px !important;
} */

.list-content-shipping-rate {
    padding: 0px 16px;
}

.list-content-shipping-rate table {
    margin-top: 0px !important;
}

.list-content-shipping-rate table tbody tr {
    border-bottom: 1px solid #f1f3f4 !important;
}

.list-content-shipping-rate table thead tr {
    border-bottom: 1px solid #f1f3f4 !important;
}

.list-content-shipping-rate table thead tr th {
    padding: 12px 0px !important;
}

.list-content-shipping-rate table tbody tr td {
    padding: 12px 0px !important;
}

.list-content-shipping-rate table tbody tr:last-child {
    border-bottom: none !important;
}

.setting-block-image {
    position: relative;
}

.setting-block-image .block-upload-image {
    position: absolute;
    margin-top: 0px;
    left: 0px;
    top: 10px;
}

.setting-block-image .block-upload-image button {
    display: block;
    height: auto;
    cursor: pointer;
    background-color: transparent;
    color: #fff;
}

.setting-block-image .block-upload-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    color: #fff;
    cursor: pointer;
}

.setting-block-image:hover .block-upload-image {
    display: block;
    background: rgba(0, 0, 0, 0.6);
}

.ladiui-button-upload input[type='file'] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
}

.table-shipping-detail thead th {
    padding-left: 15px !important;
}

.table-shipping-detail tbody td {
    padding-left: 15px !important;
}

#block-custom-interface .label-header h3 {
    font-weight: 500;
    font-size: 14px;
    color: var(--main-default);
}

#block-custom-interface .label-header p {
    font-weight: 400;
    font-size: 12px;
    margin-top: 3px;
    line-height: 16px;
    color: #6d6d6d;
}

#block-custom-interface .block-custom-checkout {
    display: block;
    width: 100%;
}

#block-custom-interface .block-custom-checkout .add-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#block-custom-interface .block-custom-checkout .add-block button {
}

#block-custom-interface .block-custom-checkout .add-block .ladiui.btn-group {
    width: 80%;
}

#block-custom-interface .block-content .block-item {
    display: block;
    padding: 21px 15px 15px 15px;
    border: 1px solid #f1f3f4;
    border-radius: 2px;
    position: relative;
}

#block-custom-interface .block-content .block-item .legend-header {
    position: absolute;
    top: -9px;
    background-color: #fff;
    width: -webkit-max-content;
    width: max-content;
}

#block-custom-interface .block-content .block-item .legend-header .tooltip-icon {
    margin: 0px 7px;
}

#block-custom-interface .block-content .block-item .choose-content {
    display: block;
    position: absolute;
    z-index: 1;
    left: 0;
    margin-top: 16px;
    background-color: #fff;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
}

#block-custom-interface .block-content .block-item .choose-content ul {
    width: -webkit-max-content;
    width: max-content;
}

#block-custom-interface .block-content .block-item .choose-content ul > li {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    padding: 12px 12px 12px 0px;
    cursor: pointer;
}

#block-custom-interface .block-content .block-item .choose-content ul > li .image {
    padding: 0px 18px;
}

#block-custom-interface .block-content .block-item .choose-content ul > li:hover {
    background-color: #f1f3f4;
}

#block-custom-interface .block-content .block-item .action-footer {
    margin-top: 17px;
}

#block-custom-interface .block-content .block-item .list-item {
    display: block;
}

#block-custom-interface .block-content .block-item .list-item .item {
    margin-bottom: 12px;
}

#block-custom-interface .block-content .block-item .list-item .item .content {
    padding: 11px 12px;
    background-color: #f1f3f4;
    border-radius: 2px;
}

.ladiui.nav-item {
    margin: 0 16px !important;
}

.ladiui.nav-item:first-child {
    margin-left: 0px !important;
}

.block-filter-header .filter-condtion-area button {
    display: flex;
    align-items: center;
}

#modal-export-data .item {
    display: flex;
    align-items: center;
}

.ladiui-link {
    color: var(--main-primary) !important;
}

.ladiui-link:hover {
    text-decoration: underline;
}

.timeline .content > ul > li {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.timeline .content > ul > li .item-avt-left {
    display: flex;
    align-items: center;
    z-index: 2;
    justify-content: start;
}

.timeline .content > ul > li .item-time-right {
    display: flex;
    width: 150px;
    align-items: center;
    justify-content: space-between;
}

.timeline .content > ul > li .item-time-right .ladiui.dropdown-menu {
    right: 0px;
}

.timeline .content > ul > li .item-avt-left .ladiui-input-group {
    width: 400px;
    padding: 12px;
}

.ladiui-order-left .w-name-product .item-variant-name-info .item-variant-list-option span {
    font-size: 12px;
    line-height: 16px;
}

.ladiui-order-left .item-variant {
    font-size: 14px;
}

.order-custom-edit .ladiui-tags {
    height: auto !important;
}

.payment-shipping .url-checkout {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
}

.payment-shipping .url-checkout .title-payment .link .link-checkout {
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    line-height: 20px;
}

.payment-shipping .url-checkout .title-payment .link {
    padding: 6px 12px;
    border: 1px solid #f1f3f4;
}

.block-create-order-staff .ladiui.dropdown button {
    min-width: 160px !important;
}

.discount-box .ladiui-input-group {
    flex-wrap: nowrap;
}

.block-custom-field.order .custom-field-content .date-item label {
    font-size: 14px;
    /* font-weight: 400; */
    line-height: 20px;
}

.block-filter-condition .result-filter-condition .ladiui.btn-tag {
    display: flex;
    align-items: center;
}

.ladiui-product-right .product-variants span {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #6d6d6d;
}

.ladiui-order-right .info-customer .custom-info.fix .order-customer {
    border-bottom: none !important;
}

.ladiui-order-right .info-customer .custom-info.fix .order-customer .customer-info {
    margin-bottom: 0px !important;
    padding-bottom: 24px;
    border-bottom: 1px solid #f1f3f4;
}

.tiket-seat-order .action-item.pull-right .search-keyword {
    margin-bottom: 24px;
}

.tiket-seat-order .action-item.pull-right .search-keyword input {
    border: 1px solid #ebedf2;
}

.ladiui.dropdown-toggle:after {
    top: 0px !important;
}

.block-filter-header .ladiui.dropdown-menu.open {
    width: 100%;
}

#modal-export-order .item {
    display: flex;
    align-items: center;
}

.modal-fix-overflow {
    overflow: inherit !important;
}

.input-upload-file .ladiui-button-upload {
    position: absolute !important;
    top: 0 !important;
    right: 0 !important;
    margin-top: 0px !important;
    width: auto !important;
    height: auto !important;
}

.input-upload-file .ladiui-button-upload button {
    padding: 4px;
    margin: 4px !important;
    background: transparent;
    border: 1px solid var(--main-primary);
    color: var(--main-primary);
    cursor: pointer;
}

#modal-customer .timeline .input-history {
    height: 43px !important;
}

#modal-customer .timeline .input-history .comment {
    bottom: 5px !important;
}

.aside .ladiui.nav ul > li .ladiui.menu-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ladiui-order-left .item-variant .image-product-variant {
    width: 82px !important;
    height: 82px !important;
    border-radius: 5px;
    overflow: hidden;
}

.ladiui.btn.rounded {
    border-radius: 20px;
    cursor: pointer;
}

.block-list-location .block-item .list-item .item-text {
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    line-height: 20px;
    max-width: 250px;
}

.block-list-location .block-item .list-item .item {
    justify-content: space-between;
}

.ladiui.dropdown-menu.custom-form-dynamic {
    max-height: 300px;
    overflow-y: scroll;
}

.address-default-btn {
    background-color: #41b983;
    padding: 2px 7px;
    color: #fff;
    border-radius: 2px;
    margin-left: 8px;
}

.btn-payment-custom {
    color: #1c00c2 !important;
}

.actions-dropdown-domain li a {
    white-space: nowrap;
}

#modal-web-hook-transactions .search-keyword input {
    height: 38px !important;
}

#modal-web-hook-transactions .search-keyword .icon-zoom {
    top: 2px !important;
}

.icon-ldp-status-warning.status-custom-error {
    background-color: #d94f45;
}

#custom_javascript_head,
#custom_javascript_body {
    border: 1px solid #f1f3f4;
    width: 622px !important;
}

#modal-page-checkout .ladiui .modal-content {
    -webkit-animation: none !important;
            animation: none !important;
}

.block-update-status .item-update-status {
    border-top: 1px solid #f1f3f4;
    align-items: center;
}

.shiping-info-edit.custom {
    border: 1px solid #f1f3f4;
    margin-bottom: 24px;
}

.shiping-info-edit.custom .shipping-info {
    border-bottom: 0px !important;
}

.shiping-info-edit.custom .shipping-info .shipping-info-heading {
    padding: 10px 24px;
    background-color: #f1f3f4;
}

.shiping-info-edit.custom .shipping-info .shipping-info-heading h3 {
    margin-bottom: 0px !important;
}

.shiping-info-edit.custom .shipping-info .shipping-info-content {
    padding: 0px 24px;
}

.shipping-info-customer.shipping-edit {
    border: 1px solid #f1f3f4;
}

.shipping-info-customer.shipping-edit .order-customer .order-heading {
    padding: 10px 24px !important;
    background-color: #f1f3f4;
}

.shipping-info-customer.shipping-edit .order-customer .customer-info {
    padding: 24px 24px;
}

.shipping-info-customer.shipping-edit .shipping-address .order-customer .order-heading {
    background-color: transparent;
    padding: 24px 24px 0px 24px !important;
}

.shipping-info-customer.shipping-edit .shipping-address .order-customer {
    border-bottom: 0px !important;
}

#modal-image-variant .ladiui-button-upload {
    width: auto !important;
    height: auto !important;
}

#modal-shipping-form .ladiui.modal-body .right .order-customer .customer-info {
    padding-bottom: 24px !important;
}

.block-tracking-info .item-tracking {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.block-tracking-info .item-tracking:last-child {
    margin-bottom: 0px !important;
}

.block-tracking-info .item-tracking span {
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.block-tracking-info .item-tracking .show-content {
    width: 200px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.block-tracking-info .item-tracking span i {
    margin-right: 8px;
}

.order-unsuccess .block-shipping-address .order-customer {
    padding-bottom: 24px;
}

.block-customer-info-right .order-custom-field.set-max-width {
    max-width: 330px !important;
    /* overflow: hidden; */
}

.create-order-customer-right .order-customer.not-customer-selected.block-dropdown-customer .title-block-customer {
    padding: 10px 24px !important;
    background-color: #f1f3f4;
}

.create-order-customer-right .order-customer.not-customer-selected.block-dropdown-customer .block-custom-content {
    padding: 16px 16px 0px 16px;
}

.create-order-customer-right .panel-customer .order-customer .order-heading {
    padding: 10px 24px;
    background-color: #f1f3f4;
}

.create-order-customer-right .panel-customer .order-customer .customer-info {
    margin: 0px 24px;
    padding: 24px 0px;
    border-bottom: 1px solid #f1f3f4;
}

.create-order-customer-right .order-customer {
    border-bottom: none !important;
}

.create-order-customer-right .panel-shipping-address {
    margin: 24px;
}

.create-order-customer-right .panel-shipping-address .customer-info.custom-shipping-address {
    padding-bottom: 24px;
    border-bottom: 1px solid #f1f3f4;
}

.block-total-all-order {
    padding: 10px 32px;
    border-radius: 2px;
    margin-top: 20px;
    background-color: #f1f3f4;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.btn-print-order-my-order {
    padding: 7px;
    border: 1px solid var(--main-primary);
    background-color: transparent;
    color: var(--main-primary);
    display: flex;
    align-items: center;
}

.block-content-shippings {
    display: block;
    overflow: hidden;
    margin-top: 20px;
}

.info-customer .order-unfinish .order-customer .order-heading {
    padding: 10px 20px !important;
    background-color: #f1f3f4;
}

.info-customer .order-unfinish .order-customer .customer-info {
    margin: 0px 24px;
    padding: 24px 0px;
    border-bottom: 1px solid #f1f3f4;
}

.info-customer .order-unfinish .order-customer {
    border-bottom: 0px !important;
}

.email-confirm .input-custom {
    background-color: #fff !important;
}

.DateRangePicker_picker {
    z-index: 1111 !important;
}

.incomplete-order {
    display: block;
    width: 100%;
    overflow: hidden;
    margin-top: 24px;
}

.block-list-actions-orders > li a {
    white-space: nowrap !important;
}

.dropdown-payment-method.arrow-down::after {
    content: '\ea4a';
    font-family: 'LadiIcons';
    font-size: 12px;
    /* top: 21px; */
    top: 10px;
    position: absolute;
    z-index: 0;
    color: #8697a8;
    padding-left: 6px;
}

.dropdown-payment-method.arrow-up::after {
    content: '\ea47';
    font-family: 'LadiIcons';
    font-size: 12px;
    /* top: 20px; */
    top: 10px;
    position: absolute;
    z-index: 0;
    color: #8697a8;
    padding-left: 6px;
}

.input-general-custom {
    margin-top: 24px !important;
    margin-bottom: 0px !important;
}

#modal-variant .ladiui.dropdown-toggle:after {
    top: auto !important;
}

/* .block-product-content-left .block-custom-field h3 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 16px;
    margin-top: 24px;
} */

.block-product-content-left .block-custom-field .custom-field-content label {
    font-size: 14px;
}

.list-filter-condition.show .ladiui.form-group {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.list-filter-condition.show .ladiui.form-group .ladiui.tags {
    padding: 1px 8px !important;
}

.list-filter-condition.show .ladiui.form-group .ladiui.tags .async-auto-complete.menu {
    min-width: 100% !important;
}

.ladiui.table-vertical.order-cancel td:nth-child(2) a {
    color: rgba(108, 114, 147, 0.56) !important;
    text-decoration: line-through;
}

/* #tab-general-edit .ladiui-product-right .ladiui.dropdown-toggle:after {
    top: auto !important;
} */

#add-product .ladiui.modal-body .list-product .product-name {
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    max-width: 100%;
    padding-right: 10px;
}

.ladiui.dropdown-toggle.dropdown-discount-type:after {
    top: auto !important;
}

.tool-help-fix-top .list-tool {
    left: -85px !important;
    min-width: 200px !important;
}

.tool-help-fix-top .btn-help {
    background: transparent !important;
    cursor: pointer;
}

.tiket-seat-order-edit #modal-ticket-seat {
    overflow: inherit !important;
}

.tiket-seat-order-edit #modal-ticket-seat .ladiui.modal-dialog {
    margin-top: 0px !important;
}

#modal-ticket-seat .ladiui.modal-body {
    overflow-x: hidden !important;
    overflow-y: auto;
} 

#modal-ticket-seat .ladiui.modal-body .ladi-card table {
    width: 100%;
    display: block !important;
    overflow: auto !important;
} 

.url-checkout .ladiui.btn-group {
    width: 30%;
    max-width: 30%;
    padding-right: 12px;
}

.url-checkout .ladiui.btn-group .select-checkout-config button {
    min-width: 100%;
    width: 100%;
    max-width: 100%;
}

.url-checkout .title-payment {
    width: 70%;
    /* margin-left: 12px; */
}

.block-billing-infomation .customer-info .item-info {
    display: flex;
    align-items: center;
    line-height: 20px;
}

.block-billing-infomation .customer-info .item-info > span {
    color: var(--main-default);
}

.block-billing-infomation .customer-info .item-info > label {
    color: #6d6d6d;
    white-space: nowrap;
}

.block-customer-field .customer-info .item-info {
    display: flex;
    line-height: 20px;
}

.block-customer-field .customer-info .item-info > span {
    color: var(--main-default);
}

.block-customer-field .customer-info .item-info > label {
    color: #6d6d6d;
    white-space: nowrap;
}

.ladiui.btn-tag.custom {
    margin-right: 8px;
}

#modal-shipping-form .ladiui.modal-body .left .ladiui.form-group.custom-form-group {
    margin-top: 24px !important;
}

#modal-edit-custom-field .custom-field-content .ladiui.dropdown-menu.custom-form-dynamic {
    top: -184px !important;
}
/* 
#modal-customer .custom-field-content .ladiui.dropdown-menu.custom-form-dynamic {
    top: -184px !important;
} */

#modal-adjust .ladiui-button-upload {
    position: relative;
    overflow: hidden;
    display: inline-block;
    margin-top: 20px;
    width: 0px !important;
    height: 0px !important;
}

.ladi-card.fix-width {
    max-width: 900px !important;
}

/* .block-info-top>label {
    font-size: 16px;
    font-weight: 500;
}
.block-info-top .content-info {
    font-size: 14px;
    font-weight: 400;
    color: #6d6d6d;
} */

.ladiui.form-group .publish-times .publish-path {
    width: 100%;
}

.ladiui.form-group .publish-times .publish-redirect-after {
    padding-left: 24px;
    /* width: 30%; */
}

.btn-add-auto-assign {
    border: none !important;
}

.btn-add-auto-assign:hover {
    box-shadow: none !important;
}

.btn-add-auto-assign::after {
    background: #fff !important;
}
.btn-add-auto-assign span {
    color: var(--main-primary);
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.add-auto-assign {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.toggle-dropdown-product-edit h2 {
    position: relative;
    background-color: #f1f3f4;
}
.toggle-dropdown-product-edit .ladiui.arrow-down::after {
    content: '\ea4a';
    font-family: 'LadiIcons';
    font-size: 12px;
    top: 6px;
    right: 10px;
    position: absolute;
    z-index: 0;
    color: #6d6d6d;
    padding-left: 6px;
}

.toggle-dropdown-product-edit .ladiui.arrow-up::after {
    content: '\ea47';
    font-family: 'LadiIcons';
    font-size: 12px;
    right: 10px;
    top: 6px;
    position: absolute;
    z-index: 0;
    color: #6d6d6d;
    padding-left: 6px;
}
.ladiui.list-customer-form-edit li {
    padding: 12px 16px;
}

.ladiui.list-customer-form-edit li .text-name {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-default);
}

.ladiui.list-customer-form-edit li .email {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6d6d6d;
}

.ladiui.list-customer-form-edit li span {
    display: block;
}
.ladiui.list-customer-form-edit li:hover {
    background-color: #f1f3f4;
}
.ladiui.list-customer-form-edit {
    max-height: 300px;
    overflow: auto;
    overflow-y: scroll;
}

.table-account th {
    white-space: nowrap;
}

.utm-info .async-auto-complete.menu {
    display: none !important;
}

.toggle-dropdown-product-edit .title-block {
    padding: 6px 16px;
    background-color: #f1f3f4;
}
.toggle-dropdown-product-edit .title-block {
    font-size: 14px;
    font-weight: 500;
}

.list-image-product .list-image .item-image {
    width: -webkit-max-content;
    width: max-content;
}

.list-image-product .list-image .item-image .block-icon-star {
    position: absolute;
    background-color: rgba(32, 32, 32, 0.7);
    top: 3px;
    padding: 3px 5px;
    border-radius: 2px;
    display: none;
    left: 3px;
}
.list-image-product .list-image .item-image:hover .block-icon-star {
    display: block;
}

.list-image-product .list-image .item-image .block-delete-img {
    position: absolute;
    background-color: rgba(32, 32, 32, 0.7);
    top: 3px;
    padding: 3px 5px;
    display: none;
    border-radius: 2px;
    right: 3px;
}
.list-image-product .list-image .item-image:hover .block-delete-img {
    display: block;
}
#modal-shipping-form .modal-fix-overflow .ladiui.modal-dialog {
    margin-top: 0px !important;
}
/* .ladiui .block-filter-condition .ladiui.tags .tag:first-child {
    margin-left: 10px!important;
} */
.table.table-order tfoot tr {
    height: 40px;
    border-radius: 2px;
    line-height: 40px;
    background-color: #f1f3f4;
}
.table.table-order tfoot tr > td > strong {
    font-weight: 500;
    font-size: 14px;
    color: var(--main-default);
    padding-left: 32px;
}

/* #panel-setting-checkout .ladi-card .table.list-active-payment-method {
    height: 502px;
    max-height: 502px;
    overflow: scroll;
} */

#panel-setting-checkout .ladi-card .table.list-active-payment-method tbody {
    width: 100%;
    display: inline-table;
}

.ladiui-product-right.service .ladiui-label.custom::first-letter {
    text-transform: capitalize;
}

.ladiui.custom-btn-list-landingpage .ladiui.dropdown button {
    line-height: 26px;
}

#tab-package-info .package-content-info {
    border: 1px solid var(--main-line);
    width: 394px;
    padding: 24px;
}

#tab-package-info .pkg-btn-upgrade {
    text-align: end;
    display: flex;
    justify-content: end;
    padding: 35px 0 0;
}

.payment-method-note {
    margin-left: 24px;
    color: var(--main-secondary);
    width: -webkit-fill-available !important;
}

.page-discount {
    background-color: #fff;
    padding: 24px;
}

.page-discount .block-title-action .block-left {
    display: flex;
    align-items: center;
}

.page-discount .block-title-action .block-left .title {
    margin-right: 26px;
}

.ladiui.table {
    box-shadow: none;
}

.page-discount .ladiui.pagination-block {
    display: block;
    overflow: hidden;
}

#modal-discount .condition-group {
    border-top: none;
    margin-top: 0px;
}

#modal-discount .condition-group>label {
    position: inherit;
    top: 0px;
    padding-bottom: 16px;
}

/* .page-discount .ladiui.content-main-tool {
    padding: 14px 0px;
} */

.page-discount .ladiui.content-main-tool .btn-group {
    padding: 0px;
}

#modal-discount .ladiui.form-group {
    display: block;
    margin-bottom: 24px;
}

#modal-discount .ladiui.form-group .ladiui.checkbox {
    margin-right: 8px;
}
#modal-discount .ladiui.form-group .coupon-create-types {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
}
#modal-discount .ladiui.form-group .coupon-create-types .item {
    display: flex;
    align-items: center;
}
#modal-discount .line-title-condition {
    display: block;
    width: 100%;
    overflow: hidden;
}
#modal-discount .line-title-condition h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #4d4d4d;
    margin: 24px 0px;
}
.page-integrate {
    background-color: #fff;
    padding: 24px;
}

.page-integrate .ladiui-tab-content {
    padding: 0px;
}

.page-integrate .ladiui.pagination-block {
    display: block;
    overflow: hidden;
}

#modal-form-account .list-integrate {
    display: grid;
    /* grid-template-columns: auto auto auto; */
    grid-template-columns: 240px 240px 240px;
    grid-column-gap: 16px;
    -webkit-column-gap: 16px;
            column-gap: 16px;
    grid-row-gap: 16px;
    row-gap: 16px;
}

#modal-form-account .list-integrate .item-integrate {
    padding: 16px;
    cursor: pointer;
    border: 1px solid #f1f3f4;
}

#modal-form-account .list-integrate .item-integrate .img-title {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
}

#modal-form-account .list-integrate .item-integrate .description {
    font-size: 14px;
    color: #6d6d6d;
}
#modal-form-account .list-integrate .item-integrate .description p {
    line-height: 20px;
}

#modal-form-account .list-integrate .item-integrate .img-title .icon-form-account {
    margin-right: 16px;
}

.page-report-content {
    background-color: #fff;
    padding: 24px;
}

#page-customer {
    padding: 24px;
    background-color: #fff;
}

#page-customer .ladiui.pagination-block {
    display: block;
    overflow: hidden;
}

#page-customer .block-title-action .block-left {
    display: flex;
    align-items: center;
}

#page-customer .block-title-action .block-left .title {
    margin-right: 26px;
}

#page-checkout {
    padding: 24px;
    background-color: #fff;
}

#page-checkout .ladiui.pagination-block {
    display: block;
    overflow: hidden;
}

#page-checkout .block-title-action .block-left {
    display: flex;
    align-items: center;
}

#page-checkout .block-title-action .block-left .title {
    margin-right: 26px;
}

#page-tags {
    padding: 0px;
}

#page-tags .ladiui.pagination-block {
    display: block;
    overflow: hidden;
}

#page-tags .block-title-action .block-left {
    display: flex;
    align-items: center;
}

#page-tags .block-title-action .block-left .title {
    margin-right: 26px;
}
.tab-config-sdk .ladiui .sdk-page {
    display: block;
    height: 100vh;
    width: 100%;
    float: left;
}
.tab-config-sdk .ladiui .sdk-page .setting-sdk {
    width: 70%;
    height: 100vh;
    /* float: left; */
    padding-right: 24px;
    /* overflow-y: scroll; */
    /* overflow-x: auto; */
    background: #fff;
}
.tab-config-sdk .ladiui .sdk-page .preview-sdk {
    overflow: hidden;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0px;
    /* width: 506px; */
    width: 30%;
    float: right;
    background-image: url(data:image/gif;base64,R0lGODlhEAAQAJEAAAAAAP////X19f///yH5BAEAAAMALAAAAAAQABAAAAIflG+hq4jM3IFLJhqswNly/XkcBpIiVaInlLJr9FZWAQA7);
    height: calc(100vh - 70px);
    border-top-right-radius: 5px;
}
.ladisales-widget-frame {
    display: block;
    border: none;
    position: relative;
    width: 100%;
    height: 100%;
    opacity: 1;
    overflow: hidden;
    background: none transparent;
    padding: 10px;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    transition: none 0s ease 0s;
    visibility: visible;
}
.preview-sdk .screen {
    height: calc(100% - 92px);
    overflow: auto;
}
.preview-sdk .screen #ladisales {
    height: 100%;
}
.preview-sdk .select-screen {
    background: #fff;
    display: flex;
    padding: 10px 0 8px;
    align-items: center;
}
.preview-sdk .select-screen .title {
    margin-right: 24px;
    white-space: nowrap;
}
.sdk-page .setting-sdk .panel {
    width: 100%;
    margin-bottom: 24px;
    display: block;
}
.sdk-page .setting-sdk .panel .panel-line {
    display: flex;
    margin: 8px 0px;
}
.sdk-page .setting-sdk .panel .panel-line .main-description {
    color: var(--main-black);
    width: 20%;
    padding-top: 10px;
    font-weight: 500;
}
.sdk-page .setting-sdk .panel .panel-line .content {
    width: 100%;
    text-align: left;
    padding-left: 10px;
}
.sdk-page .setting-sdk .panel .panel-line .content .color-picker {
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    z-index: 10;
}
.color-item {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    margin: 4px 6px 0;
    text-align: center;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
}
.color-item.btn-add-background {
    border: 1px solid #AFAFAF;
    font-size: 20px;
}
.color-item.checked {
    pointer-events: none!important;
    border: 1px solid var(--main-line);
}
.sdk-page .setting-sdk .panel .panel-line .content .widget-position {
    display: flex;
}
.select-text-color.white {
    background: var(--main-white);
    border: 1px solid var(--main-secondary);
}
.select-text-color.black {
    background: var(--main-black);
}
.sdk-page .setting-sdk .panel .col-50 {
    margin-left: 0px!important;
}
.sdk-page .setting-sdk .panel .panel-line .main-description .sub-description {
    line-height: 16px;
    margin-top: 6px;
    color: var(--main-mute);
    font-size: 12px;
    font-weight: 400;
}
#store-logo {
    position: relative;
}
#store-logo img .store-logo {
    background-color: var(--main-line);
    border: 1px solid var(--main-line);
    object-fit: cover;
    width: 56px;
    height: 56px;
    border-radius: 50%;
}
#store-logo-overlay {
    align-items: center;
    text-align: center;
    width: 56px;
    height: 56px;
    top: 0;
    z-index: 2;
    position: absolute;
    display: none;
    border-radius: 50%;
    background-color: rgba(0,0,0,.6);
}
.preview-store-logo #store-logo:hover>#store-logo-overlay {
    display: flex;
}
#store-logo-overlay .icon {
    background-color: var(--main-white);
    margin-left: 16px;
}
.ladiui.icon-ldp-pen-edit {
    box-sizing: unset;
    display: inline-block;
    width: 24px;
    height: 24px;
    pointer-events: none;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/icons/ldicon-edit.svg) no-repeat 50% 50%;
}
.ladiui.danger-text {
    color: var(--danger-color);
}
.sdk-page .setting-sdk .panel .panel-line .content .preview-store-logo {
    text-align: left;
    padding: 12px 9px 10px 0;
    height: 80px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
}
.sdk-page .setting-sdk .panel .panel-line .content .preview-store-logo .button.upload label {
    cursor: pointer;
}
.panel-line .content .preview-store-logo .store-logo {
    border: 1px solid var(--main-line);
    object-fit: cover;
    width: 56px;
    height: 56px;
    border-radius: 50%;
}
.ladiui.button.upload {
    margin-left: 11px;
    display: inline-block;
    box-shadow: none;
    margin-top: 18px;
    color: var(--main-primary);
}
.setting-sdk .panel .panel-line .content .cart-bg-image {
    display: flex;
    margin: 10px 0;
    line-height: 20px;
    background: #fff;
    border: 1px solid var(--main-line);
    border-radius: 3px;
}
.cart-bg-image.back-ground {
    display: inline-flex;
    width: 316px;
    height: 126px;
    background: var(--main-secondary);
    justify-content: center;
    position: relative;
    padding: unset!important;
    cursor: pointer;
}

.setting-content-menu-banner .panel .content .block-add-image-background {
    width: calc(25% - 9px);
    text-align: center;
    height: 90px;
    background-color: #eee;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #6d6d6d;
    align-items: center;
    cursor: pointer;
}
.setting-content-menu-banner .panel .content .block-list-banner-images {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 12px;
    gap: 12px;
}
.setting-content-menu-banner .panel .content .block-list-banner-images .banner-image-item {
    width: calc(25% - 9px);
    height: 90px;
    cursor: pointer;
    position: relative;
}
.setting-content-menu-banner .panel .content .block-list-banner-images .banner-image-item .block-action-item {
    align-items: center;
    position: absolute;
    top: 4px;
    right: 4px;
    display: none;
}
.setting-content-menu-banner .panel .content .block-list-banner-images .banner-image-item .block-action-item .block-icon-link {
    background-color: rgba(32,32,32,.7);
    padding: 3px 5px;
    border-radius: 2px;
    margin-right: 4px;
}
.setting-content-menu-banner .panel .content .block-list-banner-images .banner-image-item .block-action-item .block-icon-delete {
    background-color: rgba(32,32,32,.7);
    padding: 3px 5px;
    border-radius: 2px;
}
.setting-content-menu-banner .panel .content .block-list-banner-images .banner-image-item .block-input-add-link {
    position: absolute;
    bottom: 0px;
    left: 0px;
    background: #fff;
    width: 100%;
}
.setting-content-menu-banner .panel .content .block-list-banner-images .banner-image-item .block-input-add-link .ldicon-save {
    position: absolute;
    right: 8px;
    cursor: pointer;
    bottom: 6px;
}
.setting-content-menu-banner .panel .content .block-list-banner-images .banner-image-item .block-input-add-link .ladiui.form-control {
    height: 24px !important;
    width: 100% !important;
    margin: 0 !important;
    padding-right: 30px;
    font-size: 12px;
}
.setting-content-menu-banner .panel .content .block-list-banner-images .banner-image-item:hover .block-action-item {
    display: flex;
}
.setting-content-menu-banner .panel .content .block-list-banner-images .banner-image-item:hover .ldicon-link {
    background-color: #fff;
}
.setting-content-menu-banner .panel .content .block-list-banner-images .banner-image-item:hover .ldicon-delete {
    background-color: #fff;
}
.setting-content-menu-banner .panel .content .block-list-banner-images .banner-image-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.content .cart-bg-image #upload-background-overlay {
    position: absolute;
    top: 50px;
    left: 95px;
    display: none;
}
.content .cart-bg-image.back-ground:hover>#upload-background-overlay {
    display: flex;
}
.content .cart-bg-image.back-ground a {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 30px;
    border-radius: 50%;
    margin-left: 20px;
    background-color: var(--main-primary);
}
.content .cart-bg-image.back-ground .white-background .icon {
    background-color: var(--danger-color);
}
.content .cart-bg-image.back-ground .white-background {
    background-color: var(--main-white);
}
.content .cart-bg-image.back-ground img {
    max-width: 380px;
    width: 380px;
    object-fit: cover;
}
#upload-background-overlay .ladiui.ldicon-upload {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-upload.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-upload.svg);
    background-color: var(--main-white);
    width: 16px;
    height: 16px;
    display: inline-block;
}
.ladiui.icon-checked {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-check.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-check.svg);
    background-color: var(--main-white);
    width: 16px;
    height: 16px;
    display: inline-block;
}
.select-text-color.white .ladiui.icon-checked {
    background-color: var(--main-secondary) !important;
}
.ladi-element {
    position: relative;
    z-index: 1;
}

.ladi-gallery {
    position: absolute;
    width: 100%;
    height: 100%;
}

.ladi-gallery .ladi-gallery-view {
    position: absolute;
    overflow: hidden;
}

.ladi-gallery .ladi-gallery-view>.ladi-gallery-view-item {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 100%;
    position: relative;
    display: none;
    transition: left 400ms ease-in-out;
}

@media all and (transform-3d),
(-webkit-transform-3d) {
    .ladi-gallery .ladi-gallery-view>.ladi-gallery-view-item {
        transition: -webkit-transform 400ms ease-in-out;
        transition: transform 400ms ease-in-out;
        transition: transform 400ms ease-in-out, -webkit-transform 400ms ease-in-out;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-perspective: 1000px;
        perspective: 1000px;
    }

    .ladi-gallery .ladi-gallery-view>.ladi-gallery-view-item.next,
    .ladi-gallery .ladi-gallery-view>.ladi-gallery-view-item.selected.right {
        left: 0;
        -webkit-transform: translate3d(100%, 0, 0);
                transform: translate3d(100%, 0, 0);
    }

    .ladi-gallery .ladi-gallery-view>.ladi-gallery-view-item.prev,
    .ladi-gallery .ladi-gallery-view>.ladi-gallery-view-item.selected.left {
        left: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
                transform: translate3d(-100%, 0, 0);
    }

    .ladi-gallery .ladi-gallery-view>.ladi-gallery-view-item.next.left,
    .ladi-gallery .ladi-gallery-view>.ladi-gallery-view-item.prev.right,
    .ladi-gallery .ladi-gallery-view>.ladi-gallery-view-item.selected {
        left: 0;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}

.ladi-gallery .ladi-gallery-view>.selected,
.ladi-gallery .ladi-gallery-view>.next,
.ladi-gallery .ladi-gallery-view>.prev {
    display: block;
}

.ladi-gallery .ladi-gallery-view>.selected {
    left: 0;
}

.ladi-gallery .ladi-gallery-view>.next,
.ladi-gallery .ladi-gallery-view>.prev {
    position: absolute;
    top: 0;
    width: 100%;
}

.ladi-gallery .ladi-gallery-view>.next {
    left: 100%;
}

.ladi-gallery .ladi-gallery-view>.prev {
    left: -100%;
}

.ladi-gallery .ladi-gallery-view>.next.left,
.ladi-gallery .ladi-gallery-view>.prev.right {
    left: 0;
}

.ladi-gallery .ladi-gallery-view>.selected.left {
    left: -100%;
}

.ladi-gallery .ladi-gallery-view>.selected.right {
    left: 100%;
}

.ladi-gallery .ladi-gallery-control {
    position: absolute;
    overflow: hidden;
}

.ladi-gallery.ladi-gallery-top .ladi-gallery-view {
    width: 100%;
}

.ladi-gallery.ladi-gallery-top .ladi-gallery-control {
    top: 0;
    width: 100%;
}

.ladi-gallery.ladi-gallery-bottom .ladi-gallery-view {
    top: 0;
    width: 100%;
}

.ladi-gallery.ladi-gallery-bottom .ladi-gallery-control {
    width: 100%;
    bottom: 0;
}

.ladi-gallery.ladi-gallery-left .ladi-gallery-view {
    height: 100%;
}

.ladi-gallery.ladi-gallery-left .ladi-gallery-control {
    height: 100%;
}

.ladi-gallery.ladi-gallery-right .ladi-gallery-view {
    height: 100%;
}

.ladi-gallery.ladi-gallery-right .ladi-gallery-control {
    height: 100%;
    right: 0;
}

.ladi-gallery .ladi-gallery-view .ladi-gallery-view-arrow-left {
    position: absolute;
    width: 48px;
    height: 48px;
    top: calc(50% - 24px);
    left: 5px;
    background: url("https://w.ladicdn.com/source/arrows.svg") no-repeat;
    background-position-x: -48px;
    cursor: pointer;
    z-index: 90000050;
}

.ladi-gallery .ladi-gallery-view .ladi-gallery-view-arrow-right {
    position: absolute;
    width: 48px;
    height: 48px;
    top: calc(50% - 24px);
    right: 5px;
    background: url("https://w.ladicdn.com/source/arrows.svg") no-repeat;
    cursor: pointer;
    z-index: 90000050;
}

.ladi-gallery.ladi-gallery-top .ladi-gallery-control .ladi-gallery-control-arrow-left {
    position: absolute;
    width: 48px;
    height: 48px;
    top: calc(50% - 24px);
    left: 5px;
    background: url("https://w.ladicdn.com/source/arrows.svg") no-repeat;
    background-position-x: -48px;
    cursor: pointer;
    z-index: 90000050;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
}

.ladi-gallery.ladi-gallery-top .ladi-gallery-control .ladi-gallery-control-arrow-right {
    position: absolute;
    width: 48px;
    height: 48px;
    top: calc(50% - 24px);
    right: 5px;
    background: url("https://w.ladicdn.com/source/arrows.svg") no-repeat;
    cursor: pointer;
    z-index: 90000050;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
}

.ladi-gallery.ladi-gallery-bottom .ladi-gallery-control .ladi-gallery-control-arrow-left {
    position: absolute;
    width: 48px;
    height: 48px;
    top: calc(50% - 24px);
    left: 5px;
    background: url("https://w.ladicdn.com/source/arrows.svg") no-repeat;
    background-position-x: -48px;
    cursor: pointer;
    z-index: 90000050;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
}

.ladi-gallery.ladi-gallery-bottom .ladi-gallery-control .ladi-gallery-control-arrow-right {
    position: absolute;
    width: 48px;
    height: 48px;
    top: calc(50% - 24px);
    right: 5px;
    background: url("https://w.ladicdn.com/source/arrows.svg") no-repeat;
    cursor: pointer;
    z-index: 90000050;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
}

.ladi-gallery.ladi-gallery-left .ladi-gallery-control .ladi-gallery-control-arrow-left {
    position: absolute;
    width: 48px;
    height: 48px;
    top: 5px;
    left: calc(50% - 24px);
    background: url("https://w.ladicdn.com/source/arrows.svg") no-repeat;
    background-position-x: -48px;
    -webkit-transform: rotate(90deg) scale(0.6);
            transform: rotate(90deg) scale(0.6);
    cursor: pointer;
    z-index: 90000050;
}

.ladi-gallery.ladi-gallery-left .ladi-gallery-control .ladi-gallery-control-arrow-right {
    position: absolute;
    width: 48px;
    height: 48px;
    bottom: 5px;
    left: calc(50% - 24px);
    background: url("https://w.ladicdn.com/source/arrows.svg") no-repeat;
    -webkit-transform: rotate(90deg) scale(0.6);
            transform: rotate(90deg) scale(0.6);
    cursor: pointer;
    z-index: 90000050;
}

.ladi-gallery.ladi-gallery-right .ladi-gallery-control .ladi-gallery-control-arrow-left {
    position: absolute;
    width: 48px;
    height: 48px;
    top: 5px;
    left: calc(50% - 24px);
    background: url("https://w.ladicdn.com/source/arrows.svg") no-repeat;
    background-position-x: -48px;
    -webkit-transform: rotate(90deg) scale(0.6);
            transform: rotate(90deg) scale(0.6);
    cursor: pointer;
    z-index: 90000050;
}

.ladi-gallery.ladi-gallery-right .ladi-gallery-control .ladi-gallery-control-arrow-right {
    position: absolute;
    width: 48px;
    height: 48px;
    bottom: 5px;
    left: calc(50% - 24px);
    background: url("https://w.ladicdn.com/source/arrows.svg") no-repeat;
    -webkit-transform: rotate(90deg) scale(0.6);
            transform: rotate(90deg) scale(0.6);
    cursor: pointer;
    z-index: 90000050;
}

.ladi-gallery .ladi-gallery-control .ladi-gallery-control-box {
    position: relative;
}

.ladi-gallery.ladi-gallery-top .ladi-gallery-control .ladi-gallery-control-box {
    display: inline-flex;
    left: 0;
    transition: left 150ms ease-in-out;
}

.ladi-gallery.ladi-gallery-bottom .ladi-gallery-control .ladi-gallery-control-box {
    display: inline-flex;
    left: 0;
    transition: left 150ms ease-in-out;
}

.ladi-gallery.ladi-gallery-left .ladi-gallery-control .ladi-gallery-control-box {
    display: inline-grid;
    top: 0;
    transition: top 150ms ease-in-out;
}

.ladi-gallery.ladi-gallery-right .ladi-gallery-control .ladi-gallery-control-box {
    display: inline-grid;
    top: 0;
    transition: top 150ms ease-in-out;
}

.ladi-gallery .ladi-gallery-control .ladi-gallery-control-box .ladi-gallery-control-item {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    float: left;
    position: relative;
    cursor: pointer;
    opacity: 0.75;
}

.ladi-gallery .ladi-gallery-control .ladi-gallery-control-box .ladi-gallery-control-item:hover {
    opacity: 1;
}

.ladi-gallery .ladi-gallery-control .ladi-gallery-control-box .ladi-gallery-control-item.selected {
    opacity: 1;
}

.ladi-gallery .ladi-gallery-control .ladi-gallery-control-box .ladi-gallery-control-item:last-child {
    margin-right: 0 !important;
    margin-bottom: 0 !important;
    ;
}
.ladi-gallery>.ladi-gallery-control {
    height: 80px;
}
.ladi-gallery>.ladi-gallery-control>.ladi-gallery-control-box>.ladi-gallery-control-item {
    width: 80px;
    height: 80px;
    margin-right: 5px;
}
.ladi-gallery>.ladi-gallery-view {
    height: calc(100% - 85px);
}

a.product-image-tools {
    display: block;
    position: absolute;
    top: 6px;
    left: 5px;
    opacity: 0.9;
    color: #fff;
    font-size: 11px;
    text-align: center;
    background: #1853fa;
    border-radius: 50%;
    width: 20px;
    height: 20px;
}

a.product-image-tools i {
    font-size: 10px;
    line-height: 20px;
}
.react-datepicker {
    /* box-shadow: 0 8px 16px 0 rgba(0, 0, 0, .2); */
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    font-family: inherit;
    border: 0px;
}

.react-datepicker__time-container {
    border-left: 1px solid #eaedf0;
}

.react-datepicker__header {
    background-color: #fff;
    padding-top: 12px;
    border-bottom: 1px solid #ebedf2;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-range:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--selected:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--selected:hover {
    background-color: var(--main-primary);
    color: #fff !important;
    border-radius: 4px;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle:before {
    border-bottom-color: #fff;
}

.react-datepicker__day,
.react-datepicker__day-name,
.react-datepicker__time-name {
    color: #000;
    width: 30px;
    margin: 3px;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled {
    cursor: default;
    color: #ccc !important;
}

.react-datepicker__navigation {
    top: 13px;
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__input-container {
    width: 100%;
}

.ladi-date-picker-icon {
    float: right;
    margin-right: 12px;
    margin-top: -25px;
    position: relative;
    z-index: 2;
    cursor: pointer;
}

.react-datepicker__day {
    width: 32px;
    height: 32px;
    line-height: 32px;
}

.react-datepicker__current-month {
    font-weight: 500;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    line-height: 30px;
    padding: inherit;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: var(--main-primary);
    color: #fff !important;
    font-weight: initial;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    font-weight: 500;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
    border-top-color: inherit;
}

.react-datepicker-popper {
    z-index: 6;
}

.custom-btn-date-panel {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
}
/** @format */

.DateRangePickerInput {
    background-color: #fff !important;
    background-clip: padding-box !important;
    border: 1px solid var(--secondary-color) !important;
    border-radius: 2px !important;
    height: 36px;
}

.DateInput_input__focused {
    border-color: #fff !important;
}

.DateInput_fang {
    top: 38px !important;
}

.CalendarMonth_table {
    margin-top: 10px;
}

.CalendarMonth_caption::first-letter {
    text-transform: capitalize !important;
}

.CalendarMonth_caption {
    font-size: 16px !important;
    font-weight: 500;
}

.CalendarDay__selected_span {
    background: #5578eac7 !important;
    border: 1px double #ffffff !important;
    color: #fff !important;
}

.CalendarDay__selected {
    background: var(--main-primary) !important;
    border: 1px double #ffffff !important;
    color: #fff !important;
}

.CalendarDay__selected:hover {
    background: #5578eac7 !important;
    border: 1px double #ffffff !important;
    color: #fff !important;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
    background: #5578eac7 !important;
    border: 1px double #ffffff !important;
    color: #fff !important;
}

.CalendarDay {
    vertical-align: middle !important;
}

.DateInput_input::-webkit-input-placeholder {
    font-size: 13px !important;
}

.DateInput_input::-webkit-input-placeholder,
.DateInput_input::placeholder {
    font-size: 13px !important;
}
.DateRangePickerInput_calendarIcon {
    padding: 7px !important;
}
.DateRangePickerInput_calendarIcon i {
    vertical-align: middle !important;
}

.DateInput_input {
    font-weight: inherit !important;
}

.DateRangePicker .custom-btn-date-panel {
    padding: 0px 22px 11px 22px;
}

.DateRangePicker .custom-btn-date {
    position: relative;
    height: 100%;
    text-align: center;
    background: none;
    border: 1px solid var(--main-primary);
    border-radius: 2px;
    color: var(--main-primary);
    padding: 4px 4px;
    margin-right: 4px;
    margin-bottom: 5px;
    font-weight: 500;
    line-height: normal;
    overflow: visible;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 13px;
}

.DateRangePicker .custom-btn-date:active {
    outline: 0;
}

.DateRangePicker .custom-btn-date.selected {
    color: #fff;
    background: var(--main-primary);
}

.DayPickerNavigation_button__horizontalDefault,
.DayPicker_transitionContainer,
.DayPicker__withBorder {
    border-radius: 2px;
}

.DateRangePickerInput__showClearDates {
    padding-right: 0;
}

.DateInput_input__small {
    font-size: 14px;
}

.DateRangePickerInput_clearDates__small {
    padding: 9px 23px;
    top: 47%;
}
.DateRangePickerInput_clearDates_default:focus, .DateRangePickerInput_clearDates_default:hover {
    background: transparent;
}

.report-overview {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.report-overview .item {
  width: 50%;
  padding-left: 0px;
  padding-right: 30px;
  margin-bottom: 30px;
}
.report-overview .item-top-product {
  width: 100%;
  padding-left: 0px;
  padding-right: 20px;
  margin-bottom: 20px;
}
.report-overview .chart-item {
  /* width: 50%; */
  /* border: 1px solid #b3b3b3; */
  background: #ffffff;
}

.report-overview .chart-item .head-card {
  display: flex;
  align-items: center;
  padding: 12px 0;
}
.report-overview .chart-item .head-card h3 {
  font-weight: 500;
  font-size: 15px;
}
.report-overview .chart-item .bottom-cart {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-top: 1px solid #eaedf0;
  padding: 15px 0px;
  font-weight: bold;
}
.mr-l-5 {
  margin-left: 5px;
}

.report-overview .chart-item .head-card.top-product {
  justify-content: space-between;
}

.report-overview .chart-item .head-card .item-card {
  display: flex;
  align-items: center;
}

.content-chart-utm{
  display: flex;
  /* align-items: center; */
  justify-content: center;
}

.chart-utm{
  width: 40%;
}
.table-utm{
  width: 60%;
  height: 400px;
  overflow: auto;
}

