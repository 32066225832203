.tab-config-sdk .ladiui .sdk-page {
    display: block;
    height: 100vh;
    width: 100%;
    float: left;
}
.tab-config-sdk .ladiui .sdk-page .setting-sdk {
    width: 70%;
    height: 100vh;
    /* float: left; */
    padding-right: 24px;
    /* overflow-y: scroll; */
    /* overflow-x: auto; */
    background: #fff;
}
.tab-config-sdk .ladiui .sdk-page .preview-sdk {
    overflow: hidden;
    position: sticky;
    bottom: 0px;
    /* width: 506px; */
    width: 30%;
    float: right;
    background-image: url(data:image/gif;base64,R0lGODlhEAAQAJEAAAAAAP////X19f///yH5BAEAAAMALAAAAAAQABAAAAIflG+hq4jM3IFLJhqswNly/XkcBpIiVaInlLJr9FZWAQA7);
    height: calc(100vh - 70px);
    border-top-right-radius: 5px;
}
.ladisales-widget-frame {
    display: block;
    border: none;
    position: relative;
    width: 100%;
    height: 100%;
    opacity: 1;
    overflow: hidden;
    background: none transparent;
    padding: 10px;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition: none 0s ease 0s;
    transition: none 0s ease 0s;
    visibility: visible;
}
.preview-sdk .screen {
    height: calc(100% - 92px);
    overflow: auto;
}
.preview-sdk .screen #ladisales {
    height: 100%;
}
.preview-sdk .select-screen {
    background: #fff;
    display: flex;
    padding: 10px 0 8px;
    align-items: center;
}
.preview-sdk .select-screen .title {
    margin-right: 24px;
    white-space: nowrap;
}
.sdk-page .setting-sdk .panel {
    width: 100%;
    margin-bottom: 24px;
    display: block;
}
.sdk-page .setting-sdk .panel .panel-line {
    display: flex;
    margin: 8px 0px;
}
.sdk-page .setting-sdk .panel .panel-line .main-description {
    color: var(--main-black);
    width: 20%;
    padding-top: 10px;
    font-weight: 500;
}
.sdk-page .setting-sdk .panel .panel-line .content {
    width: 100%;
    text-align: left;
    padding-left: 10px;
}
.sdk-page .setting-sdk .panel .panel-line .content .color-picker {
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    z-index: 10;
}
.color-item {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    margin: 4px 6px 0;
    text-align: center;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
}
.color-item.btn-add-background {
    border: 1px solid #AFAFAF;
    font-size: 20px;
}
.color-item.checked {
    pointer-events: none!important;
    border: 1px solid var(--main-line);
}
.sdk-page .setting-sdk .panel .panel-line .content .widget-position {
    display: flex;
}
.select-text-color.white {
    background: var(--main-white);
    border: 1px solid var(--main-secondary);
}
.select-text-color.black {
    background: var(--main-black);
}
.sdk-page .setting-sdk .panel .col-50 {
    margin-left: 0px!important;
}
.sdk-page .setting-sdk .panel .panel-line .main-description .sub-description {
    line-height: 16px;
    margin-top: 6px;
    color: var(--main-mute);
    font-size: 12px;
    font-weight: 400;
}
#store-logo {
    position: relative;
}
#store-logo img .store-logo {
    background-color: var(--main-line);
    border: 1px solid var(--main-line);
    object-fit: cover;
    width: 56px;
    height: 56px;
    border-radius: 50%;
}
#store-logo-overlay {
    align-items: center;
    text-align: center;
    width: 56px;
    height: 56px;
    top: 0;
    z-index: 2;
    position: absolute;
    display: none;
    border-radius: 50%;
    background-color: rgba(0,0,0,.6);
}
.preview-store-logo #store-logo:hover>#store-logo-overlay {
    display: flex;
}
#store-logo-overlay .icon {
    background-color: var(--main-white);
    margin-left: 16px;
}
.ladiui.icon-ldp-pen-edit {
    box-sizing: unset;
    display: inline-block;
    width: 24px;
    height: 24px;
    pointer-events: none;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/icons/ldicon-edit.svg) no-repeat 50% 50%;
}
.ladiui.danger-text {
    color: var(--danger-color);
}
.sdk-page .setting-sdk .panel .panel-line .content .preview-store-logo {
    text-align: left;
    padding: 12px 9px 10px 0;
    height: 80px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
}
.sdk-page .setting-sdk .panel .panel-line .content .preview-store-logo .button.upload label {
    cursor: pointer;
}
.panel-line .content .preview-store-logo .store-logo {
    border: 1px solid var(--main-line);
    object-fit: cover;
    width: 56px;
    height: 56px;
    border-radius: 50%;
}
.ladiui.button.upload {
    margin-left: 11px;
    display: inline-block;
    box-shadow: none;
    margin-top: 18px;
    color: var(--main-primary);
}
.setting-sdk .panel .panel-line .content .cart-bg-image {
    display: flex;
    margin: 10px 0;
    line-height: 20px;
    background: #fff;
    border: 1px solid var(--main-line);
    border-radius: 3px;
}
.cart-bg-image.back-ground {
    display: inline-flex;
    width: 316px;
    height: 126px;
    background: var(--main-secondary);
    justify-content: center;
    position: relative;
    padding: unset!important;
    cursor: pointer;
}

.setting-content-menu-banner .panel .content .block-add-image-background {
    width: calc(25% - 9px);
    text-align: center;
    height: 90px;
    background-color: #eee;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #6d6d6d;
    align-items: center;
    cursor: pointer;
}
.setting-content-menu-banner .panel .content .block-list-banner-images {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}
.setting-content-menu-banner .panel .content .block-list-banner-images .banner-image-item {
    width: calc(25% - 9px);
    height: 90px;
    cursor: pointer;
    position: relative;
}
.setting-content-menu-banner .panel .content .block-list-banner-images .banner-image-item .block-action-item {
    align-items: center;
    position: absolute;
    top: 4px;
    right: 4px;
    display: none;
}
.setting-content-menu-banner .panel .content .block-list-banner-images .banner-image-item .block-action-item .block-icon-link {
    background-color: rgba(32,32,32,.7);
    padding: 3px 5px;
    border-radius: 2px;
    margin-right: 4px;
}
.setting-content-menu-banner .panel .content .block-list-banner-images .banner-image-item .block-action-item .block-icon-delete {
    background-color: rgba(32,32,32,.7);
    padding: 3px 5px;
    border-radius: 2px;
}
.setting-content-menu-banner .panel .content .block-list-banner-images .banner-image-item .block-input-add-link {
    position: absolute;
    bottom: 0px;
    left: 0px;
    background: #fff;
    width: 100%;
}
.setting-content-menu-banner .panel .content .block-list-banner-images .banner-image-item .block-input-add-link .ldicon-save {
    position: absolute;
    right: 8px;
    cursor: pointer;
    bottom: 6px;
}
.setting-content-menu-banner .panel .content .block-list-banner-images .banner-image-item .block-input-add-link .ladiui.form-control {
    height: 24px !important;
    width: 100% !important;
    margin: 0 !important;
    padding-right: 30px;
    font-size: 12px;
}
.setting-content-menu-banner .panel .content .block-list-banner-images .banner-image-item:hover .block-action-item {
    display: flex;
}
.setting-content-menu-banner .panel .content .block-list-banner-images .banner-image-item:hover .ldicon-link {
    background-color: #fff;
}
.setting-content-menu-banner .panel .content .block-list-banner-images .banner-image-item:hover .ldicon-delete {
    background-color: #fff;
}
.setting-content-menu-banner .panel .content .block-list-banner-images .banner-image-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.content .cart-bg-image #upload-background-overlay {
    position: absolute;
    top: 50px;
    left: 95px;
    display: none;
}
.content .cart-bg-image.back-ground:hover>#upload-background-overlay {
    display: flex;
}
.content .cart-bg-image.back-ground a {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 30px;
    border-radius: 50%;
    margin-left: 20px;
    background-color: var(--main-primary);
}
.content .cart-bg-image.back-ground .white-background .icon {
    background-color: var(--danger-color);
}
.content .cart-bg-image.back-ground .white-background {
    background-color: var(--main-white);
}
.content .cart-bg-image.back-ground img {
    max-width: 380px;
    width: 380px;
    object-fit: cover;
}
#upload-background-overlay .ladiui.ldicon-upload {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-upload.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-upload.svg);
    background-color: var(--main-white);
    width: 16px;
    height: 16px;
    display: inline-block;
}
.ladiui.icon-checked {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-check.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-check.svg);
    background-color: var(--main-white);
    width: 16px;
    height: 16px;
    display: inline-block;
}
.select-text-color.white .ladiui.icon-checked {
    background-color: var(--main-secondary) !important;
}