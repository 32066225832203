/** @format */

body {
    line-height: 1.2em;
    font-size: 14px;
}

a {
    color: var(--main-primary);
}

.variant-active {
    color: #fff;
    background-color: #1853fa;
    border-color: #1853fa;
}

.variant-option-item {
    /* padding-top: 12px; */
    clear: both;
    width: 100%;
}

.ladiui-form-group.variant-option-list .list-item {
    margin-bottom: 12px;
}

.variant-option-item .ladiui-tags {
    border: 1px solid #ebedf2;
    border-radius: 4px;
}

.variant-option-item .ladiui-tags>input {
    border: none;
}

.variant-text {
    color: #1853fa;
    padding-left: 20px;
}

#gallery-create,
#gallery-edit {
    width: 400px;
    height: 430px;
}

.ladi-gallery-container:hover .ladi-gallery-control-item {
    opacity: 0.3;
}

.ladi-gallery-container:hover .close-image {
    opacity: 1;
}

.close-image {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.search-panel li {
    display: inline-block;
    padding: 0px 20px 0px 20px;
    width: 30%;
}

.discount-option {
    position: relative;
    display: initial;
}

.shipping-option {
    position: relative;
    display: initial;
}

.discount-box {
    width: 300px;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    z-index: 7;
    background: #fff;
    padding: 10px;
    border-radius: 4px;
}

.discount-option .discount-reason {
    margin: 15px 0px;
}

.discount-option .discount-reason>label {
    display: inherit;
    text-align: left;
}

.discount-button>button {
    margin-left: 15px;
}

.discount-button-active {
    color: #6c7293 !important;
    border-color: #ebedf2 !important;
    background-color: #f4f5f8 !important;
}

.shipping-box {
    width: 450px;
    left: 440px;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    z-index: 7;
    background: #fff;
    padding: 20px;
    border-radius: 4px;
}

.shipping-item {
    width: 48.5%;
    margin-right: 10px;
    display: inline-block;
}

.shipping-group {
    margin-bottom: 15px;
}

.shipping-group .shipping-item:last-child {
    margin-right: 0px;
}

.shipping-button>button {
    margin-left: 15px;
}

/* .order-address-dropdown {
    cursor: pointer;
    padding: 5px !important;
} */

/* .order-address-dropdown:hover {
    background-color: #f8fafb;
} */

@media (min-width: 1366px) {
    .order-page {
        width: 85%;
        margin: 0px auto;
    }

    .setting-page {
        width: 85%;
        margin: 0px auto;
    }

    .shipping-page {
        width: 85%;
        margin: 0px auto;
    }
}

.customer-info>span {
    display: flex;
    align-items: center;
    line-height: inherit;
    margin-bottom: 16px;
    font-size: 14px;
}

.customer-info>span img {
    margin-right: 8px;
}

.order-custom-field .more-info span i {
    margin-right: 8px !important;
}

.discount-date {
    width: 40%;
}

.ladiui.form-group .discount-item {
    margin-bottom: 16px;
    display: flex;
}

.ladiui.form-group .discount-item:last-child {
    margin-bottom: 0px;
}

.ladi-date-picker-icon {
    z-index: 0 !important;
}

.order-head-right {
    position: absolute;
    top: 28px;
    right: 0px;
}

.order-page {
    position: relative;
}

.ladiui.table .order-table-item {
    color: var(--main-primary);
    font-weight: 400;
}

.ladiui.table .ladiui.checkall {
    width: 40px;
}

.builder-loading {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    position: fixed;
    overflow: hidden;
    background-color: #fafafa;
    z-index: 900000080;
    background-size: 128px;
}

.builder-loading-blur {
    background-color: rgba(12, 97, 242, 0.05);
}

.setting-page {
    position: relative;
}

.setting-content {
    background-color: #fff;
    padding: 15px;
}

.setting-content>ul {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
}

.setting-content>ul::after {
    content: '';
    width: 400px;
}

.setting-item {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    line-height: 1.4;
    font-size: 15px;
}

/* .setting-item:hover {
    background-color: #f9fafb;
    text-decoration: none;
    outline: none;
    cursor: pointer;
    border-radius: 3px;
} */

.setting-item .title {
    font-weight: 500;
    margin: 0;
    color: #1853fa;
}

.setting-item .description {
    color: #637381;
}

.setting-item .icon {
    width: 40px;
    height: 40px;
    margin-right: 15px;
    text-align: center;
    background-color: #dfe3e8;
    border-radius: 3px;
}

.setting-item .icon .ladi-icon {
    margin-top: 12px;
}

.setting-item .text {
    width: 315px;
}

.account-content {
    margin-top: 5px;
    font-size: 14px;
}

/* .account-table .ladiui.table>tbody>tr>td:first-child {
    padding: 10px !important;
} */

.account-table .avatar {
    border-radius: 50%;
}

.account-body .ladiui-table-actions {
    min-height: 62px;
    border-radius: 5px;
    padding: 10px;
}

.account-body .ladiui-table-actions .ladiui.form-group {
    display: flex;
    align-items: center;
}

.account-body .ladiui-table-actions .ladiui.form-group .ladiui-label {
    margin-right: 15px;
}

.account-body .ladiui-table-actions .ladiui.form-group .data-permission {
    width: 200px;
}

.scope-group {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

.scope-group .scope-parent {
    margin-right: 50px;
    margin-bottom: 5px;
}

.scope-group .scope-parent>label {
    font-weight: 400;
}

.scope-group .scope-children {
    margin-left: 20px;
}

.scope-group .scope-children>label {
    margin-right: 15px;
}

.upgrade-heading {
    font-weight: 500;
    margin: 20px 0px;
}

.upgrade-content {
    width: 650px;
    margin: 0px auto;
    background-color: #fff;
    padding: 15px;
    border-radius: 3px;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
}

.upgrade-package>span {
    display: inline-block;
    margin-right: 30px;
}

.upgrade-package label {
    font-weight: 500;
    font-size: 14px;
}

.upgrade-package-time li {
    display: inline-block;
    background: #fafafa;
    padding: 10px 15px;
}

.upgrade-package-time li.active {
    background-color: #1853fa;
    color: #fff;
}

.upgrade-checkout {
    margin-top: 50px;
}

.upgrade-total {
    display: block;
    float: right;
    margin-top: -22px;
    font-weight: 500;
}

.product-variants small {
    font-size: 15px;
    /* font-style: italic; */
}

.ladiui-product-right .ladiui.textarea {
    min-height: 100px;
}

.condition-group>label {
    position: relative;
    top: -10px;
    background: #fff;
    display: inline-block;
    padding-right: 10px;
}

.condition-group {
    border-top: 1px solid #ebedf2;
    clear: both;
}

#modal-discount .ladiui.form-group {
    display: inline-table;
}

.ls-dashboard-chart {
    margin: 20px 0px;
}

.ls-dashboard-chart>div {
    height: 250px !important;
}

.ls-dashboard-chart .highcharts-container {
    height: 250px !important;
}

.search-variant .variant {
    display: inline-block;
}

.search-variant .image {
    width: 20%;
}

.search-variant .name {
    width: 60%;
}

.shipping-not-delivery .title .icon-delivery {
    font-size: 20px;
    margin-right: 10px;
}

.shipping-not-delivery .title h3 {
    text-transform: uppercase;
    display: inline-block;
}

.shipping-not-delivery .title button {
    float: right;
}

#modal-shipping-form .ladiui-row {
    display: flex;
}

#modal-shipping-form .left {
    flex: 0.6;
    margin-right: 50px;
}

#modal-shipping-form .right {
    flex: 0.4;
    position: relative;
}

#modal-shipping-form .shipping-footer {
    display: flex;
    justify-content: space-between;
}

#modal-shipping-form .shipping-footer .button {
    margin-left: 10px;
}

#modal-shipping-form .ladiui-row .ladiui.form-group {
    margin-right: 15px;
}

#modal-shipping-form .ladiui-row .ladiui.form-group:last-child {
    margin-right: 0px;
}

#modal-shipping-form .ladiui-row .coupon {
    flex: 0.4;
}

#modal-shipping-form .ladiui-row .size {
    flex: 1;
}

#modal-shipping-form .order-info {
    overflow: hidden;
    background: #fff;
    padding: 24px 0px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.order-info .heading h3 {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 24px;
}

#modal-setting-shipping #tab-service .item {
    margin-bottom: 15px;
    border-radius: 10px;
}

#modal-setting-shipping #tab-service .item:last-child {
    margin-bottom: 0px;
}

#modal-setting-shipping #tab-service .item .shipping-partner-logo {
    height: 25px;
}

#modal-setting-shipping #tab-service .item .description {
    margin-top: 15px;
    padding-bottom: 15px;
    line-height: 22px;
    font-size: 15px;
}

#modal-setting-shipping #tab-service .item .partner-account {
    margin-top: 15px;
}

#modal-setting-shipping #tab-service .item .partner-footer {
    display: flex;
    padding-bottom: 15px;
    border-bottom: 1px solid #ebedf2;
    justify-content: flex-end;
}

#modal-setting-shipping #tab-service .item .partner-footer .right button {
    margin-left: 10px;
}

#modal-setting-shipping #tab-service .item .partner-footer .left button {
    margin-left: 10px;
}

#modal-shipping-form #tab-service .select-hub {
    display: inline-block;
    width: 210px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.shipping-delivered .title {
    display: flex;
    justify-content: flex-start;
}

.shipping-delivered .title i {
    margin-right: 15px;
    color: #16bf0e;
    font-size: 20px;
}

.shipping-detail {
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    padding: 15px;
    border-radius: 4px;
    overflow: inherit;
    min-width: 325px;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    background-color: #fff;
}

.shipping-detail .title {
    display: flex;
}

.shipping-detail .title h3 {
    font-weight: 500;
    margin-right: 30px;
}

.shipping-detail .badge-status {
    background-color: #feaf9a;
    border-radius: 20px;
    padding: 6px 10px 0px 10px;
}

.shipping-detail .badge-status label {
    font-size: 13px;
}

.shipping-detail .shipping-action {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #ebedf2;
    margin-top: 15px;
}

.shipping-detail .shipping-action button {
    margin-top: 15px;
}

.ladiui.button.disabled {
    color: #6c7293;
    border-color: #ebedf2;
    background-color: #f4f5f8;
}

.ladiui-shipping-left {
    width: 70%;
    float: left;
    position: relative;
}

.ladiui-shipping-right {
    width: 28%;
    float: right;
}

.shipping-page {
    position: relative;
}

.shipping-head-right {
    position: absolute;
    top: 28px;
    right: 0px;
}

.shipping-head-right .placed-on {
    margin-right: 30px;
}

.shipping-head-right .placed-on span:first-child {
    font-weight: 500;
}

.ladiui-shipping-right .order-customer {
    position: relative;
}

.shipping-info {
    border-bottom: 1px solid #f1f3f4;
    padding-bottom: 20px;

    /* overflow: hidden;
    background: #fff;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 20px;
    box-shadow: 0 0px 11px 0 rgba(0, 0, 0, .1); */
}

.shipping-info .shipping-info-heading h3 {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
}

.shipping-info .item {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    align-items: center;
}

.shipping-info .item.status {
    border-top: 1px solid #ebedf2;
    padding-top: 15px;
}

.shipping-info .item.status .ladiui.button {
    padding: 2px 15px;
}

.shipping-stack-total.hide-border {
    border: none !important;
}

.ladiui-shipping-left {
    background: #fff;
}

.ladiui-shipping-left .shipping-item-box {
    background: #fff;
    display: flex;
    justify-content: space-between;
    padding: 20px 15px 20px 15px;
    border-bottom: 1px solid #efefef;
    border-top: 1px solid #efefef;
}

.ladiui-shipping-left .shipping-item-box h3 {
    font-weight: 500;
    font-size: 17px;
}

.ladiui-shipping-left .shipping-item-box h3 i {
    margin-right: 20px;
}

.ladiui-shipping-left .actions {
    padding: 20px 15px 0px 15px;
    justify-content: space-between;
}

#modal-image-variant .image-variant .item {
    padding: 3px;
    border: 1px solid rgba(195, 207, 216, 0.3);
    cursor: pointer;
    display: inline-block;
    margin: 5px;
}

.selected-image-variant {
    border: 1px solid #0b61f2 !important;
    border-radius: 3px;
}

#modal-image-variant .image-variant .item img {
    display: block;
    margin: auto;
}

.topbar-profile .ladiui.avatar {
    background-color: #1853fa;
    color: white;
    width: 35px;
    height: 35px;
    display: inline-flex;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    margin-top: -7px;
    margin-right: 0px;
    background-size: contain !important;
}

#modal-new-product .info {
    display: flex;
}

#modal-new-product .info>div {
    margin-right: 15px;
}

#modal-new-product .info>div:first-child {
    flex: 0.5;
}

#modal-new-product .info>div:nth-child(2) {
    flex: 0.3;
}

#modal-new-product .info>div:nth-child(3) {
    flex: 0.2;
}

#modal-new-product .info>div:last-child {
    margin-right: 0px;
}

.custom-input-autocomplete {
    cursor: pointer;
}

.async-auto-complete.menu {
    margin-left: -13px;
    margin-top: 10px;
    max-height: 175px !important;
    min-height: 100px !important;
    /* min-width: 552px!important; */
    width: 100%;
    z-index: 999999;
}

.async-auto-complete.menu .item {
    overflow: hidden;
    padding: 8px 16px;
    text-overflow: ellipsis;
    white-space: nowrap;

    position: relative;
    display: table;
    width: 100%;
    cursor: pointer;
}

.async-auto-complete.menu .item .image {
    text-align: left;
    width: 55px;
    display: table-cell;
}

.async-auto-complete.menu .item .name {
    display: table-cell;
    vertical-align: middle;
    text-align: left;
}

.async-auto-complete.menu .item .price {
    text-align: right;
    display: table-cell;
    vertical-align: middle;
}

#autocomplete-customer .item .name span {
    font-weight: 500;
}

.condition-group {
    border-top: 1px solid #ebedf2;
    clear: both;
    margin: 10px 0px;
}

.ladiui-tabs li.active a {
    padding-bottom: 8px;
}

.ladiui-modal-content .ladiui-tabs {
    margin: 0px -15px;
}

.ladiui-tab-item .ladiui.table {
    box-shadow: none;
}

.ladiui-tab-content .ladiui.input,
.ladiui-tab-content .ladiui.select {
    box-shadow: none;
    -webkit-box-shadow: none;
}

.ladiui-label,
.variant-option-head>span {
    /* font-size: 15px; */
    font-size: 14px;
    font-weight: 500;
}

.discount-from-date,
.discount-to-date {
    width: 200px;
}

.discount-from-date {
    margin-right: 20px;
}

.discount-check-endate {
    width: 196px;
    vertical-align: middle;
    display: block;
    overflow: hidden;
    line-height: 93px;
}

.discount-from-date,
.discount-check-endate {
    float: left;
}

.discount-to-date {
    float: left;
    margin-right: 20px;
}

.discount-to-date label,
.discount-from-date label {
    margin: 5px 0px;
    display: block;
}

.ladiui-input-group-prepend button {
    height: 40px;
}

.discount-button-active {
    background: var(--main-primary) !important;
    color: #fff !important;
    border-top: 0px !important;
    border-bottom: 0px !important;
}

.ladiui-tag-list {
    display: flex;
    /* flex-wrap: wrap; */
}

.ladiui-tag-list .ladiui.dropdown-toggle {
    min-width: unset !important;
    border: none !important;
}

.ladiui-tag-list .ladiui.dropdown-toggle:after {
    /* display: none !important; */
    /* background: #ffffff !important; */
    background: unset !important;
}

.ladiui-tag-list .dropdown-text {
    color: var(--main-primary) !important;
}

.ladiui-tags-label {
    display: flex;
    justify-content: space-between;
}

.ladiui-tags-label .all-tag {
    color: rgb(26, 115, 232);
    cursor: pointer;
    font-weight: 400;
}

.modal-all-tag .ladiui.form-group {
    min-height: 70px;
}

.modal-all-tag .ladiui-tags {
    border: 0px;
}

.modal-all-tag .tag {
    cursor: pointer;
}

.disabled-tag {
    background: #1853fa59 !important;
}

.ladiui-tags {
    max-width: 100%;
    border: 1px solid #f1f3f4;
    border-radius: 2px;
    min-height: 35px !important;
    display: flex !important;
    flex-wrap: wrap !important;
    padding: 5px 4px;
}

.ladiui-tags .tag {
    position: relative;
    display: flex;
    align-items: center;
    float: left;
    color: var(--main-default);
    background: #f1f3f4;
    border-radius: 2px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding: 2px 8px;
    margin-right: 8px;

    /* padding: 8px 18px 8px 12px; */
    /* margin: 4px; */
    transition: all 0.3s ease-out;
}

.ladiui-tags .tag .close {
    /* position: absolute;
    top: 0px;
    right: 0;
    width: 14px;
    height: 100%; */
    /* background: var(--main-primary); */
    line-height: 20px;
    cursor: pointer;
    border-radius: 0 2px 2px 0;
    transition: background 0.3s;
}

.table-address .default {
    background-color: #f8fafb;
}

.ladiui-order-left .ladi-card.order-products {
    /* box-shadow: 0 0px 5px 0 rgba(0, 0, 0, .1); */
    border-radius: 4px;
    background: #fff;
}

#autocomplete-variant .async-auto-complete.menu {
    top: 50px !important;
    max-height: 300px !important;
}

#autocomplete-variant .async-auto-complete.menu .item .image img {
    vertical-align: middle;
    border-radius: 3px;
}

.ladiui-order-left .ladiui.input,
.ladiui-order-left .ladiui.select {
    box-shadow: none;
    -webkit-box-shadow: none;
}

/* #autocomplete-variant .ladiui.input {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    height: 50px;
    border-bottom: 0px;
} */

.ladiui-order-left .ladiui-search-custom::after {
    line-height: 50px;
}

.ladiui-order-left .ladi-card.order-products .ladiui.table {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    box-shadow: none;
    -webkit-box-shadow: none;
    margin-bottom: 30px;
}

.order-stack-total:first-child td {
    padding-top: 30px !important;
}

.discount-box .ladiui-input-group .ladiui-input-group-prepend button {
    border-radius: 4px;
    border-left: 0px;
}

button.discount-percent {
    border-radius: 0px !important;
}

button.discount-fixed {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.discount-box .ladiui-input-group .ladiui-input-group-prepend {
    margin: 0px !important;
}

.discount-box .ladiui-input-group .ladiui.input {
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
}

/* .ladiui.table tbody tr td {
    padding: 10px 15px;
    line-height: 24px;
} */

.price-option {
    position: relative;
}

.price-box {
    width: 300px;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    z-index: 7;
    background: #fff;
    padding: 20px;
}

.price-option .discount-reason>label {
    display: inherit;
    text-align: left;
}

.price-option .discount-reason {
    margin: 15px 0px;
}

.discount-option .discount-code {
    margin: 15px 0px;
}

.shipping-group>span {
    display: inline-block;
    margin-right: 30px;
}

.discount-fixed {
    border-left: 0px;
}

.order-customer .async-auto-complete .item .info span {
    font-weight: 500;
    margin-bottom: 5px;
    display: block;
}

.discount-box::before {
    content: '';
    width: 0px;
    height: 0px;
    border-left: 10px solid #ba222200;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fafafa;
    top: -10px;
    position: absolute;
}

.price-option {
    display: initial;
}

.ladiui.input,
.ladiui.select {
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    -webkit-box-shadow: 0 2px 6px 0 rgba(169, 194, 209, 0.1);
}

.ladiui-modal-content .ladiui-select-options {
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    -webkit-box-shadow: 0 1px 2px 0 rgba(169, 194, 209, 0.1);
}

.ladiui-modal-content .order-customer,
.ladiui-modal-content .order-info {
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
}

.order-customer #autocomplete-customer .async-auto-complete {
    top: 45px !important;
    min-width: 325px !important;
    left: -15px !important;
}

.ladiui-search-custom.has-default .async-auto-complete>div:first-child {
    padding: 8px 10px;
}

#autocomplete-product-tag .item .name {
    line-height: 40px;
}

.async-auto-complete .item .tag-item:hover {
    background: #fafafa;
}

.shipping-rate-name {
    font-weight: 500;
    font-size: 14px;
}

.shipping-rate-table.ladiui.table tbody tr {
    border-bottom: 0px;
}

.shipping-rate-table.ladiui.table tbody tr.last-row {
    border-bottom: 1px solid #efefef;
}

.shipping-rate-table.ladiui.table tbody tr.last-row:last-child {
    border-bottom: 0px;
}

#modal-shipping-rate-detail .item-district {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#modal-shipping-rate-detail .item-district .ladiui.form-group {
    margin-right: 25px;
}

#modal-shipping-rate-detail .item-district .ladiui.form-group:first-child {
    flex: 0.3;
}

#modal-shipping-rate-detail .item-district .ladiui.form-group:nth-child(2) {
    flex: 0.45;
}

#modal-shipping-rate-detail .item-district .ladiui.form-group:nth-child(3) {
    flex: 0.25;
}

.account-table .ladiui.table {
    box-shadow: none;
}

#modal-customer .condition-group>label {
    font-size: 16px;
}

.ladiui-label {
    color: var(--main-default);
    /* color: var(--main-secondary); */
    padding-bottom: 8px;
    display: block;
}

.ladiui-label>i {
    margin-left: 5px;
    font-size: 10px;
    font-weight: 600;
    color: #71a3e6;
}

strong {
    font-weight: 500;
}

#modal-shipping-form .suggestion {
    line-height: normal;
    line-height: initial;
    font-style: italic;
    background-color: var(--secondary-color);
    padding: 10px 5px 10px 8px;
    border-radius: 4px;
    font-size: 15px;
}

#modal-shipping-form .suggestion::before {
    content: '\ea55';
    font-family: 'LadiIcons';
    margin-right: 10px;
    color: cornflowerblue;
    font-style: initial;
}

.welcome .init-store {
    margin-top: 100px;
    font-size: 40px;
    margin-bottom: 30px;
}

.welcome {
    width: 760px;
    margin: 0 auto;
    line-height: 20px;
    height: 100vh;
}

.welcome .btn-create-store {
    clear: both;
    float: right;
}

#modal-discount .overview-item {
    display: block;
    line-height: 20px;
    font-size: 14px;
    margin-bottom: 8px;
    /* font-style: italic; */
}

#modal-discount .overview-content {
    padding-left: 12px;
}

.action-item .date {
    width: 135px;
}

.order-customer .avatar {
    top: 45px;
}

.order-table .order-assign {
    padding: 5px;
    background-color: #feaf9a82;
    border-radius: 7px;
    cursor: pointer;
}

.customer-order .order-overview {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(6, 21, 40, 0.05);
    padding-bottom: 16px;
}

.customer-order .order-overview .item p {
    color: var(--main-secondary);
    font-size: 14px;
}

.customer-order .order-overview .item h3 {
    font-weight: 500;
    text-align: center;
}

.customer-order .heading {
    margin-bottom: 15px;
}

.customer-order .heading label {
    display: inline-block;
}

.customer-order .heading a {
    float: right;
}

.payment-shipping {
    /* height: 60px; */
    /* border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef; */
    /* display: table; */
    width: 100%;
    margin-bottom: 24px;
}

.payment-shipping .title-payment {
    /* width: calc(100% - 150px);
    display: table-cell;
    vertical-align: middle;
    padding-left: 15px; */
}

.payment-shipping .title-payment i {
    /* margin-right: 15px; */
    color: #16bf0e;
    font-size: 10px;
    border: 1px solid;
    border-radius: 50%;
    padding: 3px;
}

.payment-shipping button {
    /* float: right;
    margin-top: 10px;
    margin-right: 15px; */
}

.ladiui-order-left {
    clear: both;
}

.tag-color .ladiui-tags {
    border: none;
    float: left;
}

.tag-color .add-tag {
    cursor: pointer;
    display: inline-block;
    margin: 4px;
    border-radius: 2px;
    padding: 6px 8px;
}

.tag-color .add-tag i {
    color: #42526e;
    position: relative;
    top: 2px;
}

.tag-color .selected-tag {
    margin-top: 30px;
    padding: 5px;
    display: inline-block;
    border-radius: 3px;
}

.tag-color .ladiui-tags .tag .close:after {
    /* position: absolute; */
    content: '\00d7';
    /* top: 0px; */
    /* left: 3px; */
    font-weight: 900;
}

.tag-color .ladiui-tags .tag-name {
    padding: 0px 10px 0px 8px;
    position: relative;
    text-align: left;
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
}

.tag-color .ladiui-tags .tag {
    position: relative;
    margin: 0px 4px;
    line-height: 20px;
    height: 24px;
    border-radius: 2px;
    font-size: 14px;
    transition: all 0.3s ease-out;
    padding: 1px 8px;
    white-space: nowrap;
}

.order-table .order-cancel td:nth-child(2) a {
    color: #6c72938f !important;
    text-decoration: line-through;
}

.shipping-table .shipping-cancel td:nth-child(2) a {
    color: #6c72938f !important;
    text-decoration: line-through;
}

.ladiui-order-right .btn-group-order {
    position: absolute;
    display: inline;
    top: -55px;
    right: 0;
}

.ladiui-order-right .btn-group-order button {
    margin-left: 10px;
}

#modal-setting-shipping .ladiui.table tbody tr td {
    padding: 6px 15px;
    line-height: inherit;
}

/* .ladiui.table thead tr th.sorting::after,
.ladiui.table thead tr th.sorting.down::after,
.ladiui.table thead tr th.sorting.up::after {
    top: 17px;
} */

input[disabled] {
    background-color: rgb(6 21 40 / 29%) !important;
}

.item-district input[type='checkbox'] {
    display: inline-block;
    position: relative;
    top: 2px;
}

.form-tags .tag-item {
    color: var(--main-black);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.form-tags .recent-header {
    border-bottom: 1px solid #eaedf0;
    width: 100%;
    padding-left: 9px;
    padding-top: 8px;
}

.order-stack-total td {
    padding: 5px 15px !important;
}

.order-stack-total td strong {
    font-weight: 500;
    font-size: 15px;
    color: #44566c;
}

.customer-info {
    padding-bottom: 0px;
}

.customer-info>span:last-child {
    margin-bottom: 0px;
}

.async-auto-complete.menu .icon-c-add {
    position: relative;
    top: 2px;
    display: inline-block;
}

.ladiui.table {
    font-size: 15px;
}

.ladiui-order-left {
    width: 100%;
    float: left;
    /* position: relative; */
    padding-right: 354px;
}

.tag-color {
    width: 100%;
    float: left;
    /* margin: 4px 0px; */
    display: flex;
}

.tag-color .ladiui-tags .ladiui-tag-list .dropdown-tag button {
    padding: 0px !important;
    height: 24px !important;
}

.order-actions {
    width: 25%;
    float: right;
    text-align: right;
}

.order-actions>button {
    display: inline-block;
    margin-left: 15px;
}

.tag-color .ladiui-tags .tag .close {
    line-height: 20px;
    right: 8px;
}

.page-title {
    padding: 26px 0px 20px 0px;
    color: var(--main-default);
    font-weight: 500;
    font-family: 'Roboto';
    font-size: 18px;
    margin-right: 27px;
}

.head-name-screen {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: var(--main-secondary);
}

.tag-color .ladiui-tag-list .tag:first-child {
    margin-left: 0px;
}

.order-page .discount-note {
    font-style: italic;
    font-size: 14px;
}

.payment-shipping span {
    /* text-transform: uppercase; */
    font-size: 14px;
}

.order-page .order-assignee {
    background: #dfe4e8;
    border-radius: 28px;
    font-size: 12px;
    padding: 6px 10px;
    margin-right: 15px;
    min-width: 65px;
    display: inline-block;
    text-align: center;
}

.payment-shipping .btn-status {
    float: right;
    margin-top: 10px;
    margin-right: 15px;
}

/* .ladiui.table thead tr th {
    font-size: 14px;
} */

.ladiui-table-actions .action-item {
    margin-right: 24px;
}

.shipping-status {
    padding: 2px 10px !important;
    border: none !important;
    font-size: 12px !important;
    border-radius: 20px !important;
    line-height: 24px;
}

.shipping-status-1 {
    background-color: #ffc58b !important;
    color: #222 !important;
}

.shipping-status-2 {
    background-color: #47c1bf !important;
    color: #fff !important;
}

.shipping-status-3 {
    background-color: #06a95f !important;
    color: #fff !important;
}

.shipping-status-4 {
    background-color: #f7c85a !important;
    color: #222 !important;
}

.shipping-status-5 {
    background-color: #6849c1 !important;
    color: #fff !important;
}

.shipping-status-6 {
    background-color: #c4cdd5 !important;
    color: #222 !important;
}

.order-shipping-package {
    border: 1px solid #f1f3f4;
}

.order-shipping-package strong:first-child {
    display: block;
    background-color: #fff;
    padding: 15px;
    /* border-bottom: 1px solid #efefef; */
    background: #f8fafb;
}

.ladiui-input-group>.ladiui.input:not(:first-child) {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding-left: 35px;
}

.ladiui-money .prefix {
    border: 0px;
    padding: 1px 6px;
}

.ladiui-money {
    margin-left: 0px;
    position: absolute;
    right: 1px;
    z-index: 1;
    top: 3px;
}

.ladiui-modal-body {
    max-height: 75vh;
    overflow: scroll;
}

.ladiui-tab-content {
    max-height: none !important;
    overflow-y: unset;
    overflow-x: unset;
}

.unset-modal {
    overflow: unset !important;
}

.ladiui.table tbody tr.order-create:last-child td {
    padding-bottom: 20px !important;
}

span[disabled] {
    background-color: rgba(6, 21, 40, 0.05) !important;
}

.ladiui.table {
    font-size: 14px;
}

/* .group-prices > div {
    width: 30.5%;
} */

.group-weight .ladiui.input {
    display: inline-block;
    width: 75%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.group-weight .ladiui-select-options {
    display: inline-block;
    min-width: 25%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0px;
    border-color: #ebedf2;
}

.group-weight .ladiui-select-options>ul {
    min-width: 25%;
}

.order-page .select-assignee,
.order-page .select-checkout-config {
    /* position: absolute; */
    /* right: 345px; */
    /* width: 200px; */
    /* margin-top: 9px; */
    margin-right: 15px;
    box-shadow: none;
    webkit-box-shadow: none;
}

.order-page .select-assignee {
    z-index: 6;
}

#modal-confirm-payment-order .ladiui-modal-footer .send-email {
    display: inline-block;
    float: left;
}

#modal-confirm-payment-order .ladiui-modal-footer .button {
    display: inline-block;
}

#modal-confirm-payment-order .ladiui-modal-footer .button>.ladiui.button {
    margin: 0px 5px;
}

#modal-confirm-payment-order .ladiui-modal-body {
    font-size: 14px;
    line-height: 20px;
}

#modal-confirm-payment p {
    line-height: 24px !important;
}

#modal-export-order .item,
#modal-export-data .item {
    margin-bottom: 15px;
}

li.custom-menu-dropdown .dropdown-content {
    left: -216% !important;
    padding: 20px 3px 4px 10px;
    width: 810px;
    margin-top: 12px !important;
    /* box-shadow: 0 2px 6px rgba(0, 0, 0, .05), 0 0 0 1px rgba(0, 0, 0, .07) !important; */
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
}

li.custom-menu-dropdown .dropdown-content>li {
    display: block;
    width: 33%;
    float: left;
}

li.custom-menu-dropdown .dropdown-content>li.search {
    padding: 0px 0px 12px 12px;
    float: right;
}

li.custom-menu-dropdown .dropdown-content>li.search button {
    margin-top: 15px;
    min-width: 120px;
    margin-right: 20px;
    float: right;
}

li.custom-menu-dropdown .dropdown-content>li.title {
    width: 100%;
}

li.custom-menu-dropdown .dropdown-content>li.separator {
    width: 100%;
    border-top: 1px solid #efefef;
}

/* .order-tab {
    border-radius: 4px 4px 0px 0px;
    box-shadow: 0 8px 16px 0 rgba(169, 194, 209, .1);
    font-size: 14px;
    color: #6c7293;
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #efefef;
} */

.order-table {
    border-radius: 0px 0px 4px 4px !important;
}

.ladiui-select-options>ul>li:hover {
    background: #f8fafb;
}

li.custom-menu-dropdown li.title {
    width: 100%;
    margin-bottom: 15px;
}

li.custom-menu-dropdown .ladiui-select-options {
    box-shadow: none;
    -webkit-box-shadow: none;
}

li.custom-menu-dropdown {
    font-size: 14px;
}

li.custom-menu-dropdown label {
    font-size: 14px;
}

.order-tab .ladiui-tabs li a {
    color: #747577;
}

.order-tab .ladiui-tabs li.active a {
    color: #1853fa;
}

.DateRangePickerInput {
    background-color: #fff !important;
    background-clip: padding-box !important;
    border: 1px solid var(--secondary-color) !important;
    border-radius: 4px !important;
}

.DateInput_input__focused {
    border-color: #fff !important;
}

.DateInput_fang {
    top: 38px !important;
}

.CalendarMonth_table {
    margin-top: 10px;
}

.CalendarMonth_caption::first-letter {
    text-transform: capitalize !important;
}

.CalendarMonth_caption {
    font-size: 16px !important;
}

.CalendarDay__selected_span {
    background: #5578eac7 !important;
    border: 1px double #ffffff !important;
    color: #fff !important;
}

.CalendarDay__selected {
    background: #0b61f2 !important;
    border: 1px double #ffffff !important;
    color: #fff !important;
}

.CalendarDay__selected:hover {
    background: #5578eac7 !important;
    border: 1px double #ffffff !important;
    color: #fff !important;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
    background: #5578eac7 !important;
    border: 1px double #ffffff !important;
    color: #fff !important;
}

.CalendarDay {
    vertical-align: middle !important;
}

.DateInput_input::-webkit-input-placeholder,
.DateInput_input::placeholder {
    font-size: 13px !important;
}

.DateRangePickerInput_calendarIcon i {
    vertical-align: middle !important;
}

.DateInput_input {
    font-weight: inherit !important;
}

.ladiui.submenu>li>a.active {
    color: #1853fa;
}

.ladiui-topbar.fixed {
    z-index: 15;
}

.order-tab .ladiui-tabs li {
    padding: 10px 20px 10px 15px;
}

.custom-menu-dropdown .dropdown-content::before {
    content: '';
    display: block;
    position: absolute;
    top: -7px;
    left: 48%;
    width: 12px;
    height: 12px;
    background: #ffffff;
    border-right: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    -moz-transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.timeline {
    /* padding: 0px calc(18% - 100px); */
}

.timeline .container {
    max-width: 350px;
    max-height: 630px;
    overflow: hidden;
    margin: 30px auto 0;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    font-family: 'Open Sans', sans-serif;
}

.timeline .navbar {
    background: #b97cfc;
    color: #fff;
    padding: 1em 0.5em;
}

.timeline .navbar a {
    color: #fff;
    text-decoration: none;
    font-size: 1.3em;
    float: left;
}

.timeline .navbar span {
    font-size: 1.1em;
    font-weight: 300;
    display: block;
    text-align: center;
}

.timeline .profile-pic {
    width: 30px;
    height: 30px;
    display: inline-block;
    float: right;
    position: relative;
}

.timeline .profile-pic img {
    width: 100%;
    border-radius: 50%;
}

.timeline .notification {
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    top: 2px;
    right: 2px;
    background: #f93b69;
}

.timeline .header {
    background: url(https://unsplash.it/1080/720?image=1044);
    background-size: cover;
    color: #fff;
    position: relative;
}

.timeline .color-overlay {
    padding: 3em 2em;
    box-sizing: border-box;
    background: rgba(123, 94, 155, 0.5);
}

.timeline .actionbutton {
    position: absolute;
    background: #f93b69;
    width: 50px;
    height: 50px;
    font-size: 3em;
    font-weight: 300;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: -25px;
    right: 20px;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
}

.timeline .day-number {
    font-size: 4em;
    display: inline-block;
    margin-right: 15px;
}

.timeline .date-right {
    display: inline-block;
}

.timeline .day-name {
    font-size: 1.6em;
}

.timeline .month {
    text-transform: uppercase;
    font-weight: 300;
    font-size: 0.6em;
    letter-spacing: 2px;
    margin-top: 2px;
}

.timeline .content>ul {
    padding: 1em 0 0 0;
    margin: 0;
    list-style: none;
    position: relative;
}

.timeline .content>ul li {
    padding: 16px 0px;
}

.timeline .content>ul::before {
    content: ' ';
    height: 100%;
    width: 1px;
    background-color: #f1f3f4;
    position: absolute;
    top: 0;
    left: 17px;
    z-index: 1;
}

.timeline li>div {
    display: inline-block;
    /* margin: 1em 0; */
    vertical-align: middle;
    margin-right: 5px;
    /* z-index: 2; */
    position: relative;
    /* border: 3px solid #ffffff; */
}

.timeline .bullet {
    width: 1em;
    height: 1em;
    box-sizing: border-box;
    border-radius: 50%;
    background: #fff;
    z-index: 2;
    margin-right: 1em;
    position: relative;
}

.timeline .bullet.pink {
    border: 2px solid #f93b69;
}

.timeline .bullet.green {
    border: 2px solid #b0e8e2;
}

.timeline .bullet.orange {
    border: 2px solid #eb8b6e;
}

.timeline .time {
    /* width: 15%; */
    font-size: 12px;
    padding-top: 0.25em;
    color: var(--main-secondary);
}

.timeline .desc {
    width: 100%;
}

.timeline h3 {
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    padding-bottom: 2px;
}

.timeline h4 {
    margin: 0;
    font-size: 14pz;
    font-weight: 400;
    color: #808080;
}

.timeline .avatar img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
}

.timeline .credits,
.timeline .video {
    position: absolute;
    bottom: 10px;
    color: #808080;
    font-size: 100%;
    text-decoration: underline;
}

.timeline .credits {
    left: 10px;
}

.timeline .video {
    right: 10px;
}

.timeline .top div {
    display: inline-block;
}

.timeline .top .avatar {
    /* margin: 1em 0.5em 1em -2px; */
    margin: 0px 5px 5px -2px;
    vertical-align: middle;
    display: inherit;
    width: 35px;
    height: 35px;
}

/* .timeline .top {
    padding: 0 0 0 1.5em;
} */

.timeline .top .input-text {
    width: 70%;
    /* margin-right: 15px; */
    margin-left: 7px;
}

.timeline .top input {
    vertical-align: middle;
}

.timeline .showmore {
    text-align: center;
    margin-top: 15px;
}

.timeline .ladiui.button[disabled] {
    color: #6c7293;
    border-color: #ebedf2;
    background-color: #f4f5f8;
}

.ladiui-topbar-noavatar {
    background: #1c00c2;
    color: #fff;
    width: 75px;
    height: 75px;
    display: block;
    border-radius: 50%;
    text-align: center;
    padding-top: 15%;
}

.timeline .noavatar {
    background: #1c00c2;
    color: #fff;
    width: 35px;
    height: 35px;
    display: block;
    border-radius: 50%;
    text-align: center;
    padding-top: 25%;
    font-size: 14px;
}

.timeline .content {
    max-height: 400px;
    overflow-y: scroll;
}

.timeline .avatar {
    width: 35px;
    height: 35px;
    border-radius: 50%;
}

.timeline .top .noavatar {
    width: 40px;
    height: 40px;
}

.timeline .ladiui-input-group {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ebedf2;
    border-radius: 4px;
    display: inline-block;
}

.timeline .ladiui-input-group input {
    width: 100% !important;
    border-right: 0px;
    border-radius: 4px 0px 0px 4px;
    border: 0px;
}

.timeline .ladiui-input-group .wrapper-input {
    display: inline-block;
    width: 100%;
}

/* .timeline .ladiui-input-group .ladiui-input-group-prepend {
    padding: 5px;
    display: inline-block;
    width: 100%;
} */

.timeline .ladiui-input-group .ladiui-input-group-prepend span {
    float: right;
}

.timeline .ladiui-input-group .ladiui-input-group-prepend span .action {
    margin-right: 8px;
    width: 50px;
}

.timeline .ladiui-input-group .ladiui-input-group-prepend span .action:last-child {
    margin-right: 0px;
}

.timeline .top .input-text>input::placeholder {
    font-size: 13px;
    /* font-style: italic; */
}

[contenteditable='true'] {
    -moz-box-sizing: border-box;
    border: 0px;
    max-height: 70px;
    max-width: 450px;
    word-wrap: break-word;
    margin: 0;
    min-height: 20px;
    width: 100%;
    overflow: hidden;
    position: relative;
    padding-left: 5px;
    vertical-align: top;
    box-sizing: border-box;
    font-size: 13px;
}

.timeline .expand .item {
    display: none;
    height: 100%;
    padding: 10px;
}

.timeline .top .comment {
    float: right;
    margin-top: 1em;
}

.timeline .expand {
    cursor: pointer;
}

.timeline .expand .title i {
    vertical-align: middle;
    margin-left: 5px;
    cursor: pointer;
}

.timeline .expand .item .key {
    font-size: 0.9em;
    font-weight: 500;
}

.timeline .expand .item .value {
    font-size: 0.7em;
    font-weight: 400;
    color: #808080;
}

.timeline .ladiui-input-group .ladiui-input-group-prepend button {
    height: 30px;
    padding: 2px 5px;
}

.timeline .row-actions {
    vertical-align: bottom;
}

.timeline .row-actions ul {
    right: 0px;
}

.order-timeline {
    font-weight: 500;
    /* border-bottom: 1px solid #efefef; */
    background-color: #fff;
    border-radius: 5px 5px 0px 0px;
    padding: 8px 0px;
    margin-top: 24px;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px; */
    /* background: #f8fafb; */
}

.order-page .timeline {
    background-color: #fff;
    border-radius: 0px 0px 5px 5px;
    padding-bottom: 15px;
}

.order-page .timeline .content {
    overflow-y: none;
    height: 100%;
}

#modal-store .welcome {
    height: 100%;
}

.dont-check-inventory {
    text-align: center;
    cursor: pointer;
}

[data-tooltip]:before {
    content: attr(data-tooltip);
}

[data-tooltip] {
    display: inline-block;
    position: relative;
    cursor: pointer;
}

/* Tooltip styling */
[data-tooltip]:before {
    content: attr(data-tooltip);
    display: none;
    position: absolute;
    background: #6c7293;
    color: #fff;
    padding: 4px 8px;
    font-size: 12px;
    line-height: 1.4;
    min-width: 100px;
    text-align: center;
    border-radius: 4px;
}

/* Dynamic horizontal centering */
[data-tooltip-position='top']:before,
[data-tooltip-position='bottom']:before {
    left: 50%;
    -ms-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

/* Dynamic vertical centering */
[data-tooltip-position='right']:before,
[data-tooltip-position='left']:before {
    top: 50%;
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

[data-tooltip-position='top']:before {
    bottom: 100%;
    margin-bottom: 6px;
}

[data-tooltip-position='right']:before {
    left: 100%;
    margin-left: 6px;
}

[data-tooltip-position='bottom']:before {
    top: 100%;
    margin-top: 6px;
}

[data-tooltip-position='left']:before {
    right: 100%;
    margin-right: 6px;
}

/* Tooltip arrow styling/placement */
[data-tooltip]:after {
    content: '';
    display: none;
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}

/* Dynamic horizontal centering for the tooltip */
[data-tooltip-position='top']:after,
[data-tooltip-position='bottom']:after {
    left: 50%;
    margin-left: -6px;
}

/* Dynamic vertical centering for the tooltip */
[data-tooltip-position='right']:after,
[data-tooltip-position='left']:after {
    top: 50%;
    margin-top: -6px;
}

[data-tooltip-position='top']:after {
    bottom: 100%;
    border-width: 6px 6px 0;
    border-top-color: #6c7293;
}

[data-tooltip-position='right']:after {
    left: 100%;
    border-width: 6px 6px 6px 0;
    border-right-color: #6c7293;
}

[data-tooltip-position='bottom']:after {
    top: 100%;
    border-width: 0 6px 6px;
    border-bottom-color: #6c7293;
}

[data-tooltip-position='left']:after {
    right: 100%;
    border-width: 6px 0 6px 6px;
    border-left-color: #6c7293;
}

/* Show the tooltip when hovering */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
    display: block;
    z-index: 50;
}

.group-adjust-quantity {
    border-radius: 5px;
    margin: 0px auto;
}

.group-adjust-quantity input {
    display: inline-block !important;
    width: 150px !important;
    border-radius: 0px !important;
    height: 36px !important;
}

.group-adjust-quantity input:focus {
    border-color: #ebedf2 !important;
}

.group-adjust-quantity button {
    border-radius: 0px !important;
}

.group-adjust-quantity item:first-child {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}

.group-adjust-quantity .mode {
    background-color: #fff;
}

.group-adjust-quantity .mode.active {
    color: #6c7293 !important;
    border-color: #ebedf2 !important;
    background-color: #f4f5f8 !important;
}

.group-adjust-quantity .item:last-child {
    line-height: 20px !important;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

#modal-update-batch .group-adjust-quantity input {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

.group-adjust-quantity .remove-right {
    border-right: none !important;
}

.inventory-adjust-triagle {
    margin: 0px 10px;
    font-size: 10px;
    color: var(--main-primary);
}

.inventory-page button.primary[disabled] {
    background-color: #759ee4;
    border-color: #759ee4;
}

textarea {
    resize: none;
}

#modal-refund .ladiui.form-group {
    display: inline-block;
    position: relative;
    width: 100%;
}

#modal-refund .ladiui.form-group .ladiui-row .col-50:first-child {
    top: 20%;
    position: absolute;
}

#modal-refund button[disabled] {
    background-color: #759ee4;
    border-color: #759ee4;
}

.ladiui-user-type {
    position: relative;
    display: inline-block;
    top: 13px;
    padding: 4px 15px;
    color: #fff;
    font-weight: 700;
    border-radius: 50px;
    text-align: center;
    cursor: pointer;
    background: #1853fa;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.ladiui-logo {
    width: 135px;
}

.ladiui-logo img {
    width: 120px;
}

.pkg-STARTER {
    background: linear-gradient(90deg, #57a900, #bfe500);
}

.pkg-PREMIUM {
    background: linear-gradient(90deg, #f22c29, #ff7b4a);
}

.package-table-info tr {
    margin: 10px;
    display: inline-block;
    width: 100%;
}

.package-table-info tr td:first-child {
    width: 200px;
}

/* li.order-address-dropdown {
    line-height: 22px;
    border-bottom: 1px solid #f6f7f9;
    font-weight: 300;
}

ul li.order-address-dropdown:last-child {
    border-bottom: none;
} */

.order-tag {
    color: var(--main-default);
    background: #1c00c2;
    border-radius: 2px;
    font-size: 13px;
    transition: all 0.3s ease-out;
    padding: 4px 15px;
    cursor: pointer;
}

.custom-menu-dropdown .ladiui-modal-footer .icon {
    width: auto;
    height: auto;
}

.custom-menu-dropdown #modal-all-tag {
    height: 100%;
    min-height: 50vh;
}

.custom-menu-dropdown #modal-all-tag .ladiui-modal-content {
    width: 600px;
}

.customer-page li.custom-menu-dropdown .dropdown-content,
.product-page li.custom-menu-dropdown .dropdown-content {
    width: 500px;
    left: -112% !important;
}

.customer-page #modal-all-tag .ladiui-modal-content,
.product-page #modal-all-tag .ladiui-modal-content {
    width: 400px;
}

.ladiui-modal {
    overflow: unset;
}

.ladiui-modal.visible {
    overflow: scroll;
}

.ladiui-modal-body {
    overflow: unset;
    max-height: inherit;
}

.dropdown-tag {
    float: left;
}

.dropdown-tag .item {
    padding: 5px !important;
    border-radius: 4px;
    margin: 8px 10px;
}

.dropdown-tag .item:first-child {
    margin-top: 2px;
}

.dropdown-tag .item:hover {
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
}

.dropdown-tag .ladiui-search-dropdown-menu {
    overflow-y: unset;
}

.dropdown-tag .ladiui-search-input {
    background: transparent !important;
    border: 0px !important;
    box-shadow: none !important;
}

.dropdown-tag.ladiui-search-dropdown::after {
    content: '' !important;
}

.dropdown-tag .ladiui.text::before {
    content: '\ea05';
    font-family: 'LadiIcons';
    font-size: 12px;
    display: block;
    position: absolute;
    left: 0px;
    padding-right: 10px;
    line-height: 40px;
    top: 0px;
    cursor: pointer;
    z-index: 2;
}

.form-default-color {
    margin-top: 24px;
}

.form-default-color .item {
    float: left;
    height: 30px;
    padding: 0;
    width: 64px;
    cursor: pointer;
    text-align: center;
    line-height: 32px;
    border-radius: 2px;
}

.form-default-color .item i {
    color: var(--main-primary);
}

.order-tag-item {
    padding: 5px 15px;
    display: inline-block;
    cursor: pointer;
    border-radius: 2px;
}

.ladiui.table.scope tr td {
    width: 1%;
    white-space: nowrap;
}

#modal-edit-account .ladiui-modal-content {
    max-width: 1200px;
}

.onoffswitch {
    position: relative;
    width: 70px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    margin-top: 14px;
}

.onoffswitch.m-t-0 {
    margin-top: 0px !important;
}

.onoffswitch-checkbox {
    display: none;
}

.onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0px solid #ffffff;
    border-radius: 20px;
}

.onoffswitch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 20px;
    padding: 0;
    line-height: 20px;
    font-size: 10px;
    color: white;
    box-sizing: border-box;
}

.onoffswitch-inner:before {
    content: 'ONLINE';
    padding-left: 10px;
    background-color: var(--main-primary);
    color: #ffffff;
    text-align: left;
}

.onoffswitch-inner:after {
    content: 'OFFLINE';
    padding-right: 10px;
    background-color: #eeeeee;
    color: #999999;
    text-align: right;
}

.onoffswitch-switch {
    display: block;
    width: 8px;
    height: 8px;
    margin: 5px;
    background: #ffffff;
    position: absolute;
    top: 0px;
    bottom: 0;
    right: 49px;
    border: 2px solid #ffffff;
    border-radius: 16px;
    -webkit-transition: all 0.3s ease-in 0s;
    transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-switch {
    right: 3px;
    top: 1px;
}

.basic-rest-weight {
    font-size: 12px;
    font-weight: 400;
    font-style: italic;
    margin-left: 5px;
}

#modal-assign .onoffswitch {
    float: right;
    top: -15px;
}

#modal-assign .onoffswitch-inner::before {
    content: 'ON';
}

#modal-assign .onoffswitch-inner:after {
    background-color: #484848;
    content: 'OFF';
}

.rule-group {
    position: relative;
    display: flex;
    flex-direction: column;
}

.rule-group .rule-content {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    border: 1px solid #f1f3f4;
    border-radius: 2px;
    padding: 16px;
}

.rule-group .rule-item .item {
    display: inline-block;
    min-width: 20%;
    position: relative;
}

.rule-group .select-assignee .ladiui-select-options {
    z-index: inherit;
}

.rule-group .select-assignee .ladiui-select-options>.ladiui-select-init:after {
    z-index: 0;
    right: 0px;
}

.rule-group.other .ladiui-select-options>.ladiui-select-init:after {
    z-index: 0;
}

.rule-group .rule,
.rule-group .assign {
    display: block;
    width: 100%;
    float: left;
    /* padding: 0px 15px; */
}

/* .rule-group .assign {
    margin-bottom: 15px;
} */

.rule-group .assign .apply-to {
    display: inline-block;
    min-width: 25%;
    font-weight: 500;
}

.rule-group .assign .select-assignee {
    display: inline-block;
    width: 100%;
}

.rule-group .assign .ladiui-select-options {
    display: block;
    float: left;
    width: 150px;
    margin-right: 10px;
}

.rule-group.other .select-assignee .ladiui-select-options>.ladiui-select-init {
    text-indent: 15px;
}

.rule-group.other .ladiui-select-options {
    width: 250px;
    z-index: inherit;
}

.rule-group.other .select-assignee .ladiui-select-options {
    width: 240px;
}

.rule-group .rule .ladiui-tags {
    position: relative;
}

.rule-group .ladiui-tags {
    border: none;
    display: block;
    float: left;
    max-width: 600px !important;
}

.rule-group .rule .rule-item .ladiui-select-options,
.rule-group .rule .rule-item .custom-input-autocomplete,
.rule-group .assign .ladiui-select-options {
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
    box-shadow: none;
    border-radius: 0px;
    padding-bottom: 7px;
}

.rule-group .ladiui-tag-list {
    /* margin-top: 10px; */
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.rule-group .flex-tag {
    display: flex;
    flex-wrap: wrap;
    padding: 4px 8px !important;
}

.rule-group .ladiui-tag-list .tag {
    background: #f1f3f4;
    border-radius: 2px;
    padding: 2px 8px;
    margin: 4px 4px;
    height: fit-content;
}

.rule-group .ladiui-tag-list .tag img {
    margin-left: 9px;
}

.rule-group .rule .rule-item .input {
    height: 32px !important;
}

.rule-group .ladiui-tags .tag {
    position: relative;
    display: block;
    float: left;
    color: #191919;
    background: #fafafa;
    padding: 4px 23px 4px 16px;
    margin: 4px;
    border-radius: 20px;
    font-size: 14px;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.rule-group .ladiui-tags .close {
    color: #191919;
    background: #fafafa;
    right: 5px;
    top: 0px;
    height: 26px;
    border-radius: 10px;
    line-height: 13px;
}

.rule-group .icon-c-add {
    clear: both;
    position: absolute;
    top: -10px;
    right: -21px;
    cursor: pointer;
    color: #033c9d;
}

.rule-group .icon-c-add::before {
    font-size: 15px;
}

.rule-group .icon-bin {
    font-size: 13px;
    top: 0px;
    right: 5px;
    position: absolute;
    cursor: pointer;
    margin-left: 10px;
}

.rule-group .ladiui.input:focus,
.rule-group .select-assignee .ladiui-select-options>.ladiui-select-init:hover,
.rule-group .select-assignee .ladiui-select-options>.ladiui-select-init:focus,
.rule-group .select-assignee .ladiui-select-options:hover,
.rule-group .select-assignee .ladiui-select-options:focus {
    border-color: #ebedf2;
}

.rule-group .select-assignee .ladiui-select-options>.ladiui-select-init {
    text-indent: 0px;
}

.rule-group .rule .rule-item {
    position: relative;
    margin-bottom: 24px;
}

.rule-group .rule .rule-item:last-child {
    margin-bottom: 0;
}

.rule-group .ladiui-search-dropdown {
    /* position: absolute; */
    /* clear: both;
    top: -20px;
    right: -33px; */
    background: #fff;
    min-width: 150px;
}

.rule-group .dropdown-tag .item {
    margin: 0px !important;
}

.rule-group .dropdown-tag .item:hover {
    box-shadow: none;
}

.ladiui-table-responsive {
    overflow-x: inherit;
}

.order-table .tag-color .ladiui-tags .tag {
    padding: inherit;
    position: relative;
    display: flex;
    align-items: center;
    color: #ffffff;
    background: #1853fa;
    border-radius: 2px;
    font-size: 12px;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    padding: 4px 2px;
    margin: 1px;
    line-height: 1.2;
}

.rule-group .place-holder {
    position: relative;
    cursor: pointer;
    margin-top: 15px;
    margin-right: 20px;
    float: left;
}

.rule-group .item .title label {
    cursor: pointer;
}

.rule-group .item .title {
    position: relative;
    width: 160px;
    cursor: pointer;
    float: left;
    min-height: 40px;
    margin-top: 15px;
}

.rule-group .item .title::after {
    content: '\ea05';
    font-family: 'LadiIcons';
    font-size: 12px;
    display: block;
    position: absolute;
    right: 0px;
    padding-right: 10px;
    line-height: 40px;
    top: -11px;
    cursor: pointer;
    z-index: 2;
}

.rule-group .search-panel {
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    width: 331px;
    min-height: 250px;
    position: absolute;
    top: 40px !important;
    padding: 15px;
    z-index: 3;
    background: #fff;
    border-radius: 5px;
    overflow: auto;
}

.rule-group .search-panel .ladiui-search-custom {
    width: 300px;
    border-radius: 3px;
    border: 1px solid #ebedf27a;
}

.rule-group .search-panel .ladiui-search-custom input {
    border-bottom: 0px;
}

.rule-group .search-panel .ladiui-search-custom .menu {
    top: 111% !important;
    box-shadow: none !important;
}

.rule-group .search-panel .ladiui-search-custom::after {
    content: '\ea44';
    font-family: 'LadiIcons';
    font-size: 18px;
    display: block;
    z-index: 9999;
    position: absolute;
    right: 0px;
    padding-right: 10px;
    line-height: 40px;
    top: 0px;
    cursor: pointer;
    z-index: 2;
}

.rule-group .popover {
    float: left;
}

.rule-group .search-panel.utm {
    min-height: 100px;
}

.rule-group .search-panel .add {
    margin-top: 15px;
    float: right;
}

.ladiui.search-keyword {
    width: 400px;
}

.badge {
    display: inline-block;
    font-size: 10px;
    font-weight: 700;
    line-height: 18px;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border-radius: 2px;
    min-width: fit-content;
    width: 20px;
    height: 18px;
    /* position: absolute; */
    /* left: 0px;
    bottom: -4px; */
    cursor: pointer;
    /* padding: 0px 8px 0px 18px; */
    padding: 0px 8px;
}

.badge-light {
    color: #fff;
    /* background-color: rgba(243, 83, 83, 1); */
    background-color: var(--active-warning-color);
}

.badge-child-light {
    color: #fff;
    /* background-color: #d94f45; */
    background-color: var(--active-warning-color);
}

/* 
.badge > i {
    position: absolute;
    left: 4px;
    top: 4px;
} */

/* .order-table .duplicate {
    background-color: rgb(248, 250, 251);
} */

.payment-shipping .order-cancel {
    color: #313942 !important;
}

.order-page .more-info i {
    float: left;
    margin-right: 5px;
    cursor: pointer;
}

.order-custom-field .more-info span {
    font-size: 14px;
    /* margin-bottom: 10px; */
    margin-right: 5px;
    margin-left: 0px;
    display: inline-block;
    /* width: 100%; */
}

.order-custom-field .more-info [data-tooltip-position='bottom']:before {
    left: 90px !important;
}

.order-custom-field .more-info [data-tooltip-position='bottom']:after {
    left: 90px !important;
}

#order-custom-field .order-page .more-info span {
    margin-right: 0px !important;
}

.order-page .more-info span h3 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.order-page .more-info b {
    font-weight: 500;
    margin-right: 1px;
    display: inline-block;
    float: left;
    margin-right: 5px;
}

.DateRangePicker .custom-btn-date-panel {
    padding: 0px 22px 11px 22px;
}

.DateRangePicker .custom-btn-date {
    position: relative;
    height: 100%;
    text-align: center;
    background: none;
    border: 1px solid #0b61f2;
    border-radius: 5px;
    color: #0b61f2;
    padding: 4px 9px;
    margin-right: 5px;
    margin-bottom: 5px;
    font-weight: 500;
    line-height: normal;
    overflow: visible;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 14px;
}

.DateRangePicker .custom-btn-date:active {
    outline: 0;
}

.DateRangePicker .custom-btn-date.selected {
    color: #fff;
    background: var(--main-primary);
}

.more-filter {
    width: 380px;
    right: -380px;
    position: fixed;
    background: #fff;
    z-index: 16;
    height: 100vh;
    top: 0px;
    /* box-shadow: 0 31px 41px 0 rgba(32, 42, 53, .2), 0 2px 16px 0 rgba(32, 42, 54, .08);
     */
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    transition: right 1s;
}

.more-filter.show-filter {
    right: 0px;
}

.more-filter.hide-filter {
    right: -380px;
}

.more-filter .heading {
    border-bottom: 1px solid #efefef;
    padding: 20px 15px;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
}

.more-filter .main-content {
    height: calc(100vh - 130px);
    overflow-y: scroll;
}

.more-filter .footer {
    position: absolute;
    bottom: 0;
    right: 0px;
    width: 380px;
    text-align: right;
    padding: 15px 15px;
    clear: both;
    border-top: 1px solid #ebedf2;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
}

.close-more-filter {
    position: absolute;
    top: 12px;
    right: 0;
    font-size: 18px;
    padding: 11px;
    cursor: pointer;
    opacity: 1;
    transition: opacity 150ms linear;
    background: 0 0;
    border: 0;
}

.more-filter .title:hover {
    background-image: linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3));
}

.more-filter .item .title {
    position: relative;
    cursor: pointer;
    padding: 15px 0px 10px 15px;
}

.more-filter .item .title label {
    cursor: pointer;
    font-size: 14px;
}

.more-filter h3 {
    font-size: 18px;
    font-weight: 500;
}

.more-filter .main-content .item .title:focus {
    /* box-shadow: inset 0.2rem 0 0 #5478ec; */
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
}

.more-filter .main-content .item .content {
    display: block;
    max-height: 0px;
    margin: 0px 15px 5px 15px;
    overflow: hidden;
    transition: max-height 0.15s ease-out;
}

.more-filter .main-content .item .content.full.tag {
    overflow: unset;
}

.more-filter .main-content .item .content.full {
    max-height: 300px;
    overflow: scroll;
    overflow-x: hidden;
}

.more-filter .main-content .item .content ul li {
    font-size: 14px;
    margin: 0px 0px 5px 1px;
}

.more-filter .main-content .item .short-content {
    background-color: #dfe3e8;
    color: #212b36;
    padding: 1px 9px;
    border-radius: 34px;
    font-size: 12px;
    font-weight: 400;
    margin-right: 5px;
    display: inline-block;
    margin-bottom: 4px;
}

.more-filter .main-content .item .short-content:last-child {
    margin-right: 0px;
}

.more-filter .main-content .item.collapse {
    border-bottom: 1px solid #efefef;
    padding-bottom: 15px;
}

.more-filter .main-content>div.item.collapse:last-child {
    border-bottom: none;
}

.more-filter .main-content .item .clear {
    display: block;
    cursor: pointer;
    margin-top: 15px;
    color: #006fbb;
    font-size: 14px;
}

.more-filter .main-content .item .arrow {
    position: absolute;
    top: 3px;
    right: 0;
    font-size: 14px;
    padding: 11px;
    cursor: pointer;
    opacity: 1;
    -webkit-transition: opacity 150ms linear;
    transition: opacity 150ms linear;
    background: 0 0;
    border: 0;
}

.ladiui-search-dropdown .ladiui-search-dropdown-menu {
    max-height: 3000x;
}

.no-scroll {
    overflow: hidden;
}

.mt-6 {
    margin-top: 6px;
}

button.loader::before {
    margin: 0px auto;
    position: absolute;
    border-top: 2px solid var(--main-line);
    border-right: 2px solid var(--main-line);
    border-bottom: 2px solid var(--main-line);
    border-left: 2px solid var(--main-primary);
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
    display: inline-block;
    content: '' !important;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    top: calc(50% - 10px);
    left: 6px;
    z-index: 10;
}

button.loader {
    padding-left: 30px !important;
    opacity: 0.6 !important;
}

.glossary.ladiui.table tbody .header {
    font-weight: 500;
    text-align: center;
}

.glossary.ladiui.table tbody tr {
    border: 1px solid #efefef;
}

.glossary.ladiui.table tbody tr td {
    border-right: 1px solid #efefef;
    padding: 10px;
}

.glossary.ladiui.table tbody tr td:last-child {
    border-right: none;
}

a.link {
    color: #1853fa;
}

.variant-option-item .adjust {
    height: 32px;
    line-height: 32px;
    margin-left: 15px;
}

.variant-option-item .adjust a {
    font-size: 13px;
}

#modal-adjust .swatch-text .ladiui.form-group {
    /* width: 500px;
     */
    display: flex;
    align-items: center;
}

#modal-adjust .visual-swatch .ladiui.form-group {
    width: 500px;
}

#modal-adjust .swatch-text .ladiui.form-group .ladiui.input,
#modal-adjust .visual-swatch .ladiui.form-group .ladiui.input {
    display: inline-block;
    width: 70%;
}

#modal-adjust .swatch-text .ladiui.form-group .ladi-icon,
#modal-adjust .visual-swatch .ladiui.form-group .ladi-icon {
    display: inline-block;
    margin-left: 15px;
}

.swatch-picker {
    width: 40px;
    height: 40px;
    display: inline-block;
    border: 1px solid #efefef;
    border-radius: 6px;
    margin-right: 15px;
    vertical-align: middle;
    cursor: pointer;
    background-color: #ffff;
    position: relative;
}

.swatch-picker div {
    margin: 4px;
    background-color: #dedede;
    width: 30px !important;
    height: 30px;
    border-radius: 4px;
    position: absolute;
}

.swatch-picker div.image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.visual-swatch .ladiui-navbar.-horizontal>li {
    float: inherit;
}

.visual-swatch .ladiui-navbar.-horizontal,
.visual-swatch ul.ladiui-navbar.-vertical {
    margin-left: -12px;
}

.visual-swatch .popover-picker {
    z-index: 1;
    top: 45px !important;
}

.variant-image {
    cursor: pointer;
}

.order-customer .total-order {
    margin-left: 5px;
    font-size: 12px;
    font-style: italic;
    color: #6c7293;
    font-weight: 500;
}

.ladiui.showing {
    min-width: 300px;
    width: auto;
}

.group-date {
    clear: both;
}

.group-date .date-item {
    width: 50%;
    display: inline-block;
}

.group-date .date-item:first-child {
    margin-right: 24px;
}

.group-date .date-item:last-child {
    right: 0px;
}

#modal-setting-payment .item {
    margin-bottom: 15px;
    border-radius: 10px;
}

#modal-setting-payment .item:last-child {
    margin-bottom: 0px;
}

#modal-setting-payment .item .shipping-partner-logo {
    height: 25px;
}

#modal-setting-payment .item .shipping-partner-logo.shopee-pay {
    height: 45px !important;
}

#modal-setting-payment .item .description {
    margin-top: 15px;
    padding-bottom: 15px;
    line-height: 22px;
    font-size: 15px;
}

#modal-setting-payment .item .partner-account {
    margin-top: 15px;
}

#modal-setting-payment .item .partner-footer {
    display: flex;
    padding-bottom: 15px;
    justify-content: flex-end;
}

#modal-setting-payment .item .partner-footer .right button {
    margin-left: 10px;
}

#modal-setting-payment .item .partner-footer .left button {
    margin-left: 10px;
}

#tab-checkout .col-33 {
    width: 31.5%;
    margin-left: 0px;
}

.col-33 {
    width: 33%;
    float: left;
    margin: 0px 15px;
}

#tab-checkout .publish div,
#tab-checkout-config .publish div {
    clear: both;
}

#tab-checkout .publish div label.border-bottom,
#tab-checkout-config .publish div label.border-bottom {
    display: inline-block;
    border-radius: 0 !important;
    border: 0 !important;
    border-bottom: 1px solid rgba(6, 21, 40, 0.1) !important;
    padding: 5px 12px 5px 0px;
    font-weight: 400;
    height: 35px;
    font-style: italic;
}

#tab-checkout .publish div input,
#tab-checkout-config .publish div input {
    display: inline-block;
    width: 21%;
    margin-right: 15px;
    border-radius: 0 !important;
    /* border: 0 !important;
    border-bottom: 1px solid rgba(6, 21, 40, .1) !important; */
}

#tab-checkout .publish div button,
#tab-checkout-config .publish div button {
    display: inline-block;
}

#modal-store .onoffswitch {
    float: left;
    top: -15px;
}

#modal-store .onoffswitch-inner::before {
    content: 'ON';
}

#modal-store .onoffswitch-inner:after {
    background-color: #484848;
    content: 'OFF';
}

.checkout-config-item {
    margin-left: 20px;
}

.checkout-config-item.disabled {
    color: #6c72938f !important;
    text-decoration: line-through;
}

.ladiui-row.product-service {
    clear: both;
}

.ladiui-row.product-service .ladiui.form-group {
    width: 32.1%;
    display: inline-block;
    margin-right: 15px;
}

.ladiui-row.product-service .ladiui.form-group:last-child {
    margin-right: 0px;
}

#autocomplete-price-unit.ladiui-search-custom::after {
    content: '' !important;
}

.package-price-description {
    margin-top: 5px;
    display: block;
    font-size: 13px;
    font-style: italic;
}

.group-store-logo {
    width: 70%;
    display: flex;
    padding-right: 20px;
    margin-bottom: 20px;
}

.group-store-logo .image {
    width: 100%;
    display: flex;
}

.group-store-logo img {
    height: 150px;
}

.group-store-logo img {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 1px;
}

.checkout-config .ladiui.table thead {
    border-bottom: none;
}

.checkout-config .ladiui.table tbody tr {
    border-bottom: none;
}

.checkout-config .ladiui.table tbody tr td {
    padding: 8px 16px;
}

.checkout-config .ladiui.table tbody tr:last-child td {
    padding-bottom: 16px;
}

.checkout-config .ladiui.table thead tr th {
    padding: 5px 0px 10px 15px;
}

.ladiui.table tbody tr.default {
    background-color: #f8fafb;
}

.panel-setting-checkout.preview,
.panel-setting-checkout.preview .ladiui.table {
    padding: 10px;
    border-radius: 4px;
}

.order-page.order-edit .btn-link-checkout {
    z-index: 5;
}

.order-page .link-checkout {
    margin-left: 33px;
    display: block;
    width: 500px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    left: 15px;
    position: absolute;
}

.payment-shipping .title-payment i.copy {
    margin-left: 5px;
    color: inherit;
    cursor: pointer;
    border: none;
    font-size: 14px;
}

.checkout-page .ladiui-order-right {
    top: 275px;
}

.email-confirm {
    background-color: #f1f3f4;
    border: 1px solid #f1f3f4;
    border-radius: 4px;
    padding: 15px;
    margin-bottom: 15px;
    min-height: 148px;
    margin-top: 20px;
}

/* .email-confirm.sent {
    background-color: #1853fa2e;
} */

.email-confirm button,
.email-confirm input {
    margin-top: 10px;
}

.ladiui-select-options>ul>li.header-item {
    cursor: pointer;
    display: block;
    color: #313942;
    width: 100%;
    padding: 0px 12px;
    line-height: 40px;
}

.ladiui.table .badge.badge-child-light.sold-out,
.ladiui.table .badge.badge-child-light.sold-out {
    left: 0px;
    position: relative;
    display: block;
    margin-top: -3px;
    margin-bottom: -13px;
}

.ladiui.table tr.sold-out,
.ladiui.table tr.sold-out {
    background-color: rgb(248, 250, 251) !important;
}

.ladiui.table tr.sold-out>td:first-child,
.ladiui.table tr.sold-out>td:first-child {
    position: relative;
}

.DateRangePickerInput__showClearDates {
    padding-right: 0px !important;
}

span.buy-information {
    font-weight: 400;
    font-size: 12px;
    margin-left: 5px;
    font-style: italic;
}

.onoffswitch.ticket-seat {
    width: 92px;
}

.onoffswitch.ticket-seat .onoffswitch-inner:before {
    content: 'Đã tham gia';
}

.onoffswitch.ticket-seat .onoffswitch-inner:after {
    content: 'Chưa tham gia';
}

.onoffswitch.ticket-status .onoffswitch-inner:before {
    content: 'Đã bán';
}

.onoffswitch.ticket-status .onoffswitch-inner:after {
    content: 'Chưa bán';
}

.onoffswitch.ticket-seat .onoffswitch-switch {
    right: 73px;
}

.ladiui-select-options.select-option-checkout-config ul {
    max-height: 515px;
    overflow: scroll;
}

.ladi-card.ladiui-table-responsive.checkout-config tr th {
    font-weight: 400;
}

.scope-description {
    white-space: break-spaces;
    display: block;
    font-weight: 400;
    font-style: italic;
    font-size: 11px;
    line-height: 12px;
}

.price-before-discount {
    color: #6d6d6d !important;
    text-decoration: line-through;
    margin-right: 9px;
    display: block;
}

/* .ladiui.menu {
    overflow-y: scroll;
    max-height: calc(100vh - 100px);
}

.ladiui.menu::-webkit-scrollbar {
    width: 0px;
} */

.ladiui-toast {
    position: relative;
}

.ladiui-toast .ladi-icon.icon-e-remove {
    position: absolute;
    top: 3px;
    right: 0;
    font-size: 18px;
    padding: 11px;
    cursor: pointer;
    opacity: 1;
    transition: opacity 150ms linear;
    background: 0 0;
    border: 0;
}

/* .qr-code-publish {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: 9000000080;
    background: rgba(0, 0, 0, .5);
} */

/* .qr-code-publish img {
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    object-fit: scale-down;
    max-width: 90%;
    max-height: 90%;
} */

#tab-checkout-config .publish div,
#tab-checkout .publish div {
    clear: both;
}

.qr-code-publish,
.qr-code-publish img {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
}

.qr-code-publish {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9000000080;
    background: rgba(0, 0, 0, 0.5);
}

.qr-code-publish img {
    position: absolute;
    object-fit: scale-down;
    max-width: 90%;
    max-height: 90%;
}

/* .panel-publish a {
    width: 78%;
    border-bottom: 1px solid rgba(6, 21, 40, .1) !important;
    padding-bottom: 7px;
} */

.panel-publish .item {
    margin-right: 5px;
    border-top: none;
    border-left: none;
    border-right: none;
    /* border-bottom: 1px solid rgba(6, 21, 40, .1) !important; */
    border-radius: 0px;
    box-shadow: none;
}

.panel-publish .group {
    clear: both;
    display: flex;
    /* width: 83%; */
    align-items: center;
}

.panel-publish .group .item.ladiui-select-options>.ladiui-select-init {
    margin-left: 30px;
    text-indent: 5px;
}

.panel-publish .group .item.ladiui-select-options>.ladiui-select-init:after {
    right: inherit;
    left: 10px;
}

.qr-code-publish-close {
    width: 30px;
    height: 30px;
    position: absolute;
    z-index: 9000000090;
    background: url(https://w.ladicdn.com/v2/source/ladi-icons.svg) hsla(0, 0%, 100%, 0.2) no-repeat;
    background-position: -108px;
    transform: scale(0.7);
    -webkit-transform: scale(0.7);
    cursor: pointer;
    top: calc(50vh - 155px);
    right: calc(50vw - 155px);
}

#tab-checkout-config .publish div,
#tab-checkout .publish div {
    clear: both;
}

.ladiui-search-custom::after {
    content: '\ea44';
}

/* .ladiui.nav>.menu>li>a {
    font-size: 16px;
} */

.ellipsis {
    display: inline-block;
    width: 180px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.ladiui-modal-header h3 {
    display: inline-block;
}

.ladiui-modal-header .modal-hight-light-title {
    color: #1853fa;
    margin-left: 106px;
    font-weight: 500;
}

.prevent-click {
    pointer-events: none;
}

#confirm-order-not-show .ladiui-modal-content.confirm {
    top: 40%;
}

/* #tab-tracking-custom {
    max-height: 395px;
    overflow: auto;
} */

#tab-tracking-custom .tracking-custom-item {
    margin-bottom: 15px;
}

.tr-disable {
    background-color: #f8fafb;
}

/* .detail-checkout-config {
    margin-left: 20px;
    position: relative;
    top: -15px;
} */

#tab-checkout {
    margin-left: 5px;
}

.ladi-card.ladiui-table-responsive.form-group.checkout-config {
    border-radius: 4px;
    font-size: 14px;
    color: #6c7293;
    background-color: #fff;
    min-width: 250px;
}

.ladi-card.ladiui-table-responsive.form-group.checkout-config>.ladiui.table {
    box-shadow: none;
}

#autocomplete-variant .badge.sold-out {
    left: 0px;
}

#tab-product .ladiui-select-page-checkout {
    display: block;
    width: 30%;
    margin-bottom: 10px;
}

#tab-product .ladiui-select-page-checkout .ladiui-select-options {
    border-radius: 0px !important;
}

#autocomplete-product-page-checkout {
    display: block;
    float: left;
    width: 100%;
}

#autocomplete-product-page-checkout .badge {
    left: 1px;
}

.form-config-no-account {
    line-height: 20px;
    width: 100%;
    text-align: center;
    margin-top: 10px;
    font-weight: 100;
    margin-bottom: 15px;
}

.text-toggle-input-type {
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    position: relative;
    right: 5px;
    top: -31px;
    /* border-radius: 2px; */
    float: right;
    /* font-size: 12px; */
    /* background: #f1f1f1; */
    padding: 1px 5px;
    /* color: #353535; */
}

.mapping-fields .btn-add-custom-fields {
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
}

.mapping-fields tr {
    border-bottom: none !important;
}

.mapping-fields tr td:first-child {
    padding-left: 0px !important;
}

.mapping-fields tr td:last-child {
    padding-right: 0px !important;
}

.margin-left-15 {
    margin-left: 15px;
}

.margin-top-20 {
    margin-top: 20px;
}

.button-link {
    color: #0c61f2;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.a-link {
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.m-bt-10 {
    margin-bottom: 10px;
}

.label-custom-fields {
    font-weight: 400;
    font-size: 14px;
}

.form-config-account.google-sheet .ladiui-search-dropdown::after {
    content: '\ea44';
    font-size: 15px;
}

.form-config-account.google-sheet .google-sheet-sheet {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.form-config-account.google-sheet .google-sheet-sheet .ladiui.dropdown {
    width: 80% !important;
}

.form-config-account.google-sheet .google-sheet-sheet .ladiui-search-dropdown {
    flex: 1;
    margin-right: 15px;
}

#modal-tag-edit .ladiui.search-keyword {
    width: 195px;
    position: absolute;
    right: 27px;
    z-index: 1;
}

/* #modal-tag-edit .ladiui.search-keyword input#keyword {
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    box-shadow: none;
    border-radius: initial;
    padding-top: 15px;
} */

#modal-tag-edit .ladiui.search-keyword i {
    padding-top: 11px;
}

#modal-tag-edit table tr:hover i {
    display: inline-block;
    transition: display 5s ease-out;
}

#modal-tag-edit table tr i {
    display: none;
    margin-top: 4px;
    transform: rotate(-45deg);
}

.order_payment_transaction {
    background-color: #fffcf6;
    border: 1px solid #f6eee0;
    border-radius: 4px;
    padding: 5px;
    margin-left: 33px;
    max-width: 400px;
    font-style: italic;
}

.order_payment_transaction span {
    display: block;
    text-transform: inherit;
    line-height: 22px;
}

.order_payment_transaction .order_transaction_id {
    text-transform: uppercase !important;
}

.ladiui.form-group .ladiui-checkout-payment-body .ladiui-checkout-payment-header-show-2 {
    margin-bottom: 16px;
}


.ladiui.form-group .ladiui-label.payment-method-title {
    margin-bottom: 4px;
}

.client-order-code [data-tooltip]::before {
    width: 300px !important;
    text-align: left;
}

.pc_description .ladiui-label,
.mb_description .ladiui-label {
    display: flex;
    align-items: center;
}

.pc_description .ladiui-label>i,
.mb_description .ladiui-label>i {
    font-size: 15px;
    color: #333;
    margin-right: 8px;
}

.form-group .builder-modal-input-label {
    position: relative;
}

.form-group .builder-modal-input-label label {
    position: absolute;
    top: -8px;
    left: 10px;
    padding: 0 5px;
    background: #fff;
    z-index: 1;
    font-size: 13px;
    font-weight: 500;
}

.form-group .builder-modal-input-label input {
    font-size: 13px;
    padding: 24px 12px;
}

.form-group.input-image {
    display: inline-block;
    clear: both;
}

.form-group .builder-modal-input-label.show-link-img {
    width: 80%;
    float: left;
}

.ladiui-button-upload {
    position: relative;
    width: 82px;
    height: 82px;
    background: #f1f3f4;
    border-radius: 2px;
}

.ladiui-button-upload button {
    margin: 0px auto !important;
    cursor: pointer;
}

.ladiui-button-upload button .icon-data-upload {
    padding-right: 5px;
}

.ladiui-button-upload.link-img {
    margin-top: 0px;
    float: right;
    border-radius: 5px;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
}

.ladiui-button-upload.link-img button {
    background-color: #fbfafa;
}

.block-change-language {
    max-width: 250px;
}

.order-cancel .reference-no a {
    color: rgba(108, 114, 147, 0.56) !important;

    text-decoration: line-through;
}

.code-ticket-canceled {
    text-decoration: line-through;
}

#modal-setting-custom-field .ladiui-modal-body .pull-right {
    display: block;
    width: 100%;
    float: right;
    text-align: right;
}

.is-Show-Advanced {
    display: block;
    clear: both;
    margin-bottom: 20px;
}

.is-Show-Advanced.product {
    margin-top: 10px;
}

#modal-setting-custom-field .ladiui-modal-body .block-search .input-search {
    display: inline;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
}

#modal-setting-custom-field .ladiui-modal-body .block-search label {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 10px;
    display: block;
}

#modal-setting-custom-field .ladiui-modal-body .block-search button {
    background-color: #0341ac;
    color: #fff;
    padding: 10px;
    cursor: pointer;
}

#modal-setting-web-hook .ladiui-modal-footer {
    text-align: left;
    font-style: italic;
    font-size: 13px;
}

#order-custom-field {
    background-color: #fff;
    /* padding: 10px; */
    position: relative;
    /* margin-bottom: 20px; */
    border-radius: 5px;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
}

#order-custom-field .title {
    display: flex;
    justify-content: space-between;
    /* margin-bottom: 10px; */
}

.btn-custom-field-add {
    float: right;
}

#modal-setting-custom-field .block-search {
    clear: both;
}

.customer-info.customer-edit-custom-field {
    text-align: right;
    text-align: right;
    position: absolute;
    right: 33px;
    top: 10px;
}

.customer-info.customer-edit-custom-field a {
    margin: 0px 5px;
}

.customer-info.customer-edit-custom-field a:last-child {
    margin-right: 0px;
}

.ladiui.form-group .custom-field-content {
    /* max-height: 320px;
    overflow: scroll; */
    /* padding: 0px 5px; */
    overflow-x: hidden;
    width: 100%;
}

.ladiui.form-group .custom-field-content.custom {
    max-height: none;
    overflow: none;
}

.ladiui.form-group .hidden-custom-field {
    position: absolute;
    top: 10px;
    right: 10px;
}

.hidden-custom-field .icon-down-arrow,
.order-customer-edit .icon-e-remove {
    font-size: 12px;
    color: #585f67;
}

.show-error-text {
    color: #ff0000;
    font-size: 13px;
    margin-top: 8px;
    display: block;
}

.order-customer-edit-btn {
    top: 5px !important;
}

.panel-setting-checkout .config-payments table tr td {
    position: relative;
}

.panel-setting-checkout .config-payments table tr td .icon-onpin {
    transform: rotate(-45deg);
    transition: ease-in-out;
}

.panel-setting-checkout .config-payments table tr:hover .icon-onpin {
    display: inline !important;
    box-sizing: border-box;
}

#modal-checkout-config .child-content {
    padding-left: 20px;
}

#modal-checkout-config .child-content div {
    padding-bottom: 12px;
}

#modal-checkout-config .child-content div:first-child {
    padding-top: 12px !important;
}

#modal-checkout-config .child-content div:last-child {
    padding-bottom: 0;
}

#modal-checkout-config .child-content p .icon-move-2 {
    float: right;
    display: none;
}

#modal-checkout-config .child-content p:hover .icon-move-2 {
    display: block;
}

.panel-setting-checkout .config-payments .table.list-active-payment-method {
    display: block;
}

#modal-setting-web-hook .ladi-card.account-table .ladiui.table .show-name {
    max-width: 140px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

#modal-form-account .connect {
    position: relative;
    line-height: 40px;
}

#modal-form-account .connect .button-connect {
    margin: 0px 15px;
}

input[disabled] {
    background-color: rgba(6, 21, 40, 0.05) !important;
}

.input-padding-left {
    padding: 7px 28px !important;
}

.input-padding-left.pl-10 {
    padding-left: 10px !important;
}

.page-content {
    padding: 24px;
    background-color: #ffffff;
    overflow: auto;
    min-height: 100vh;
}

.page-head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.align-item-baseline {
    align-items: baseline !important;
}

.overflow-hide {
    overflow: hidden !important;
}

.icon-add-border {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: solid 0.5px var(--main-primary);
    padding: 2px;
    margin-right: 5px;
}

/* .flex-center {
    display: flex !important;
    align-items: center !important;
} */

.flex-center-left {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
}

a {
    cursor: pointer;
}

.flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.flex-top-start {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.flex-space-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ladiui.modal.show {
    display: block !important;
}

.ladiui.modal-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: #3e526a;
}

.text-end {
    text-align: right !important;
}

.ladiui.modal-content .description-header {
    padding: 24px 24px 0px !important;
}

.ladiui.modal-content .description-header p {
    font-weight: 400;
    font-size: 14px;
    color: #6e7d8f;
    line-height: 20px;
}

.ladiui.modal-content .block-account-type {
    padding: 24px 24px 0px !important;
    display: flex;
    align-items: center;
    color: var(--main-default);
}

.ladiui.modal-content .block-account-type strong {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.ladiui.modal-content .block-account-type span {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.timeline .input-history {
    width: 100% !important;
    position: relative;
    height: 60px;
    border: 1px solid #f1f3f4;
}

.timeline .input-history .input-custom {
    border: none;
}

.timeline .input-history .comment {
    width: 24px;
    height: 24px;
    border-radius: 50px;
    background-color: var(--main-primary);
    cursor: pointer;
    position: absolute;
    right: 10px;
    bottom: 10px;
    margin-top: 0px !important;
    text-align: center;
}

.timeline .input-history .comment span {
    display: block;
    margin-top: 2px;
}

.timeline .list-comment-history ul li {
    padding: 0px !important;
}

.timeline .top {
    display: flex !important;
    align-items: center;
}

.custom-info .order-customer .order-heading {
    padding: 10px 24px;
    background-color: #f1f3f4;
}

.custom-info .order-customer .customer-info {
    margin: 24px;
}

.info-customer .block-shipping-address {
    padding: 24px !important;
}

.order-customer .order-heading.title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.order-custom-field.custom.bottom {
    padding: 24px !important;
    margin-bottom: 24px;
}

.custom-info .order-customer .customer-info .info span {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.custom-info .order-customer .customer-info .info span img {
    margin-right: 8px;
}

.custom-info .order-customer .customer-info .info span:last-child {
    margin-bottom: 0px !important;
}

.custom-info .order-customer .customer-info {
    /* display: flex;
    justify-content: space-between; */
}

.create-order-field-right .order-custom-field {
    padding: 10px 24px !important;
}

.custom-info .order-customer .customer-info.draft-order {
    display: block !important;
}

.custom-dropdown-staff {
    min-width: auto !important;
    width: auto !important;
    padding-right: 30px !important;
}

.dropdown-tag-custom:hover {
    box-shadow: none !important;
}

.ladiui.btn.btn-outline-light.ellipsis {
    width: auto !important;
    padding-right: 30px;
}

.ladiui-tag-list .tag-product-item {
    background-color: #f1f3f4;
    padding: 2px 8px;
    border-radius: 6px;
    margin-right: 5px;
}

.info-customer.info-customer-shipping {
    padding: 24px !important;
}

.info-customer-shipping .shipping-info-customer {
    padding-top: 24px !important;
}

.info-customer-shipping .shipping-info-customer .order-customer .order-heading {
    padding-bottom: 24px !important;
}

.info-customer-shipping .shipping-info-customer .order-customer .customer-info {
    padding-bottom: 24px;
}

.info-customer-shipping .shipping-info-customer .order-customer .order-heading.create-customer-shipping {
    padding-top: 24px !important;
}

.info-customer-shipping .shipping-info-customer .shipping-address .customer-info {
    padding-bottom: 0px !important;
}

.ladiui.status-premium {
    width: unset !important;
}

.ladiui.menu-item .ladiui.dropdown-toggle {
    position: inherit !important;
}

#modal-billing-address .action-item .ladiui.btn-group .ladiui.dropdown-toggle:after {
    top: -3px !important;
}

.modal-shipping-address-custom .action-item .ladiui.btn-group .ladiui.dropdown-toggle:after {
    top: -3px !important;
}

.ladiui.block-item-district {
    max-height: 350px;
    overflow: auto;
    border: 1px solid #f1f3f4;
    padding: 16px 0px 16px 16px;
}

.ladiui-product-right.event-custom .group-date .date-item {
    width: 50% !important;
}

.package-content-info ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0px;
    border-bottom: 1px solid #f1f3f4;
}

.ladiui.btn.btn-primary[disabled] {
    opacity: 0.65 !important;
    background-color: var(--main-primary) !important;
}

.ladiui.btn.btn-outline-primary[disabled] {
    opacity: 0.65 !important;
    border: 1px solid var(--main-primary) !important;
}

.ladiui.btn.btn-outline-light[disabled] {
    opacity: 0.65 !important;
    border: 1px solid var(--main-primary) !important;
    color: var(--main-primary);
}

.block-adjustBatch .list .item {
    padding: 10px;
    border-bottom: 1px solid #f1f3f4;
}

.block-adjustBatch .list .item:last-child {
    border-bottom: unset;
}

.block-help-ladisales-top li a i {
    width: 14px !important;
}

.ladiui.block-avatar.custom {
    margin-right: 12px;
}

.ladiui.show-avata-name {
    border: 2px solid var(--main-primary);
    border-radius: 50%;
    height: 40px;
    width: 40px;
}

.ladiui.show-text-avata {
    align-items: center;
    color: var(--main-white);
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: 500;
    height: 36px;
    justify-content: center;
}

.ladiui.role-text {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.25px;
    line-height: 16px;
}

.ladiui.show-avata-name.custom {
    border: none !important;
}

.block-content-product-tag .list .list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 18px 12px 0px;
}

.block-content-product-tag .list .list-item:first-child {
    padding-top: 0px;
}

.block-content-product-tag .list {
    max-height: 350px;
    overflow-y: auto;
}

.ladiui.account-body {
    max-height: 200px;
    overflow: auto;
}

#tab-terms-of-use .form-group .ladiui-label {
    color: var(--main-secondary);
}

#tab-privacy-policy .form-group .ladiui-label {
    color: var(--main-secondary);
}

#tab-refund-policy .form-group .ladiui-label {
    color: var(--main-secondary);
}

.ladiui.form-control {
    padding: 7px 12px;
}

.action-record-table {
    position: absolute;
    top: 5px;
    z-index: 10;
    /* z-index: 999; */
}

.table-custom thead th {
    padding: 12px 18px !important;
}

.table-custom tbody td {
    padding: 12px 18px !important;
}

.ladiui.form-check-input-checkbox.vertical-middle {
    vertical-align: middle;
}

.ladiui-tag-list .tag .close-tag-icon {
    display: flex;
    cursor: pointer;
}

.ladiui.table-vertical.main {
    border-bottom: 1px solid var(--secondary-color);
}

/* .ladiui.table-vertical.main:last-child {
    border-bottom: none;
} */

.ladiui.table-custom .table-vertical.header th {
    white-space: nowrap;
}

.ladiui.table-vertical.main:last-child td {
    padding-bottom: 24px !important;
}

.table-custom thead tr th:first-child {
    padding-left: 0px !important;
}

.table-custom tbody tr td:first-child {
    padding-left: 0px !important;
}

.icon-close-no-bg-16 {
    background-color: var(--status-mute-color);
    width: 8px;
    height: 8px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-close-no-bg.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-close-no-bg.svg) no-repeat 50% 50%;
}

.ladiui-btn-dropdown.dropdown-toggle .ladiui.icon.icon-ldp-dot {
    vertical-align: middle;
}

.ladi-icon.icon-bin {
    background-color: var(--status-mute-color);
    width: 16px;
    height: 16px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-delete.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-delete.svg) no-repeat 50% 50%;
}

.ladi-icon.icon-bin.red-color {
    background-color: #D94F45 !important;
}

.ladi-icon.icon-qrcode {
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-qrcode.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-qrcode.svg) no-repeat 50% 50%;
}

.ladi-icon.icon-copy {
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-coppy.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-coppy.svg) no-repeat 50% 50%;
}

.icon-calendar-date-2 {
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-calendar.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-calendar.svg) no-repeat 50% 50%;
}

.icon-tag-footer-modal {
    background-color: #1c00c2;
    width: 16px;
    height: 16px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-tag.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-tag.svg) no-repeat 50% 50%;
}

.list-image-product .list-image .item-image:hover .ladi-icon.icon-bin {
    background-color: var(--main-white);
}

.ladiui.table-vertical.custom-tag td {
    padding-top: 0px !important;
}

.tag-color .ladiui-tags {
    padding: 0px !important;
    min-height: auto !important;
}

.email-address-store-custom {
    overflow-wrap: anywhere;
}

#block-dropdown-tag-custom .item:first-child {
    padding-top: 10px;
}

.url-checkout .ladiui.btn-group .dropdown button span {
    width: 100%;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 0 !important;
}

.checkout-url-custom .more-info .item-tracking .show-content {
    width: 100%;
    max-width: 200px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
}

.block-content-product-tag .list .list-item .image {
    position: relative;
}

.block-content-product-tag .list .list-item .image .badge-child-light {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: -8px;
    right: initial;
    left: 63px;
}

.ladiui.my-account-avata .show-avt-name {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #fff;
    border-radius: 50%;
}

.ladiui.account-avatar.block-info .show-avt-name {
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #fff;
    border-radius: 50%;
}

.block-shipping-address.checkout .order-customer {
    padding-bottom: 0px !important;
}

.ladiui.account-header-text .account-email.subtitle-2 {
    overflow-wrap: anywhere !important;
}

.block-product-content-left .list-image-product .image-select {
    position: relative;
}

.block-product-content-left .list-image-product .image-select .block-delete-img {
    position: absolute;
    background-color: rgba(32, 32, 32, 0.7);
    top: 8px;
    padding: 3px 5px;
    display: none;
    border-radius: 2px;
    right: 8px;
}

.block-product-content-left .list-image-product .image-select:hover .block-delete-img {
    display: block;
}

.block-product-content-left .list-image-product .image-select:hover .ladi-icon.icon-bin {
    background-color: var(--main-white);
}

.ladiui.modal-body {
    max-height: calc(100vh - 300px);
    overflow: auto;
}

#modal-event .input-upload-file {
    display: block;
    margin-bottom: 24px;
    clear: both;
}



/* -----------------Icon CTA---------------------- */
.cta-add {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-add.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-add.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
}

.cta-calendar {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-calendar.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-calendar.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
}

.cta-cart {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-shopping-cart.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-shopping-cart.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
}

.cta-add-member {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-add-member.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-add-member.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
}

.cta-download {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-import.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-import.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
}

.cta-discount-coupon {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-discount-coupon.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-discount-coupon.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;

}

.ldicon-subcategory {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-subcategori.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-subcategori.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;

}

.ldicon-discount-coupon {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-discount-coupon.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-discount-coupon.svg);
    background-color: var(--main-secondary);
    width: 24px;
    height: 24px;
    display: inline-block;
}

.ldicon-buying-time {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-buying-time.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-buying-time.svg);
    background-color: var(--main-secondary);
    width: 24px;
    height: 24px;
    display: inline-block;
}

.cta-tag {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-tag.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-tag.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
}

.icon-export {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-export.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-export.svg);
    background-color: var(--main-primary);
    width: 16px;
    height: 16px;
    display: block;
}

#modal-create-version-digital .block-upload-file {
    padding: 16px;
    border: 1px solid #EEE;
    border-radius: 2px;
    margin-bottom: 24px;
}

#modal-create-version-digital .block-upload-file .ladiui-button-upload {
    display: flex;
    cursor: pointer;
    margin-left: 24px;
}

#modal-create-version-digital .block-upload-file .ladiui-button-upload button {
    padding: 4px;
    margin: 4px !important;
    display: flex;
    width: 80px;
    justify-content: center;
    background: transparent;
    border: 1px solid var(--main-primary);
    color: var(--main-primary);
    cursor: pointer;
}

#modal-create-version-digital .block-upload-file .line-bar {
    border-bottom: 1px solid #eee;
    margin: 24px 0;
    position: relative;
}

#modal-create-version-digital .block-upload-file .line-bar span {
    position: absolute;
    top: -9px;
    left: 0;
    padding-right: 16px;
    background-color: #fff;
}

#modal-create-version-digital .block-selected-files {
    border: 1px solid #eee;
}

#modal-create-version-digital .block-selected-files .title {
    padding: 12px 16px;
    background-color: #eee;
}

#modal-create-version-digital .block-selected-files .list-files {
    padding: 16px;
}

#modal-create-version-digital .block-selected-files .list-files ul .file-item {
    margin-top: 16px;
}

#modal-create-version-digital .block-selected-files .list-files ul .file-item:first-child {
    margin-top: 0px;
}

#modal-create-version-digital .request-format-file {
    display: block;
    font-size: 12px;
    line-height: 16px;
    color: var(--main-secondary);
}

input[type="file"] {
    cursor: pointer;
}

#modal-create-version-digital .input-upload-file .limit-text-upload {
    padding-right: 100px;
    display: block;
    text-overflow: ellipsis;
    overflow-wrap: anywhere;
}

#modal-create-version-digital .input-upload-file .limit-text-link {
    display: block;
    text-overflow: ellipsis;
    overflow-wrap: anywhere;
}


.block-seo-social .input-upload-file .ladiui-button-upload {
    background-color: transparent !important;
}

.ghn-form .list-options .ladiui.form-group .item {
    margin-bottom: 8px;
}

.ghn-form .list-options .ladiui.form-group .item:last-child {
    margin-bottom: 0;
}

.ghn-form .block-required-note {
    margin-bottom: 24px;
}

.ghn-form .block-required-note .item {
    margin-bottom: 8px;
}

.ghn-form .block-required-note .item:last-child {
    margin-bottom: 0px;
}

.ldicon-chevron-left {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-chevron-left.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-chevron-left.svg);
    background-color: var(--main-default);
    width: 12px;
    height: 12px;
    display: inline-block;
}

.ldicon-chevron-right {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-chevron-right.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-chevron-right.svg);
    background-color: var(--main-default);
    width: 12px;
    height: 12px;
    display: inline-block;
}

.ldicon-forward {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-forward.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-forward.svg);
    background-color: var(--main-default);
    width: 12px;
    height: 12px;
    display: inline-block;
}

.ldicon-backward {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-backward.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-backward.svg);
    background-color: var(--main-default);
    width: 12px;
    height: 12px;
    display: inline-block;
}

.ldicon-link {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-link.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-link.svg);
    background-color: var(--main-default);
    width: 12px;
    height: 12px;
    display: inline-block;
}

.ldicon-delete {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-delete.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-delete.svg);
    background-color: var(--main-default);
    width: 12px;
    height: 12px;
    display: inline-block;
}

.ldicon-save {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-save.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-save.svg);
    background-color: var(--main-default);
    width: 12px;
    height: 12px;
    display: inline-block;
}

.ldicon-image {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-image.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-image.svg);
    background-color: var(--main-secondary);
    width: 24px;
    height: 24px;
    display: inline-block;
}

.ldicon-menu {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-menu.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-menu.svg);
    background-color: var(--main-secondary);
    width: 24px;
    height: 24px;
    display: inline-block;
}

.ldicon-user {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-user.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-user.svg);
    background-color: var(--main-secondary);
    width: 24px;
    height: 24px;
    display: inline-block;
}

.ldicon-circle-check {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-circle-check.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-circle-check.svg);
    background-color: var(--main-secondary);
    width: 24px;
    height: 24px;
    display: inline-block;
}

#modal-tag-edit .ladiui .modal-content {
    animation: none !important;
}

.ladiui .dropdown-input-search-custom {
    position: sticky;
    padding: 16px 16px 8px 16px;
    top: 0;
    z-index: 999999;
    margin: 0;
    background-color: #fff;
    border-bottom: 1px solid #eee;

}

.ladiui .dropdown-input-search-custom input {
    padding-left: 40px !important;
    background-color: #eee;
}

.ldicon-search {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-search.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-search.svg);
    background-color: var(--main-default);
    width: 16px;
    height: 16px;
    display: inline-block;
}

.ladiui .dropdown-input-search-custom i {
    position: absolute;
    bottom: 18px;
    left: 30px;
}

.ladiui.dropdown-menu.custom-form-dynamic {
    padding: 0 !important
}

.ladiui.dropdown-menu.custom-form-dynamic .ladiui.dropdown-item:first-child {
    padding-top: 10px !important;
}

.icon-page-checkout-ladisales {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-brand-ladisales.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-brand-ladisales.svg);
    background-color: var(--main-default);
    width: 28px;
    height: 28px;
    display: inline-block;
}

.icon-other-page-checkout {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-integration.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-integration.svg);
    background-color: var(--main-default);
    width: 28px;
    height: 28px;
    display: inline-block;
}

.ladiui .block-new-page-checkout li .title-description h3 {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-default);
}

.ladiui .block-new-page-checkout li .title-description span {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--main-secondary);
}

#modal-info-ticket-seat .ladiui.form-group .ladiui-label {
    width: 30%;
    float: left;
}

#modal-info-ticket-seat .ladiui.form-group .status-checkin-ticket {
    padding: 2px 8px;
    border-radius: 2px;
    color: #fff;
}

.ladiui.modal {
    overflow-x: auto !important;
}

.sdk-script-content {
    height: 70px;
    background-color: var(--main-line);
    margin-bottom: 20px;
    width: 100%;
    border-radius: 5px;
    padding: 13px;
    font-size: 13px;
    color: var(--main-default);
}

#modal-discount .block-preview-discount {
    padding: 32px;
    border: 1px solid #eee;
    border-radius: 2px;
}

#modal-discount .block-preview-discount h3 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 16px;
    color: var(--main-black);
}

#modal-discount .block-preview-discount .block-overview .block-item {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 8px;
}

#modal-discount .block-preview-discount .block-overview .block-item label {
    padding-bottom: 0px !important;
    max-width: 130px;
    width: 100%;
    line-height: 24px;
}

#modal-discount .block-preview-discount .line-bar {
    border-bottom: 1px solid #eee;
    margin: 12px 0;
}

#modal-discount .promotion-conditions-apply {
    display: inline-flex;
    margin-top: 24px;
    width: 100%;
    justify-content: space-between;
}

#modal-discount .promotion-conditions-apply .item-condition {
    width: 100%;
    max-width: 250px;
}

.page-checkin-content {
    background-color: #e5e5e5;
}

.page-checkin-content .block-header-checkin {
    background-color: #fff;
    padding: 10px;
}

.page-checkin-content .block-header-checkin .content-header {
    width: 980px;
    margin: 0px auto;
    display: flex;
    align-items: center;
    padding: 0px 24px;
}

.page-checkin-content .block-header-checkin .qr-code {
    width: 32px;
    height: 32px;
    cursor: pointer;
}

.page-checkin-content .block-header-checkin .qr-code img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
}

.page-checkin-content .block-guest-list .content {
    width: 980px;
    margin: 0px auto;
}

.page-checkin-content .block-guest-list .content h3 {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    margin: 30px 0px;
    line-height: 28px;
    padding: 0px 24px;
    color: var(--main-black);
}

.page-checkin-content .block-guest-list .content .list-guest {
    background-color: #fff;
    padding: 24px;
}

.page-checkin-content .block-guest-list .content .list-guest .title {
    color: var(--main-secondary);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    display: block;
    margin-bottom: 24px;
}

.page-checkin-content .block-guest-list .content .list-guest .list {
    display: block;
    width: 100%;
}

.page-checkin-content .block-guest-list .content .list-guest .list .list-guest-item {
    display: flex;
    align-items: center;
    padding: 12px 0px;
    border-bottom: 1px solid #f1f3f4;
}

.page-checkin-content .block-guest-list .content .list-guest .list .list-guest-item:first-child {
    padding-top: 0px !important;
}

.list-guest .list .list-guest-item .block-icon-check {
    display: flex;
    margin-right: 24px;
    align-items: center;
    justify-content: center;
}

.list-guest .list .list-guest-item .block-icon-check .icon-circle-check {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-circle-check.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-circle-check.svg);
    background-color: var(--main-secondary);
    width: 35px;
    height: 35px;
    display: inline-block;
}

.list-guest .list .list-guest-item .block-icon-check .icon-circle-check.checked-in {
    background-color: #41B983;
}

.list-guest .list .list-guest-item .info {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.list-guest .list .list-guest-item .info .info-item {
    display: block;
    width: 100%;
    margin-bottom: 8px;
}

.block-guest-list .powered-by .ladi-icon.icon-ladisales {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-colored-brand-ladisales.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-colored-brand-ladisales.svg);
    background-color: var(--main-default);
    width: 20px;
    height: 20px;
    display: inline-block;
}

.block-guest-list .powered-by span {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    margin-bottom: 24px;
}

.page-checkin-info {
    max-width: 375px;
    padding: 16px;
    width: 100%;
    margin: 0px auto;
    background-color: #e5e5e5;
}

.list-guest .list .list-guest-item .info .block-icon-check {
    display: none;
}

.list-guest .list .list-guest-item .info .block-group-btn-checkin {
    display: block;
}

.list-guest .list .list-guest-item .block-group-btn-checkin {
    display: none;
}

@media screen and (max-width: 768px) {
    .page-checkin-content .block-guest-list .content {
        width: auto;
    }

    .page-checkin-content .block-header-checkin .content-header {
        width: auto;
    }

    .page-checkin-info {
        max-width: 100%;
    }

    .list-guest .list .list-guest-item .block-icon-check {
        display: none;
    }

    .list-guest .list .list-guest-item .info .block-icon-check {
        display: block;
        margin-right: 0px;
    }

    .list-guest .list .list-guest-item .info .block-group-btn-checkin {
        display: none;
    }

    .list-guest .list .list-guest-item {
        flex-direction: column;
    }

    .list-guest .list .list-guest-item .block-group-btn-checkin {
        display: block;
        width: 100%;
        margin-top: 16px;
    }

    .list-guest .list .list-guest-item .block-group-btn-checkin .btn-checkin button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .list-guest .list .list-guest-item .block-group-btn-checkin .group-btn-checkin {
        justify-content: space-between;
    }

    .page-checkin-content .block-guest-list .content .list-guest .pagination-checkin {
        flex-direction: column-reverse;
    }

    .page-checkin-content .block-guest-list .content .list-guest .pagination-checkin .ladiui.pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 16px;
    }

    .page-checkin-content .block-guest-list .content .list-guest .ladiui.search-action {
        align-items: unset;
    }
}

.page-checkin-info .block-actions-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.page-checkin-info .block-actions-top button {
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: transparent;
}

.page-checkin-info .block-info-checkin {
    margin-top: 16px;
    background-color: #fff;
    padding: 16px;
    border-radius: 2px;
}

.page-checkin-info .block-info-checkin .title {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    color: var(--main-black);
    text-align: center;
}

.page-checkin-info .block-info-checkin .info {
    display: block;
    width: 100%;
}

.page-checkin-info .block-info-checkin .info .item {
    display: block;
    width: 100%;
    margin-bottom: 16px;
    font-size: 14px;
    color: var(--main-secondary);
}

.page-checkin-info .block-info-checkin .info .item:first-child {
    margin-top: 16px;
}

.page-checkin-info .block-info-checkin .info .item strong {
    font-size: 14px;
    font-weight: 500;
    color: var(--main-black);
}

.page-checkin-info .block-info-checkin .info .item .status {
    padding: 2px 8px;
    border-radius: 2px;
}

.page-checkin-info .block-info-checkin .info .item .status.checkin {
    background-color: #E89D35;
    color: #fff;
}

.page-checkin-info .block-info-checkin .info .item .status.checked-in {
    background-color: #41B983;
    color: #fff;
}

.page-checkin-info .block-info-checkin .btn-checkin {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
}

.page-checkin-info .block-history-checkin {
    margin-top: 24px;
    background-color: #fff;
    padding: 16px;
    border-radius: 2px;
}

.page-checkin-info .block-history-checkin .title {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    color: var(--main-black);
    text-align: center;
}

.page-checkin-info .block-history-checkin .list-history {
    display: block;
    max-height: 200px;
    overflow: auto;
}

.page-checkin-info .block-history-checkin .list-history .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
}

.page-checkin-info .powered-by span {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    margin-bottom: 24px;
}

.page-checkin-info .block-info-checkin .actions-checkin-cancel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;
}

.page-checkin-info .block-info-checkin .actions-checkin-cancel .cancel-checkin {
    color: #D94F45;
    cursor: pointer;
}

#block-custom-btn-checkout {
    display: block;
    max-width: 900px;
}

#block-custom-btn-checkout .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.block-custom-html-selector {
    display: block;
    max-width: 900px;
}

.block-custom-html-selector .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

#block-content-product-course .list-chapters {
    display: block;
}

#block-content-product-course .list-chapters .chapter-list-item {
    border: 1px solid var(--main-line);
    border-radius: 2px;
    margin-bottom: 24px;
}

#block-content-product-course .list-chapters .chapter-list-item .title-chapter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    background-color: var(--main-line);
}

#block-content-product-course .list-chapters .chapter-list-item .title-chapter .icon-edit-chapter {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-edit.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-edit.svg);
    background-color: var(--main-default);
    width: 16px;
    height: 16px;
    display: none;
    cursor: pointer;
}

#block-content-product-course .list-chapters .chapter-list-item .title-chapter:hover .icon-edit-chapter {
    display: inline-block;
}

#block-content-product-course .list-chapters .chapter-list-item .add-lesson .btn-add-lesson {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0px;
    font-weight: 500;
    font-size: 14px;
    color: var(--main-secondary);
    cursor: pointer;
}

#block-content-product-course .list-chapters .chapter-list-item .list-lesson {
    display: block;
}

#block-content-product-course .list-chapters .chapter-list-item .list-lesson .lesson-item {
    display: flex;
    align-items: center;
    padding: 12px 16px 12px 24px;
    justify-content: space-between;
    border-bottom: 1px solid var(--main-line);
}

#block-content-product-course .list-chapters .chapter-list-item .list-lesson .lesson-item.selected {
    color: #1c00c2;
}

#block-content-product-course .list-chapters .chapter-list-item .list-lesson .lesson-item.selected .ldicon-lesson-post {
    background-color: #1c00c2;
}

#block-content-product-course .list-chapters .chapter-list-item .list-lesson .lesson-item.selected .ldicon-lesson-video {
    background-color: #1c00c2;
}

#block-content-product-course .ladiui-product-course-right .choose-content {
    padding: 50px 0px;
    border-radius: 2px;
    border: 1px solid #eee;
    margin-top: 24px;
}

#block-content-product-course .ladiui-product-course-right .choose-content .title {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: var(--main-secondary);
}

.ladiui-product-course-right .choose-content .list-box-content .box-content {
    width: 100px;
    height: 100px;
    border: 1px solid var(--main-line);
    box-shadow: 0px 1px 4px rgba(186, 186, 186, 0.3);
    border-radius: 4px;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.ladiui-product-course-right .choose-content .list-box-content .box-content:hover {
    border: 1px solid var(--main-primary);
}

.ladiui-product-course-right .choose-content .list-box-content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

/* .ladiui-product-course-right .block-add-content .choose-content .list-box-content .box-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
} */
.ladiui-product-course-right .choose-content .list-box-content .box-content .box-name {
    margin-top: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--main-secondary);
}

.ladiui-sub-title {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: var(--main-secondary);
    margin-bottom: 8px;
    display: block;
}

#block-content-product-course .ladiui-product-course-right .block-content-video {
    display: block;
}

#block-content-product-course .ladiui-product-course-right .block-content-video .show-link-video {
    padding: 8px 12px;
    background-color: #e5e5e5;
    overflow: hidden;
}
#block-content-product-course .ladiui-product-course-right .block-content-video .show-link-video span {
    display: block;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 8px;
}

#tabProductCourseEdit .ladiui-product-course-right .thumbnail {
    width: 253px;
    height: 100%;
    max-height: 180px;
    border-radius: 2px;
    position: relative;
}

#tabProductCourseEdit .ladiui-product-course-right .block-content-video .group-input {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    width: calc(100% - 277px);
}

#tabProductCourseEdit .ladiui-product-course-left .thumbnail {
    width: 100%;
    height: 316px;
    border-radius: 2px;
    position: relative;
    background-color: #f1f3f4;
}

#tabProductCourseEdit .ladiui-product-course-right .image {
    width: 100%;
    object-fit: cover;
    height: 100%;
    opacity: 1;
    display: block;
    transition: .5s ease;
    backface-visibility: hidden;
}

#tabProductCourseEdit .ladiui-product-course-left .image {
    width: 100%;
    height: 100%;
    opacity: 1;
    display: block;
    transition: .5s ease;
    backface-visibility: hidden;
}

#tabProductCourseEdit .thumbnail .block-upload-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    color: #fff;
    cursor: pointer;
}

#tabProductCourseEdit .thumbnail:hover .block-upload-image {
    display: block;
    background: rgba(0, 0, 0, 0.6);
}

#tabProductCourseEdit .thumbnail:hover .block-upload-image .block-overlay {
    margin-top: 60px;
    text-align: center;
}

#tabProductCourseEdit .ladiui-product-course-left .thumbnail:hover .block-upload-image .block-overlay {
    margin-top: 130px;
    text-align: center;
}

#tabProductCourseEdit .thumbnail:hover .block-upload-image .block-overlay .text {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

#tabProductCourseEdit .thumbnail:hover .block-upload-image .block-overlay .ldicon-upload {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-upload.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-upload.svg);
    background-color: var(--main-white);
    width: 16px;
    height: 16px;
    display: inline-block;
}

.ldicon-stack {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-stack.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-stack.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
    display: inline-block;
}

.ldicon-file {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-new-file.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-new-file.svg);
    background-color: var(--main-secondary);
    width: 12px;
    height: 12px;
    display: inline-block;
}

.block-list-path {
    padding: 16px 0px;
    border-top: 1px solid #f1f3f4;
    margin-top: 24px;
}

.block-list-path .path-item {
    margin-bottom: 12px;
}

.block-list-path .path-item .path-name {
    margin: 0;
    max-width: 100%;
    line-height: 22px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.ldicon-lesson-post {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-new-file.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-new-file.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
    display: inline-block;
}

.ldicon-lesson-video {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-video.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-video.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
    display: inline-block;
}

#modal-create-category .block-upload-image-category {
    display: block;
    width: 100%;
    height: 180px;
    background-color: var(--main-line);
    border-radius: 2px;
    cursor: pointer;
    position: relative;
}

#modal-create-category .block-upload-image-category .block-upload-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    color: #fff;
    cursor: pointer;
}

#modal-create-category .block-upload-image-category .image {
    width: 100%;
    object-fit: cover;
    height: 100%;
    opacity: 1;
    display: block;
    transition: .5s ease;
    backface-visibility: hidden;
}

#modal-create-category .block-upload-image-category:hover .block-upload-image {
    display: block;
    background: rgba(0, 0, 0, 0.6);
}

#modal-create-category .block-upload-image-category:hover .block-upload-image .block-overlay {
    margin-top: 45px;
    text-align: center;
}

#modal-create-category .block-upload-image-category:hover .block-upload-image .block-overlay .ldicon-upload {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-upload.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-upload.svg);
    background-color: var(--main-white);
    width: 16px;
    height: 16px;
    display: inline-block;
}

#modal-create-category .ladiui.modal-content {
    animation: none !important;
}

.setting-content-menu-banner {
    max-width: 900px;
    height: 100vh;
    background: #fff;
}

.setting-content-menu-banner .block-category-top-menu h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.setting-content-menu-banner .block-category-top-menu .sub-title {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #4d4d4d;
    padding-top: 4px;
    padding-bottom: 24px;
    display: block;
}

.config-panel .panel {
    width: 100%;
    margin-bottom: 24px;
    display: block;
}

.config-panel .panel .panel-line {
    display: flex;
    margin: 8px 0;
}

.config-panel .panel .panel-line .main-description {
    color: var(--main-black);
    width: 250px;
    padding-top: 10px;
    font-weight: 500;
}

.config-panel .panel .panel-line .content {
    width: 100%;
    text-align: left;
    padding-left: 10px;
}

.config-panel .panel .panel-line .content .color-picker {
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    z-index: 10;
}

.config-panel .panel .panel-line .main-description .sub-description {
    line-height: 16px;
    margin-top: 6px;
    color: var(--main-mute);
    font-size: 12px;
    font-weight: 400;
}

.setting-content-menu-banner .block-category-show-home {
    margin-top: 24px;
}

.block-category-show-home .btn-add-category button {
    font-weight: 500;
    font-size: 14px;
}

.block-category-show-home .block-list-category {
    display: block;
}

.block-list-category .category-list-item {
    border: 1px solid #eee;
    margin-bottom: 16px;
}

.block-list-category .category-list-item .block-add-featured-course {
    padding: 0px 24px 16px 24px;
}

.block-list-category .category-list-item .content-top-item {
    display: flex;
    padding: 16px 12px;
    align-items: center;
    justify-content: space-between;
    background-color: #eee;
}

.block-list-category .category-list-item .content-top-item .icon-name span {
    display: -webkit-box;
    width: 450px;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.block-list-category .category-list-item .content-top-item .ladi-icon.icon-bin {
    background-color: #d94f45;
}

.block-list-category .category-list-item .content-top-item .action-right {
    display: flex;
    align-items: center;
    column-gap: 24px;
    justify-content: space-between;
}

.setting-content-menu-banner .block-custom-interface {
    margin-top: 32px;
}

.setting-content-menu-banner .block-custom-interface .title h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.setting-content-menu-banner .block-custom-interface .title .sub-title {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    padding-top: 4px;
    color: #6d6d6d;
}

#modal-interactive-chanel .block-list-chanels .title {
    font-weight: 500;
    font-size: 14px;

}

.setting-content-domain-course .content {
    max-width: 900px;
}

.setting-content-domain-course .content .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--main-secondary);
}

.setting-content-domain-course .content .block-seo-social {
    max-width: 900px !important;
}

.setting-content-domain-course .content .block-seo-social h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--main-secondary);
}

.setting-content-domain-course .ladiui.form-group .link-default {
    padding: 8px 12px;
    border: 1px solid #e5e5e5;
    border-radius: 2px;
}

/* .setting-content-domain-course .preview-store-logo #store-logo {
    position: relative;
    width: 100%;
    height: 64px;
    max-width: 106px;
    overflow: hidden;
}
.setting-content-domain-course .preview-store-logo #store-logo .store-logo {
    width: 100%;
    height: 100%;
    border-radius: 8px;
} */

.page-course-welcome .content {
    padding: 100px 40px;
    display: flex;
    align-items: center;
}

.page-course-welcome .content .left-content {
    width: 50%;
}

.page-course-welcome .content .left-content .title {
    font-weight: 400;
    font-size: 28px;
    line-height: 26px;
    color: var(--main-black);
}

.page-course-welcome .content .left-content .block-list-functions {
    display: block;
    margin-top: 24px;
    margin-bottom: 34px;
}

.page-course-welcome .content .left-content .block-list-functions li {
    list-style-type: none;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.page-course-welcome .content .left-content .block-list-functions li:last-child {
    margin-bottom: 0;
}

.page-course-welcome .content .left-content .block-list-functions li span {
    font-weight: 400;
    font-size: 14px;
    color: var(--main-black);
    line-height: 16px;
}

.page-course-welcome .content .left-content .block-list-functions li .ldicon-circle-check {
    background-color: #4AC000;
}

.page-course-welcome .content .right-content {
    width: 50%;
}

.page-course-welcome .content .right-content .block-image img {
    width: 100%;
    object-fit: cover;
}

.block-suggestion-category h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.block-suggestion-category .sub-title {
    font-weight: 400;
    font-size: 12px;
    color: #4d4d4d;
    display: block;
    padding-top: 4px;
    padding-bottom: 24px;
}

.setting-content-domain-course .preview-store-logo #store-logo {
    width: max-content;
}

.setting-content-domain-course .preview-store-logo #store-logo .store-logo {
    width: 64px;
    height: 64px;
    border-radius: 2px;
}

.setting-content-domain-course .preview-store-logo #store-logo:hover #store-logo-overlay {
    width: 64px;
    height: 64px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.setting-content-domain-course .preview-store-logo #store-logo #store-logo-overlay .icon {
    margin-left: 0px;
}

.block-content-product-course .ladiui-product-course-left .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #4D4D4D;
    margin-bottom: 24px;
}

.block-content-product-course .ladiui-product-course-right .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #4D4D4D;
}

.dropdown-custom-lds .ladiui.dropdown-text {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
}

#dropdown-domain-custom {
    width: calc(100% - 48px) !important;
}

#dropdown-active-chanel-custom {
    width: calc(100% - 48px) !important;
}

#dropdown-confirm-payment {
    width: calc(100% - 48px) !important;
}


.ladiui.table-vertical.main {
    min-height: 57px;
    border-bottom: 1px solid var(--secondary-color);
}

.ladiui.table-vertical.category-item {
    position: relative;
    display: flex;
    align-items: center;
}


.ladiui.drag-vertical.category {
    align-items: center;
    display: flex;
    width: 100%;
    height: 56px;
}
.ladiui.drag-vertical .ladiui.col-first {
    font-weight: 500;
    width: 3%;
}
.ladiui.drag-vertical .ladiui.col-name {
    font-weight: 500;
    width: 40%;
}
.ladiui.drag-vertical .ladiui.col-five {
    font-weight: 500;
    width: 24%;
}

.ladiui.table-vertical.category-item {
    position: relative;
    display: flex;
    align-items: center;
}

.ladiui.table-vertical.category-item .category-name {
    margin-top: 4px;
    width: 42%;
}

.ladiui.table-vertical.category-item .category-name a {
    overflow: hidden;
    padding-right: 12px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
}

.ladiui.table-vertical.category-item .td-draghandle {
    width: 24px;
    display: flex;
}

.ladiui.category-count {
    width: 24%;
}

.category-item .ladiui.end-table-option {
    position: absolute;
    right: 24px;
}

.ladiui.end-table-option {
    width: 33px;
}
.ladiui.table-vertical.category-item:hover .td-draghandle .icon-draggable {
    display: block !important;
}
.ladiui.table-vertical.category-item:hover .td-draghandle .ldicon-subcategory {
    display: none !important;
}
.block-seo-social .panel .preview-store-logo #store-logo .store-logo {
    width: 64px;
    height: 64px;
    border-radius: 2px;
}
.block-seo-social .panel .preview-store-logo #store-logo:hover #store-logo-overlay {
    width: 64px;
    height: 64px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.block-seo-social .panel .preview-store-logo #store-logo #store-logo-overlay .icon {
    margin-left: 0px;
}
.block-seo-social .panel .preview-store-logo #store-logo {
    width: max-content;
}

#modal-add-video .list-videos {
    display: block;
}
#modal-add-video .list-videos .list-video-item {
    margin-bottom: 16px;
}
#modal-add-video .list-videos .list-video-item label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
#modal-add-video .list-videos .list-video-item:last-child {
    margin-bottom: 0px;
}
#modal-add-video .list-videos .list-video-item .video-info {
    display: flex;
    align-items: center;
}
#modal-add-video .list-videos .list-video-item .video-info img {
    width: 80px;
    height: auto;
    object-fit: cover;
    border-radius: 2px;
}
#modal-add-video .list-videos .list-video-item .video-info span {
    margin-left: 16px;
    margin-right: 16px;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
#modal-add-video .list-videos .list-video-item .date-time {
    display: flex;
    align-items: center;
}
#modal-add-video .list-videos .list-video-item .date-time span {
    display: block;
    white-space: nowrap;
}
#modal-add-video .list-videos .list-video-item .date-time .time-video {
    margin-right: 24px;
}
#modal-add-video .ladiui.modal-footer-custom {
    padding: 0px 24px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#modal-add-video .ladiui.modal-footer-custom .btn-upload-video {
    display: flex;
    cursor: pointer;
    align-items: center;
}
#modal-add-video .ladiui.modal-footer-custom span {
    font-weight: 500;
    color: var(--main-primary);
}
#modal-add-video .ladiui.modal-footer-custom .ldicon-upload{
    -webkit-mask-size: cover;
mask-size: cover;
-webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-upload.svg);
mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-upload.svg);
background-color: var(--main-primary);
width: 16px;
height: 16px;
display: inline-block;
}
.ldicon-status-success {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #4AC000;
}
.ldicon-status-error {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #E01A1A;
}
.position-initial {
    position: initial!important;
}
#dropdown-customer-field {
    width: calc(100% - 48px)!important;
}
#dropdown-choose-customer {
    width: calc(100% - 48px) !important;
}
.limit-text-vertical {
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
}
#modal-add-category-child .block-list-cates ul {
    padding: 16px;
    border-radius: 2px;
    border: 1px solid #e5e5e5;
    max-height: 350px;
    overflow: scroll;
}
#modal-add-category-child .block-list-cates ul li {
    margin-bottom: 16px;
    cursor: pointer;
}
#modal-add-category-child .block-list-cates ul li:last-child {
    margin-bottom: 0;
}
#modal-preview-video-bunny .block-preview-video .block-progress-video {
    display: block;
    width: 100%;
    background-color: #4d4d4d;
    padding: 64px;
}
#modal-preview-video-bunny .block-preview-video .block-progress-video .content {
    padding: 24px;
    background: rgba(0, 0, 0, 0.3);
    color: #fff;
    border-radius: 2px;
    text-align: center;
}
#modal-preview-video-bunny .block-preview-video .block-progress-video .content h3 {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 16px;
}
#modal-preview-video-bunny .block-preview-video .block-progress-video .content p {
    font-weight: 400;
    font-size: 11px;
}